import React, { FC } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router";
import arrowdownIcon from "../../common/images/ico-arrowdown.svg";

const InventoryHeaderComponent: FC = () => {
    let history = useHistory();
    let { id }: any = useParams();

    return (<div className="row px-0 mx-0 title-bar d-flex flex-nowrap justify-content-between align-items-center">
        <div className="col-sm-12 d-flex flex-no-wrap px-4 justify-content-between align-items-center">
            <div className="page-title d-flex flex-nowrap align-items-center">
                <div className="step-back mr-3 p-2" onClick={() => history.goBack()}><img src={arrowdownIcon} alt="Wstecz" /></div>
                <h2 id="page-title" className="m-0">{id ? "Edytuj inwentaryzację" : "Dodaj nową inwentaryzację"}</h2>
            </div>
        </div>
    </div>);
}

export default InventoryHeaderComponent;