import React, { FC } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Acl from "../../application/services/acl.service";
import arrowdownIcon from "../../common/images/ico-arrowdown.svg";
import editIcon from "../../common/images/ico-edit-full.svg";
import deleteIcon from "../../common/images/ico-xred.svg";

const ProductDetailsHeaderComponent: FC = () => {
  let history = useHistory();
  const { id }: any = useParams();

  return (
    <div className="row px-0 mx-0 title-bar d-flex flex-nowrap justify-content-between align-items-center">
      <div className="col-sm-12 d-flex flex-no-wrap px-4 justify-content-between align-items-center">
        <div className="page-title d-flex flex-nowrap align-items-center">
          <div className="step-back mr-3 p-2" onClick={() => history.goBack()}>
            <img src={arrowdownIcon} alt="Wstecz" />
          </div>
          <h2 id="page-title" className="m-0">
            Szczegóły produktu
          </h2>
        </div>
        {Acl.getIsAdmin() && (
          <div className="d-flex flex-no-wrap  justify-content-end align-items-center">
            <Link type="button" className="btn btn-expand btn-light ml-2" to={`/product/${id}`}>
              <img src={editIcon} alt="Edytuj" />
              <div>Edytuj</div>
            </Link>
            <a
              type="button"
              className="btn btn-expand btn-light ml-2"
              data-toggle="modal"
              href="#product-delete"
            >
              <img src={deleteIcon} alt="Usuń" />
              <div>Usuń</div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetailsHeaderComponent;
