import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { ProductWarehouseStateDetailsPropsInterface } from "../types/product.types";

const ProductWarehouseStateDetailsComponent: FC<ProductWarehouseStateDetailsPropsInterface> = ({
  productWarehouseState,
  availableUnits,
}: ProductWarehouseStateDetailsPropsInterface) => {
  const [warehouseSum, setWarehouseSum] = useState([]);

  useEffect(() => {
    const calculateWarehouseSum = () => {
      let tmpWarehouseSum: any = [];

      productWarehouseState.forEach((warehouseState: any) => {
        let index = _.findIndex(
          tmpWarehouseSum,
          (el: any) => el.warehouseId === _.get(warehouseState, "warehouse.warehouseId")
        );

        if (index > -1)
          tmpWarehouseSum[index].productAmount += _.get(warehouseState, "product.amount");
        else
          tmpWarehouseSum.push({
            warehouseId: _.get(warehouseState, "warehouse.warehouseId"),
            warehouseName: _.get(warehouseState, "warehouse.name"),
            productAmount: _.get(warehouseState, "product.amount"),
            unit:
              _.get(warehouseState, "product.unit") &&
              _.size(availableUnits) > 0 &&
              _.find(availableUnits, { value: warehouseState.product.unit }) &&
              _.find(availableUnits, { value: warehouseState.product.unit }).label,
          });
      });

      setWarehouseSum(tmpWarehouseSum);
    };

    if (_.size(productWarehouseState) > 0) calculateWarehouseSum();
  }, [productWarehouseState, availableUnits]);
  return (
    <div
      className="tab-pane fade show active"
      id="tab1"
      role="tabpanel"
      aria-labelledby="details-home-tab"
    >
      <div className="table table-responsive my-4">
        <table id="table-warehouses" className="table table-hover mb-0">
          <thead>
            <tr className="thead-light">
              <th scope="col" className="col-md-2">
                Seria
              </th>
              <th className="col-md-3">Magazyn</th>
              <th className="col-md-3">Pozycja magazynowa</th>
              <th className="col-md-1">Ilość</th>
              <th className="col-md-1">Jednostka</th>
              <th className="col-md-2">Data ważności</th>
            </tr>
          </thead>

          <tbody>
            {_.size(productWarehouseState) > 0 ? (
              productWarehouseState.map((el: any, key: number) => {
                return (
                  <tr key={key}>
                    <td>
                      <h3 className="text-left mt-1">{_.get(el, "product.seriesNr", "-")}</h3>
                    </td>
                    <th scope="row">{_.get(el, "warehouse.name", "-")}</th>
                    <td>
                      <div className="badge badge-light p-2 border border-2 ">
                        {_.get(el, "code", "-")}
                      </div>
                    </td>
                    <td>{_.get(el, "product.amount", "-")}</td>
                    <td>
                      {_.get(el, "product.unit") &&
                        _.size(availableUnits) > 0 &&
                        _.find(availableUnits, { value: el.product.unit }) &&
                        _.find(availableUnits, { value: el.product.unit }).label}
                    </td>
                    <td>{moment(_.get(el, "product.expirationDate")).format("YYYY-MM-DD")}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="col-md-12 text-center" colSpan={6}>
                  Brak elementów
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="table table-responsive my-4">
        <table id="table-warehouses" className="table table-hover mb-0">
          <thead>
            <tr className="thead-light">
              <th className="col-md-3">Magazyn</th>
              <th className="col-md-1">Suma</th>
              <th className="col-md-1">Jednostka</th>
            </tr>
          </thead>

          <tbody>
            {_.size(warehouseSum) > 0 ? (
              warehouseSum.map((el, key) => {
                return (
                  <tr key={key}>
                    <th scope="row">{_.get(el, "warehouseName")}</th>
                    <td>{_.get(el, "productAmount")}</td>
                    <td>{_.get(el, "unit")}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="col-md-12 text-center" colSpan={3}>
                  Brak elementów
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductWarehouseStateDetailsComponent;
