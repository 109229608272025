import moment from "moment";
import { InventoriesStateInterface, InventoriesActionType } from "../types/inventory.types";

const initialState: InventoriesStateInterface = {
    inventories: [],
    total: 0,
    filters: {
        name: '',
        warehouse: '',
        completed: null,
        dateFrom: moment().startOf("month").format("MM/DD/YYYY"),
        dateTo: moment().format("MM/DD/YYYY")
    },
    params: {
        limit: 20,
        offset: 0,
        sort: null
    },
    page: 1
}

const inventoriesReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case InventoriesActionType.SET_INVENTORIES: {
            return {
                ...state,
                inventories: action.inventories,
                total: action.total
            }
        }
        case InventoriesActionType.CLEAR_INVENTORIES: {
            return {
                ...state,
                inventories: [],
                total: 0
            }
        }
        case InventoriesActionType.SET_FILTERS: {
            return {
                ...state,
                filters: action.filters
            }
        }
        case InventoriesActionType.CLEAR_FILTERS: {
            return {
                ...state,
                filters: {
                    name: '',
                    warehouse: '',
                    completed: null,
                    dateFrom: moment().startOf("month").format("MM/DD/YYYY"),
                    dateTo: moment().format("MM/DD/YYYY")
                }
            }
        }
        case InventoriesActionType.SET_PARAMS: {
            return {
                ...state,
                params: action.params
            }
        }
        case InventoriesActionType.CLEAR_PARAMS: {
            return {
                ...state,
                params: {
                    limit: 20,
                    offset: 0,
                    sort: null
                }
            }
        }
        case InventoriesActionType.SET_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        default: return state;
    }
}

export default inventoriesReducer;