import React, { FC, Fragment } from "react";
import { DocumentsPropsInterface } from "../types/document.types";
import ListComponent from "../../common/components/list.component";
import DocumentsListCardComponent from "./documentsListCard.component";
import DocumentsListFiltersComponent from "./documentsListFilters.components";
import DocumentsListHeaderComponent from "./documentsListHeader.component";
import DocumentsListStatusFiltersComponent from "./documentsListStatusFilters.component";

const DocumentsListComponent: FC<DocumentsPropsInterface> = ({ documents, total, params, setFilters, clearFilters, setPage, setParams, filters, page }: DocumentsPropsInterface) => {

    const getColumns = () => {
        return [
            {
                key: "documentNumber",
                label: "Numer dokumentu"
            },
            {
                key: "contractor.name",
                label: "Kontrahent"
            },
            {
                key: "description",
                label: "Uwagi",
                disableSort: true
            },
            {
                key: "completion.status",
                label: "Status"
            }
        ]
    }

    return (<Fragment>
        <DocumentsListHeaderComponent filters={filters} onClearFilters={() => clearFilters(true)} onFiltersChange={(filters: any) => setFilters(filters, true)} />
        <div className="container mw100vw content-container ">
            <div className="row">
                <DocumentsListFiltersComponent filters={filters} onClearFilters={() => clearFilters(true)} onFiltersChange={(filters: any) => setFilters(filters, true)} />
                <div id="content-container-right" className="col-md-9 col-sm-12 scrolling py-4 pl-4 pr-2">
                    <DocumentsListStatusFiltersComponent filters={filters} onClearFilters={() => clearFilters(true)} onFiltersChange={(filters: any) => setFilters(filters, true)} />
                    <ListComponent
                        id="table-documents"
                        items={documents}
                        total={total}
                        itemsOnPage={params.limit}
                        ItemCard={DocumentsListCardComponent}
                        currentPage={page}
                        currentSort={params.sort}
                        route="/documents"
                        columns={getColumns()}
                        onPageChange={setPage}
                        onSortChange={(sortKey: string, sortValue: number) => setParams(Object.assign({}, params, { sort: { [sortKey]: sortValue } }), true)}
                    />
                </div>
            </div>
        </div></Fragment>)
}

export default DocumentsListComponent;