import React, { FC, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router";
import { GenericObject } from "../../common/types/common.types";
import { AppDispatch, RootState } from "../../config/store";
import { getProfile, clearProfile, saveProfile } from "../actions/profile.actions";
import ProfileComponent from "../components/profile.component";
import { ProfilePropsInterface } from "../types/profile.types";

const ProfileContainer: FC<ProfilePropsInterface> = (props: ProfilePropsInterface) => {
    const { getProfile, clearProfile } = props;
    let { id }: any = useParams();

    useEffect(() => {
        getProfile();
        return () => {
            clearProfile();
        }
    }, [getProfile, clearProfile, id])


    return <ProfileComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    profile: store.profile.profile
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getProfile: () => dispatch(getProfile()),
        clearProfile: () => dispatch(clearProfile()),
        saveProfile: (data: GenericObject) => saveProfile(data, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);