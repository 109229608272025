import _ from "lodash"
import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { GenericObject, UserInterface } from "../../common/types/common.types"
import { AppDispatch, GetState } from "../../config/store"
import { RolesActionType, RolesStateInterface } from "../types/role.types";
import { toast } from "react-toastify"

export const getRoles = (hideLoader?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        const { roles: { filters, params, page } } = getState();
        if (!hideLoader) {
            dispatch(isLoading(true));
        }

        let sendParams: GenericObject = {
            limit: params.limit,
            offset: (page - 1) * params.limit,
            joinUsers: true
        }

        if (_.isObject(params.sort) && !_.isEmpty(params.sort)) {
            let sortKey: string = _.keys(params.sort)[0];
            let sortValue: number = _.values(params.sort)[0];
            sendParams[`sort[${sortKey}]`] = sortValue;
        }
        if (filters.name) {
            sendParams.name = filters.name;
        }

        let res = await Api.get("/application/role", sendParams);
        if (res.success) {
            dispatch(setRoles(res.documents, res.total));
        } else {
            toast.error("Błąd pobierania roli");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania roli");
        dispatch(isLoading(false));
    }
}

export const setRoles = (roles: Array<UserInterface>, total: number): AnyAction => {
    return {
        type: RolesActionType.SET_ROLES,
        roles,
        total
    }
}

export const clearRoles = (): AnyAction => {
    return {
        type: RolesActionType.CLEAR_ROLES
    }
}

export const setFilters = (filters: RolesStateInterface["filters"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: RolesActionType.SET_FILTERS,
        filters
    })
    if (reloadList) {
        dispatch(getRoles(true));
    }
}

export const clearFilters = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: RolesActionType.CLEAR_FILTERS
    });
    if (reloadList) {
        dispatch(getRoles(true));
    }
}

export const setParams = (params: RolesStateInterface["params"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: RolesActionType.SET_PARAMS,
        params
    });
    if (reloadList) {
        dispatch(getRoles(true));
    }
}

export const clearParams = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: RolesActionType.CLEAR_PARAMS
    })
    if (reloadList) {
        dispatch(getRoles(true));
    }
}

export const setPage = (page: number): AnyAction => {
    if (page <= 0) page = 1;
    return {
        type: RolesActionType.SET_PAGE,
        page
    }
}