import { AnyAction } from "redux";
import { AnyDateFormat, AsyncThunkAction, GenericObject, Nullable, WarehouseDocumentInterface } from "../../common/types/common.types";

export enum DocumentsActionType {
    SET_DOCUMENTS = 'DOCUMENTS_SET_DOCUMENTS',
    CLEAR_DOCUMENTS = 'DOCUMENTS_CLEAR_DOCUMENTS',
    SET_FILTERS = 'DOCUMENTS_SET_FILTERS',
    CLEAR_FILTERS = 'DOCUMENTS_CLEAR_FILTERS',
    SET_PARAMS = 'DOCUMENTS_SET_PARAMS',
    CLEAR_PARAMS = 'DOCUMENTS_CLEAR_PARAMS',
    SET_PAGE = `DOCUMENTS_SET_PAGE`
}

export enum DocumentActionType {
    SET_DOCUMENT = 'DOCUMENT_SET_DOCUMENT',
    CLEAR_DOCUMENT = 'DOCUMENT_CLEAR_DOCUMENT',
    SET_HISTORY = 'DOCUMENT_SET_HISTORY',
    CLEAR_HISTORY = 'DOCUMENT_CLEAR_HISTORY',
    SET_FILTERS = 'DOCUMENT_SET_FILTERS',
    CLEAR_FILTERS = 'DOCUMENT_CLEAR_FILTERS',
    SET_PARAMS = 'DOCUMENT_SET_PARAMS',
    CLEAR_PARAMS = 'DOCUMENT_CLEAR_PARAMS',
    SET_PAGE = `DOCUMENT_SET_PAGE`
}

export interface DocumentsStateInterface {
    documents: Array<WarehouseDocumentInterface>,
    total: number,
    filters: {
        completionStatus: string,
        inspectionStatus: string,
        warehouse: Array<GenericObject>,
        relatedWarehouse: Array<GenericObject>,
        type: string,
        transportType: string,
        product: string,
        customer: string,
        completionUser: string,
        inspectionUser: string,
        dateFrom: AnyDateFormat,
        dateTo: AnyDateFormat
    },
    params: {
        limit: number,
        offset: number,
        sort: Nullable<{ [fieldname: string]: number }>
    },
    page: number
}

export interface DocumentStateInterface {
    document: Nullable<WarehouseDocumentInterface>
}

export interface DocumentsPropsInterface {
    documents: DocumentsStateInterface["documents"],
    total: DocumentsStateInterface["total"],
    filters: DocumentsStateInterface["filters"],
    params: DocumentsStateInterface["params"],
    page: number,
    getDocuments: () => AsyncThunkAction,
    clearDocuments: () => AnyAction,
    setFilters: (filters: DocumentsStateInterface["filters"], reloadList?: boolean) => AsyncThunkAction,
    clearFilters: (reloadList?: boolean) => AsyncThunkAction,
    setParams: (params: DocumentsStateInterface["params"], reloadList?: boolean) => AsyncThunkAction,
    clearParams: (reloadList?: boolean) => AsyncThunkAction,
    setPage: (page: number) => AnyAction
}

export interface DocumentsListHeaderPropsInterface {
    onFiltersChange: (filters: DocumentsStateInterface["filters"]) => void,
    onClearFilters: () => void,
    filters: any
}

export interface DocumentsListCardPropsInterface {
    item: WarehouseDocumentInterface
}

export interface DocumentsFiltersPropsInterface {
    onFiltersChange: (filters: DocumentsStateInterface["filters"]) => void,
    onClearFilters: () => void,
    filters: any
}

export interface DocumentDetailsPropsInterface {
    warehouseDocument: DocumentStateInterface["document"],
    getDocument: (id: string) => AsyncThunkAction,
    clearDocument: () => AnyAction,
    deleteDocument: (id: string) => Promise<any>
}

export interface DocumentDetailsProductTablePropsInterface {
    status: number,
    items: Array<GenericObject>
}

export interface DocumentDetailsHeaderPropsInterface {
    showDelete: boolean,
     warehouseDocument: DocumentStateInterface["document"]
}