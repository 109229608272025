import _ from 'lodash';
import { DictInterface, GenericObject } from '../../common/types/common.types';

class AclService implements AclService {
    settings: GenericObject
    dicts: Array<DictInterface>

    constructor() {
        this.settings = {};
        this.dicts = [];
    }

    setDicts(dicts: Array<DictInterface>) {
        this.dicts = dicts;
    }

    setSettings(settings: GenericObject) {
        this.settings = settings;
    }

    clearDictsAndSettings() {
        this.settings = {};
        this.dicts = [];
    }

    getDicts() {
        return this.dicts;
    }

    getSettings() {
        return this.settings;
    }

    getDict(type: number) {
        return _.find(this.dicts, (dict) => dict.type === type);
    }

    getDictAsOptions(type: number) {
        let dict = _.find(this.dicts, (dict) => dict.type === type);
        if (dict) {
            return dict.values.map((v: any) => {
                return { label: v.name, value: v.value };
            })
        }
        return [];
    }

    getSetting(setting: string, defaultValue?: any) {
        return _.get(this.settings, setting, defaultValue);
    }

    getDictValueName(type: number, value: number, defaultName: string) {
        let dict = _.find(this.dicts, (dict) => dict.type === type);
        if (dict) {
            let dictValue = _.find(dict.values, (dv) => dv.value === value);
            if (dictValue) {
                return dictValue.name;
            }
        }
        return defaultName;
    }

}

export default new AclService();