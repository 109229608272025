import { GenericObject, Nullable } from "../../common/types/common.types";
import { AppDispatch } from "../../config/store";
import { toast } from "react-toastify";

class ApiService {
    host: string
    host2: string
    dispatch?: AppDispatch

    constructor() {
        // this.host = "http://localhost:3001"
        this.host = "https://api-wms-new.overcloud.usermd.net"
        this.host2 = "http://localhost:3000";
    }

    getUrl() {
        return this.host;
    }

    getToken() {
        return localStorage.getItem('token');
    }

    setDispatch(dispatch: AppDispatch) {
        this.dispatch = dispatch;
    }

    getDispatch() {
        return this.dispatch;
    }

    get(url: string, params?: any) {
        if (params) {
            const tmp = [];
            for (const key in params) {
                tmp.push(`${key}=${params[key]}`);
            }
            url += tmp.length > 0 ? '?' + tmp.join('&') : '';
        }
        return this.request('GET', this.host + url, null);
    }

    put(url: string, data: any, isFormData?: boolean) {
        return this.request('PUT', this.host + url, data, isFormData);
    }

    post(url: string, data: any, isFormData?: boolean, v2Api?: boolean, isFile?: boolean) {
        return this.request('POST', (v2Api ? this.host2 : this.host) + url, data, isFormData, isFile);
    }

    async delete(url: string) {
        return await this.request('DELETE', this.host + url, null);
    }

    setFormData(obj: any, form?: FormData, namespace?: string) {
        let fd = form || new FormData();
        let formKey;
        for (let property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (namespace) {
                    formKey = namespace + '[' + property + ']';
                } else {
                    formKey = property;
                }
                if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                    this.setFormData(obj[property], fd, formKey);
                } else {
                    fd.append(formKey, obj[property]);
                }
            }
        }
        return fd;
    };

    async request(method: string, url: string, data?: Nullable<GenericObject>, isFormData?: boolean, isFile?: boolean) {
        try {

            let options: GenericObject = {
                method: method,
                headers: isFormData ? {} : {
                    'Content-Type': 'application/json'
                }
            };

            const token = await this.getToken();
            if (token) {
                options.headers.Authorization = `JWT:${token}`
            }

            if (data && Object.keys(data).length > 0) {
                if (isFormData) {
                    options.body = this.setFormData(data);
                } else {
                    options.body = JSON.stringify(data);
                }
            }

            let response = await fetch(url, options);
            switch (response.status) {
                case 201:
                    break;
                case 209:
                    break;
                case 404:
                    toast.error("Brak zasobu");
                    break;
                case 403:
                    toast.error("Brak uprawnień");
                    break;
                case 422:
                    break;
                case 500:
                    toast.error("Błąd serwera");
                    break;
                default: break;
            }
            if (isFile) {
                let blob = await response.blob()
                return blob;
            }
            let json = await response.json();
            return json;

        } catch (err) {
            return { success: false };
        }
    }
}

export default new ApiService();
