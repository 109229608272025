import * as Yup from 'yup';
// import { UserInterface } from '../../common/types/common.types';

export const initialValues = {
    _id: '',
    name: '',
    code: '',
}

export const warehouseValidationSchema = Yup.object({
    _id: Yup.string(),
    name: Yup.string().required("Wartość jest wymagana"),
    code: Yup.string().required("Wartość jest wymagana"),
})
