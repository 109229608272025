import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import InventoryDetailsComponent from "../components/inventoryDetails.component";
import { InventoryPropsInterface, InventoryStateInterface } from "../types/inventory.types";
import { getInventory, clearInventory, deleteInventory, getHistory, clearHistory, setFilters, clearFilters, setParams, clearParams, setPage, saveInventory, resetPosition } from "../actions/inventory.actions";
import { AppDispatch, RootState } from "../../config/store";
import { connect } from 'react-redux'

const InventoryDetailsContainer: FC<InventoryPropsInterface> = (props: InventoryPropsInterface) => {
    const { getInventory, clearInventory, getHistory, clearHistory, page } = props;
    let { id }: any = useParams();

    useEffect(() => {
        if (id) {
            getHistory(id);
        }
        return () => {
            clearHistory();
        }
    }, [getHistory, clearHistory, id, page]);

    useEffect(() => {
        if (id) {
            getInventory(id);
        }
        return () => {
            clearInventory();
        }
    }, [getInventory, clearInventory, id])


    return <InventoryDetailsComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    inventory: store.inventory.inventory,
    history: store.inventory.history,
    total: store.inventory.total,
    filters: store.inventory.filters,
    params: store.inventory.params,
    page: store.inventory.page
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getInventory: (id: string) => dispatch(getInventory(id)),
        clearInventory: () => dispatch(clearInventory()),
        deleteInventory: (id: string) => deleteInventory(id, dispatch),
        getHistory: (id: string) => dispatch(getHistory(id)),
        clearHistory: () => dispatch(clearHistory()),
        setFilters: (filters: InventoryStateInterface["filters"], reloadList?: boolean) => dispatch(setFilters(filters, reloadList)),
        clearFilters: (reloadList?: boolean) => dispatch(clearFilters(reloadList)),
        setPage: (page: number) => dispatch(setPage(page)),
        setParams: (params: InventoryStateInterface["params"], reloadList?: boolean) => dispatch(setParams(params, reloadList)),
        clearParams: (reloadList?: boolean) => dispatch(clearParams(reloadList)),
        saveInventory: (data: any) => saveInventory(data, dispatch),
        resetPosition: (id: string) => resetPosition(id, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryDetailsContainer);