import React, { FC } from "react";
import { TextInputComponentPropsInterface } from "../types/component.types";

const TextInputComponent: FC<TextInputComponentPropsInterface> = ({
    error,
    placeholder = "Wpisz wartość",
    label,
    id,
    type = "text",
    disabled = false,
    customClass,
    value,
    defaultValue,
    debounce,
    hideLabel,
    info,
    onChange
}: TextInputComponentPropsInterface) => {

    let amountDelayTimer: any;
    const updateText = (id: string, text: string) => {
        clearTimeout(amountDelayTimer);
        amountDelayTimer = setTimeout(function () {
            onChange(id, text);
        }, debounce);
    }

    return (<div className={customClass ? customClass : 'form-group input-group my-3'}>
        <span className={`has-float-label ${info && 'd-flex flex-no-wrap'}`}>
            <input
                className={`form-control ${error ? 'is-invalid' : ''}`}
                type={type}
                placeholder={placeholder}
                autoComplete="off"
                disabled={disabled}
                value={value}
                id={id}
                defaultValue={defaultValue}
                onChange={(e) => debounce ? updateText(id, e.target.value) : onChange(id, e.target.value)}
            />
            {!hideLabel && <label onClick={() => document.getElementById(id)?.focus()}>{label}</label>}
            <label onClick={() => document.getElementById(id)?.focus()}>{label}</label>
            {info && <div className="input-group-append">
                <span className="input-group-text">{info}</span>
            </div>}
        </span>
        {error && <span className="is-invalid-alert">{error.toString()}</span>}
    </div>);
}

export default TextInputComponent;