import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { ZpDocumentInterface } from "../../common/types/common.types"
import { AppDispatch } from "../../config/store"
import { ZpActionType } from "../types/zp.types";
import { toast } from "react-toastify"

export const getZp = (id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get(`/document/zp/${id}`, { joinValues: true });
        if (res.success) {
            dispatch(setZp(res.document));
        } else {
            toast.error("Błąd pobierania dokumentu");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania dokumentu");
        dispatch(isLoading(false));
    }
}

export const setZp = (zp: ZpDocumentInterface): AnyAction => {
    return {
        type: ZpActionType.SET_ZP,
        zp
    }
}

export const clearZp = (): AnyAction => {
    return {
        type: ZpActionType.CLEAR_ZP
    }
}