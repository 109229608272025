import { GeneratorStateInterface, GeneratorActionType } from "../types/generator.types";

const initialState: GeneratorStateInterface = {
    barcodes: [],
    total: 0,
    filters: {
        name: '',
        ean: '',
        seriesNr: ''
    },
    params: {
        limit: 20,
        offset: 0,
        sort: null
    },
    page: 1
}

const generatorReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case GeneratorActionType.SET_BARCODES: {
            return {
                ...state,
                barcodes: action.barcodes,
                total: action.total
            }
        }
        case GeneratorActionType.CLEAR_BARCODES: {
            return {
                ...state,
                barcodes: [],
                total: 0
            }
        }
        case GeneratorActionType.SET_FILTERS: {
            return {
                ...state,
                filters: action.filters
            }
        }
        case GeneratorActionType.CLEAR_FILTERS: {
            return {
                ...state,
                filters: {
                    name: '',
                    ean: '',
                    seriesNr: ''
                }
            }
        }
        case GeneratorActionType.SET_PARAMS: {
            return {
                ...state,
                params: action.params
            }
        }
        case GeneratorActionType.CLEAR_PARAMS: {
            return {
                ...state,
                params: {
                    limit: 20,
                    offset: 0,
                    sort: null
                }
            }
        }
        case GeneratorActionType.SET_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        default: return state;
    }
}

export default generatorReducer;