import { Formik } from 'formik';
import moment from 'moment';
import React, { FC, Fragment } from "react";
import { useHistory } from 'react-router';
import Autocomplete from '../../common/components/autocomplete.component';
import TextInputComponent from "../../common/components/textInput.component";
import codeIcon from "../../common/images/ico-kody.svg";
import { barcodeValidationSchema, initialValues } from "../schemas/barcode.schema";
import { BarcodePropsInterface } from "../types/generator.types";
import BarcodeHeaderComponent from "./barcodeHeader.component";

const BarcodeComponent: FC<BarcodePropsInterface> = ({ saveBarcode }: BarcodePropsInterface) => {

    const handleProductSelect = (formik: any, selected: any) => {
        formik.setFieldValue("name", selected.name);
        formik.setFieldValue("ean", selected.ean);
        formik.setFieldValue("expirationDate", moment().add(selected.expiresIn, "months").format("YYYY-MM-DD"));
    }
    let history = useHistory();

    return (<Fragment>
        <BarcodeHeaderComponent />
        <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-4">
            <div className="bg-white rounded-10 p-1">
                <div className="d-flex align-items-center justify-content-center">

                    <Formik
                        initialValues={initialValues}
                        validationSchema={barcodeValidationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize={true}
                        onSubmit={(values, { setErrors }) => {
                            values.expirationDate = moment(values.expirationDate, "YYYY-MM-DD").format("X");
                            saveBarcode(values).then((res: any) => {
                                if (res.success) {
                                    history.push("/generator/page/1")
                                } else if (res.errors) {
                                    setErrors(res.errors);
                                }
                            })
                        }}
                    >{formik => (
                        <form className="col-md-10 col-sm-12 d-flex flex-wrap py-5 my-5" onSubmit={formik.handleSubmit}>
                            <div className="col-sm-12">
                                <Autocomplete
                                    url={"/product/product"}
                                    id="product"
                                    multi={false}
                                    onOptionSelect={(id: string, option: any) => handleProductSelect(formik, option)}
                                    placeholder="Szukaj"
                                    label="Nazwa produktu"
                                    optionIdField="warehouseId"
                                    optionLabelField="name"
                                    value={null}
                                    queryFields={["name", "code", "ean"]}
                                    additionalParams={{ limit: 5, select: "name,ean,expiresIn" }}
                                    parseOption={(option: any) => option}
                                    hideChips={true}
                                    readonly={false}
                                    preload={true}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-4">
                                <TextInputComponent
                                    label="Numer EAN"
                                    type="text"
                                    placeholder="Wpisz numer EAN"
                                    id="ean"
                                    onChange={() => null}
                                    value={formik.values.ean || ""}
                                    error={formik.errors.ean}
                                    customClass="form-group input-group"
                                    disabled={true}
                                />
                            </div>
                            <div className="col-sm-4">
                                <TextInputComponent
                                    label="Numer serii"
                                    type="text"
                                    placeholder="Wpisz numer serii"
                                    id="seriesNr"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.seriesNr || ""}
                                    error={formik.errors.seriesNr}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-4">
                                <TextInputComponent
                                    label="Data ważności"
                                    type="date"
                                    placeholder="Wybierz datę"
                                    id="expirationDate"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.expirationDate.toString()}
                                    error={formik.errors.expirationDate}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-12 text-center">
                                <button type="submit" className="btn-wms color-white rounded border-0 px-4 my-5 py-2"><img src={codeIcon} alt="generuj"/> Generuj</button>
                            </div>
                        </form>)}
                    </Formik>
                </div>
            </div>
        </div>
    </Fragment>);
}

export default BarcodeComponent;