import * as Yup from 'yup';
import { GenericObject } from '../../common/types/common.types';

export const initialValues: GenericObject = {
    _id: '',
    name: '',
    login: '',
    email: '',
    phone: '',
    role: null,
    avatar: '',

    oldPassword: "",
    newPassword: "",
    repeatPassword: ""
}

export const profileValidationSchema = Yup.object({
    _id: Yup.string(),
    name: Yup.string().required("Wartość jest wymagana"),
    login: Yup.string().required("Wartość jest wymagana"),
    email: Yup.string().required("Wartość jest wymagana").email("Niepoprawny email"),
    phone: Yup.string(),
    role: Yup.object().nullable(),
    avatar: Yup.string().nullable(),
    oldPassword: Yup.string(),
    newPassword: Yup.string().when('oldPassword', {
        is: undefined,
        then: Yup.string(),
        otherwise: Yup.string().required("Hasło jest wymagane")
    }),
    repeatPassword: Yup.string().when('oldPassword', {
        is: undefined,
        then: Yup.string(),
        otherwise: Yup.string().required("Powtórz hasło").test('password_match', 'Hasła do siebie nie pasują', function (value) {
            return value === this.parent.newPassword;
        })
    })
})
