import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { GenericObject } from "../../common/types/common.types"
import { AppDispatch } from "../../config/store"
import { DashboardActionType } from "../types/dashboard.types";
import { toast } from "react-toastify"

export const getDashboard = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get('/application/dashboard');
        if (res.success) {
            dispatch(setDashboard(res.document));
        } else {
            toast.error("Błąd pobierania kokpitu");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania kokpitu");
        dispatch(isLoading(false));
    }
}

export const saveDashboard = async (data: GenericObject, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.put("/application/dashboard", data, true);

        dispatch(isLoading(false));

        if (res.success) {
            toast.success("Zapisano kokpit");
            return res;
        } else if (res.errors) {
            toast.error("Błąd zapisu kokpitu");
            return res;
        } else {
            toast.error("Błąd zapisu kokpitu");
            return { success: false, errors: { message: "Błąd zapisu" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd zapisu kokpitu");
        return { success: false, errors: { message: "Błąd zapisu" } };
    }
}

export const setDashboard = (dashboard: GenericObject): AnyAction => {
    return {
        type: DashboardActionType.SET_DASHBOARD,
        dashboard
    }
}

export const clearDashboard = (): AnyAction => {
    return {
        type: DashboardActionType.CLEAR_DASHBOARD
    }
}