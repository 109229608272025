import React, { FC } from "react";
import { RequireAtLeastOne } from "../types/common.types";
import { TextAreaComponentPropsInterface } from "../types/component.types";

const TextAreaComponent: FC<RequireAtLeastOne<TextAreaComponentPropsInterface, 'value' | 'defaultValue'>> = ({
    error,
    placeholder = "Wpisz wartość",
    label,
    id,
    disabled = false,
    customClass,
    value,
    defaultValue,
    debounce,
    onChange,
    rows = 1
}: RequireAtLeastOne<TextAreaComponentPropsInterface, 'value' | 'defaultValue'>) => {

    let amountDelayTimer: any;
    const updateText = (id: string, text: string) => {
        clearTimeout(amountDelayTimer);
        amountDelayTimer = setTimeout(function () {
            onChange(id, text);
        }, debounce);
    }

    return (<div className={customClass ? customClass : 'form-group input-group my-3'}>
        <span className="has-float-label">
            <textarea
                className={`form-control ${error ? 'is-invalid' : ''}`}
                placeholder={placeholder}
                autoComplete="off"
                disabled={disabled}
                value={value?.toString()}
                id={id}
                defaultValue={defaultValue?.toString()}
                rows={rows}
                onChange={(e) => debounce ? updateText(id, e.target.value) : onChange(id, e.target.value)}
            />
            <label onClick={() => document.getElementById(id)?.focus()}>{label}</label>
        </span>
        {error && <span className="is-invalid-alert">{error.toString()}</span>}
    </div>);
}

export default TextAreaComponent;