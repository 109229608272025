import React, { FC } from "react";
import { CheckboxComponentPropsInterface } from "../types/component.types";

const CheckboxComponent: FC<CheckboxComponentPropsInterface> = ({
    label,
    id,
    disabled = false,
    customClass,
    checked,
    title,
    onChange
}: CheckboxComponentPropsInterface) => {
    return (<div className={customClass ? customClass : "custom-control custom-checkbox"} title={title}>
        <input
            type="checkbox"
            checked={checked !== undefined ? checked : false}
            disabled={disabled}
            className="custom-control-input"
            onChange={(e) => onChange(id, e.target.checked)}
        />
        <label className="custom-control-label" onClick={() => onChange(id, !checked)} title={title}>{label}</label>
    </div>)
}

export default CheckboxComponent;