import { AnyAction } from "redux";
import {
  ProductInterface,
  AsyncThunkAction,
  Nullable,
  AnyDateFormat,
} from "../../common/types/common.types";
import { CategoryInterface } from "../../common/types/component.types";

export enum ProductActionType {
  SET_PRODUCT = "PRODUCT_SET_PRODUCT",
  CLEAR_PRODUCT = "PRODUCT_CLEAR_PRODUCT",
  SET_PRODUCT_WAREHOUSE_STATE = "PRODUCT_SET_PRODUCT_WAREHOUSE_STATE",
  CLEAR_PRODUCT_WAREHOUSE_STATE = "PRODUCT_CLEAR_PRODUCT_WAREHOUSE_STATE",
  SET_PRODUCT_WAREHOUSE_POSITION_HISTORY = "PRODUCT_SET_PRODUCT_WAREHOUSE_POSITION_HISTORY",
  CLEAR_PRODUCT_WAREHOUSE_POSITION_HISTORY = "PRODUCT_CLEAR_PRODUCT_WAREHOUSE_POSITION_HISTORY",
  SET_PRODUCT_DOCUMENT_RESERVATIONS = "PRODUCT_SET_PRODUCT_DOCUMENT_RESERVATIONS",
  CLEAR_PRODUCT_DOCUMENT_RESERVATIONS = "PRODUCT_CLEAR_PRODUCT_DOCUMENT_RESERVATIONS",
  SET_FILTERS = "PRODUCT_SET_FILTERS",
  CLEAR_FILTERS = "PRODUCT_CLEAR_FILTERS",
  SET_PARAMS = "PRODUCT_SET_PARAMS",
  CLEAR_PARAMS = "PRODUCT_CLEAR_PARAMS",
  SET_PAGE = "PRODUCT_SET_PAGE",
}

export enum ProductsActionType {
  SET_PRODUCTS = "PRODUCTS_SET_PRODUCTS",
  CLEAR_PRODUCTS = "PRODUCTS_CLEAR_PRODUCTS",
  SET_FILTERS = "PRODUCTS_SET_FILTERS",
  CLEAR_FILTERS = "PRODUCTS_CLEAR_FILTERS",
  SET_PARAMS = "PRODUCTS_SET_PARAMS",
  CLEAR_PARAMS = "PRODUCTS_CLEAR_PARAMS",
  SET_PAGE = "PRODUCTS_SET_PAGE",
}

export interface ProductsStateInterface {
  products: Array<ProductInterface>;
  total: number;
  filters: {
    name: string;
    category: string;
    EAN: string;
    code: string;
    type: string;
    source: string;
    isGratis: Nullable<boolean>;
    PAO: Nullable<boolean>;
    active: Nullable<boolean>;
  };
  params: {
    limit: number;
    offset: number;
    sort: Nullable<{ [fieldname: string]: number }>;
  };
  page: number;
}

export interface ProductStateInterface {
  product: Nullable<ProductInterface>;
  categories: Array<CategoryInterface>;
  productWarehouseState: Array<any>;
  productWarehousePositionHistory: Array<any>;
  productDocumentReservations: Array<any>;
  total: number;
  filters: {
    documentNumber: string;
    seriesNr: string;
    position: string;
    dateFrom: AnyDateFormat;
    dateTo: AnyDateFormat;
  };
  params: {
    limit: number;
    offset: number;
  };
  page: number;
}

export interface ProductsPropsInterface {
  products: ProductsStateInterface["products"];
  categories: Array<CategoryInterface>;
  total: ProductsStateInterface["total"];
  params: ProductsStateInterface["params"];
  filters: ProductsStateInterface["filters"];
  page: number;
  getProducts: () => AsyncThunkAction;
  clearProducts: () => AsyncThunkAction;
  setPage: (page: number) => AnyAction;
  setParams: (params: ProductsStateInterface["params"], reloadList?: boolean) => AsyncThunkAction;
  setFilters: (
    filters: ProductsStateInterface["filters"],
    reloadList?: boolean
  ) => AsyncThunkAction;
  clearFilters: (reloadList?: boolean) => AsyncThunkAction;
  getCategories: (content: string) => AsyncThunkAction;
}

export interface ProductsFiltersPropsInterface {
  onFiltersChange: (filters: ProductsStateInterface["filters"]) => void;
  onClearFilters: () => void;
  filters: any;
  categories: Array<CategoryInterface>;
}

export interface ProductsListCardPropsInterface {
  item: ProductInterface;
}

export interface ProductPropsInterface {
  product: ProductInterface;
  productWarehouseState: Array<any>;
  productWarehousePositionHistory: Array<any>;
  productDocumentReservations: Array<any>;
  categories: ProductStateInterface["categories"];
  total: ProductStateInterface["total"];
  filters: ProductStateInterface["filters"];
  params: ProductStateInterface["params"];
  page: number;
  getProduct: (id: string) => AsyncThunkAction;
  getCategories: (content: string) => AsyncThunkAction;
  getProductWarehouseState: (productId: string) => AsyncThunkAction;
  getProductDocumentReservations: (productId: string) => AsyncThunkAction;
  getProductWarehousePositionHistory: (productId: string) => AsyncThunkAction;
  saveProduct: (data: ProductStateInterface["product"]) => Promise<any>;
  deleteProduct: (id: string) => Promise<any>;
  setPage: (page: number) => AnyAction;
  setFilters: (filters: ProductStateInterface["filters"], reloadList?: boolean) => AsyncThunkAction;
  setParams: (params: ProductStateInterface["params"], reloadList?: boolean) => AsyncThunkAction;
  clearProduct: () => AnyAction;
  clearParams: (reloadList?: boolean) => AsyncThunkAction;
  clearFilters: (reloadList?: boolean) => AsyncThunkAction;
  clearProductWarehouseState: () => AnyAction;
  clearProductDocumentReservations: () => AnyAction;
  clearProductWarehousePositionHistory: () => AnyAction;
}

export interface ProductParametersInterface {
  unitsInPackage: number | null;
  singleUnitWeight: number | null;
  packagesOnEuroPallet: number | null;
  pkwiu: string;
  packageWeight: string;
  totalVolume: string;
  width: string;
  height: string;
  length: string;
}

export interface ProductWarehouseStateDetailsPropsInterface {
  productWarehouseState: any;
  availableUnits: Array<any>;
}

export interface ProductWarehousePositionHistoryPropsInterface {
  items: Array<any>;
  itemsOnPage: number;
  total: number;
  currentPage: number;
  filters: any;
  onFiltersChange: (filters: ProductStateInterface["filters"]) => void;
  onClearFilters: () => void;
  onPageChange: (page: number) => void;
}

export interface ProductDocumentReservationPropsInterface {
  items: Array<any>;
}
