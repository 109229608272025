import React, { FC, Fragment } from "react";
import { useParams, useHistory } from "react-router";
import { UserPropsInterface } from "../types/user.types";
import adminIcon from "../../common/images/ico-admin.svg";
import mobileIcon from "../../common/images/ico-mobile.svg";
import blankAvatar from "../../common/images/ufo-user.jpg";
import completedDocIcon from "../../common/images/ico-zrealizowane.svg";
import inProgressDocIcon from "../../common/images/ico-wrealizacji.svg";
import Api from "../../application/services/api.service";
import _ from "lodash";
import DeleteConfirmModal from "../../common/components/deleteConfirmModal.component";
import { GenericObject } from "../../common/types/common.types";
import UserDetailsHeaderComponent from "./userDetailsHeader.component";

const UserDetailsComponent: FC<UserPropsInterface> = ({ user, deleteUser }: UserPropsInterface) => {
    let { id }: any = useParams();
    let history = useHistory();
    const getUserAvatarSrc = () => {
        if (user && user.avatar) {
            return Api.getUrl() + user.avatar;
        }
        return blankAvatar;
    }

    const renderLastDocuments = () => {
        if (user && !_.isEmpty(user.lastDocuments)) {
            let statusNames: GenericObject = { 2: "W trakcie realizacji", 3: "Zrealizowany" };
            let statusIcons: GenericObject = { 2: inProgressDocIcon, 3: completedDocIcon }

            return user.lastDocuments!.map((document, index) => {
                let docStatus: number = document.completion.status;
                return (<tr key={`doc${index}`}>
                    <td>{index + 1}</td>
                    <th scope="row">
                        <h3 className="text-left mt-1">{document.documentNumber}</h3>
                    </th>
                    <td>{statusNames[docStatus]} <img src={statusIcons[docStatus]} className="mb-1 ml-2" alt="Status" /></td>
                    <td>
                        <h3 className="full-time mb-0"><span>14:42</span>08.06.2021</h3>
                    </td>
                    <td>
                        <h3 className="full-time mb-0"><span>14:52</span>08.06.2021</h3>
                    </td>
                </tr>)
            });
        } else {
            return (<tr>
                <td colSpan={5}>Brak dokumentów</td>
            </tr>)
        }
    }

    const renderWarehouses = () => {
        if (user && _.isArray(user.warehouse)) {
            let names = user.warehouse.map((warehouse) => {
                return warehouse.name;
            })
            return names.join(", ");
        }
        return "-";
    }

    return <Fragment>
        <DeleteConfirmModal id="user-delete" onDelete={() => {
            deleteUser(id).then((res) => {
                if (res.success) {
                    document.getElementById(`user-delete-cancel`)?.click();
                    history.push("/users/page/1");
                }
            })
        }} />
        <UserDetailsHeaderComponent />
        <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2">
            <div className="bg-white boxshadow rounded-10 p-1">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="col-md-8 col-sm-12 py-5 my-5 d-flex flex-wrap">
                        <div className="col-sm-3">
                            <div className=" upload-user-photo form-frame">
                                <img src={getUserAvatarSrc()} className="user-photo" alt="Avatar" />
                            </div>
                        </div>
                        <div className="col-sm-9 pr-0 d-flex flex-wrap">
                            <div className="form-group input-group col-12 mx-0">
                                <h2 className="text-center">{_.get(user, "name", "-")}
                                    {user?.isAdmin && <img src={adminIcon} title="Administrator" className="mx-2" alt="Administrator" />}
                                    {user?.allowMobile && <img src={mobileIcon} title="Dostęp do aplikacji mobilnej" alt="Mobilna" />}
                                </h2>
                            </div>
                            <div className="col-sm-12 px-0">
                                <hr className="mt-0" />
                            </div>
                            <div className="specs-list-cont col-sm-12 px-0">
                                <div className="specs-list">Rola<span>{_.get(user, "role.name", "-")}</span></div>
                                <div className="specs-list">Login<span>{_.get(user, "login", "-")}</span></div>
                                <div className="specs-list">Adres e-mail<span>{_.get(user, "email", "-")}</span></div>
                                <div className="specs-list">Telefon kontaktowy<span>{_.get(user, "phone", "-")}</span></div>
                                <div className="specs-list">Magazyn <span>{renderWarehouses()}</span></div>
                            </div>
                        </div>
                        <div className="col-sm-12 text-center px-0">
                            <h3 className="my-4 px-3 flex-grow-1">Ostatnie dokumenty</h3>
                            <div className=" table table-responsive my-4">
                                <table id="table-user" className="table table-hover mb-0">
                                    <thead>
                                        <tr className="thead-light">
                                            <th scope="col" className="col-md-1 text-center">Lp.</th>
                                            <th scope="col" className="col-md-2">Numer dokumentu</th>
                                            <th className="col-md-2">Status</th>
                                            <th className="col-md-3">Data rozpoczęcia</th>
                                            <th className="col-md-3">Data zrealizowania</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderLastDocuments()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}

export default UserDetailsComponent;