import React, { FC } from "react";
import { DeleteConfirmModalPropsInterface } from "../types/component.types";
import deleteIcon from "../images/ico-xred-c.svg";

const DeleteConfirmModal: FC<DeleteConfirmModalPropsInterface> = ({
    id,
    onDelete
}: DeleteConfirmModalPropsInterface) => {
    return (<div id={id} className="modal fade">
        <div className="modal-dialog modal-confirm">
            <div className="modal-content">
                <div className="modal-header flex-column">
                    <div className="icon-box">
                        <img src={deleteIcon} className="modal-delete-img" alt="Usuwanie"/>
                    </div>
                    <h4 className="modal-title w-100 mt-3">Uwaga!</h4>
                </div>
                <div className="modal-body">
                    <p>Czy na pewno chcesz usunąć ten element?</p>
                </div>
                <div className="modal-footer justify-content-center">
                    <button type="button" className="btn btn-danger" onClick={onDelete}>Usuń</button>
                    <button type="button" id={`${id}-cancel`} className="btn btn-secondary" data-dismiss="modal">Anuluj</button>
                </div>
            </div>
        </div>
    </div>)
}

export default DeleteConfirmModal;