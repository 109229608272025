import { AnyAction } from "redux";
import { AsyncThunkAction, Nullable, GenericObject } from "../../common/types/common.types";

export enum ProfileActionType {
    SET_PROFILE = 'PROFILE_SET_PROFILE',
    CLEAR_PROFILE = 'PROFILE_CLEAR_PROFILE'
}

export interface ProfileStateInterface {
    profile: Nullable<GenericObject>
}

export interface ProfilePropsInterface {
    profile: ProfileStateInterface["profile"],
    getProfile: () => AsyncThunkAction,
    clearProfile: () => AnyAction,
    saveProfile: (data: GenericObject) => Promise<any>
}

export interface ProfileHeaderPropsInterface {
    name: string
}
