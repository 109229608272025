import { RoleStateInterface, RoleActionType } from "../types/role.types";

const initialState: RoleStateInterface = {
    role: null
}

const roleReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case RoleActionType.SET_ROLE: {
            return {
                ...state,
                role: action.role
            }
        }
        case RoleActionType.CLEAR_ROLE: {
            return {
                ...state,
                role: null
            }
        }
        default: return state;
    }
}

export default roleReducer;