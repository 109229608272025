import React, { FC, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router";
import { AppDispatch, RootState } from "../../config/store";
import { clearDocument, getDocument, deleteDocument } from "../actions/document.actions";
import DocumentDetailsComponent from "../components/documentDetails.component";
import { DocumentDetailsPropsInterface } from "../types/document.types";

const DocumentDetailsContainer: FC<DocumentDetailsPropsInterface> = (props: DocumentDetailsPropsInterface) => {
    const { getDocument, clearDocument } = props;
    let { id }: any = useParams();

    useEffect(() => {
        getDocument(id);
        return () => {
            clearDocument();
        }
    }, [getDocument, clearDocument, id])


    return <DocumentDetailsComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    warehouseDocument: store.document.document
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getDocument: (id: string) => dispatch(getDocument(id)),
        clearDocument: () => dispatch(clearDocument()),
        deleteDocument: (id: string) => deleteDocument(id, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetailsContainer);