import * as Yup from 'yup';

export const initialValues = {
    supportedDocuments: [
        { type: "ZP", action: "internal" },
        { type: "WZ", action: "subtract" },
        { type: "KWZ2", action: "correction" },
        { type: "MMP", action: "add" },
        { type: "MMW", action: "subtract" },
        { type: "PZ 2", action: "add" },
        { type: "PZN2", action: "add" },
        { type: "RWNP", action: "subtract" },
        { type: "PWP", action: "add" },
        { type: "ZD", action: "production" },
        { type: "ZPM", action: "production" },
        { type: "ZPK", action: "production" },
        { type: "RWP", action: "production" },
        { type: "PWP", action: "production" },
    ],
    //Ustawienia automatycznej kompletacji
    autoCompletion: [],
    notifications: [],
    planner: null,
    salesplanDeadlineDayOfMonth: 10,
    simulationWarehouses: [],
    salesplanCalculationDocuments: ["WZ"],
    addEventsToSaturday: false,
    addEventsToSunday: false,
    holidays: [],
    deliveryQuarantineHours: 48,
    maxDaysToConfection: 14
}

export const settingsValidationSchema = Yup.object({
    supportedDocuments: Yup.array().of(Yup.object().shape({
        type: Yup.string().required("Wartość jest wymagana"),
        action: Yup.string().required("Wartość jest wymagana")
    })),
    //Ustawienia automatycznej kompletacji
    autoCompletion: Yup.array().of(Yup.object().shape({
        type: Yup.string().required("Wartość jest wymagana"),
        warehouse: Yup.string().required("Wartość jest wymagana")
    })),
    notifications: Yup.array().of(Yup.object().shape({
        eventName: Yup.string().required("Wartość jest wymagana"),
        emails: Yup.array()
    })),
    planner: Yup.object().shape({
        name: Yup.string(),
        userId: Yup.string()
    }).nullable(),
    salesplanDeadlineDayOfMonth: Yup.number().required("Wartość jest wymagana"),
    simulationWarehouses: Yup.array().of(Yup.object().shape({
        warehouseId: Yup.string(),
        name: Yup.string()
    })),
    salesplanCalculationDocuments: Yup.array().of(Yup.string()),
    addEventsToSaturday: Yup.boolean(),
    addEventsToSunday: Yup.boolean(),
    holidays: Yup.array().of(Yup.object().shape({
        name: Yup.string().required("Wartość jest wymagana"),
        date: Yup.date().required("Wartość jest wymagana")
    })),
    deliveryQuarantineHours: Yup.number().required("Wartość jest wymagana"),
    maxDaysToConfection: Yup.number().required("Wartość jest wymagana")
})
