import moment from "moment";
import { ZpListStateInterface, ZpListActionType } from "../types/zp.types";

const initialState: ZpListStateInterface = {
    documents: [],
    total: 0,
    filters: {
        positionFrom: "",
        positionTo: "",
        creator: "",
        product: "",
        dateFrom: moment().subtract(2, "weeks").startOf("week").format("MM/DD/YYYY"),
        dateTo: moment().format("MM/DD/YYYY")
    },
    params: {
        limit: 20,
        offset: 0,
        sort: null
    },
    page: 1
}

const zpListReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case ZpListActionType.SET_ZPS: {
            return {
                ...state,
                documents: action.documents,
                total: action.total
            }
        }
        case ZpListActionType.CLEAR_ZPS: {
            return {
                ...state,
                documents: [],
                total: 0
            }
        }
        case ZpListActionType.SET_FILTERS: {
            return {
                ...state,
                filters: action.filters
            }
        }
        case ZpListActionType.CLEAR_FILTERS: {
            return {
                ...state,
                filters: {
                    positionFrom: "",
                    positionTo: "",
                    creator: "",
                    product: "",
                    dateFrom: moment().subtract(2, "weeks").startOf("week").format("MM/DD/YYYY"),
                    dateTo: moment().format("MM/DD/YYYY")
                }
            }
        }
        case ZpListActionType.SET_PARAMS: {
            return {
                ...state,
                params: action.params
            }
        }
        case ZpListActionType.CLEAR_PARAMS: {
            return {
                ...state,
                params: {
                    limit: 20,
                    offset: 0,
                    sort: null
                }
            }
        }
        case ZpListActionType.SET_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        default: return state;
    }
}

export default zpListReducer;