import React, { FC } from "react";
import LoginComponent from "../components/login.component";
import { login } from '../actions/application.actions';
import { connect } from 'react-redux'
import { AppDispatch, RootState } from "../../config/store";

const LoginContainer: FC<any> = (props: any) => {
    return <LoginComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    user: store.application.user
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        login: (email: string, password: string) => login(email, password, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);