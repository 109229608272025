import _ from "lodash";
import { FC } from "react";
import { ProductDocumentReservationPropsInterface } from "../types/product.types";
import moment from "moment";

const ProductDocumentReservationComponent: FC<ProductDocumentReservationPropsInterface> = ({
  items,
}: ProductDocumentReservationPropsInterface) => {
  return (
    <div className="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="details-contact-tab">
      <div className=" table table-responsive my-4">
        <table id="table-warehouses" className="table table-hover mb-0">
          <thead>
            <tr className="thead-light">
              <th className="col-md-3">Utworzono</th>
              <th className="col-md-3">Dokument</th>
              <th className="col-md-2">Pozycja</th>
              <th className="col-md-2">Seria</th>
              <th className="col-md-2">Ilość</th>
            </tr>
          </thead>

          <tbody>
            {_.size(items) > 0 ? (
              items.map((item, key) => {
                return (
                  _.get(item, "series") &&
                  _.size(item.series) > 0 &&
                  item.series.map((series: any, index: number) => {
                    return (
                      <tr key={`${key}-${index}`}>
                        <th scope="row">
                          <h3 className="full-time mb-0">
                            <span>{moment(_.get(item, "createdAt")).format("hh:mm")}</span>
                            {moment(_.get(item, "createdAt")).format("DD.MM.YYYY")}
                          </h3>
                        </th>
                        <td>
                          <h3 className="text-left mt-1">
                            {_.get(item, "document.documentNumber", "-")}
                          </h3>
                        </td>
                        <td>
                          <div className="badge badge-light p-2 border border-2 ">
                            {_.get(series, "position.code", "-")}
                          </div>
                        </td>
                        <td>
                          <h3 className="text-left mt-1">{_.get(series, "seriesNr", "-")}</h3>
                        </td>
                        <td>{_.get(series, "amount", "-")}</td>
                      </tr>
                    );
                  })
                );
              })
            ) : (
              <tr>
                <td className="col-md-12 text-center" colSpan={5}>
                  Brak elementów
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductDocumentReservationComponent;
