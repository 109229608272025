import { AnyAction } from "redux";
import { InventoryInterface, AsyncThunkAction, Nullable, GenericObject, AnyDateFormat } from "../../common/types/common.types";

export enum InventoriesActionType {
    SET_INVENTORIES = 'INVENTORIES_SET_INVENTORIES',
    CLEAR_INVENTORIES = 'INVENTORIES_CLEAR_INVENTORIES',
    SET_FILTERS = 'INVENTORIES_SET_FILTERS',
    CLEAR_FILTERS = 'INVENTORIES_CLEAR_FILTERS',
    SET_PARAMS = 'INVENTORIES_SET_PARAMS',
    CLEAR_PARAMS = 'INVENTORIES_CLEAR_PARAMS',
    SET_PAGE = `INVENTORIES_SET_PAGE`
}

export enum InventoryActionType {
    SET_INVENTORY = 'INVENTORY_SET_INVENTORY',
    CLEAR_INVENTORY = 'INVENTORY_CLEAR_INVENTORY',
    SET_HISTORY = 'INVENTORY_SET_HISTORY',
    CLEAR_HISTORY = 'INVENTORY_CLEAR_HISTORY',
    SET_FILTERS = 'INVENTORY_SET_FILTERS',
    CLEAR_FILTERS = 'INVENTORY_CLEAR_FILTERS',
    SET_PARAMS = 'INVENTORY_SET_PARAMS',
    CLEAR_PARAMS = 'INVENTORY_CLEAR_PARAMS',
    SET_PAGE = `INVENTORY_SET_PAGE`
}

export interface InventoriesStateInterface {
    inventories: Array<InventoryInterface>,
    total: number,
    filters: {
        name: string,
        warehouse: string,
        completed: Nullable<boolean>,
        dateFrom: AnyDateFormat,
        dateTo: AnyDateFormat
    },
    params: {
        limit: number,
        offset: number,
        sort: Nullable<{ [fieldname: string]: number }>
    },
    page: number
}

export interface InventoryStateInterface {
    inventory: Nullable<InventoryInterface>,
    history: Array<any>,
    total: number,
    filters: {
        user: string,
        product: string,
        seriesNr: string,
        position: String
    },
    params: {
        limit: number,
        offset: number,
        sort: Nullable<{ [fieldname: string]: number }>
    },
    page: number
}

export interface InventoriesPropsInterface {
    inventories: InventoriesStateInterface["inventories"],
    total: InventoriesStateInterface["total"],
    filters: InventoriesStateInterface["filters"],
    params: InventoriesStateInterface["params"],
    page: number,
    getInventories: () => AsyncThunkAction,
    clearInventories: () => AnyAction,
    setFilters: (filters: InventoriesStateInterface["filters"], reloadList?: boolean) => AsyncThunkAction,
    clearFilters: (reloadList?: boolean) => AsyncThunkAction,
    setParams: (params: InventoriesStateInterface["params"], reloadList?: boolean) => AsyncThunkAction,
    clearParams: (reloadList?: boolean) => AsyncThunkAction,
    setPage: (page: number) => AnyAction
}

export interface InventoryPropsInterface {
    inventory: InventoryStateInterface["inventory"],
    history: InventoryStateInterface["history"],
    total: InventoryStateInterface["total"],
    filters: InventoryStateInterface["filters"],
    params: InventoryStateInterface["params"],
    page: number,
    getInventory: (id: string) => AsyncThunkAction,
    clearInventory: () => AnyAction,
    saveInventory: (data: GenericObject) => Promise<any>,
    deleteInventory: (id: string) => Promise<any>,
    getHistory: (hideLoader?: boolean) => AsyncThunkAction,
    clearHistory: () => AsyncThunkAction,
    setFilters: (filters: InventoryStateInterface["filters"], reloadList?: boolean) => AsyncThunkAction,
    clearFilters: (reloadList?: boolean) => AsyncThunkAction,
    setParams: (params: InventoryStateInterface["params"], reloadList?: boolean) => AsyncThunkAction,
    clearParams: (reloadList?: boolean) => AsyncThunkAction,
    setPage: (page: number) => AnyAction,
    resetPosition: (id: string) => Promise<any>
}

export interface InventoriesListCardPropsInterface {
    item: InventoryInterface
}

export interface InventoriesFiltersPropsInterface {
    onFiltersChange: (filters: InventoriesStateInterface["filters"]) => void,
    onClearFilters: () => void,
    filters: any
}

export interface InventoriesHeaderPropsInterface {
    onFiltersChange: (filters: InventoriesStateInterface["filters"]) => void,
    onClearFilters: () => void,
    filters: any
}

export interface InventoryHistoryPropsInterface {
    inventory: InventoryStateInterface["inventory"],
    items: Array<any>,
    itemsOnPage: number,
    total: number,
    currentPage: number,
    filters: any,
    onFiltersChange: (filters: InventoriesStateInterface["filters"]) => void,
    onClearFilters: () => void,
    onPageChange: (page: number) => void,
    onPositionReset: (code: string) => void,
    onPositionEdit: (code: string) => void
}

export interface InventoryDetailsHeaderPropsInterface {
    onInventoryFinish: () => void,
    inventory: InventoryStateInterface["inventory"]
}
