import React, { FC, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { AppDispatch, RootState } from "../../config/store";
import {
  clearProduct,
  getProduct,
  deleteProduct,
  getProductWarehouseState,
  clearProductWarehouseState,
  getProductWarehousePositionHistory,
  clearProductWarehousePositionHistory,
  getProductDocumentReservations,
  clearProductDocumentReservations,
  setFilters,
  clearFilters,
  setParams,
  clearParams,
  setPage,
} from "../actions/product.actions";
import ProductDetailsComponent from "../components/productDetails.component";
import { ProductPropsInterface, ProductStateInterface } from "../types/product.types";

const ProductDetailsContainer: FC<ProductPropsInterface> = (props: ProductPropsInterface) => {
  const {
    page,
    getProduct,
    getProductWarehouseState,
    getProductWarehousePositionHistory,
    getProductDocumentReservations,
    clearProduct,
    clearFilters,
    clearProductWarehouseState,
    clearProductDocumentReservations,
    clearProductWarehousePositionHistory,
  } = props;
  let { id }: any = useParams();

  useEffect(() => {
    if (id) getProductWarehousePositionHistory(id);

    return () => {
      clearProductWarehousePositionHistory();
    };
  }, [getProductWarehousePositionHistory, clearProductWarehousePositionHistory, id, page]);

  useEffect(() => {
    if (id) {
      getProduct(id);
      getProductWarehouseState(id);
      getProductDocumentReservations(id);
    }
    return () => {
      clearProduct();
      clearFilters();
      clearProductWarehouseState();
      clearProductDocumentReservations();
    };
  }, [
    getProduct,
    getProductWarehouseState,
    getProductDocumentReservations,
    clearProduct,
    clearFilters,
    clearProductWarehouseState,
    clearProductDocumentReservations,
    id,
  ]);

  return <ProductDetailsComponent {...props} />;
};

const mapStateToProps = (store: RootState) => ({
  product: store.product.product,
  productWarehouseState: store.product.productWarehouseState,
  productWarehousePositionHistory: store.product.productWarehousePositionHistory,
  productDocumentReservations: store.product.productDocumentReservations,
  total: store.product.total,
  filters: store.product.filters,
  params: store.product.params,
  page: store.product.page,
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
  return {
    getProduct: (id: string) => dispatch(getProduct(id)),
    getProductWarehouseState: (productId: string) => dispatch(getProductWarehouseState(productId)),
    getProductWarehousePositionHistory: (productId: string) =>
      dispatch(getProductWarehousePositionHistory(productId)),
    getProductDocumentReservations: (productId: string) =>
      dispatch(getProductDocumentReservations(productId)),
    deleteProduct: (id: string) => deleteProduct(id, dispatch),
    setFilters: (filters: ProductStateInterface["filters"], reloadList?: boolean) =>
      dispatch(setFilters(filters, reloadList)),
    setPage: (page: number) => dispatch(setPage(page)),
    setParams: (params: ProductStateInterface["params"], reloadList?: boolean) =>
      dispatch(setParams(params, reloadList)),
    clearProduct: () => dispatch(clearProduct()),
    clearParams: (reloadList?: boolean) => dispatch(clearParams(reloadList)),
    clearFilters: (reloadList?: boolean) => dispatch(clearFilters(reloadList)),
    clearProductWarehouseState: () => dispatch(clearProductWarehouseState()),
    clearProductDocumentReservations: () => dispatch(clearProductDocumentReservations()),
    clearProductWarehousePositionHistory: () => dispatch(clearProductWarehousePositionHistory()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsContainer);
