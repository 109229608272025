import _ from "lodash";
import React, { useState, FC, Fragment, useEffect } from "react";
import { CategoryFiltersPropsInterface } from "../types/categoryFilters.types";

import ArrowIcon from "../images/ico-arrowdown.svg";

import CheckboxComponent from "./checkbox.component";

const CategoryFiltersComponent: FC<CategoryFiltersPropsInterface> = ({
  categories,
  filters,
  onCategoryChange,
}: CategoryFiltersPropsInterface) => {
  const [checked, setChecked] = useState<string[]>([]);
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (rendered) {
      let newFilters = Object.assign({}, filters, {
        category: _.join(checked, ","),
      });

      onCategoryChange(newFilters);
    }
  }, [checked, rendered]);

  const onCategoryCheck = (id: string, val: boolean, path: string) => {
    if (rendered === false) setRendered(true);

    _.set(_.get(categories, path), "checked", val);

    if (val === true) setChecked((prevState) => [...prevState, id]);
    else setChecked((prevState) => prevState.filter((item) => item !== id));
  };

  const renderCategories = () => {
    return categories.map(({ name, children, _id, checked }, key) => {
      return (
        <li key={key}>
          <div className="d-flex">
            <CheckboxComponent
              onChange={(id, val) => onCategoryCheck(id, val, `${key}`)}
              checked={checked || false}
              id={_id}
              label={name}
            />
            {_.size(children) > 0 && (
              <button
                className="subcategories badge badge-light collapsed border-0"
                type="button"
                data-toggle="collapse"
                data-target={`#categoryFilters${key}children`}
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <img src={ArrowIcon} className="cat-arrow" alt="arrow" />
                Rozwiń
              </button>
            )}
          </div>
          {children && _.size(children) > 0
            ? renderCategoryChildrens(
                children,
                `[${key}].children`,
                `categoryFilters${key}children`
              )
            : null}
        </li>
      );
    });
  };

  const renderCategoryChildrens = (children: any, path: string, targetId: string) => {
    return (
      <ul className="collapse" id={targetId}>
        <li>
          {children.map(({ name, children, _id, checked }: any, key: number) => {
            let newTargetId = `${targetId}${key}children`;

            return (
              <Fragment key={key}>
                <div className="d-flex">
                  <CheckboxComponent
                    onChange={(id, val) => onCategoryCheck(id, val, `${path}[${key}]`)}
                    checked={checked || false}
                    id={_id}
                    label={name}
                  />
                  {_.size(children) > 0 && (
                    <button
                      className="subcategories badge badge-light collapsed border-0"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#${newTargetId}`}
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <img src={ArrowIcon} className="cat-arrow" alt="arrow" />
                      Rozwiń
                    </button>
                  )}
                </div>
                {children && _.size(children) > 0
                  ? renderCategoryChildrens(children, `${path}[${key}].children`, newTargetId)
                  : null}
              </Fragment>
            );
          })}
        </li>
      </ul>
    );
  };

  return (
    <div className="product-categories">
      <div className="d-flex align-items-stretch category-adder px-0">
        <div className="has-float-label pt-4">
          <label htmlFor="product-category">Filtr&nbsp;kategorii</label>
        </div>
        <ul className="col-sm-12 form5-frame pt-3 form-frame scrolling rounded mb-0">
          {renderCategories()}
        </ul>
      </div>
    </div>
  );
};

export default CategoryFiltersComponent;
