import React, { FC, useState, useRef } from 'react';
import ReportsWarehousesProductsComponent from "./reportsWarehousesProducts.component";
import ReportsProductsValidityComponent from './reportsProductsValidity.component';
import ReportsRealizationTimeComponent from "./reportsRealizationTime.component";
import ReportsWarehousesProductsSeriesComponent from './reportsWarehoseProductsSeries.component';
import ReportsGmpComponent from './reportsGmp.component';
import ReportsProductsExportComponent from './reportsProductsExport.component';
import ReportsDocumentsProductsComponent from './reportsDocumentsProducts.component';
import ReportsProductsAvaiabilityComponent from './reportsProductsAvailability.component';
import IcoMag from '../../common/images/ico-mag.svg';
import IcoExpiry from '../../common/images/ico-expiry.svg';
import IcoWorkTime from '../../common/images/ico-work-time.svg';
import IcoMagSerie from '../../common/images/ico-mag-serie.svg';
import IcoGmp from '../../common/images/ico-gmp.svg';
import IcoExport from '../../common/images/ico-export.svg';
import IcoProdDoc from '../../common/images/ico-prod-doc.svg';
import IcoProdAlert from '../../common/images/ico-prod-alert.svg';
import IcoRaporty from '../../common/images/ico-raporty.svg';
import { getReport } from '../actions/reports.action';

const ReportsComponent: FC = () => {
    //data
    const [warehousesProductsData, setWarehousesProductsData] = useState({ warehouse: "" });
    const [productsValidityData, setProductsValidityData] = useState({ warehouse: "" });
    const [realizationTimeData, setRealizationTimeData] = useState({ warehouse: "" });
    const [warehouseProductsSeriesData, setWarehouseProductsSeriesData] = useState({ warehouse: "" });
    const [GmpData, setGmpData] = useState({ warehouse: "", product: "", seriesNr: "" });
    const [productsExportData, setProductsExportData] = useState({});
    const [documentsProductsData, setDocumentsProductsData] = useState({ warehouse: "", documentType: "" });
    const [productsAvailabilityData, setProductsAvailabilityData] = useState({});

    //refs for mobile
    const warehousesProductsRef = useRef<HTMLAnchorElement>(null);
    const productsValidityRef = useRef<HTMLAnchorElement>(null);
    const realizationTimeRef = useRef<HTMLAnchorElement>(null);
    const warehouseProductsSeriesRef = useRef<HTMLAnchorElement>(null);
    const GmpRef = useRef<HTMLAnchorElement>(null);
    const productsExportRef = useRef<HTMLAnchorElement>(null);
    const documentsProductsRef = useRef<HTMLAnchorElement>(null);
    const productsAvailabilityRef = useRef<HTMLAnchorElement>(null);

    const handleMobileNav = (e: any) => {
        let { value } = e.target;
        switch (value) {
            case "warehouse-products":
                warehousesProductsRef?.current?.click();
                break;
            case "products-validity":
                productsValidityRef?.current?.click();
                break;
            case "realization-time":
                realizationTimeRef?.current?.click();
                break;
            case "warehouse-products-series":
                warehouseProductsSeriesRef?.current?.click();
                break;
            case "gmp":
                GmpRef?.current?.click();
                break;
            case "products-export":
                productsExportRef?.current?.click();
                break
            case "documents-products":
                documentsProductsRef?.current?.click();
                break;
            case "products-availability":
                productsAvailabilityRef?.current?.click();
                break;
        }
    }

    //errors
    const [warehouseError, setWarehouseError] = useState("");
    const [productsError, setProductsError] = useState("");
    const [seriesNrError, setSeriesNrError] = useState("");
    const [documentTypeError, setDocumentTypeError] = useState("");

    const clearErr = () => {
        setWarehouseError("");
        setProductsError("");
        setSeriesNrError("");
        setDocumentTypeError("");
    }

    const [activeForm, setActiveForm] = useState("report-form-stan");

    const handleSubmit = (e: any) => {
        let shouldBreak = false;

        e.preventDefault();
        clearErr();
        switch (activeForm) {
            case 'report-form-stan':
                if (!warehousesProductsData.warehouse) {
                    setWarehouseError("Wybierz magazyn!");
                    break;
                }
                getReport(warehousesProductsData);
                break;
            case 'report-form-terminy':
                if (!productsValidityData.warehouse) {
                    setWarehouseError("Wybierz magazyn!");
                    break;
                }
                getReport(productsValidityData);
                break;
            case 'report-form-czasy':
                if (!realizationTimeData.warehouse) {
                    setWarehouseError("Wybierz magazyn!");
                    break;
                }
                getReport(realizationTimeData);
                break;
            case 'report-form-stan-serie':
                if (!warehouseProductsSeriesData.warehouse) {
                    setWarehouseError("Wybierz magazyn!");
                    break;
                }
                getReport(warehouseProductsSeriesData);
                break;
            case "report-form-gmp":
                shouldBreak = false;
                if (!GmpData.warehouse) {
                    setWarehouseError("Wybierz magazyn!");
                    shouldBreak = true;
                }
                if (!GmpData.product) {
                    setProductsError("Wybierz produkt!");
                    shouldBreak = true;
                }
                if (!GmpData.seriesNr) {
                    setSeriesNrError("Uzupełnij numer serii!");
                    shouldBreak = true;
                }
                if (shouldBreak) {
                    break;
                }
                getReport(GmpData);
                break;
            case "report-form-eksport":
                getReport(productsExportData);
                break;
            case "report-form-dokumenty":
                shouldBreak = false;
                if (!documentsProductsData.warehouse) {
                    setWarehouseError("Wybierz magazyn!");
                    shouldBreak = true;
                }
                if (!documentsProductsData.documentType) {
                    setDocumentTypeError("Wybierz typ dokumentu!");
                    shouldBreak = true;
                }
                if (shouldBreak) {
                    break;
                }
                getReport(documentsProductsData);
                break;
            case "report-form-dostepnosc":
                getReport(productsAvailabilityData);
                break;
        }
    }
    return (
        <form id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-4" onSubmit={handleSubmit}>
            <ul className="d-flex justify-content-stretch report-tiles mb-4 nav " role="tablist">
                <li id="report-stan" className="flex-grow-1 report-tile-container" role="presentation">
                    <a ref={warehousesProductsRef} onClick={() => { clearErr(); setActiveForm("report-form-stan") }} href="#report-form-stan" type="button" className="report-tile nav-link" data-toggle="pill" role="tab" id="report-form-stan-tab" aria-controls="report-form-stan" aria-selected="true"><img src={IcoMag} alt="" /><h5>Stan magazynowy produktów</h5></a>
                </li>
                <li id="report-terminy" className="flex-grow-1 report-tile-container" role="presentation">
                    <a ref={productsValidityRef} onClick={() => { clearErr(); setActiveForm("report-form-terminy") }} href="#report-form-terminy" type="button" className="report-tile nav-link" data-toggle="pill" role="tab" id="report-form-terminy-tab" aria-controls="report-form-terminy" aria-selected="false"><img src={IcoExpiry} alt="" /><h5>Terminy ważności produktów</h5></a>
                </li>
                <li id="report-czasy" className="flex-grow-1 report-tile-container" role="presentation">
                    <a ref={realizationTimeRef} onClick={() => { clearErr(); setActiveForm("report-form-czasy") }} href="#report-form-czasy" type="button" className="report-tile nav-link" data-toggle="pill" role="tab" id="report-form-czasy-tab" aria-controls="report-form-czasy" aria-selected="false"><img src={IcoWorkTime} alt="" /><h5>Czasy realizacji dokumentów</h5></a>
                </li>
                <li id="report-stan-serie" className="flex-grow-1 report-tile-container" role="presentation">
                    <a ref={warehouseProductsSeriesRef} onClick={() => { clearErr(); setActiveForm("report-form-stan-serie") }} href="#report-form-stan-serie" type="button" className="report-tile nav-link" data-toggle="pill" role="tab" id="report-form-stan-serie-tab" aria-controls="report-form-stan-serie" aria-selected="false"><img src={IcoMagSerie} alt="" /><h5>Stan magazynowy produktów z seriami</h5></a>
                </li>
                <li id="report-gmp" className="flex-grow-1 report-tile-container" role="presentation">
                    <a ref={GmpRef} onClick={() => { clearErr(); setActiveForm("report-form-gmp") }} href="#report-form-gmp" type="button" className="report-tile nav-link" data-toggle="pill" role="tab" id="report-form-gmp-tab" aria-controls="report-form-gmp" aria-selected="false"><img src={IcoGmp} alt="" /><h5>Raport GMP+</h5></a>
                </li>
                <li id="report-eksport" className="flex-grow-1 report-tile-container" role="presentation">
                    <a ref={productsExportRef} onClick={() => { clearErr(); setActiveForm("report-form-eksport") }} href="#report-form-eksport" type="button" className="report-tile nav-link" data-toggle="pill" role="tab" id="report-form-eksport-tab" aria-controls="report-form-eksport" aria-selected="false"><img src={IcoExport} alt="" /><h5>Eksport produktów</h5></a>

                </li>
                <li id="report-dokumenty" className="flex-grow-1 report-tile-container" role="presentation">
                    <a ref={documentsProductsRef} onClick={() => { clearErr(); setActiveForm("report-form-dokumenty") }} href="#report-form-dokumenty" type="button" className="report-tile nav-link" data-toggle="pill" role="tab" id="report-form-dokumenty-tab" aria-controls="report-form-dokumenty" aria-selected="false"><img src={IcoProdDoc} alt="" /><h5>Produkty z dokumentów</h5></a>
                </li>
                <li id="report-dostepnosc" className="flex-grow-1 report-tile-container" role="presentation">
                    <a ref={productsAvailabilityRef} onClick={() => { clearErr(); setActiveForm("report-form-dostepnosc") }} href="#report-form-dostepnosc" type="button" className="report-tile nav-link" data-toggle="pill" role="tab" id="report-form-dostepnosc-tab" aria-controls="report-form-dostepnosc" aria-selected="false"><img src={IcoProdAlert} alt="" /><h5>Dostępność produktów</h5></a>
                </li>
            </ul>
            <div className="bg-white rounded-10 p-1 report-container d-flex flex-wrap justify-content-center">
                <div className="col-sm-12 report-tiles-mobile form-group input-group align-items-center mt-3 ">
                    <label className="form-group has-float-label d-flex justify-content-stretch">
                        <select id="mobile-nav" className="selectpicker form-control custom-select " title="Wybierz raport" onChange={handleMobileNav}>
                            <option value="warehouse-products">Stan magazynowy produktów</option>
                            <option value="products-validity">Terminy ważności produktów</option>
                            <option value="realization-time">Czasy realizacji dokumentów</option>
                            <option value="warehouse-products-series">Stan magazynowy produktów z seriami</option>
                            <option value="gmp">Raport GMP+</option>
                            <option value="products-export">Eksport produktów</option>
                            <option value="documents-products">Produkty z dokumentów</option>
                            <option value="products-availability">Dostępność produktów</option>
                        </select >
                        <span>Rodzaj raportu</span>
                    </label >
                </div >
                <div className="d-flex align-items-center justify-content-center ">
                    <div id="report-form-container" className="tab-content d-flex flex-wrap col-xl-8 col-lg-8 col-md-10 col-sm-12 d-flex py-5 my-5 ">
                        <ReportsWarehousesProductsComponent onDataChange={setWarehousesProductsData} warehouseError={warehouseError} />
                        <ReportsProductsValidityComponent onDataChange={setProductsValidityData} warehouseError={warehouseError} />
                        <ReportsRealizationTimeComponent onDataChange={setRealizationTimeData} warehouseError={warehouseError} />
                        <ReportsWarehousesProductsSeriesComponent onDataChange={setWarehouseProductsSeriesData} warehouseError={warehouseError} />
                        <ReportsGmpComponent onDataChange={setGmpData} warehouseError={warehouseError} productsError={productsError} seriesNrError={seriesNrError} />
                        <ReportsProductsExportComponent onDataChange={setProductsExportData} />
                        <ReportsDocumentsProductsComponent onDataChange={setDocumentsProductsData} warehouseError={warehouseError} documentTypeError={documentTypeError} />
                        <ReportsProductsAvaiabilityComponent onDataChange={setProductsAvailabilityData} />
                    </div>
                </div>
                <button type="submit" className="btn-wms color-white rounded border-0 px-4 mb-5 py-2"><img src={IcoRaporty} alt="" /> Generuj</button>
            </div >
        </form >
    );
};
export default ReportsComponent;
