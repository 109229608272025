import _ from "lodash";
import moment from "moment";
import React, { FC, Fragment } from "react";
import { useHistory, useParams } from "react-router";
import DeleteConfirmModal from "../../common/components/deleteConfirmModal.component";
import completionTodoIcon from "../../common/images/ico-dorealizacji.svg";
import inspectionTodoIcon from "../../common/images/ico-dosprawdzenia.svg";
import inspectionInProgressIcon from "../../common/images/ico-sprawdzanie.svg";
import inspectedIcon from "../../common/images/ico-sprawdzone.svg";
import completionInProgressIcon from "../../common/images/ico-wrealizacji.svg";
import completedIcon from "../../common/images/ico-zrealizowane.svg";
import { DocumentDetailsPropsInterface } from "../types/document.types";
import DocumentDetailsHeaderComponent from "./documentDetailsHeader.component";
import DocumentDetailsProductTable from "./documentDetailsProductTable";
import Api from "../../application/services/api.service";
import Acl from "../../application/services/acl.service";
import blankAvatar from "../../common/images/ufo-user.jpg";
import dictService from "../../application/services/dict.service";
import utilityService from "../../application/services/utility.service";

const DocumentDetailsComponent: FC<DocumentDetailsPropsInterface> = ({ warehouseDocument, deleteDocument }: DocumentDetailsPropsInterface) => {
    let { id }: any = useParams();
    let history = useHistory();
    let showReservations = _.get(warehouseDocument, "reservations.length", 0) > 0;
    let completionStatus = _.get(warehouseDocument, "completion.status", 1);
    let inspectionStatus = _.get(warehouseDocument, "inspection.status", 1);
    let shouldShowDelete = Acl.isAllowedMethod("document/document", "delete") && completionStatus === 1 && _.get(warehouseDocument, "reservations.length", 0) === 0;

    const getCompletionIcon = () => {
        switch (completionStatus) {
            case 3:
            case "3": return <img src={completedIcon} alt="zrealizowany" />;
            case 2:
            case "2": return <img src={completionInProgressIcon} alt="w trakcie realizacji" />;
            case 1:
            case "1":
            default: return <img src={completionTodoIcon} alt="do realizacji" />;
        }
    }

    const getCompletionStatusName = () => {
        switch (completionStatus) {
            case 3:
            case "3": return "Zrealizowany";
            case 2:
            case "2": return "W trakcie realizacji";
            case 1:
            case "1":
            default: return "Do realizacji";
        }
    }

    const getInspectionIcon = () => {
        switch (inspectionStatus) {
            case 3:
            case "3": return <img src={inspectedIcon} alt="sprawdzony" className="ml-3" />;
            case 2:
            case "2": return <img src={inspectionInProgressIcon} alt="w trakcie sprawdzania" className="ml-3" />;
            case 1:
            case "1":
            default: return <img src={inspectionTodoIcon} alt="do sprawdzenia" className="ml-3" />;
        }
    }

    const getInspectionStatusName = () => {
        switch (inspectionStatus) {
            case 3:
            case "3": return "Sprawdzony";
            case 2:
            case "2": return "W trakcie sprawdzania";
            case 1:
            case "1":
            default: return "Do sprawdzenia";
        }
    }

    const renderContractorData = () => {
        let name = _.get(warehouseDocument, "contractor.name");
        let address = _.get(warehouseDocument, "contractor.address");
        let nip = _.get(warehouseDocument, "contractor.nip");

        return (<div className="form-control2" id="document-status">
            {name && <div>{name}</div>}
            {address && <div>{address.postCode || ""} {address.city || ""} {address.street || ""} {address.houseNumber || ""} </div>}
            {nip && <div>NIP: {nip}</div>}
        </div>)
    }

    const getUserAvatarSrc = (avatar: string) => {
        if (avatar) {
            return Api.getUrl() + avatar;
        }
        return blankAvatar;
    }

    const renderReservations = () => {
        let index = 0;
        let arr: any = [];
        _.each(_.get(warehouseDocument, "reservations", []), (reservation: any, rIndex: number) => {
            _.each(reservation.series, (series: any, sIndex: number) => {
                index++;
                arr.push(<tr key={`r${rIndex}s${sIndex}`}>
                    <td>{index}</td>
                    <th scope="row">{reservation.product.name}</th>
                    <td>
                        <div className="badge badge-light p-2 border border-2 ">{series.position.code}</div>
                    </td>
                    <td>
                        <h3 className="text-left mt-1">{series.documentSeriesNr}</h3>
                    </td>
                    <td>
                        <h3 className="text-left mt-1">{series.seriesNr}</h3>
                    </td>
                    <td>{series.amount}</td>
                    <td>{dictService.getDictValueName(3, series.unit, "szt")}</td>
                </tr>);
            });
        });
        return arr;
    }

    return <Fragment>
        <DeleteConfirmModal id="document-delete" onDelete={() => {
            deleteDocument(id).then((res: any) => {
                if (res.success) {
                    document.getElementById(`document-delete-cancel`)?.click();
                    history.push("/documents/page/1");
                }
            })
        }} />
        <DocumentDetailsHeaderComponent showDelete={shouldShowDelete} warehouseDocument={warehouseDocument} />

        <div id="content-container-right" className="document-details-container col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2 ">
            <div className="bg-white rounded-10 boxshadow p-0">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="col-md-10 col-sm-12 d-flex flex-wrap py-5 my-5">
                        <div className="row col-sm-12 px-0">
                            <div className="col-sm-12 col-md-8">
                                <h2 className="text-center">{_.get(warehouseDocument, "documentNumber", "-")}{getInspectionIcon()} {getCompletionIcon()} </h2>
                                <div className="row col-sm-12 my-4">
                                    <hr className="mt-0" />
                                </div>
                                <div className="row col-sm-12 document-details ">
                                    <div className="form-group col-md-4 col-sm-12 input-group d-flex flex-wrap">
                                        <span className="has-float-label">
                                            <div className="form-control2" id="document-status">{getCompletionStatusName()}</div>
                                            <label htmlFor="document-status">Status dokumentu</label>
                                        </span>
                                    </div>
                                    <div className="form-group col-md-3 col-sm-12  input-group d-flex flex-wrap">
                                        <span className="has-float-label">
                                            <div className="form-control2" id="document-status">{getInspectionStatusName()}</div>
                                            <label htmlFor="document-status">Status zlecenia</label>
                                        </span>
                                    </div>
                                    <div className="form-group col-md-5 col-sm-12  input-group d-flex flex-wrap">
                                        <span className="has-float-label">
                                            <div className="form-control2" id="document-status">{_.get(warehouseDocument, "warehouse.name", "-")}</div>
                                            <label htmlFor="document-status">Magazyn</label>
                                        </span>
                                    </div>
                                </div>
                                <div className="row col-sm-12 document-details">
                                    {warehouseDocument?.contractor && <div className="form-group col-md-4 col-sm-12 input-group d-flex flex-wrap">
                                        <span className="has-float-label">
                                            {renderContractorData()}
                                            <label htmlFor="document-status">Kontrahent</label>
                                        </span>
                                    </div>}
                                    {warehouseDocument?.description && <div className="form-group col-md-8 col-sm-12  input-group d-flex flex-wrap">
                                        <span className="has-float-label">
                                            <div className="form-control2" id="document-status">
                                                {_.get(warehouseDocument, "description", "-")}
                                            </div>
                                            <label htmlFor="document-status">Opis dokumentu</label>
                                        </span>
                                    </div>}
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 px-0 document-details">
                                <div className="d-flex flex-column my-3">
                                    <div className="d-flex align-items-center">
                                        <div className="worker-img px-3 mb-3">
                                            <img className="rounded-circle" src={getUserAvatarSrc(_.get(warehouseDocument, "creator.avatar"))} alt="avatar" />
                                        </div>
                                        <div className="has-float-label">
                                            <div className="form-control2 pb-0" id="document-add">
                                                <h3>{_.get(warehouseDocument, "creator.name")}</h3>
                                            </div>
                                            <label htmlFor="document-add">Dodanie</label>
                                            <h5>{moment(warehouseDocument?.createdAt).format("DD.MM.YYYY")} <span>{moment(warehouseDocument?.createdAt).format("HH:mm")}</span></h5>
                                        </div>
                                    </div>
                                    {_.get(warehouseDocument, "completion.user.userId") && <Fragment>
                                        <div className="row col-sm-12 my-2">
                                            <hr className="mt-0" />
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="worker-img px-3 mb-3">
                                                <img className="rounded-circle" src={getUserAvatarSrc(_.get(warehouseDocument, "completion.user.avatar"))} alt="avatar" />
                                            </div>
                                            <div className="has-float-label">
                                                <div className="form-control2 pb-0" id="document-done">
                                                    <h3>{_.get(warehouseDocument, "completion.user.name")}</h3>
                                                </div>
                                                <label htmlFor="document-done">Realizacja</label>
                                                {completionStatus === 3 && <h5>
                                                    {moment(warehouseDocument?.completion.completionStart).format("DD.MM.YYYY")}
                                                    <span> {moment(warehouseDocument?.completion.completionStart).format("HH:mm")}</span>
                                                </h5>}
                                                {completionStatus === 3 && <h5>
                                                    {moment(warehouseDocument?.completion.completionEnd).format("DD.MM.YYYY")}
                                                    <span> {moment(warehouseDocument?.completion.completionEnd).format("HH:mm")}</span>
                                                </h5>}
                                                <h5>Czas kompletacji: <span>{warehouseDocument?.completion.completionTime} {utilityService.polishPlural("minuta", "minuty", "minut", _.get(warehouseDocument, "completion.completionTime", 0))}</span></h5>
                                            </div>
                                        </div>
                                    </Fragment>}
                                    {_.get(warehouseDocument, "inspection.user.userId") && <Fragment><div className="row col-sm-12 my-2">
                                        <hr className="mt-0" />
                                    </div>
                                        <div className="d-flex align-items-center">
                                            <div className="worker-img px-3 mb-3">
                                                <img className="rounded-circle" src={getUserAvatarSrc(_.get(warehouseDocument, "inspection.user.avatar"))} alt="avatar" />
                                            </div>
                                            <div className="has-float-label">
                                                <div className="form-control2 pb-0" id="document-check">
                                                    <h3>{_.get(warehouseDocument, "inspection.user.name")}</h3>
                                                </div>
                                                <label htmlFor="document-check">Sprawdzenie</label>
                                                <h5>
                                                    {moment(warehouseDocument?.inspection.inspectedAt).format("DD.MM.YYYY")}
                                                    <span> {moment(warehouseDocument?.inspection.inspectedAt).format("HH:mm")}</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </Fragment>}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 px-0 mx-0 mb-4 tabs-container">
                            <ul className="nav nav-pills mb-3 mx-3" id="details-tab" role="tablist">
                                <li className="nav-item " role="presentation">
                                    <a className="nav-link active" id="details-home-tab" data-toggle="tab" href="#tab1" data-bs-toggle="pill" data-bs-target="#tab1" type="button" role="tab" aria-controls="details-1" aria-selected="true">Produkty</a>
                                </li>
                                {showReservations && <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="details-profile-tab" data-toggle="tab" href="#tab2" data-bs-toggle="pill" data-bs-target="#tab2" type="button" role="tab" aria-controls="details-2" aria-selected="false">Rezerwacje</a>
                                </li>}
                            </ul>
                            <div className="tab-content" id="details-tabContent">
                                <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="details-home-tab">
                                    <div className=" table table-responsive my-4">
                                        <DocumentDetailsProductTable status={completionStatus} items={completionStatus === 3 ? _.get(warehouseDocument, "completion.result", []) : _.get(warehouseDocument, "products", [])} />
                                    </div>
                                </div>
                                {showReservations && <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="details-profile-tab">
                                    <div className=" table table-responsive my-4">
                                        <table id="table-warehouses" className="table table-hover mb-0">
                                            <thead>
                                                <tr className="thead-light">
                                                    <th scope="col" className="col-md-1 text-center">Lp.</th>
                                                    <th scope="col" className="col-md-5">Nazwa</th>
                                                    <th className="col-md-2">Pozycja magazynowa</th>
                                                    <th className="col-md-2">Seria w dokumencie</th>
                                                    <th className="col-md-2">Seria</th>
                                                    <th className="col-md-1">Ilość</th>
                                                    <th className="col-md-1">Jednostka</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderReservations()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}

export default DocumentDetailsComponent;