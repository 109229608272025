import React, { FC, useState } from 'react';
import ReportsPropsInterface from '../types/reports.types';
import Autocomplete from '../../common/components/autocomplete.component';
import moment from 'moment';
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';

const ReportsRealizationTimeComponent: FC<ReportsPropsInterface> = ({ onDataChange, warehouseError }: ReportsPropsInterface) => {

    const type = "3";
    const [warehouse, setWarehouse] = useState("");

    const filter = { dateFrom: moment().startOf("week").format('MM/DD/YYYY'), dateTo: moment().format('MM/DD/YYYY') };

    let props = { type: type, warehouse: warehouse, dateFrom: filter.dateFrom, dateTo: filter.dateTo };

    const handleFindWarehouse = (value: any) => {
        if (value === null) {
            setWarehouse("");
            props.warehouse = "";
            onDataChange(props);
        }
        if (value) {
            setWarehouse(value.code);
            props.warehouse = value.code;
            onDataChange(props);
        };
    };

    const handleRangeChange = (event: any, picker: any) => {
        let newFilters = {
            dateFrom: picker.startDate.clone().format("MM/DD/YYYY"),
            dateTo: picker.endDate.clone().format('MM/DD/YYYY')
        };
        props.dateFrom = newFilters.dateFrom;
        props.dateTo = newFilters.dateTo;
        onDataChange(props);
    }

    return (

        <div id="report-form-czasy" className="report-form tabpane fade" role="tabpanel" aria-labelledby="report-form-czasy-tab">
            <div className="col-md-4 col-sm-12  form-group input-group my-0">
                <div className="has-float-label">
                    <Autocomplete
                        id="report-warehouse"
                        multi={false}
                        placeholder="Szukaj"
                        label="Magazyn"
                        url="/warehouse/warehouse"
                        optionLabelField="wyszukaj"
                        optionIdField="reportWarehouseId"
                        onOptionSelect={(id: string, value: any) => handleFindWarehouse(value)}
                        value={warehouse}
                        queryFields={["name", "code", "_id"]}
                        additionalParams={{ limit: 5, select: "name,code,_id" }}
                        parseOption={(option: any) => {
                            option.warehouseId = option._id;
                            return option;
                        }}
                        hideChips={false}
                        readonly={false}
                        preload={false}
                        error={warehouseError}
                    />
                </div>
            </div>

            <div id="date-filter-container" className="col-md-4 col-sm-12 px-0 d-flex align-items-center">
                <div id="current-filter" className="date-filter"><span>Dzień:</span>
                    <DateRangePicker
                        initialSettings={{ startDate: filter.dateFrom, endDate: filter.dateTo }}
                        onApply={handleRangeChange}
                    >
                        <input type="text" name="datefilter" />
                    </DateRangePicker>
                </div>
            </div>
        </div>
    );
};
export default ReportsRealizationTimeComponent;