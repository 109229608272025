import { DocumentStateInterface, DocumentActionType } from "../types/document.types";

const initialState: DocumentStateInterface = {
    document: null
}

const documentReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case DocumentActionType.SET_DOCUMENT: {
            return {
                ...state,
                document: action.document
            }
        }
        case DocumentActionType.CLEAR_DOCUMENT: {
            return {
                ...state,
                document: null
            }
        }
        default: return state;
    }
}

export default documentReducer;