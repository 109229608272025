import Api from "../services/api.service";
import { toast } from "react-toastify";

export const getShortcutsInfo = async () => {
    try {
        let res = await Api.get("/application/dashboard");
        if (res.success) {
            return res;
        } else if (res.errors) {
            toast.error("Błąd zapisu skrótu")
            return res;
        } else {
            toast.error("Błąd zapisu skrótu")
            return { success: false, errors: { message: "Brak autoryzacji" } };
        }
    } catch (err) {
        toast.error("Błąd pobierania kokpitu");
        return { success: false, errors: { message: "Brak autoryzacji" } };
    }
}

export const saveShortcut = async (id: string, shortcuts: any) => {
    try {
        let res = await Api.put(`/application/dashboard/${id}`, { shortcuts: shortcuts });
        if (res.success) {
            toast.success("Zapisano skrót")
            return { success: true };
        } else if (res.errors) {
            toast.error("Błąd zapisu skrótu")
            return res;
        } else {
            toast.error("Błąd zapisu skrótu")
            return { success: false, errors: { message: "Brak autoryzacji" } };
        }
    } catch (err) {
        toast.error("Błąd zapisu skrótu");
        return { success: false, errors: { message: "Brak autoryzacji" } };
    }
}

