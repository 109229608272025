import _ from "lodash";
import React, { FC, Fragment, useEffect, useState } from 'react';
import Api from "../../application/services/api.service";
import deleteIcon from "../images/ico-x.svg";
import { GenericObject } from '../types/common.types';
import { AutocompletePropsInterface } from "../types/component.types";

const Autocomplete: FC<AutocompletePropsInterface> = ({
    id,
    url,
    multi,
    placeholder,
    label,
    optionLabelField,
    optionIdField,
    onOptionSelect,
    value,
    error,
    queryFields,
    additionalParams,
    parseOption,
    hideChips,
    readonly,
    preload,
    customClass
}: AutocompletePropsInterface) => {

    let [isLoading, setIsLoading] = useState(false);
    let [options, setOptions] = useState([]);
    let [selected, setSelected] = useState([]);
    let [showOptions, setShowOptions] = useState(false);
    let [optionHovered, setOptionHovered] = useState(false);
    let [changeBlocked, setChangeBlocked] = useState(false);
    let [noResults, setNoResults] = useState(false);
    const defaultParams = {
        limit: 5
    }

    useEffect(() => {
        if (!_.isEmpty(value) && !changeBlocked) {
            let castValue: any = _.castArray(value);
            setSelected(castValue);
            setChangeBlocked(false);
        } else if (_.isEmpty(value)) {
            setSelected([]);
        }
    }, [value])

    const getRequestParams = (query: string, preload: boolean = false) => {
        let reqParams: GenericObject = {};
        if (_.isArray(queryFields) && !preload) {
            _.each(queryFields, (qField) => {
                reqParams[`${qField}[or]`] = query;
            });
        }
        return Object.assign({}, defaultParams, additionalParams, reqParams);
    }

    const getOptions = async (query: string, preload: boolean = false) => {
        setNoResults(false);
        if (_.size(query) > 2 || preload) {
            setIsLoading(true);
            let res = await Api.get(url, getRequestParams(query, preload));
            if (res && res.success) {
                if (_.isEmpty(res.documents)) {
                    setNoResults(true);
                }
                setOptions(res.documents);
            }
            setIsLoading(false);
        } else if (_.size(query) < 3 && !_.isEmpty(options)) {
            setOptions([]);
        }
    }

    const renderOptions = () => {
        if (!showOptions) return null;
        if (isLoading) {
            return (<div className="ac-item d-flex flex-wrap justify-content-between" onMouseOver={() => setOptionHovered(true)} onMouseOut={() => setOptionHovered(false)}>
                <div className="ac-name col-md-11 col-sm-12">Ładowanie ...</div>
            </div>);
        } else {
            if (noResults) {
                return (<div className="ac-item d-flex flex-wrap justify-content-between" onMouseOver={() => setOptionHovered(true)} onMouseOut={() => setOptionHovered(false)}>
                    <div className="ac-name col-md-11 col-sm-12">Brak wyników</div>
                </div>);
            } else {
                return options.map((option: any, index) => {
                    option = _.isFunction(parseOption) ? parseOption(option) : option;
                    return (<Fragment key={`${id}option${index}`}>
                        <div className="dropdown-divider"></div>
                        <div
                            onClick={() => handleSelect(index)}
                            className="ac-item d-flex flex-wrap justify-content-between"
                            onMouseOver={() => setOptionHovered(true)}
                            onMouseOut={() => setOptionHovered(false)}
                        >
                            <div className="ac-name col-md-11 col-sm-12">{_.get(option, optionLabelField, _.get(option, "name", `Opcja ${index + 1}`))}</div>
                        </div>
                    </Fragment>
                    );
                });
            }
        }
    }

    const handleSelect = (index: number) => {
        setChangeBlocked(true);
        if (!!multi) {
            let newSelected: any = _.uniqBy(_.concat(selected, _.nth(options, index)), (o: any) => o[optionIdField]);
            if (_.isFunction(onOptionSelect)) {
                onOptionSelect(id, newSelected);
            }
            setSelected(newSelected);
        } else {
            setSelected([options[index]]);
            if (_.isFunction(onOptionSelect)) {
                onOptionSelect(id, options[index]);
            }
        }
        if (id) {
            let input: any = document.getElementById(id);
            if (input) input.value = "";
        }
        setShowOptions(false);
    }

    const handleDeleteSelection = (index: number) => {
        setChangeBlocked(true);
        if (!!multi) {
            let newSelected: any = _.without(selected, _.nth(selected, index));
            if (_.isFunction(onOptionSelect)) {
                onOptionSelect(id, newSelected);
            }
            setSelected(newSelected);
        } else {
            if (_.isFunction(onOptionSelect)) {
                onOptionSelect(id, null);
            }
            setSelected([]);
        }
    }

    const renderChips = () => {
        if (hideChips) return null;
        if (!_.isEmpty(selected)) {
            return selected.map((sel, index) => {
                return (<div
                    className="badge badge-pill ac-badge border border-1 py-1 px-2 mb-2 mr-2"
                    key={`${id}chip${index}`}
                >
                    {_.get(sel, optionLabelField, _.get(sel, "name", `Opcja ${index + 1}`))}
                    <img src={deleteIcon} onClick={() => handleDeleteSelection(index)} alt="Usuń" />
                </div>);
            });
        }
        return null;
    }

    let amountDelayTimer: any;
    const updateText = (text: string) => {
        clearTimeout(amountDelayTimer);
        amountDelayTimer = setTimeout(function () {
            getOptions(text, false);
        }, 1000);
    }


    useEffect(() => {
        if (preload) {
            getOptions("", true);
        }
    }, [preload]);

    return (<Fragment>
        <div className={customClass ? customClass : "form-group input-group"}>
            {renderChips()}
            <div className="has-float-label">
                <input
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                    id={id}
                    onChange={(e) => updateText(e.target.value)}
                    placeholder={placeholder}
                    autoComplete="off"
                    type="text"
                    readOnly={!!readonly}
                    onFocus={() => setShowOptions(true)}
                    onBlur={() => optionHovered ? null : setShowOptions(false)}
                />
                <label onClick={() => document.getElementById(id)?.focus()}>{label}</label>
            </div>
            {error && <span className="is-invalid-alert">{error.toString()}</span>}
        </div>
        {((showOptions && options.length > 0) || noResults) && <div className="autocomplete-form-cont-nofilters col-sm-12">
            <div className="autocomplete-form border border-1">
                <div className="ac-options">
                    {renderOptions()}
                </div>
            </div>
        </div>}
    </Fragment>)
}

export default Autocomplete;