import { DashboardStateInterface, DashboardActionType } from "../types/dashboard.types";

const initialState: DashboardStateInterface = {
    dashboard: null
}

const dashboardReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case DashboardActionType.SET_DASHBOARD: {
            return {
                ...state,
                dashboard: action.dashboard
            }
        }
        case DashboardActionType.CLEAR_DASHBOARD: {
            return {
                ...state,
                dashboard: null
            }
        }
        default: return state;
    }
}

export default dashboardReducer;