import React, { FC, Fragment, useState } from 'react';
import Autocomplete from '../../common/components/autocomplete.component';
import CheckboxComponent from '../../common/components/checkbox.component';
import ReportsPropsInterface from '../types/reports.types';

const ReportsWarehousesProductsComponent: FC<ReportsPropsInterface> = ({ onDataChange, warehouseError }: ReportsPropsInterface) => {
    const type = "1";
    const [warehouse, setWarehouse] = useState("");
    const [includeDocuments, setIncludeDocuments] = useState(false);

    let props = { type: type, warehouse: warehouse, includeDocuments: includeDocuments }

    const handleFindWarehouse = (value: any) => {
        if (value === null) {
            setWarehouse("");
            props.warehouse = "";
            onDataChange(props);
        }
        if (value) {
            setWarehouse(value.code);
            props.warehouse = value.code;
            onDataChange(props);
        }
    }

    const handleIncludeDocuments = () => {
        setIncludeDocuments(!includeDocuments);
        props.includeDocuments = !includeDocuments;
        onDataChange(props);
    }

    return (
        <Fragment>

            <div id="report-form-stan" className="report-form tab-pane fade show active" role="tabpanel" aria-labelledby="report-form-stan-tab">
                <div className="form-group input-group my-0">
                    <div className="has-float-label">
                        <Autocomplete
                            id="report-warehouse"
                            multi={false}
                            placeholder="Szukaj"
                            label="Magazyn"
                            url="/warehouse/warehouse"
                            optionLabelField="wyszukaj"
                            optionIdField="reportWarehouseId"
                            onOptionSelect={(id: string, value: any) => handleFindWarehouse(value)}
                            value={warehouse}
                            queryFields={["name", "code", "_id"]}
                            additionalParams={{ limit: 5, select: "name,code,_id" }}
                            parseOption={(option: any) => {
                                option.warehouseId = option._id;
                                return option;
                            }}
                            hideChips={false}
                            readonly={false}
                            preload={false}
                            error={warehouseError}
                        />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12 custom-control custom-checkbox text-center">
                    <CheckboxComponent
                        label="Uwzględnij zaimportowane dokumenty"
                        id="product-pao"
                        checked={includeDocuments || false}
                        onChange={() => handleIncludeDocuments()}
                    />
                </div>
            </div >
        </Fragment >
    );
};
export default ReportsWarehousesProductsComponent;
