import { FC, useState, useEffect, useRef } from "react";
import printIcon from "../../common/images/ico-print.svg";
import { useReactToPrint } from 'react-to-print';
import { GeneratorListCardPropsInterface } from "../types/generator.types";
import moment from "moment";
import _ from "lodash";

const GeneratorListCardComponent: FC<GeneratorListCardPropsInterface> = ({ item }: GeneratorListCardPropsInterface) => {

    const componentRef = useRef<any>();
    let [units, setUnits] = useState<any>(item.unitsInPackage);
    let [date, setDate] = useState(moment(item.expirationDate).format("YYYY-MM-DD"));

    useEffect(() => {
        if (item) {
            setUnits(JSON.parse(JSON.stringify(item.unitsInPackage)));
            setDate(moment(item.expirationDate).format("YYYY-MM-DD"));
        }
    }, [item]);

    const getPrintableConent = () => {
        return (<div className="print-only text-center mt-5" ref={componentRef}>
            <p>[{item.seriesNr}]</p>
            <img src={item.image} alt="kod" />
            <p><b>Nazwa: </b> {item.name}</p>
            <p><b>ZAWARTOŚĆ OPAK.ZB.: </b> {item.unitsInPackage}</p>
            <p><b>TERMIN WAŻNOŚCI: </b> {date}</p>
        </div>);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (<tr>
        <th scope="row" className="col-sm-4 px-3">
            <h3><span>EAN:</span> {item.ean}</h3>
            <h3><span>Seria:</span> {item.seriesNr}</h3>
            <h3><span>Zawartość opak. zb.:</span><input type="text" className="border-0 rounded " id={`${item._id}units`} value={units} onChange={(e) => setUnits(e.target.value)} /></h3>
            <h3><span>Termin ważności:</span><input type="date" className="border-0 rounded" id={`${item._id}date`} value={date} onChange={(e) => setDate(e.target.value)} /></h3>
        </th>
        <td className="col-sm-6">
            <h5 className="text-center mb-0 mt-2">{item.name}</h5>
            <img src={item.image} className="mx-auto d-block" alt="kod" />
        </td>
        <td className="col-sm-2">
            <button type="button" className="bg-light rounded border-0 px-4 py-2" onClick={handlePrint}><img src={printIcon} alt="drukuj" /> Drukuj</button>
            {getPrintableConent()}
        </td>
    </tr>);
}

export default GeneratorListCardComponent;