import { FC } from "react";
import { PositionsListPropsInterface } from "../types/position.types";
import { Link } from "react-router-dom";

import addPositionIcon from "../../common/images/ico-edit-wh.svg";

import ListComponent from "../../common/components/list.component";
import PositionsListCard from './PositionListCard.component'


const PositionsListComponent: FC<PositionsListPropsInterface> = ({
    positions,
    warehouseId,
    positionParams,
    positionFilters,
    positionPage,
    positionTotal,
    setParamsPage
}: PositionsListPropsInterface) => {

    return (
        <div className="bg-white rounded-10 boxshadow p-0 mt-3">

            <div className="col-sm-12 d-flex flex-wrap">
                <div className="col-sm-12 d-flex align-items-center px-0 justify-content-between">
                    <div className="d-flex justify-content-start align-items-center">
                        <h3 className="my-4 px-3 flex-grow-1">Lista pozycji magazynowych</h3>
                        <Link type="button" className="btn btn-primary btn-wms ml-4" to={`/position/${warehouseId}`} ><img src={addPositionIcon} className="px-1" alt="dodaj" />Dodaj pozycję</Link>
                    </div>
                </div>
            </div>

            <div className="col-sm-12 px-0 d-flex flex-wrap">
                <div className="col-sm-12 d-flex flex-wrap justify-content-center">
                    <ListComponent
                        id="table-warehouse"
                        items={positions}
                        total={positionTotal}
                        itemsOnPage={positionParams.limit}
                        ItemCard={PositionsListCard}
                        currentPage={positionPage}
                        currentSort={positionParams.sort}
                        route={`/warehouses/${warehouseId}`}
                        columns={[]}
                        onPageChange={setParamsPage}
                        onSortChange={() => console.log('')}
                    />
                </div>

            </div>

        </div>
    );
}

export default PositionsListComponent;