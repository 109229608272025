import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { GenericObject, WarehouseDocumentInterface } from "../../common/types/common.types"
import { AppDispatch } from "../../config/store"
import { DocumentActionType } from "../types/document.types";
import { toast } from "react-toastify"

export const getDocument = (id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get(`/document/document/${id}`, { joinValues: true, checkAmount: true });
        if (res.success) {
            dispatch(setDocument(res.document));
        } else {
            toast.error("Błąd pobierania dokumentu");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania dokumentu");
        dispatch(isLoading(false));
    }
}

export const deleteDocument = async (id: string, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        toast.success("Usunięto dokument");
        let res = await Api.delete(`/document/document/${id}`);
        dispatch(isLoading(false));
        return res
    } catch (err) {
        toast.error("Błąd usuwania dokumentu");
        dispatch(isLoading(false));
    }
}

export const saveDocument = async (data: GenericObject, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = null;
        if (data._id) {
            res = await Api.put(`/document/document/${data._id}`, data, true);
        } else {
            res = await Api.post("/application/document", data, true);
        }
        dispatch(isLoading(false));

        if (res.success) {
            toast.success("Zapisano dokument");
            return res;
        } else if (res.errors) {
            toast.error("Błąd zapisu dokumentu");
            return res;
        } else {
            toast.error("Błąd zapisu dokumentu");
            return { success: false, errors: { message: "Błąd zapisu" } };
        }
    } catch (err) {
        toast.error("Błąd zapisu dokumentu");
        dispatch(isLoading(false));
        return { success: false, errors: { message: "Błąd zapisu" } };
    }
}

export const setDocument = (document: WarehouseDocumentInterface): AnyAction => {
    return {
        type: DocumentActionType.SET_DOCUMENT,
        document
    }
}

export const clearDocument = (): AnyAction => {
    return {
        type: DocumentActionType.CLEAR_DOCUMENT
    }
}