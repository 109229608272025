import { AnyAction } from "redux";
import { AsyncThunkAction, Nullable, GenericObject } from "../../common/types/common.types";

export enum DashboardActionType {
    SET_DASHBOARD = 'DASHBOARD_SET_DASHBOARD',
    CLEAR_DASHBOARD = 'DASHBOARD_CLEAR_DASHBOARD'
}

export interface DashboardStateInterface {
    dashboard: Nullable<GenericObject>
}

export interface DashboardPropsInterface {
    dashboard: DashboardStateInterface["dashboard"],
    getDashboard: () => AsyncThunkAction,
    clearDashboard: () => AnyAction,
    saveDashboard: (data: GenericObject) => Promise<any>
}

export interface DashboardHeaderPropsInterface {
    name: string
}
