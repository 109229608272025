import { InventoryStateInterface, InventoryActionType } from "../types/inventory.types";

const initialState: InventoryStateInterface = {
    inventory: null,
    history: [],
    total: 0,
    filters: {
        user: "",
        product: "",
        seriesNr: "",
        position: ""
    },
    params: {
        limit: 5,
        offset: 0,
        sort: null
    },
    page: 1
}

const inventoryReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case InventoryActionType.SET_INVENTORY: {
            return {
                ...state,
                inventory: action.inventory
            }
        }
        case InventoryActionType.CLEAR_INVENTORY: {
            return {
                ...state,
                inventory: null
            }
        }
        case InventoryActionType.SET_HISTORY: {
            return {
                ...state,
                history: action.history,
                total: action.total
            }
        }
        case InventoryActionType.CLEAR_HISTORY: {
            return {
                ...state,
                history: [],
                total: 0
            }
        }
        case InventoryActionType.SET_FILTERS: {
            return {
                ...state,
                filters: action.filters
            }
        }
        case InventoryActionType.CLEAR_FILTERS: {
            return {
                ...state,
                filters: {
                    name: '',
                    warehouse: '',
                    completed: null
                }
            }
        }
        case InventoryActionType.SET_PARAMS: {
            return {
                ...state,
                params: action.params
            }
        }
        case InventoryActionType.CLEAR_PARAMS: {
            return {
                ...state,
                params: {
                    limit: 20,
                    offset: 0,
                    sort: null
                }
            }
        }
        case InventoryActionType.SET_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        default: return state;
    }
}

export default inventoryReducer;