import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { DictInterface } from "../../common/types/common.types"
import { AppDispatch } from "../../config/store"
import { DictsActionType } from "../types/dicts.types";
import { toast } from "react-toastify"

export const getDict = (type: number) => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get('/application/dictionary', { type });
        if (res.success) {
            let dict = res.documents[0];
            dict.values = dict.values.map((val: any) => {
                val.readonly = true;
                return val;
            });
            dispatch(setDict(dict));
        } else {
            toast.error("Błąd pobierania słownika");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania słownika");
        dispatch(isLoading(false));
    }
}

export const saveDict = async (data: DictInterface, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = null;
        if (data._id) {
            res = await Api.put(`/application/dictionary/${data._id}`, data);
        } else {
            res = await Api.post("/application/dictionary", data);
        }
        dispatch(isLoading(false));

        if (res.success) {
            toast.success("Zapisano słownik");
            return res;
        } else if (res.errors) {
            toast.error("Błąd zapisu słownika");
            return res;
        } else {
            toast.error("Błąd zapisu słownika");
            return { success: false, errors: { message: "Błąd zapisu" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd zapisu słownika");
        return { success: false, errors: { message: "Błąd zapisu" } };
    }
}

export const setDict = (dict: DictInterface): AnyAction => {
    return {
        type: DictsActionType.SET_DICT,
        dict
    }
}

export const clearDict = (): AnyAction => {
    return {
        type: DictsActionType.CLEAR_DICT
    }
}