import React, { FC } from "react";
import { logout } from '../actions/application.actions';
import { connect } from 'react-redux'
import { AppDispatch, RootState } from "../../config/store";
import TopbarComponent from "../components/topbar.component";
import { getSearchedResults } from "../../common/actions/globalSearch.actions";

const TopbarContainer: FC<any> = (props: any) => {
    return <TopbarComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    user: store.application.user,
    socketConnection: store.application.socketConnection,
    searchedResults: store.globalsearch.searchedResults,
    isLoading: store.globalsearch.isLoading
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        logout: () => dispatch(logout()),
        getSearchedResults: (searchedValue: string)=> dispatch(getSearchedResults(searchedValue))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TopbarContainer);