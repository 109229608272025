import { FC, Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { PositionDetailsPropsInterface } from '../types/position.types';
import { clearPosition, getPosition, getPositionHistory, clearPositionHistory, setHistoryPage } from "../actions/position.actions";
import { AppDispatch, RootState } from "../../config/store";
import { connect } from 'react-redux';
import dictService from '../../application/services/dict.service';

import _ from 'lodash';

import arrowdownIcon from "../../common/images/ico-arrowdown.svg";

import PositionProductsList from '../components/PositionProductsList.component';
import PositionHistoryListComponent from "../components/PositionHistoryList.component";

const WarehouseDetailsContainer: FC<PositionDetailsPropsInterface> = ({ getPosition, clearPosition, position, getPositionHistory, positionHistory, clearPositionHistory, positionHistoryParams, positionHistoryTotal, setHistoryPage, positionHistoryPage }: PositionDetailsPropsInterface) => {
    const { id, page }: any = useParams();
    const history = useHistory();

    const [dict, setDict] = useState<any>(null)
    const [activeTab, setActiveTab] = useState<string>('products')

    const pageParams: any = useParams();

    useEffect(() => {
        setHistoryPage(parseInt(page));
        getPositionHistory(id);
    }, [page, pageParams.page, id, getPositionHistory, setHistoryPage])

    useEffect(() => {
        const dicts = dictService.getDict(2);
        setDict(dicts);
    }, [])

    const getPositionName = () => {
        if (!dict || !position) return;
        return _.get(dict, `values[${position.type}].name`)
    }

    useEffect(() => {
        if (id) {
            getPosition(id);
            getPositionHistory(id);
        }
        return () => {
            clearPosition();
            clearPositionHistory();
        }
    }, [id, getPosition, getPositionHistory, clearPosition, clearPositionHistory])


    return (
        <Fragment>
            {/* Header */}
            <div className="row px-0 mx-0 title-bar d-flex flex-nowrap justify-content-between align-items-center">
                <div className="col-sm-12 d-flex flex-no-wrap px-4 justify-content-between align-items-center">
                    <div className="page-title d-flex flex-nowrap align-items-center">
                        <div className="step-back mr-3 p-2" onClick={() => history.goBack()}><img src={arrowdownIcon} alt="Wstecz" /></div><h2 id="page-title" className="m-0">
                            Szczegóły pozycji magazynowej</h2>
                    </div>
                </div>
            </div>
            <div id="content-container-right" className="warehouse-filters col-md-12 col-sm-12 scrolling py-4 px-4 ">

                {/* Opis pozycji */}
                <div className=" p-0">
                    <div className="warehouse-spot-cont d-flex align-items-center justify-content-center flex-wrap ">
                        <div className="mt-4 mb-5">
                            <h2 className="text-center my-0">{_.get(position, 'warehouse.name')}</h2>
                            <h4 className="product-preview-codes text-center my-0"><span>Typ pozycji:</span>{getPositionName()}</h4>
                        </div>
                        <h2>
                            <div className="badge badge-light p-3 border border-2 ml-3 ">{_.get(position, 'warehouse.code')}</div>
                            <div className="badge badge-dark btn-wms p-3 border border-2 ml-1 ">{_.get(position, 'code')}</div>
                        </h2>
                    </div>
                </div>

                {/* Filtry */}

                {/* Listy */}

                <div className="col-sm-12 px-0 d-flex flex-wrap p-0 mt-3">
                    <div className="col-sm-12 px-0 mx-0 mb-4 tabs-container">
                        <ul className="nav nav-pills mb-3 mx-3" id="details-tab" role="tablist">
                            <li className="nav-item " role="presentation">
                                <button onClick={() => { setActiveTab('products') }} className={`nav-link boxshadow active`} id="details-home-tab" data-toggle="tab" data-bs-toggle="pill" data-bs-target="#tab1" type="button" role="tab" aria-controls="details-1" aria-selected="true">Produkty</button>
                            </li>

                            <li className="nav-item" role="presentation">
                                <button onClick={() => { setActiveTab('history') }} className="nav-link boxshadow bg-white" id="details-profile-tab" data-toggle="tab" data-bs-toggle="pill" data-bs-target="#tab2" type="button" role="tab" aria-controls="details-2" aria-selected="false">Historia</button>
                            </li>
                        </ul>

                        <div className="tab-content  bg-white rounded-10 boxshadow " id="details-tabContent">

                            {/* <!--Produkty zakladka--> */}
                            <PositionProductsList
                                products={_.get(position, 'products')}
                                active={activeTab === 'products' ? true : false}
                            />

                            {/* Historia zakladka */}
                            <PositionHistoryListComponent
                                history={positionHistory}
                                active={activeTab === 'history' ? true : false}
                                total={positionHistoryTotal}
                                itemsOnPage={positionHistoryParams.limit}
                                currentPage={positionHistoryPage}
                                onPageChange={setHistoryPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = (store: RootState) => ({
    position: store.warehouse.position,
    positionHistory: store.warehouse.positionHistory,
    positionHistoryParams: store.warehouse.positionHistoryParams,
    positionHistoryTotal: store.warehouse.positionHistoryTotal,
    positionHistoryPage: store.warehouse.positionHistoryPage
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getPosition: (id: string) => dispatch(getPosition(id)),
        clearPosition: () => dispatch(clearPosition()),
        getPositionHistory: (code: string) => dispatch(getPositionHistory(code)),
        clearPositionHistory: () => dispatch(clearPositionHistory()),
        setHistoryPage: (page: number) => dispatch(setHistoryPage(page)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseDetailsContainer);