import { FC, Fragment, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { WarehousePropsInterface, WarehouseStateInterface } from "../types/warehouse.types";
import { getWarehouse, clearWarehouse, getWarehousePositions } from "../actions/warehouse.actions";
import { setParamsPage, setFilters, clearFilters } from "../actions/position.actions";
import { AppDispatch, RootState } from "../../config/store";
import { connect } from 'react-redux';

import _ from 'lodash';

import PositionsListComponent from "../components/PositionsList.component";
import PositionFiltersComponent from '../components/PositionsFiltersComponent';

import arrowdownIcon from "../../common/images/ico-arrowdown.svg";

const WarehouseDetailsContainer: FC<WarehousePropsInterface> = ({ getWarehouse, setFilters, clearFilters, getWarehousePositions, clearWarehouse, setParamsPage, warehouse, positions, positionParams, positionFilters, positionPage, positionTotal }: WarehousePropsInterface) => {
    const { id, page }: any = useParams();
    const history = useHistory();

    useEffect(() => {
        if (page) {
            setParamsPage(parseInt(page));
        }
        if (id) {
            getWarehouse(id);
            getWarehousePositions(id);
        }
        return () => {
            clearWarehouse();
        }
    }, [getWarehouse, clearWarehouse, getWarehousePositions, setParamsPage, id, page])

    return (
        <Fragment>
            {/* Header */}
            <div className="row px-0 mx-0 title-bar d-flex flex-nowrap justify-content-between align-items-center">
                <div className="col-sm-12 d-flex flex-no-wrap px-4 justify-content-between align-items-center">
                    <div className="page-title d-flex flex-nowrap align-items-center">
                        <div className="step-back mr-3 p-2" onClick={() => history.goBack()}><img src={arrowdownIcon} alt="Wstecz" /></div><h2 id="page-title" className="m-0">Szczegóły magazynu</h2>
                    </div>
                </div>
            </div>
            <div id="content-container-right" className="warehouse-filters col-md-12 col-sm-12 scrolling py-4 px-4 ">
                {/* Info */}
                <div className=" p-0">
                    <div className="d-flex align-items-center justify-content-center flex-wrap">
                        <h2 className="text-center my-4">{_.get(warehouse, 'name', '-')}
                            <div className="badge badge-light p-3 border border-2 ml-3 ">{_.get(warehouse, 'code', '-')}</div>
                        </h2>
                    </div>
                </div>

                {/* Filters */}

                <PositionFiltersComponent
                    filters={positionFilters}
                    onClearFilters={() => clearFilters(id, true)}
                    onFiltersChange={(filters: any) => setFilters(filters, id, true)}
                />

                {/* Positions list */}
                <PositionsListComponent
                    positions={positions}
                    warehouseId={_.get(warehouse, '_id')}
                    positionParams={positionParams}
                    positionFilters={positionFilters}
                    positionPage={positionPage}
                    positionTotal={positionTotal}
                    setParamsPage={setParamsPage}
                />

            </div>

        </Fragment>
    )
}

const mapStateToProps = (store: RootState) => ({
    warehouse: store.warehouse.warehouse,
    positions: store.warehouse.positions,
    positionTotal: store.warehouse.positionTotal,
    positionParams: store.warehouse.positionParams,
    positionPage: store.warehouse.positionPage,
    positionFilters: store.warehouse.positionFilters,
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getWarehouse: (id: string) => dispatch(getWarehouse(id)),
        getWarehousePositions: (id: string) => dispatch(getWarehousePositions(id)),
        clearWarehouse: () => dispatch(clearWarehouse()),
        setParamsPage: (page: number) => dispatch(setParamsPage(page)),
        setFilters: (filters: WarehouseStateInterface["positionFilters"], id: string, reloadList?: boolean) => dispatch(setFilters(filters, id, reloadList)),
        clearFilters: (id: string, reloadList?: boolean) => dispatch(clearFilters(id, reloadList)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseDetailsContainer);