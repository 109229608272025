import { Formik } from 'formik';
import _ from "lodash";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import CheckboxComponent from "../../common/components/checkbox.component";
import TextInputComponent from '../../common/components/textInput.component';
import { initialValues, roleValidationSchema } from "../schemas/role.schema";
import { RolePropsInterface } from "../types/role.types";
import RoleHeaderComponent from "./roleHeader.component";
import roleIcon from "../../common/images/ico-role-2.svg";
import roleIconWhite from "../../common/images/ico-role.svg";
import ModalComponent from '../../common/components/modal.component';
import Autocomplete from '../../common/components/autocomplete.component';
import plusIcon from "../../common/images/ico-plus.svg"
import deleteIcon from "../../common/images/ico-x.svg";

import CategoryModalRole from './categoryModalRole.component';

const RoleComponent: FC<RolePropsInterface> = ({ role, saveRole, categories, createCategory, editCategory, deleteCategory }: RolePropsInterface) => {

    const [roleInitialValues, setRoleInitialValues] = useState(initialValues);
    const [presetRole, setPresetRole] = useState<any>(null);
    const [selectedCategories, setSelectedCategories] = useState<any>(null);
    const [presetRoleError, setPresetRoleError] = useState("");
    const history = useHistory();
    let { id }: any = useParams();

    useEffect(() => {
        if (role !== null && _.get(role, "_id")) {
            setRoleInitialValues(Object.assign({}, initialValues, role));

            //Ustawiamy chipsy categorii
            const index = _.findIndex(role.permissions, (perm: any) => perm.module === 'product' && perm.controller === 'product');
            if (index > -1 && role.permissions[index].filters) {
                setSelectedCategories(role.permissions[index].filters)
            }
        }
    }, [role]);

    const handleRemoveCategory = (category: any, formik: any) => {
        const modulePermissionsIndex = _.findIndex(formik.values.permissions, (perm: any) => perm.module === 'product' && perm.controller === 'product');
        const newCategories = selectedCategories.filter((cat: any) => cat._id !== category._id);
        setSelectedCategories(newCategories);
        formik.setFieldValue(`permissions[${modulePermissionsIndex}].filters`, newCategories);
    }

    const renderCategoryChips = (formik: any) => {
        if (selectedCategories) {
            return selectedCategories.map((cat: any, i: number) => {
                return (
                    <div key={i} className="badge badge-pill ac-badge border border-1 py-1 px-2 mb-2 mr-2">
                        {cat.name || cat}
                        <img src={deleteIcon} onClick={() => { handleRemoveCategory(cat, formik) }} alt="icon" />
                    </div>
                )
            })
        }
    }

    const setProductCategories = (formik: any, moduleName: string, controllerName: string, categories: Array<string>) => {
        const modulePermissionsIndex = _.findIndex(formik.values.permissions, (perm: any) => perm.module === moduleName && perm.controller === controllerName);
        setSelectedCategories(categories);
        formik.setFieldValue(`permissions[${modulePermissionsIndex}].filters`, categories);
    }

    const isChecked = (values: any, moduleName: string, controllerName: string, action: string) => {
        let modulePermissions = _.find(values.permissions, (perm) => perm.module === moduleName && perm.controller === controllerName);
        if (modulePermissions) {
            return _.isArray(modulePermissions.actions) && modulePermissions.actions.indexOf(action) > -1;
        }
        return false;
    }

    const changePermission = (formik: any, checked: boolean, moduleName: string, controllerName: string, action: string) => {
        let modulePermissionsIndex = _.findIndex(formik.values.permissions, (perm: any) => perm.module === moduleName && perm.controller === controllerName);
        if (modulePermissionsIndex > -1) {
            let newModulePermissions = JSON.parse(JSON.stringify(formik.values.permissions[modulePermissionsIndex]));
            if (checked) {
                newModulePermissions.actions = _.uniq(_.concat(newModulePermissions.actions, [action]));
            } else {
                newModulePermissions.actions = _.without(newModulePermissions.actions, action);
            }
            formik.setFieldValue(`permissions[${modulePermissionsIndex}]`, newModulePermissions);
        } else {
            let newPermissions = JSON.parse(JSON.stringify(formik.values.permissions));
            newPermissions.push({
                module: moduleName,
                controller: controllerName,
                actions: [action],
                filters: {}
            });
            formik.setFieldValue("permissions", newPermissions);
        }
    }

    const loadRolePermissions = (formik: any) => {
        setPresetRoleError("");
        if (presetRole && _.isArray(presetRole.permissions)) {
            formik.setFieldValue("permissions", presetRole.permissions);
            setPresetRole(null);
            document.getElementById("load-role-cancel")?.click();
        } else {
            setPresetRoleError("Nie wybrano roli źródłowej");
        }
    }

    const getModalContent = () => {
        return (<div className="mb-5">
            <Autocomplete
                url={"/application/role"}
                id="loadRoleAutocomplete"
                multi={false}
                onOptionSelect={(id: string, option: any) => {
                    setPresetRoleError("");
                    setPresetRole(option);
                }}
                placeholder="Wybierz rolę"
                label="Wybierz rolę źródłową"
                optionIdField="_id"
                optionLabelField="name"
                value={presetRole}
                queryFields={["name"]}
                additionalParams={{ limit: 5 }}
                parseOption={(option: any) => {
                    option.permissions = option.permissions.map((perm: any) => {
                        delete perm._id;
                        return perm;
                    })
                    return option
                }}
                hideChips={false}
                readonly={false}
                preload={true}
                customClass="form-group input-group"
                error={presetRoleError}
            />
        </div>)
    }

    const getModalButtons = (formik: any) => {
        return ([
            <button key="load-role-cancel" type="button" className="btn btn-light px-3" data-dismiss="modal" id="load-role-cancel">Anuluj</button>,
            <button key="load-role" type="button" className="btn btn-primary btn-wms btn-save ml-1" onClick={() => loadRolePermissions(formik)}>Wczytaj</button>
        ])
    }

    return <Fragment>
        <RoleHeaderComponent />
        <div id="content-container-right" className="role-add-container col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2">
            <div className="bg-white rounded-10 p-0 d-flex flex-wrap nooverflow">
                <div id="filter-sidebar" className="col-md-2 col-sm-12 nomobile py-4 px-0 d-flex flex-column boxshadow bg-light">
                    <div id="permissions" className="d-flex flex-column pt-5">
                        <button className="btn btn-primary mt-5" type="button" data-toggle="collapse"
                            data-target="#permissions-section1" aria-expanded="true" aria-controls="collapseExample">
                            Dokumenty
                            {/* <span className="badge badge-pill badge-primary ml-3">12</span> */}
                        </button>
                        <button className="btn btn-primary collapsed" type="button" data-toggle="collapse"
                            data-target="#permissions-section2" aria-expanded="false" aria-controls="collapseExample">
                            Produkty
                            {/* <span className="badge badge-pill badge-primary ml-3">3</span> */}
                        </button>
                        <button className="btn btn-primary collapsed" type="button" data-toggle="collapse"
                            data-target="#permissions-section3" aria-expanded="false" aria-controls="collapseExample">
                            Magazyny
                            {/* <span className="badge badge-pill badge-primary ml-3">8</span> */}
                        </button>
                        <button className="btn btn-primary collapsed" type="button" data-toggle="collapse"
                            data-target="#permissions-section4" aria-expanded="false" aria-controls="collapseExample">
                            Inwentaryzacje
                        </button>
                        <button className="btn btn-primary collapsed" type="button" data-toggle="collapse"
                            data-target="#permissions-section6" aria-expanded="false" aria-controls="collapseExample">
                            Produkcja
                        </button>
                        <button className="btn btn-primary collapsed" type="button" data-toggle="collapse"
                            data-target="#permissions-section5" aria-expanded="false" aria-controls="collapseExample">
                            Inne
                            {/* <span className="badge badge-pill badge-primary ml-3">1</span> */}
                        </button>
                    </div>
                </div>

                <Formik
                    initialValues={roleInitialValues}
                    validationSchema={roleValidationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    onSubmit={(values, { setErrors }) => {
                        if (id) values._id = id;

                        //wywalic nazwe kategorii

                        saveRole(Object.assign({}, values)).then((res) => {
                            if (res.success) {
                                history.push(`/role/${res.document}`);
                            } else if (res.errors) {
                                setErrors(res.errors);
                            }
                        })
                    }}
                >
                    {formik =>
                    (
                        <form className="d-flex col-md-10 align-items-flex-start justify-content-center mt-5 flex-wrap" onSubmit={formik.handleSubmit}>
                            <ModalComponent title="Wczytaj zestaw uprawnień" id="roles-modal" content={getModalContent()} buttons={getModalButtons(formik)} />
                            <div className="col-sm-12 mb-4 pl-0">
                                <div className="col-sm-12 px-0 d-flex flex-wrap align-items-center">
                                    <TextInputComponent
                                        label="Nazwa roli"
                                        type="text"
                                        placeholder="Wpisz nazwę"
                                        id="name"
                                        onChange={formik.setFieldValue}
                                        value={formik.values.name || ""}
                                        error={formik.errors.name}
                                        customClass="form-group input-group mb-0 col-md-8 col-sm-12"
                                    />
                                    <a type="button"
                                        className="role-import-btn bg-light rounded border-0 px-2 py-2 my-0 col-sm-12 col-md-4 text-dark text-center"
                                        data-toggle="modal" href="#roles-modal"><img src={roleIcon} alt="importuj" /> Importuj
                                        uprawnienia</a>
                                </div>
                            </div>
                            <div className="col-sm-12 mb-3">
                                <div className="col-sm-12 onmobile form-group input-group align-items-center mt-3 ">
                                    <label className="form-group has-float-label d-flex justify-content-stretch">
                                        <select className="selectpicker form-control custom-select" title="Wybierz grupę uprawnień">
                                            <option>Dokumenty</option>
                                            <option>Produkty</option>
                                            <option>Magazyny</option>
                                            <option>Inwentaryzacje</option>
                                            <option>Inne</option>
                                        </select>
                                        <span>Wybierz grupę uprawnień</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 d-flex flex-wrap mb-5 mt-2 px-0">
                                <div className="collapse col-sm-12 show" id="permissions-section1" data-parent="#permissions">
                                    <div className="card col-sm-12 card-body d-flex flex-row flex-wrap">
                                        {/* Dokumenty - dokumenty */}
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title py-1 px-2">Dokumenty
                                            </h4>
                                            {/* Dokumenty wyświetlanie - document/document get */}
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "document", "document", "get")}
                                                id="document_document_get"
                                                checked={isChecked(formik.values, "document", "document", "get")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie umożliwia pobieranie listy dokumentów"
                                            />
                                            {/* Dokumenty tworzenie - document/document create */}
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "document", "document", "create")}
                                                id="document_document_create"
                                                checked={isChecked(formik.values, "document", "document", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Dokumenty edycja - document/document update */}
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "document", "document", "update")}
                                                id="document_document_update"
                                                checked={isChecked(formik.values, "document", "document", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Dokumenty usuwanie - document/document delete */}
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "document", "document", "delete")}
                                                id="document_document_delete"
                                                checked={isChecked(formik.values, "document", "document", "delete")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Dokumenty integracja - document/document integrate */}
                                            <CheckboxComponent
                                                label="Wymuszenie integracji"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "document", "document", "integrate")}
                                                id="document_document_integrate"
                                                checked={isChecked(formik.values, "document", "document", "integrate")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie umożliwia wymuszenie integracji dokumentu do systemu zewnętrznego."
                                            />
                                        </div>
                                        {/* Dokumenty - kompletacja */}
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Kompletacja</h4>
                                            {/* Dokumenty kompletacja - document/completion completion */}
                                            <CheckboxComponent
                                                label="Kompletacja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "document", "completion", "completion")}
                                                id="document_completion_completion"
                                                checked={isChecked(formik.values, "document", "completion", "completion")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie umożliwia kompletację dokumentów w aplikacji mobilnej."
                                            />
                                            {/* Cofanie kompletacji - document/completion undoDocument */}
                                            <CheckboxComponent
                                                label="Wycofanie kompletacji"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "document", "completion", "undoDocument")}
                                                id="document_completion_undoDocument"
                                                checked={isChecked(formik.values, "document", "completion", "undoDocument")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie umożliwia wycofanie kompletacji dokumentu."
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Sprawdzanie</h4>
                                            {/* Dokumenty kompletacja - document/inspection inspection */}
                                            <CheckboxComponent
                                                label="Sprawdzanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "document", "inspection", "inspection")}
                                                id="document_inspection_inspection"
                                                checked={isChecked(formik.values, "document", "inspection", "inspection")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawenienie umożliwia sprawdzanie dokumentów w aplikacji mobilnej."
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Zmiany pozycji</h4>
                                            {/* Dokumenty kompletacja - document/zp get */}
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "document", "zp", "get")}
                                                id="document_zp_get"
                                                checked={isChecked(formik.values, "document", "zp", "get")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie umożliwia pobieranie listy dokumentów zmiany pozycji (ZP)."
                                            />
                                            {/* Dokumenty kompletacja - document/zp create */}
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "document", "zp", "create")}
                                                id="document_zp_create"
                                                checked={isChecked(formik.values, "document", "zp", "create")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie umożliwia tworzenie dokumentów zmiany pozycji (ZP) w aplikacji mobilnej."
                                            />
                                        </div>
                                        {/* Dokumenty - rezerwacje */}
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Rezerwacje</h4>
                                            {/* Dokumenty rezerwacje pobieranie - document/reservation get */}
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "document", "reservation", "get")}
                                                id="document_reservation_get"
                                                checked={isChecked(formik.values, "document", "reservation", "get")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie umożliwia pobieranie rezerwacji stanów w tym wyświetlenie aktualnych rezerwacji w szczegółach dokumentu. Wymagane do kompletacji dokumentów w aplikacji mobilnej!"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Produkty */}
                                <div className="collapse col-sm-12" id="permissions-section2" data-parent="#permissions">
                                    <div className="card col-sm-12 card-body d-flex flex-row flex-wrap">
                                        <div className="col-md-4 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Produkty</h4>
                                            {/* Produkty pobieranie - product/product get */}
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "product", "product", "get")}
                                                id="product_product_get"
                                                checked={isChecked(formik.values, "product", "product", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Produkty tworzenie - product/product create */}
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "product", "product", "create")}
                                                id="product_product_create"
                                                checked={isChecked(formik.values, "product", "product", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Produkty edycja - product/product update */}
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "product", "product", "update")}
                                                id="product_product_update"
                                                checked={isChecked(formik.values, "product", "product", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Produkty usuwanie - product/product delete */}
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "product", "product", "delete")}
                                                id="product_product_delete"
                                                checked={isChecked(formik.values, "product", "product", "delete")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Produkty pobranie pozycji - product/product getProductPositions */}
                                            <CheckboxComponent
                                                label="Pobieranie pozycji produktu"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "product", "product", "getProductPositions")}
                                                id="product_product_getProductPositions"
                                                checked={isChecked(formik.values, "product", "product", "getProductPositions")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Produkty wymuszenie integracji - product/product integrate */}
                                            <CheckboxComponent
                                                label="Wymuszenie integracji"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "product", "product", "integrate")}
                                                id="product_product_integrate"
                                                checked={isChecked(formik.values, "product", "product", "integrate")}
                                                customClass="custom-control custom-switch"
                                            />

                                            <CategoryModalRole formik={formik} categories={categories} createCategory={createCategory} editCategory={editCategory} deleteCategory={deleteCategory} setProductCategories={setProductCategories} selectedCategories={selectedCategories} />

                                            <a className="btn btn-secondary btn-secondary px-2 mt-3" type="button" href="#cat-modal" data-toggle="modal"><img src={plusIcon} alt="icon" className="mb-1" />Filtruj kategorie</a>

                                            <div className="selected-filters-cont mt-3 mb-0">
                                                {renderCategoryChips(formik)}
                                            </div>

                                        </div>
                                        {/* Generator kodów */}
                                        <div className="col-md-4 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Generator kodów</h4>
                                            {/* Generator pobieranie - generator/generator get */}
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "generator", "generator", "get")}
                                                id="generator_generator_get"
                                                checked={isChecked(formik.values, "generator", "generator", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Generator tworzenie - generator/generator create */}
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "generator", "generator", "create")}
                                                id="generator_generator_create"
                                                checked={isChecked(formik.values, "generator", "generator", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Generator edycja - generator/generator update */}
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "generator", "generator", "update")}
                                                id="generator_generator_update"
                                                checked={isChecked(formik.values, "generator", "generator", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Generator usuwanie - generator/generator delete */}
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "generator", "generator", "delete")}
                                                id="generator_generator_delete"
                                                checked={isChecked(formik.values, "generator", "generator", "delete")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                        {/* Kategorie produktowe */}
                                        <div className="col-md-4 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Kategorie produktowe</h4>
                                            {/* Kategorie pobieranie - product/category get */}
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "product", "category", "get")}
                                                id="product_category_get"
                                                checked={isChecked(formik.values, "product", "category", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Kategorie tworzenie - product/category create */}
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "product", "category", "create")}
                                                id="product_category_create"
                                                checked={isChecked(formik.values, "product", "category", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Kategorie edycja - product/category update */}
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "product", "category", "update")}
                                                id="product_category_update"
                                                checked={isChecked(formik.values, "product", "category", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Kategorie usuwanie - product/category delete */}
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "product", "category", "delete")}
                                                id="product_category_delete"
                                                checked={isChecked(formik.values, "product", "category", "delete")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Magazyny */}
                                <div className="collapse col-sm-12" id="permissions-section3" data-parent="#permissions">
                                    <div className="card col-sm-12 card-body d-flex flex-row flex-wrap">
                                        <div className="col-md-4 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Magazyny</h4>
                                            {/* Magazyny pobieranie - warehouse/warehouse get */}
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "warehouse", "get")}
                                                id="warehouse_warehouse_get"
                                                checked={isChecked(formik.values, "warehouse", "warehouse", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Magazyny Tworzenie - warehouse/warehouse get */}
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "warehouse", "create")}
                                                id="warehouse_warehouse_create"
                                                checked={isChecked(formik.values, "warehouse", "warehouse", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Magazyny edycja - warehouse/warehouse get */}
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "warehouse", "update")}
                                                id="warehouse_warehouse_update"
                                                checked={isChecked(formik.values, "warehouse", "warehouse", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                            {/* Magazyny usuwanie - warehouse/warehouse get */}
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "warehouse", "delete")}
                                                id="warehouse_warehouse_delete"
                                                checked={isChecked(formik.values, "warehouse", "warehouse", "delete")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Pozycje magazynowe</h4>
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "position", "get")}
                                                id="warehouse_position_get"
                                                checked={isChecked(formik.values, "warehouse", "position", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "position", "create")}
                                                id="warehouse_position_create"
                                                checked={isChecked(formik.values, "warehouse", "position", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "position", "update")}
                                                id="warehouse_position_update"
                                                checked={isChecked(formik.values, "warehouse", "position", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "position", "delete")}
                                                id="warehouse_position_delete"
                                                checked={isChecked(formik.values, "warehouse", "position", "delete")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                        <div className="col-md-4 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Historia pozycji</h4>
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "positionHistory", "get")}
                                                id="warehouse_positionHistory_get"
                                                checked={isChecked(formik.values, "warehouse", "positionHistory", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Inwentaryzacja */}
                                <div className="collapse col-sm-12" id="permissions-section4" data-parent="#permissions">
                                    <div className="card col-sm-12 card-body d-flex flex-row flex-wrap">
                                        <div className="col-md-4 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Inwentaryzacja</h4>
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "inventory", "get")}
                                                id="warehouse_inventory_get"
                                                checked={isChecked(formik.values, "warehouse", "inventory", "get")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie pozwala na pobieranie inwentaryzacji na magazynach, do których użytkownik ma dostęp oraz umożliwia podgląd ich postępu. Wymagane do możliwości robienia inwentaryzacji przez aplikację mobilną!"
                                            />
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "inventory", "create")}
                                                id="warehouse_inventory_create"
                                                checked={isChecked(formik.values, "warehouse", "inventory", "create")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie pozwala na tworzenie inwentaryzacji na magazynach, do których użytkownik ma dostęp."
                                            />
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "inventory", "update")}
                                                id="warehouse_inventory_update"
                                                checked={isChecked(formik.values, "warehouse", "inventory", "update")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie pozwala na edycję inwentaryzacji, w tym jej zakończenie oraz zezwalanie na edycję pozycji."
                                            />
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "inventory", "delete")}
                                                id="warehouse_inventory_delete"
                                                checked={isChecked(formik.values, "warehouse", "inventory", "delete")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie pozwala na usuwanie inwentaryzacji, na których nie został zrobiony żaden postęp."
                                            />
                                            <CheckboxComponent
                                                label="Generowanie raportu"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "generateReport", "delete")}
                                                id="warehouse_inventory_generateReport"
                                                checked={isChecked(formik.values, "warehouse", "generateReport", "delete")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie umożliwia wygenerowanie i pobranie raportu z inwentaryzacji."
                                            />
                                            <CheckboxComponent
                                                label="Inwentaryzacja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "inventory", "inventory")}
                                                id="warehouse_inventory_inventory"
                                                checked={isChecked(formik.values, "warehouse", "inventory", "inventory")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie pozwala na prowadzenie inwentaryzacji przez aplikację mobilną. Zawiera uprawnienia do rozpoczęcia i zakończnienia inwentaryzacji na pozycji oraz do zapisu, edycji i usuwania postępu. Wymagane nadanie uprawnienia pobierania inwentaryzacji do pełnego działania!"
                                            />
                                            <CheckboxComponent
                                                label="Reset pozycji"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "warehouse", "inventory", "resetPositionInventory")}
                                                id="warehouse_inventory_inventory"
                                                checked={isChecked(formik.values, "warehouse", "inventory", "resetPositionInventory")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie umożliwia zresetowanie pozycji po inwentaryzacji."
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Produkcja */}
                                <div className="collapse col-sm-12" id="permissions-section6" data-parent="#permissions">
                                    <div className="card col-sm-12 card-body d-flex flex-row flex-wrap">
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Zakłady</h4>
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "facility", "get")}
                                                id="production_facility_get"
                                                checked={isChecked(formik.values, "production", "facility", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "facility", "create")}
                                                id="production_facility_create"
                                                checked={isChecked(formik.values, "production", "facility", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "facility", "update")}
                                                id="production_facility_update"
                                                checked={isChecked(formik.values, "production", "facility", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "facility", "update")}
                                                id="production_facility_update"
                                                checked={isChecked(formik.values, "production", "facility", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Zmiany</h4>
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "shift", "get")}
                                                id="production_shift_get"
                                                checked={isChecked(formik.values, "production", "shift", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "shift", "create")}
                                                id="production_shift_create"
                                                checked={isChecked(formik.values, "production", "shift", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "shift", "update")}
                                                id="production_shift_update"
                                                checked={isChecked(formik.values, "production", "shift", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Plany sprzedażowe</h4>
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "salesplan", "get")}
                                                id="production_salesplan_get"
                                                checked={isChecked(formik.values, "production", "salesplan", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "salesplan", "create")}
                                                id="production_salesplan_create"
                                                checked={isChecked(formik.values, "production", "salesplan", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "salesplan", "update")}
                                                id="production_salesplan_update"
                                                checked={isChecked(formik.values, "production", "salesplan", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "salesplan", "update")}
                                                id="production_salesplan_update"
                                                checked={isChecked(formik.values, "production", "salesplan", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Dokumenty</h4>
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "document", "get")}
                                                id="production_document_get"
                                                checked={isChecked(formik.values, "production", "document", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "document", "create")}
                                                id="production_document_create"
                                                checked={isChecked(formik.values, "production", "document", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "document", "update")}
                                                id="production_document_update"
                                                checked={isChecked(formik.values, "production", "document", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "document", "update")}
                                                id="production_document_update"
                                                checked={isChecked(formik.values, "production", "document", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Plan produkcji</h4>
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "event", "get")}
                                                id="production_event_get"
                                                checked={isChecked(formik.values, "production", "event", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "event", "create")}
                                                id="production_event_create"
                                                checked={isChecked(formik.values, "production", "event", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "event", "update")}
                                                id="production_event_update"
                                                checked={isChecked(formik.values, "production", "event", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "event", "update")}
                                                id="production_event_update"
                                                checked={isChecked(formik.values, "production", "event", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Procesy</h4>
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "process", "get")}
                                                id="production_process_get"
                                                checked={isChecked(formik.values, "production", "process", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "process", "create")}
                                                id="production_process_create"
                                                checked={isChecked(formik.values, "production", "process", "create")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Edycja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "process", "update")}
                                                id="production_process_update"
                                                checked={isChecked(formik.values, "production", "process", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Usuwanie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "process", "update")}
                                                id="production_process_update"
                                                checked={isChecked(formik.values, "production", "process", "update")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Magazyny</h4>
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "warehouse", "get")}
                                                id="production_warehouse_get"
                                                checked={isChecked(formik.values, "production", "warehouse", "get")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Symulacja</h4>
                                            <CheckboxComponent
                                                label="Zapotrzebowanie produktowe"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "simulation", "getProductionDemand")}
                                                id="production_simulation_getProductionDemand"
                                                checked={isChecked(formik.values, "production", "simulation", "getProductionDemand")}
                                                customClass="custom-control custom-switch"
                                            />
                                            <CheckboxComponent
                                                label="Symulacja"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "production", "simulation", "simulateProduction")}
                                                id="production_simulation_simulateProduction"
                                                checked={isChecked(formik.values, "production", "simulation", "simulateProduction")}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Inne */}
                                <div className="collapse col-sm-12" id="permissions-section5" data-parent="#permissions">
                                    <div className="card col-sm-12 card-body d-flex flex-row flex-wrap">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Raporty</h4>
                                            <CheckboxComponent
                                                label="Tworzenie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "statistic", "report", "create")}
                                                id="statistic_report_inventory"
                                                checked={isChecked(formik.values, "statistic", "report", "create")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie umożliwia generowanie raportów."
                                            />
                                            {/* <div className="dropdown-divider"></div>
                                            <h4>Rodzaj raportu</h4>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch49" />
                                                <label className="custom-control-label" htmlFor="customSwitch49">Stan magazynowy
                                                    produktów</label>
                                            </div>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch50" />
                                                <label className="custom-control-label" htmlFor="customSwitch50">Terminy ważności
                                                    produktów</label>
                                            </div>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch51" />
                                                <label className="custom-control-label" htmlFor="customSwitch51">Czasy realizacji
                                                    dokumentów</label>
                                            </div>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch52" />
                                                <label className="custom-control-label" htmlFor="customSwitch52">Stan magazynowy produktów z
                                                    seriami</label>
                                            </div>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch53" />
                                                <label className="custom-control-label" htmlFor="customSwitch53">Raport GMP+</label>
                                            </div>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch54" />
                                                <label className="custom-control-label" htmlFor="customSwitch54">Eksport produktów</label>
                                            </div>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch55" />
                                                <label className="custom-control-label" htmlFor="customSwitch55">Produkty z dokumentów</label>
                                            </div>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" className="custom-control-input" id="customSwitch56" />
                                                <label className="custom-control-label" htmlFor="customSwitch56">Dostępność produktów</label>
                                            </div> */}
                                        </div>
                                        <div className="col-md-3 col-sm-12">
                                            <div className="btn py-1 px-2 btn-light float-right" data-toggle="tooltip" data-placement="top"
                                                title="Tylko własne"><input type="checkbox" aria-label="Tylko wlasne" /></div>
                                            <h4 className="card-title">Użytkownicy</h4>
                                            <CheckboxComponent
                                                label="Pobieranie"
                                                onChange={(id: string, checked: boolean) => changePermission(formik, checked, "application", "user", "get")}
                                                id="application_user_get"
                                                checked={isChecked(formik.values, "application", "user", "get")}
                                                customClass="custom-control custom-switch"
                                                title="Uprawnienie umożliwia pobieranie listy użytkowników."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 text-center">
                                    <button type="submit" className="btn-wms color-white rounded border-0 px-4 my-5 py-2">
                                        <img src={roleIconWhite} alt="zapisz" />
                                        Zapisz
                                    </button>
                                </div>
                            </div>
                        </form>)}
                </Formik>

            </div>
        </div>
    </Fragment>
}

export default RoleComponent;