import { FC, Fragment } from "react";
import { PositionHistoryListPropsInterface } from "../types/position.types";
import { Link } from "react-router-dom";

import PositionHistoryListCardComponent from "./PositionHistoryListCard.component";


const PositionHistoryListComponent: FC<PositionHistoryListPropsInterface> = ({
    history,
    active,
    total,
    itemsOnPage,
    currentPage,
    onPageChange
}: PositionHistoryListPropsInterface) => {

    const nrOfPages = Math.ceil(total / itemsOnPage);

    const renderHistory = () => {
        if (!history || history.length === 0) {

        } else {
            return history.map((item, i) => {
                return (
                    <PositionHistoryListCardComponent
                        item={item}
                        key={i}
                    />
                )
            })
        }
    }

    const getPageButtons = () => {
        if (currentPage === 1) {
            return (<Fragment>
                <li className="page-item active"><button className="page-link" type="button">1</button></li>
                {(currentPage + 1 <= nrOfPages) && <Link className="page-item" to={`${currentPage + 1}`} onClick={() => onPageChange(currentPage + 1)}><button className="page-link" type="button">{currentPage + 1}</button></Link>}
                {(currentPage + 2 <= nrOfPages) && <Link className="page-item" to={`${currentPage + 2}`} onClick={() => onPageChange(currentPage + 2)}><button className="page-link" type="button">{currentPage + 2}</button></Link>}
            </Fragment>);
        } else if (currentPage === nrOfPages) {
            return (<Fragment>
                {(currentPage - 2 > 0) && <Link className="page-item" to={`${currentPage - 2}`} onClick={() => onPageChange(currentPage - 2)}><button className="page-link" type="button">{currentPage - 2}</button></Link>}
                {(currentPage - 1 > 0) && <Link className="page-item" to={`${currentPage - 1}`} onClick={() => onPageChange(currentPage - 1)}><button className="page-link" type="button">{currentPage - 1}</button></Link>}
                <li className="page-item active"><button className="page-link" type="button">{currentPage}</button></li>
            </Fragment>);
        } else {
            return (<Fragment>
                {(currentPage - 1 > 0) && <Link className="page-item" to={`${currentPage - 1}`} onClick={() => onPageChange(currentPage - 1)}><button className="page-link" type="button">{currentPage - 1}</button></Link>}
                <li className="page-item active"><button className="page-link" type="button">{currentPage}</button></li>
                {(currentPage + 1 <= nrOfPages) && <Link className="page-item" to={`${currentPage + 1}`} onClick={() => onPageChange(currentPage + 1)}><button className="page-link" type="button">{currentPage + 1}</button></Link>}
            </Fragment>);
        }
    }

    return (
        <div className={`tab-pane ${active ? "show active" : null} `} id="tab2" role="tabpanel" aria-labelledby="details-profile-tab">

            <div className="col-sm-12 d-flex flex-wrap">
                <div className="col-sm-12 d-flex align-items-center px-0 justify-content-between">
                    <div className="d-flex justify-content-start align-items-center">
                        <h3 className="my-4 px-3 flex-grow-1">Historia pozycji magazynowych</h3>
                    </div>
                </div>
            </div>

            {
                (!history || history.length === 0) ?

                    <div className="col-md-12 text-center mb-5"> Brak historii na tej pozycji</div>
                    :

                    <div className=" table table-responsive my-4">
                        <table className="table nomobile table-hover mb-0">
                            <thead>
                                <tr className="thead-light">
                                    <th scope="col" className="col-md-2 pl-5">Dokument</th>
                                    <th className="col-md-4">Produkt</th>
                                    <th className="col-md-2">Numer serii</th>
                                    <th className="col-md-2">Ilość przed</th>
                                    <th className="col-md-2">Ilość po</th>
                                    <th className="col-md-1 pr-4">Różnica</th>
                                </tr>
                            </thead>
                        </table>


                        {renderHistory()}


                        <nav id="pagination-container">
                            <ul className="pagination">
                                {(currentPage > 1) && <Link className="page-item" to={`${1}`} onClick={() => onPageChange(1)}>
                                    <button className="page-link" type="button" aria-label="Poprzednia">
                                        <span aria-hidden="true">&laquo;</span>
                                        <span className="sr-only">Pierwsza</span>
                                    </button>
                                </Link>}
                                {getPageButtons()}
                                {(currentPage < nrOfPages) && <Link className="page-item" to={`${nrOfPages}`} onClick={() => onPageChange(nrOfPages)}>
                                    <button className="page-link" type="button" aria-label="Następna">
                                        <span aria-hidden="true">&raquo;</span>
                                        <span className="sr-only">Ostatnia</span>
                                    </button>
                                </Link>}
                            </ul>
                        </nav>
                    </div>
            }



        </div>

    );
}

export default PositionHistoryListComponent;