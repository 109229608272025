import { AsyncThunkAction, Nullable, DictInterface } from "../../common/types/common.types";

export interface DictsStateInterface {
    dict: Nullable<DictInterface>
}

export enum DictsActionType {
    SET_DICT = 'DICTS_SET_DICT',
    CLEAR_DICT = 'DICTS_CLEAR_DICT'
}

export interface DictsPropsInterface {
    getDict: (type: number) => AsyncThunkAction,
    saveDict: (dict: DictsStateInterface["dict"]) => Promise<any>,
    dict: DictsStateInterface["dict"]
}