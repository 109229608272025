import React, { FC, useEffect } from "react";
import SettingsComponent from "../components/settings.component";
import { getSettings, saveSettings, clearSettings } from '../actions/settings.actions';
import { connect } from 'react-redux'
import { AppDispatch, RootState } from "../../config/store";
import { SettingsInterface } from "../../common/types/common.types";

const SettingsContainer: FC<any> = (props: any) => {
    const { getSettings, clearSettings } = props;
    useEffect(() => {
        getSettings();
        return () => {
            clearSettings();
        }
    }, [getSettings, clearSettings])

    return <SettingsComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    settings: store.settings.settings
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getSettings: () => dispatch(getSettings()),
        saveSettings: (settings: SettingsInterface) => saveSettings(settings, dispatch),
        clearSettings: () => dispatch(clearSettings())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);