import { AsyncThunkAction, GenericObject, Nullable, UserInterface } from "../../common/types/common.types";
import { GlobalSearchStateInterface } from "../../common/types/component.types";

export interface ApplicationStateInterface {
    user: Nullable<UserInterface>,
    isLoading: boolean,
    socketConnection: boolean,
    activeUsers: Array<GenericObject>
}

export enum ApplicationActionType {
    SET_IS_LOADING = 'APPLICATION_SET_IS_LOADING',
    SET_USER = 'APPLICATION_SET_USER',
    CLEAR_USER = 'APPLICATION_CLEAR_USER',
    SET_SOCKET_CONNECTION = `APPLICATION_SET_SOCKET_CONNECTION`,
    SET_ACTIVE_USERS = `APPLICATION_SET_ACTIVE_USERS`
}

export interface AuthPropsInterface {
    user: ApplicationStateInterface["user"],
    checkAuth: () => AsyncThunkAction
}

export interface LoginPropsInterface {
    login: (login: string, password: string) => Promise<any>
}

export interface LoaderPropsInterface {
    isLoading: boolean
}

export interface TopbarPropsInterface {
    user: ApplicationStateInterface["user"],
    searchedResults: GlobalSearchStateInterface["searchedResults"];
    socketConnection: ApplicationStateInterface["socketConnection"],
    isLoading: LoaderPropsInterface["isLoading"];
    logout: () => AsyncThunkAction,
    getSearchedResults: (searchedValue: string) => AsyncThunkAction
}