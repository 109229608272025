import * as Yup from 'yup';
import { UserInterface } from '../../common/types/common.types';

export const initialValues: UserInterface = {
    _id: '',
    name: '',
    login: '',
    email: '',
    password: '',
    phone: '',
    warehouse: [],
    role: null,
    isAdmin: false,
    allowMobile: false,
    avatar: '',
    integrationUser: false,
    active: true
}

export const userValidationSchema = Yup.object({
    _id: Yup.string(),
    name: Yup.string().required("Wartość jest wymagana"),
    login: Yup.string().required("Wartość jest wymagana"),
    email: Yup.string().required("Wartość jest wymagana").email("Niepoprawny email"),
    password: Yup.string(),
    warehouse: Yup.array().nullable(),
    role: Yup.object().nullable(),
    isAdmin: Yup.boolean().required("Wartość jest wymagana"),
    allowMobile: Yup.boolean().required("Wartość jest wymagana"),
    avatar: Yup.string().nullable(),
    phone: Yup.string(),
    integrationUser: Yup.boolean(),
    active: Yup.boolean()
})
