
import { FC, Fragment } from "react";
import { PositionProductsListPropsInterface } from "../types/position.types";

import PositionProductsListCardComponent from './PositionProductsListCard.component'

const PositionProductsListComponent: FC<PositionProductsListPropsInterface> = ({ products, active }: PositionProductsListPropsInterface) => {

    const renderProducts = () => {
        if (!products) return;
        return products.map((prod, i) => {
            return <PositionProductsListCardComponent
                key={i}
                index={i}
                product={prod}
            />
        })
    }

    return (
        <div className={`tab-pane fade ${active && 'show active'}`} id="tab1" role="tabpanel" aria-labelledby="details-home-tab">
            <div className="col-sm-12 d-flex flex-wrap">
                <div className="col-sm-12 d-flex align-items-center px-0 justify-content-between">
                    <div className="d-flex justify-content-start align-items-center">
                        <h3 className="my-4 px-3 flex-grow-1">Lista produktów</h3>
                    </div>
                </div>
            </div>
            <div className=" table table-responsive my-4">
                <table id="table-warehouses" className="table table-hover mb-0">
                    {
                        (!products || products.length === 0) ?
                            <thead><tr className="col-md-12 text-center mb-5"><th> Brak produktów na tej pozycji</th></tr></thead>
                            :
                            <Fragment>
                                <thead>
                                    <tr className="thead-light">
                                        <th scope="col" className="col-md-1 text-center">Lp.</th>
                                        <th scope="col" className="col-md-5">Nazwa</th>
                                        <th className="col-md-2">Data ważności</th>
                                        <th className="col-md-2">Seria</th>
                                        <th className="col-md-1">Ilość</th>
                                        <th className="col-md-1">Jednostka</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderProducts()}
                                </tbody>
                            </Fragment>
                    }
                </table>
            </div>
        </div>
    );
}

export default PositionProductsListComponent;




