import Api from "../../application/services/api.service"
import { GenericObject } from '../../common/types/common.types';

export const getWarehouse = async () => {
    try {
        let res = await Api.get("/warehouse/warehouse");
        if (res.success) {
            return res.documents;
        } else if (res.errors) {
            return res;
        } else {
            return { success: false, errors: { message: "Brak autoryzacji" } };
        }
    } catch (err) {
        return { success: false, errors: { message: "Brak autoryzacji" } };
    }
}


export const getReport = async (data: GenericObject) => {
    try {
        let res = await Api.post("/statistic/report", data, false, false, true);
        if (res instanceof Blob && res.type !== "application/json") {
            const element = document.createElement("a");
            element.href = URL.createObjectURL(res);
            element.download = "report.xls";
            document.body.appendChild(element);
            element.click();
            element.remove();
        } else if (res.errors) {
            return res;
        } else {
            return { success: false, errors: { message: "Brak autoryzacji" } };
        }
    } catch (err) {
        return { success: false, errors: { message: "Brak autoryzacji" } };
    }
}
