import React, { FC } from "react";
import { useHistory, useParams } from "react-router";
import TextInputComponent from "../../common/components/textInput.component";
import resetIcon from "../../common/images/ico-wyczysc.svg";
import collapseIcon from "../../common/images/ico-zwin.svg";
import { ZpsFiltersPropsInterface } from "../types/zp.types";

const ZpListFiltersComponent: FC<ZpsFiltersPropsInterface> = ({ filters, onClearFilters, onFiltersChange }: ZpsFiltersPropsInterface) => {

    const history = useHistory();
    const { page }: any = useParams();

    const handleFilterChange = (field: string, value: any) => {
        let newFilters = Object.assign({}, filters, {
            [field]: value
        });
        if (page !== "1") {
            history.push("/zps/page/1");
        }
        onFiltersChange(newFilters);
    }

    const handleFiltersReset = () => {
        let positionFromInput: any = document.getElementById("nampositionFrome");
        if (positionFromInput) {
            positionFromInput.value = '';
        }
        let positionToInput: any = document.getElementById("positionTo");
        if (positionToInput) {
            positionToInput.value = '';
        }
        let creatorInput: any = document.getElementById("creator");
        if (creatorInput) {
            creatorInput.checked = '';
        }
        let productInput: any = document.getElementById("product");
        if (productInput) {
            productInput.checked = '';
        }

        if (page !== "1") {
            history.push("/zps/page/1");
        }
        onClearFilters();
    }

    const handleCollapseClick = () => {
        $('#filter-sidebar').toggleClass("toggled");
        $('#filter-toggle').toggleClass("toggled");
        if ($('#filter-toggle').hasClass('toggled')) {
            $('#filter-list').addClass("fadeOut");
            $('#filter-list').removeClass("fadeIn");
        } else {
            $('#filter-list').addClass("fadeIn");
            $('#filter-list').removeClass("fadeOut");
        }
        $('#content-container-right').toggleClass("col-md-12");
    }

    return (<div id="filter-sidebar" className="col-md-3 col-sm-12 bg-white py-4 px-4 d-flex flex-column">
        <div id="filter-toggle" className="filter-collapse" onClick={handleCollapseClick}><img src={collapseIcon} alt="zwiń" /></div>
        <h3 className="mb-4 align-items-center">Filtry
            <div id="filter-toggle3"><img src="gfx/ico-x.svg" alt="zwiń" /></div>
        </h3>
        <div id="filter-list" className="animated">
            <TextInputComponent
                label="Pozycja źródłowa"
                type="text"
                placeholder="Pozycja źródłowa"
                id="positionFrom"
                onChange={handleFilterChange}
                value={filters.positionFrom}
                hideLabel={true}
            />
            <TextInputComponent
                label="Pozycja docelowa"
                type="text"
                placeholder="Pozycja docelowa"
                id="positionTo"
                onChange={handleFilterChange}
                value={filters.positionTo}
                hideLabel={true}
            />
            <TextInputComponent
                label="Realizujący"
                type="text"
                placeholder="Realizujący"
                id="creator"
                onChange={handleFilterChange}
                value={filters.creator}
                hideLabel={true}
            />
            <TextInputComponent
                label="Produkt"
                type="text"
                placeholder="Produkt"
                id="product"
                onChange={handleFilterChange}
                value={filters.product}
                hideLabel={true}
            />
            <div className="filter-clear float-right color-gray align-self-end" onClick={handleFiltersReset}>
                <p>Wyczyść wszystko <img src={resetIcon} alt="reset" /></p>
            </div>
        </div>
    </div>);
}

export default ZpListFiltersComponent;