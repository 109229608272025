class UtilityService implements UtilityService {

    //Funkcja zwraca słowo odmienione przez liczebnik np. mamy jedno powiadomienie, dwa powiadomienia, sześć powiadomień
    //Przykład użycia: polishPlural("powiadomienie", "powiadomienia", "powiadomień", 6) zwróci 'powiadomień'
    polishPlural(singularNominativ: string, pluralNominativ: string, pluralGenitive: string, value: number) {
        if (value === 1) {
            return singularNominativ;
        } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
            return pluralNominativ;
        } else {
            return pluralGenitive;
        }
    }

}

export default new UtilityService();