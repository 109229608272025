import _ from "lodash";
import React, { FC } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Acl from "../../application/services/acl.service";
import arrowdownIcon from "../../common/images/ico-arrowdown.svg";
import editIcon from "../../common/images/ico-edit-full.svg";
import deleteIcon from "../../common/images/ico-xred.svg";
import checkIcon from "../../common/images/step-completed.svg";
import { InventoryDetailsHeaderPropsInterface } from "../types/inventory.types";
import ModalComponent from "../../common/components/modal.component";

const InventoryDetailsHeaderComponent: FC<InventoryDetailsHeaderPropsInterface> = ({ onInventoryFinish, inventory }: InventoryDetailsHeaderPropsInterface) => {
    let history = useHistory();
    let { id }: any = useParams();

    const getModalButtons = () => {
        return [
            <button key="avatar-cropper-close" type="button" className="col-sm-4 btn btn-light px-0" data-dismiss="modal" onClick={() => null}>Anuluj</button>,
            <button key="avatar-cropper-save" type="button" className="col-sm-4 btn btn-light px-0" data-dismiss="modal" onClick={onInventoryFinish}>Zapisz</button>
        ]
    }

    const getModalContent = () => {
        return <p>Czy na pewno chcesz zakończyć inwentaryzację?</p>;
    }

    return (<div className="row px-0 mx-0 title-bar d-flex flex-nowrap justify-content-between align-items-center">
        <ModalComponent title="Zakończ inwentaryzację" id="inventory-end-confirm" buttons={getModalButtons()} content={getModalContent()} />
        <div className="col-sm-12 d-flex flex-no-wrap px-4 justify-content-between align-items-center">
            <div className="page-title d-flex flex-nowrap align-items-center">
                <div className="step-back mr-3 p-2" onClick={() => history.goBack()}><img src={arrowdownIcon} alt="Wstecz" /></div>
                <h2 id="page-title" className="m-0">Szczegóły inwentaryzacji</h2>
            </div>
            <div className="d-flex flex-no-wrap  justify-content-end align-items-center">
                {!inventory?.completed && _.isEmpty(_.get(inventory, "inProgress")) && Acl.isAllowedMethod("warehouse/inventory", "update") && <a type="button" className="btn  btn-light btn-expand btn-light ml-2" href="#inventory-end-confirm" data-toggle="modal"><img src={checkIcon} alt="zakończ"/><div>Zakończ</div></a>}
                {!inventory?.completed && Acl.isAllowedMethod("warehouse/inventory", "update") && <Link type="button" className="btn  btn-light btn-expand btn-light ml-2" to={`/inventory/${id}`}><img src={editIcon} alt="edytuj"/><div>Edytuj</div></Link>}
                {!inventory?.completed && Acl.isAllowedMethod("warehouse/inventory", "delete") && <a type="button" className="btn  btn-light btn-expand btn-light ml-2" href="#inventory-delete" data-toggle="modal"><img src={deleteIcon} alt="usuń"/><div>Usuń</div></a>}
            </div>
        </div>
    </div>);
}

export default InventoryDetailsHeaderComponent;