import { FC } from "react";
import { WarehouseListCardPropsInterface } from "../types/warehouse.types";
import { useHistory } from "react-router-dom"

import warehouseIcon from "../../common/images/ico-magazyn.svg";


const WarehouseListCardComponent: FC<WarehouseListCardPropsInterface> = ({ item }: WarehouseListCardPropsInterface) => {

    let history = useHistory();

    return (
        <tr onClick={() => { history.push(`/warehouses/${item._id}/page/1`) }}>

            <td className="col-md-1 col-sm-1" ><img alt="icon" src={warehouseIcon} /></td>

            <th className="col-md-5 col-sm-5">{item.name}</th>

            <td className="col-md-4 col-sm-4">{item.code}</td>

            <td className="col-md-2 col-sm-2">{item.positionsCount} poz.</td>

        </tr>
    );
}

export default WarehouseListCardComponent;