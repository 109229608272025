import React, { FC } from "react";
import CheckboxComponent from "../../common/components/checkbox.component";
import TextInputComponent from "../../common/components/textInput.component";
import resetIcon from "../../common/images/ico-wyczysc.svg";
import { UsersFiltersPropsInterface } from "../types/user.types";
import { useHistory, useParams } from "react-router";

const UsersFiltersComponent: FC<UsersFiltersPropsInterface> = ({ filters, onClearFilters, onFiltersChange }: UsersFiltersPropsInterface) => {

    const history = useHistory();
    const { page }: any = useParams();

    const handleFilterChange = (field: string, value: any) => {
        let newFilters = Object.assign({}, filters, {
            [field]: value
        });
        if (page !== "1") {
            history.push("/users/page/1");
        }
        onFiltersChange(newFilters);
    }

    const handleFiltersReset = () => {
        let nameInput: any = document.getElementById("name");
        if (nameInput) {
            nameInput.value = '';
        }
        let warehouseInput: any = document.getElementById("warehouse");
        if (warehouseInput) {
            warehouseInput.value = '';
        }
        let roleInput: any = document.getElementById("naroleme");
        if (roleInput) {
            roleInput.value = '';
        }
        let activeInput: any = document.getElementById("active");
        if (activeInput) {
            activeInput.checked = '';
        }
        if (page !== "1") {
            history.push("/users/page/1");
        }
        onClearFilters();
    }

    return (<div className="filter-top-bar justify-content-between flex-wrap p-0">
        <div className="d-flex flex-wrap align-items-center col-md-10 col-sm-12 ">
            <TextInputComponent
                id="name"
                defaultValue={filters.name}
                onChange={handleFilterChange}
                label="Nazwa użytkownika"
                placeholder="Szukaj"
                customClass="col-md-3 col-sm-12 form-group input-group m-0 py-3 px-0"
                debounce={1000}
            />
            <TextInputComponent
                id="warehouse"
                defaultValue={filters.warehouse}
                onChange={handleFilterChange}
                label="Nazwa magazynu"
                placeholder="Szukaj"
                customClass="col-md-3 col-sm-12 form-group input-group m-0 p-3"
                debounce={1000}
            />
            <TextInputComponent
                id="role"
                defaultValue={filters.role}
                onChange={handleFilterChange}
                label="Rola"
                placeholder="Szukaj"
                customClass="col-md-3 col-sm-12 form-group input-group m-0 px-0 py-3"
                debounce={1000}
            />
            <div className="col-md-2 col-sm-12 ">
                <CheckboxComponent
                    onChange={handleFilterChange}
                    checked={filters.active || false}
                    label="Aktywny"
                    id="active"
                    customClass="custom-control custom-checkbox text-left"
                />
            </div>

        </div>
        <div className="col-md-1 col-sm-12 d-flex align-self-stretch justify-content-end p-0 m-0 rounded-right color-gray" onClick={handleFiltersReset}>
            <div className="col-sm-12 d-flex flex-wrap justify-content-center align-items-center bg-light filter-clear-topbar rounded-right px-4 py-2">
                <img src={resetIcon} alt="reset" />
                Wyczyść
            </div>
        </div>
    </div>);
}

export default UsersFiltersComponent;