import React, { FC, useState } from 'react';
import ReportsPropsInterface from '../types/reports.types';
import Autocomplete from '../../common/components/autocomplete.component';
import SelectComponent from '../../common/components/select.component';
import moment from 'moment';
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';

const ReportsDocumentsProductsComponent: FC<ReportsPropsInterface> = ({ onDataChange, warehouseError, documentTypeError }: ReportsPropsInterface) => {

    const documentTypeOptions = [
        { value: "WZ", label: "WZ" },
        { value: "WZK", label: "WZK" },
        { value: "MM", label: "MM" },
        { value: "PW", label: "PW" },
        { value: "PZ", label: "PZ" },
        { value: "RWnp", label: "RWnp" },
        { value: "ZP", label: "ZP" },
        { value: "DH", label: "DH" }
    ];
    const type = "7";
    const [warehouse, setWarehouse] = useState("");
    const [documentType, setDocumentType] = useState("");
    const filter = { dateFrom: moment().startOf("week").format('MM/DD/YYYY'), dateTo: moment().format('MM/DD/YYYY') };
    let props = { type: type, warehouse: warehouse, dateFrom: filter.dateFrom, dateTo: filter.dateTo, documentType: documentType };

    const handleFindWarehouse = (value: any) => {
        if (value === null) {
            setWarehouse("");
            props.warehouse = "";
            onDataChange(props);
        }
        if (value) {
            setWarehouse(value.code);
            props.warehouse = value.code;
            onDataChange(props);
        }
    };

    const handleDocumentType = (value: any) => {
        if (value === null) {
            props.documentType = "";
            onDataChange(props);
        }
        if (value) {
            setDocumentType(value);
            props.documentType = value;
            onDataChange(props);
        };
    };

    const handleRangeChange = (event: any, picker: any) => {
        let newFilters = {
            dateFrom: picker.startDate.clone().format("MM/DD/YYYY"),
            dateTo: picker.endDate.clone().format('MM/DD/YYYY')
        };
        props.dateFrom = newFilters.dateFrom;
        props.dateTo = newFilters.dateTo;
        onDataChange(props);
    }


    return (
        <div id="report-form-dokumenty" className="report-form tabpane fade" role="tabpanel" aria-labelledby="report-form-dokumenty-tab">
            <div className="col-md-4 col-sm-12 px-0 form-group input-group my-0">
                <div className="has-float-label">
                    <Autocomplete
                        id="report-warehouse"
                        multi={false}
                        placeholder="Szukaj"
                        label="Magazyn"
                        url="/warehouse/warehouse"
                        optionLabelField="wyszukaj"
                        optionIdField="reportWarehouseId"
                        onOptionSelect={(id: string, value: any) => handleFindWarehouse(value)}
                        value={warehouse}
                        queryFields={["name", "code", "_id"]}
                        additionalParams={{ limit: 5, select: "name,code,_id" }}
                        parseOption={(option: any) => {
                            option.warehouseId = option._id;
                            return option;
                        }}
                        hideChips={false}
                        readonly={false}
                        preload={false}
                        error={warehouseError}
                    />
                </div>
            </div>
            <div className="col-md-4 col-sm-12 px-0 form-group input-group my-0">
                <label className="form-group has-float-label my-0 mx-3">
                    <SelectComponent
                        id="type"
                        onChange={(id, value) => { handleDocumentType(value) }}
                        options={documentTypeOptions}
                        label="Typ dokumentu"
                        error={documentTypeError}
                        value={documentType}
                    />

                </label>
            </div>
            <div id="date-filter-container" className="col-md-4 px-0 d-flex align-items-center ">
                <div id="current-filter" className="date-filter"><span>Dzień:</span>
                    <DateRangePicker
                        initialSettings={{ startDate: filter.dateFrom, endDate: filter.dateTo }}
                        onApply={handleRangeChange}
                    >
                        <input type="text" name="datefilter" />
                    </DateRangePicker>
                </div>
            </div>
        </div>
    );
};
export default ReportsDocumentsProductsComponent;