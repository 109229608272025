import 'bootstrap-daterangepicker/daterangepicker.css';
import _ from 'lodash';
import moment from 'moment';
import React, { FC } from "react";
import { useHistory } from "react-router";
import arrowdownIcon from "../../common/images/ico-arrowdown.svg";
import printIcon from "../../common/images/ico-print.svg";
import deleteIcon from "../../common/images/ico-xred.svg";
import printTemplate from '../assets/printTemplate';

import { DocumentDetailsHeaderPropsInterface } from "../types/document.types";

const DocumentDetailsHeaderComponent: FC<DocumentDetailsHeaderPropsInterface> = ({ showDelete, warehouseDocument }: DocumentDetailsHeaderPropsInterface) => {

    let history = useHistory();

    const getProductsToPrint = () => {
        let status = _.get(warehouseDocument, "completion.status", 1);
        let items = status === 3 ? _.get(warehouseDocument, "completion.result", []) : _.get(warehouseDocument, "products", []);
        let index = 0;
        let result = "";
        _.each(items, (item: any) => {
            _.each(item.series, (series: any) => {
                index++;
                result += `<tr>
                <td>
                    ${index}
                </td>
                <td colspan="3">
                    ${status === 3 ? item.product.name : item.name}
                </td>
                <td colspan="2">
                   ${series.seriesNr}
                </td>
                <td colspan="2">
                    ${moment(series.expirationDate).format("DD.MM.YYYY")}
                </td>
                <td>
                    ${Math.abs(series.amount)}
                </td>
                <td>
                    szt
                </td>
            </tr>`;
            });
        });
        return result;
    }

    const handlePrint = () => {
        var mywindow: any = window.open('', 'PRINT');
        let contractorData = `${_.get(warehouseDocument, "contractor.name", "")} <br/>
        ${_.get(warehouseDocument, "contractor.address.postCode", "")} ${_.get(warehouseDocument, "contractor.address.city", "")} <br/>
        ${_.get(warehouseDocument, "contractor.address.street", "")} ${_.get(warehouseDocument, "contractor.address.streetNumber", "")} <br/>
        ${_.get(warehouseDocument, "contractor.nip", "")}`;

        let data = {
            documentNumber: warehouseDocument?.documentNumber,
            currentDate: moment().format("DD.MM.YYYY"),
            warehouse: _.get(warehouseDocument, "warehouse.name", "-"),
            contractor: contractorData
        };
        let templateGenerator = _.template(printTemplate);
        let html = templateGenerator(data);
        html = html.replaceAll("<products>", getProductsToPrint());
        mywindow.document.write(html);
        mywindow.document.close();
        mywindow.focus();
        mywindow.print();
        setTimeout(() => {
            mywindow.close();
        }, 500);
        return true;
    }

    return (<div className="row px-0 mx-0 title-bar d-flex flex-nowrap justify-content-between align-items-center">
        <div className="col-sm-12 d-flex flex-no-wrap px-4 justify-content-between align-items-center">
            <div className="page-title d-flex flex-nowrap align-items-center">
                <div className="step-back mr-3 p-2" onClick={() => history.goBack()}><img src={arrowdownIcon} alt="wstecz" /></div>
                <h2 id="page-title" className="m-0">Szczegóły dokumentu</h2>
            </div>
            <div className="d-flex flex-no-wrap  justify-content-end align-items-center">
                <button type="button" className="btn  btn-light btn-expand btn-light ml-2" onClick={handlePrint}><img src={printIcon} alt="drukuj" /><div>Drukuj</div></button>
                {showDelete && <a type="button" className="btn  btn-light btn-expand btn-light ml-2" href="#document-delete" data-toggle="modal"><img src={deleteIcon} alt="usuń" /><div>Usuń</div></a>}
            </div>
        </div>
    </div>);
}

export default DocumentDetailsHeaderComponent;