import React, { FC, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router";
import { AppDispatch, RootState } from "../../config/store";
import { clearZp, getZp } from "../actions/zp.actions";
import ZpDetailsComponent from "../components/zpDetails.component";
import { ZpDetailsPropsInterface } from "../types/zp.types";

const ZpDetailsContainer: FC<ZpDetailsPropsInterface> = (props: ZpDetailsPropsInterface) => {
    const { getZp, clearZp } = props;
    let { id }: any = useParams();

    useEffect(() => {
        getZp(id);
        return () => {
            clearZp();
        }
    }, [getZp, clearZp, id])


    return <ZpDetailsComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    zp: store.zp.zp
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getZp: (id: string) => dispatch(getZp(id)),
        clearZp: () => dispatch(clearZp())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ZpDetailsContainer);