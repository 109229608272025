import { FC } from "react";
import { WarehousesFiltersPropsInterface } from "../types/warehouse.types";
import resetIcon from "../../common/images/ico-wyczysc.svg";
import TextInputComponent from "../../common/components/textInput.component";

const WarehouseListCardComponent: FC<WarehousesFiltersPropsInterface> = ({ filters, onClearFilters, onFiltersChange }: WarehousesFiltersPropsInterface) => {

    const handleFilterChange = (field: string, value: any) => {
        let newFilters = Object.assign({}, filters, {
            [field]: value
        });
        onFiltersChange(newFilters);
    }

    const handleFiltersReset = () => {
        let nameInput: any = document.getElementById("name");
        if (nameInput) {
            nameInput.value = '';
        }
        let warehouseInput: any = document.getElementById("code");
        if (warehouseInput) {
            warehouseInput.value = '';
        }
        onClearFilters();
    }

    return (
        <div className="filter-top-bar p-0">
            <TextInputComponent
                id="name"
                defaultValue={filters.name}
                onChange={handleFilterChange}
                label="Nazwa magazynu"
                placeholder="Szukaj magazynu"
                customClass="form-group input-group m-0 p-3"
                debounce={1000}
            />
            <TextInputComponent
                id="code"
                defaultValue={filters.code}
                onChange={handleFilterChange}
                label="Kod magazynu"
                placeholder="Szukaj magazynu"
                customClass="form-group input-group m-0 py-3"
                debounce={1000}
            />

            <div className="d-flex align-self-stretch color-gray" onClick={handleFiltersReset}>
                <div className="d-flex flex-wrap justify-content-center align-items-center bg-light filter-clear-topbar rounded-right px-4 py-2">
                    <img alt="icon" src={resetIcon} className="" />
                    Wyczyść</div>
            </div>
        </div>
    );
}

export default WarehouseListCardComponent;