import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { GenericObject, RoleInterface } from "../../common/types/common.types"
import { AppDispatch } from "../../config/store"
import { RoleActionType } from "../types/role.types";
import { toast } from "react-toastify"

export const getRole = (id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get(`/application/role/${id}`);
        if (res.success) {
            dispatch(setRole(res.document));
        } else {
            toast.error("Błąd pobierania roli");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania roli");
        dispatch(isLoading(false));
    }
}

export const deleteRole = (id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.delete(`/application/role/${id}`);
        if (res.success) {
            toast.success("Usunięto rolę");
            dispatch(setRole(res.document));
        } else {
            toast.error("Błąd usuwania roli");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd usuwania roli");
        dispatch(isLoading(false));
    }
}

export const saveRole = async (data: GenericObject, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = null;
        if (data._id) {
            res = await Api.put(`/application/role/${data._id}`, data);
        } else {
            res = await Api.post("/application/role", data);
        }
        dispatch(isLoading(false));

        if (res.success) {
            toast.success("Zapisano rolę");
            return res;
        } else if (res.errors) {
            toast.error("Błąd zapisu roli");
            return res;
        } else {
            toast.error("Błąd zapisu roli");
            return { success: false, errors: { message: "Błąd zapisu" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd zapisu roli");
        return { success: false, errors: { message: "Błąd zapisu" } };
    }
}

export const setRole = (role: RoleInterface): AnyAction => {
    return {
        type: RoleActionType.SET_ROLE,
        role
    }
}

export const clearRole = (): AnyAction => {
    return {
        type: RoleActionType.CLEAR_ROLE
    }
}