import React, { FC } from "react";
import TextInputComponent from "../../common/components/textInput.component";
import resetIcon from "../../common/images/ico-wyczysc.svg";
import collapseIcon from "../../common/images/ico-zwin.svg";
import { GeneratorFiltersPropsInterface } from "../types/generator.types";

const GeneratorFiltersComponent: FC<GeneratorFiltersPropsInterface> = ({ onClearFilters, onFiltersChange, filters }: GeneratorFiltersPropsInterface) => {

    const handleFilterChange = (field: string, value: any) => {
        let newFilters = Object.assign({}, filters, {
            [field]: value
        });
        onFiltersChange(newFilters);
    }

    const handleFiltersReset = () => {
        let nameInput: any = document.getElementById("name");
        if (nameInput) {
            nameInput.value = '';
        }
        let eanInput: any = document.getElementById("ean");
        if (eanInput) {
            eanInput.value = '';
        }
        let seriesNrInput: any = document.getElementById("seriesNr");
        if (seriesNrInput) {
            seriesNrInput.value = '';
        }
        onClearFilters();
    }

    const handleCollapseClick = () => {
        $('#filter-sidebar').toggleClass("toggled");
        $('#filter-toggle').toggleClass("toggled");
        if ($('#filter-toggle').hasClass('toggled')) {
            $('#filter-list').addClass("fadeOut");
            $('#filter-list').removeClass("fadeIn");
        } else {
            $('#filter-list').addClass("fadeIn");
            $('#filter-list').removeClass("fadeOut");
        }
        $('#content-container-right').toggleClass("col-md-12");
    }

    return (<div id="filter-sidebar" className="col-md-3 col-sm-12 bg-white py-4 px-4 d-flex flex-column">
        <div id="filter-toggle" className="filter-collapse" onClick={handleCollapseClick}><img src={collapseIcon} alt="zwin" /></div>
        <h3 className="mb-4">Filtry</h3>
        <div id="filter-list" className="animated">
            <TextInputComponent
                id="name"
                defaultValue={filters.name}
                onChange={handleFilterChange}
                label="Nazwa produktu"
                placeholder="Szukaj"
                customClass="form-group input-group"
                debounce={1000}
            />
            <TextInputComponent
                id="ean"
                defaultValue={filters.ean}
                onChange={handleFilterChange}
                label="Kod EAN"
                placeholder="Szukaj"
                customClass="form-group input-group"
                debounce={1000}
            />
            <TextInputComponent
                id="seriesNr"
                defaultValue={filters.seriesNr}
                onChange={handleFilterChange}
                label="Numer serii"
                placeholder="Szukaj"
                customClass="form-group input-group"
                debounce={1000}
            />
            <div className="filter-clear float-right color-gray align-self-end" onClick={handleFiltersReset}>
                <p>Wyczyść wszystko <img src={resetIcon} alt="reset" /></p>
            </div>
        </div>
    </div>);
}

export default GeneratorFiltersComponent;