import { ZpStateInterface, ZpActionType } from "../types/zp.types";

const initialState: ZpStateInterface = {
    zp: null
}

const zpReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case ZpActionType.SET_ZP: {
            return {
                ...state,
                zp: action.zp
            }
        }
        case ZpActionType.CLEAR_ZP: {
            return {
                ...state,
                zp: null
            }
        }
        default: return state;
    }
}

export default zpReducer;