import React, { FC } from "react";
import { DocumentsFiltersPropsInterface } from "../types/document.types";
import completedIcon from "../../common/images/ico-zrealizowane.svg";
import completionInProgressIcon from "../../common/images/ico-wrealizacji.svg";
import completionTodoIcon from "../../common/images/ico-dorealizacji.svg";

import inspectedIcon from "../../common/images/ico-sprawdzone.svg";
import inspectionInProgressIcon from "../../common/images/ico-sprawdzanie.svg";
import inspectionTodoIcon from "../../common/images/ico-dosprawdzenia.svg";

const DocumentsListStatusFiltersComponent: FC<DocumentsFiltersPropsInterface> = ({ filters, onFiltersChange }: DocumentsFiltersPropsInterface) => {

    const handleFilterChange = (field: string, value: any) => {
        if (filters[field] === value) {
            value = "";
        }
        let newFilters = Object.assign({}, filters, {
            [field]: value
        });
        onFiltersChange(newFilters);
    }

    const getSelectedClass = (field: string, value: any) => {
        return filters[field] === value ? "font-weight-bold" : "";
    }

    return (<div className="table-legend">
        <div onClick={() => handleFilterChange("completionStatus", "3")}>
            <button title="Filtruj zrealizowane" className={getSelectedClass("completionStatus", "3")} type="button"><img src={completedIcon} alt="status"/> Zrealizowane</button>
        </div>
        <div onClick={() => handleFilterChange("completionStatus", "2")}>
            <button type="button" title="Filtruj w trakcie realizacji" className={getSelectedClass("completionStatus", "2")}><img src={completionInProgressIcon} alt="status"/> W trakcie
                realizacji</button>
        </div>
        <div onClick={() => handleFilterChange("completionStatus", "1")}>
            <button type="button" title="Filtruj do zrealizowania" className={getSelectedClass("completionStatus", "1")}><img src={completionTodoIcon} alt="status"/> Do
                zrealizowania</button>
        </div>
        <div onClick={() => handleFilterChange("inspectionStatus", "3")}>
            <button type="button" title="Filtruj sprawdzone" className={getSelectedClass("inspectionStatus", "3")}><img src={inspectedIcon} alt="status"/> Sprawdzone</button>
        </div>
        <div onClick={() => handleFilterChange("inspectionStatus", "2")}>
            <button type="button" title="Filtruj w trakcie sprawdzania" className={getSelectedClass("inspectionStatus", "2")}><img src={inspectionInProgressIcon} alt="status"/> W trakcie
                sprawdzania</button>
        </div>
        <div onClick={() => handleFilterChange("inspectionStatus", "1")}>
            <button type="button" title="Filtruj do sprawdzenia" className={getSelectedClass("inspectionStatus", "1")}><img src={inspectionTodoIcon} alt="status"/> Do
                sprawdzenia</button>
        </div>
    </div>);
}

export default DocumentsListStatusFiltersComponent;