import { FC } from "react";
import { PositionHistoryListCardPropsInterface } from "../types/position.types";

import _ from "lodash";


const PositionHistoryListCardComponent: FC<PositionHistoryListCardPropsInterface> = ({ item }: PositionHistoryListCardPropsInterface) => {
    const calculateDifference = (before: number, after: number) => {
        if (before === after) return 0;

        return Math.round(Math.abs(before - after));
    }

    const renderProducts = () => {
        if (!item) return;

        return _.get(item, 'products').map((prod: any, i: number) => {
            return (
                <tr key={i}>
                    <th scope="col" className="col-md-4">
                        <div className="badge p-2">{prod.name}</div>
                    </th>

                    <th className="col-md-2">
                        <h3 className="text-left mt-1">{prod.seriesNr}</h3>
                    </th>

                    <th className="col-md-2">{prod.amountBefore}</th>
                    <th className="col-md-2">{prod.amountAfter}</th>
                    <th className="col-md-1">{calculateDifference(prod.amountBefore, prod.amountAfter)}</th>
                </tr>
            )
        })
    }

    return (
        <div className="d-flex flex-wrap col-sm-12 px-0 border border-top-0 border-left-0 border-right-0">

            <div className="document-group px-0 col-sm-2 d-flex align-items-center justify-content-center">
                <h3 className="text-left mt-1">{_.get(item, 'document.documentNumber')}</h3>
            </div>

            <table className="table table-hover mb-0 col-sm-10">
                <thead>
                    <tr className="thead-light tableonmobile">
                        <th className="col-md-4">Produkt</th>
                        <th className="col-md-2">Numer serii</th>
                        <th className="col-md-2">Ilość przed</th>
                        <th className="col-md-2">Ilość po</th>
                        <th className="col-md-1 pr-4">Różnica</th>
                    </tr>
                </thead>

                <tbody>
                    {renderProducts()}
                </tbody>
            </table>
        </div>

    );
}

export default PositionHistoryListCardComponent;