import { UserStateInterface, UserActionType } from "../types/user.types";

const initialState: UserStateInterface = {
    user: null
}

const userReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case UserActionType.SET_USER: {
            return {
                ...state,
                user: action.user
            }
        }
        case UserActionType.CLEAR_USER: {
            return {
                ...state,
                user: null
            }
        }
        default: return state;
    }
}

export default userReducer;