import React, { FC } from "react";
import { RequireAtLeastOne } from "../types/common.types";
import { FileInputComponentPropsInterface } from "../types/component.types";

const FileInputComponent: FC<RequireAtLeastOne<FileInputComponentPropsInterface, 'value' | 'defaultValue'>> = ({
    error,
    label,
    id,
    type = "file",
    customClass,
    value,
    defaultValue,
    multiple = true,
    onChange,
}: RequireAtLeastOne<FileInputComponentPropsInterface, 'value' | 'defaultValue'>) => {

    return (<div className={customClass ? customClass : 'form-group input-group my-3'}>
        <span className="has-float-label">
            <input
                className={`form-control ${error ? 'is-invalid' : ''}`}
                type={type}
                multiple={multiple}
                id={id}
                defaultValue={defaultValue?.toString()}
                onChange={(e) => {
                    onChange(id, e.target.files)
                }}
            />
            <label onClick={() => document.getElementById(id)?.focus()}>{label}</label>
        </span>
        {error && <span className="is-invalid-alert">{error.toString()}</span>}
    </div>);

}

export default FileInputComponent;
