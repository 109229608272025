import { AnyAction } from "redux";
import { GenericObject } from "../../common/types/common.types";
import { ApplicationActionType } from "../types/application.types";

export const setSocketConnection = (socketConnection: boolean): AnyAction => {
    return {
        type: ApplicationActionType.SET_SOCKET_CONNECTION,
        socketConnection
    }
}

export const setActiveUsers = (activeUsers: Array<GenericObject>): AnyAction => {
    return {
        type: ApplicationActionType.SET_ACTIVE_USERS,
        activeUsers
    }
}