import React, { FC } from "react";
import { useHistory } from 'react-router';
import { ZpDetailsProductTablePropsInterface } from '../types/zp.types';

const ZpDetailsProductTable: FC<ZpDetailsProductTablePropsInterface> = ({ items }: ZpDetailsProductTablePropsInterface) => {

    let history = useHistory();

    const renderItems = () => {
        let index = 0;
        return items.map((item, productIndex) => {
            index++;
            return (<tr key={`product${productIndex}`} onClick={() => history.push("/products/" + item.productId)}>
                <td>{index}</td>
                <th scope="row">{item.name}</th>
                <td>
                    <h3 className="text-left mt-1">{item.seriesNr}</h3>
                </td>
                <td>{item.amount}</td>
                <td>szt</td>
            </tr>)
        });
    }

    return (<table id="table-warehouses" className="table table-hover mb-0">
        <thead>
            <tr className="thead-light">
                <th scope="col" className="col-md-1 text-center">Lp.</th>
                <th scope="col" className="col-md-5">Nazwa</th>
                <th className="col-md-2">Seria</th>
                <th className="col-md-1">Ilość</th>
                <th className="col-md-1">Jednostka</th>
            </tr>
        </thead>
        <tbody>
            {renderItems()}
        </tbody>
    </table>);
}

export default ZpDetailsProductTable;