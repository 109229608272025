import _ from "lodash"
import moment from "moment"
import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import apiService from "../../application/services/api.service"
import { GenericObject, UserInterface } from "../../common/types/common.types"
import { AppDispatch, GetState } from "../../config/store"
import { DocumentsActionType, DocumentsStateInterface } from "../types/document.types"

export const getDocuments = (hideLoader?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        const { documents: { filters, params, page } } = getState();
        if (!hideLoader) {
            dispatch(isLoading(true));
        }

        let sendParams: GenericObject = parseFilters(filters, {
            limit: params.limit,
            offset: (page - 1) * params.limit,
            checkAmount: true
        });

        if (_.isObject(params.sort) && !_.isEmpty(params.sort)) {
            let sortKey: string = _.keys(params.sort)[0];
            let sortValue: number = _.values(params.sort)[0];
            sendParams[`sort[${sortKey}]`] = sortValue;
        }

        let res = await apiService.get("/document/document", sendParams);
        if (res.success) {
            dispatch(setDocuments(res.documents, res.total));
        }
        dispatch(isLoading(false));
    } catch (err) {
        dispatch(isLoading(false));
    }
}

export const setDocuments = (documents: Array<UserInterface>, total: number): AnyAction => {
    return {
        type: DocumentsActionType.SET_DOCUMENTS,
        documents,
        total
    }
}

export const clearDocuments = (): AnyAction => {
    return {
        type: DocumentsActionType.CLEAR_DOCUMENTS
    }
}

export const setFilters = (filters: DocumentsStateInterface["filters"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: DocumentsActionType.SET_FILTERS,
        filters
    })
    if (reloadList) {
        dispatch(getDocuments(true));
    }
}

export const clearFilters = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: DocumentsActionType.CLEAR_FILTERS
    });
    if (reloadList) {
        dispatch(getDocuments(true));
    }
}

export const setParams = (params: DocumentsStateInterface["params"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: DocumentsActionType.SET_PARAMS,
        params
    });
    if (reloadList) {
        dispatch(getDocuments(true));
    }
}

export const clearParams = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: DocumentsActionType.CLEAR_PARAMS
    })
    if (reloadList) {
        dispatch(getDocuments(true));
    }
}

export const setPage = (page: number): AnyAction => {
    if (page <= 0) page = 1;
    return {
        type: DocumentsActionType.SET_PAGE,
        page
    }
}

const parseFilters = (filters: DocumentsStateInterface["filters"], sendParams: GenericObject) => {

    if (!_.isEmpty(filters.completionStatus)) {
        sendParams["completion.status"] = filters.completionStatus;
    }
    if (!_.isEmpty(filters.inspectionStatus)) {
        sendParams["inspection.status"] = filters.inspectionStatus;
    }
    if (!_.isEmpty(filters.warehouse)) {
        sendParams["warehouse.warehouseId[in]"] = filters.warehouse.map((w) => w.warehouseId).join(",");
    }
    if (!_.isEmpty(filters.relatedWarehouse)) {
        sendParams["relatedWarehouse.warehouseId"] = filters.relatedWarehouse.map((w) => w.warehouseId).join(",");
    }
    if (!_.isEmpty(filters.type)) {
        sendParams["type"] = filters.type;
    }
    // transportType: "",
    if (!_.isEmpty(filters.product)) {
        sendParams["products.name"] = filters.product;
    }
    if (!_.isEmpty(filters.customer)) {
        sendParams["customer.name"] = filters.customer;
    }

    if (!_.isEmpty(filters.completionUser)) {
        sendParams["completion.user.name"] = filters.completionUser;
    }
    if (!_.isEmpty(filters.inspectionUser)) {
        sendParams["inspection.user.name"] = filters.inspectionUser;
    }

    sendParams['createdAt[gte]'] = moment(filters.dateFrom, "MM/DD/YYYY").startOf("day").format("X");
    sendParams['createdAt[lte]'] = moment(filters.dateTo, "MM/DD/YYYY").endOf("day").format("X");

    return sendParams;
};
