import { Formik } from 'formik';
import React, { FC, Fragment } from "react";
import TextInputComponent from "../../common/components/textInput.component";
import loginBackground from "../../common/images/bg-overwms.jpg";
import { initialValues, loginValidationSchema } from "../schemas/login.schema";
import { LoginPropsInterface } from "../types/application.types";
import clientLogo from "../../common/images/client-logo.svg";
import animIcon from "../../common/images/anim.svg";
import bgLogowanie from "../../common/images/bg-logowanie-overwms.png";

const LoginComponent: FC<LoginPropsInterface> = ({ login }: LoginPropsInterface) => {
    return (
        <Fragment>
            <div id="login-container" className="col-md-6">
                <div className="login d-flex align-items-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 col-xl-7 mx-auto d-flex flex-column justify-content-between  h100vh">
                                <img src={clientLogo} className="login-client-logo mb-4" alt="logo" />
                                <div>
                                    <h3>Witaj ponownie</h3>
                                    <h2>Zaloguj się na swoje konto</h2>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={loginValidationSchema}
                                        validateOnChange={false}
                                        validateOnBlur={false}
                                        onSubmit={(values, { setErrors }) => {
                                            login(values.login, values.password).then((res) => {
                                                if (res.errors) {
                                                    setErrors(res.errors);
                                                }
                                            })
                                        }}
                                    >
                                        {formik => (
                                            <form onSubmit={formik.handleSubmit}>
                                                <TextInputComponent
                                                    id="login"
                                                    value={formik.values.login}
                                                    onChange={formik.setFieldValue}
                                                    label="Adres e-mail"
                                                    placeholder="Adres e-mail"
                                                    customClass="form-group input-group my-3"
                                                    error={formik.errors.message || formik.errors.login}
                                                />
                                                <TextInputComponent
                                                    id="password"
                                                    type="password"
                                                    value={formik.values.password}
                                                    onChange={formik.setFieldValue}
                                                    label="Hasło"
                                                    placeholder="Wpisz hasło"
                                                    customClass="form-group input-group b-3"
                                                    error={formik.errors.password}
                                                />
                                                {/* <CheckboxComponent
                                                    id="remember-password"
                                                    customClass="custom-control custom-checkbox mb-3"
                                                    label="Zapamiętaj hasło"
                                                    checked={true}
                                                    onChange={(id, checked) => null}
                                                /> */}
                                                <button type="submit" className="btn btn-primary btn-wms btn-block text-uppercase mb-2 mt-4 shadow-sm ">Zaloguj się</button>
                                                <div className="text-center d-flex justify-content-between mt-4 ">
                                                </div>
                                            </form>)}
                                    </Formik>
                                </div>
                                <p>Potrzebujesz pomocy?<a href="# " className="text-muted"> <u>Skontaktuj się z biurem</u></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6 d-none d-md-flex blue-bg p-0" style={{ backgroundImage: `url(${loginBackground})` }}>
                <img src={animIcon} className="login-bg-logo" alt="wms-logo" />
                <img src={bgLogowanie} className="login-bg-image" alt="logowanie" />
            </div>
        </Fragment>
    )
}

export default LoginComponent;