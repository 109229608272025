import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { useReactToPrint } from 'react-to-print';
import dictService from "../../application/services/dict.service";
import DeleteConfirmModal from "../../common/components/deleteConfirmModal.component";
import koszIcon from "../../common/images/ico-kosz.svg";
import printIcon from "../../common/images/ico-print.svg";
import { AppDispatch } from "../../config/store";
import { deleteWarehousePosition } from '../actions/position.actions';
import { PositionListCardPropsInterface } from "../types/position.types";

const PositionListCardComponent: FC<PositionListCardPropsInterface> = ({ item, deleteWarehousePosition }: PositionListCardPropsInterface) => {
    const componentRef = useRef<any>();
    const history = useHistory();
    const [dict, setDict] = useState<any>(null)

    useEffect(() => {
        const dicts = dictService.getDict(2);
        setDict(dicts);
    }, [])

    const getPositionName = () => {
        if (!dict) return;
        return _.get(dict, `values[${item.type}].name`)
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleDelete = () => {
        if (item._id) {
            deleteWarehousePosition(item._id);
            document.getElementById(`deletePosition${item._id}`)?.click()
        }
    }

    return (
        <tr className="col-sm-12">
            <td className="d-flex justify-content-center flex-wrap px-3" onClick={() => history.push(`/positions/${item._id}/page/1`)}>
                <h3 className="w-100 text-center">{_.get(item, 'warehouse.name')}</h3>
                <div className="mx-auto badge badge-light px-5 py-3 border border-2 ">
                    <h1>{_.get(item, 'code')}</h1>
                </div>
                <h3 className="w-100 text-center"><span className="float-none">Typ pozycji:</span> {getPositionName()}</h3>
            </td>

            <td className="col-sm-6">
                <img alt="zdjecie" ref={componentRef} src={_.get(item, 'barcode')} className="mx-auto d-block" />
            </td>

            <td className="">
                <button type="button" className="bg-light rounded border-0 px-4 py-2 w-75" onClick={handlePrint}><img src={printIcon} alt="zdjecie" /> Drukuj</button>
                <a type="button" href={`#deletePosition${item._id}`} className="btn-outline-danger bg-light rounded border-0 px-4 py-2 mt-2 w-75 text-center" data-toggle="modal"><img src={koszIcon} className="pb-1" alt="zdjecie" /> Usuń</a>
                <DeleteConfirmModal
                    id={`deletePosition${item._id}`}
                    onDelete={() => handleDelete()}
                />
            </td>
        </tr>
    );
}

// export default PositionListCardComponent;

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        deleteWarehousePosition: (id: string) => dispatch(deleteWarehousePosition(id))
    }
};

export default connect(null, mapDispatchToProps)(PositionListCardComponent);