import moment from "moment";
import Api from "../../application/services/api.service";
import { isLoading } from "../../application/actions/application.actions";
import { GenericObject, ProductInterface } from "../../common/types/common.types";
import { AppDispatch, GetState } from "../../config/store";
import { AnyAction } from "redux";
import { ProductActionType, ProductStateInterface } from "../types/product.types";
import { toast } from "react-toastify"

// ************ GET ***************
export const getProductWarehousePositionHistory =
  (productId: string, hideLoader?: boolean) =>
    async (dispatch: AppDispatch, getState: GetState) => {
      try {
        const {
          product: { filters, params, page },
        } = getState();

        if (!hideLoader) {
          dispatch(isLoading(true));
        }

      let sendParams: GenericObject = {
        limit: params.limit,
        offset: (page - 1) * params.limit,
        "products.productId": productId,
        "createdAt[gte]": moment(filters.dateFrom).format("X"),
        "createdAt[lte]": moment(filters.dateTo).format("X"),
      };

        if (filters.documentNumber) {
          sendParams["document.documentNumber"] = filters.documentNumber;
        }
        if (filters.seriesNr) {
          sendParams["products.seriesNr"] = filters.seriesNr;
        }
        if (filters.position) {
          sendParams["position.code"] = filters.position;
        }

        let res = await Api.get(`/warehouse/positionHistory`, sendParams);
        if (res.success) {
          dispatch(setProductWarehousePositionHistory(res.documents, res.total));
        } else {
          toast.error("Błąd pobierania historii");
        }
        dispatch(isLoading(false));
      } catch (err) {
        toast.error("Błąd pobierania historii");
        dispatch(isLoading(false));
      }
    };

export const getProductWarehouseState = (productId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isLoading(true));
    let res = await Api.get(`/product/product/getProductPositions/${productId}`);
    if (res.success) {
      dispatch(setProductWarehouseState(res.documents));
    } else {
      toast.error("Błąd pobierania stanu magazynowego produktu");
    }
    dispatch(isLoading(false));
  } catch (err) {
    toast.error("Błąd pobierania stanu magazynowego produktu");
    dispatch(isLoading(false));
  }
};

export const getProductDocumentReservations =
  (productId: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(isLoading(true));
      let res = await Api.get(`/document/reservation?product.productId=${productId}`);
      if (res.success) {
        dispatch(setProductDocumentReservations(res.documents));
      } else {
        toast.error("Błąd pobierania rezerwacji");
      }
      dispatch(isLoading(false));
    } catch (err) {
      toast.error("Błąd pobierania rezerwacji");
      dispatch(isLoading(false));
    }
  };

export const getProduct = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(isLoading(true));
    let res = await Api.get(`/product/product/${id}`);
    if (res.success) {
      let product = res.document;

      if ("categories" in product) {
        product.categories = product.categories.map((cat: any) => {
          return {
            _id: cat.categoryId,
            name: cat.name,
            ancestors: cat.ancestors,
            breadcrumb: cat.breadcrumb,
          };
        });
      }

      dispatch(setProduct(product));
    } else {
      toast.error("Błąd pobierania produktu");
    }
    dispatch(isLoading(false));
  } catch (err) {
    toast.error("Błąd pobierania produktu");
    dispatch(isLoading(false));
  }
};

// ************ SET ***************

export const setProductWarehousePositionHistory = (
  productWarehousePositionHistory: any,
  total: number
): AnyAction => {
  return {
    type: ProductActionType.SET_PRODUCT_WAREHOUSE_POSITION_HISTORY,
    productWarehousePositionHistory,
    total,
  };
};

export const setProductWarehouseState = (productWarehouseState: any): AnyAction => {
  return {
    type: ProductActionType.SET_PRODUCT_WAREHOUSE_STATE,
    productWarehouseState,
  };
};

export const setProductDocumentReservations = (productDocumentReservations: any): AnyAction => {
  return {
    type: ProductActionType.SET_PRODUCT_DOCUMENT_RESERVATIONS,
    productDocumentReservations,
  };
};

export const setProduct = (product: ProductInterface): AnyAction => {
  return {
    type: ProductActionType.SET_PRODUCT,
    product,
  };
};

export const setFilters =
  (filters: ProductStateInterface["filters"], reloadList?: boolean) =>
    async (dispatch: AppDispatch, getState: GetState) => {
      const {
        product: { product },
      } = getState();

      dispatch({
        type: ProductActionType.SET_FILTERS,
        filters,
      });

      if (reloadList) {
        dispatch(getProductWarehousePositionHistory(product._id));
      }
    };

export const setParams =
  (params: ProductStateInterface["params"], reloadList?: boolean) =>
    async (dispatch: AppDispatch, getState: GetState) => {
      const {
        product: { product },
      } = getState();
      dispatch({
        type: ProductActionType.SET_PARAMS,
        params,
      });
      if (reloadList) {
        dispatch(getProductWarehousePositionHistory(product._id));
      }
    };

export const setPage = (page: number): AnyAction => {
  if (page <= 0) page = 1;
  return {
    type: ProductActionType.SET_PAGE,
    page,
  };
};

// ************ CLEAR ***************
export const clearProduct = (): AnyAction => {
  return {
    type: ProductActionType.CLEAR_PRODUCT,
  };
};

export const clearProductWarehousePositionHistory = (): AnyAction => {
  return {
    type: ProductActionType.CLEAR_PRODUCT_WAREHOUSE_POSITION_HISTORY,
  };
};

export const clearProductWarehouseState = (): AnyAction => {
  return {
    type: ProductActionType.CLEAR_PRODUCT_WAREHOUSE_STATE,
  };
};

export const clearProductDocumentReservations = (): AnyAction => {
  return {
    type: ProductActionType.CLEAR_PRODUCT_DOCUMENT_RESERVATIONS,
  };
};

export const clearFilters =
  (reloadList?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    const {
      product: { product },
    } = getState();
    dispatch({
      type: ProductActionType.CLEAR_FILTERS,
    });
    if (reloadList) {
      dispatch(getProductWarehousePositionHistory(product._id));
    }
  };

export const clearParams =
  (reloadList?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    const {
      product: { product },
    } = getState();
    dispatch({
      type: ProductActionType.CLEAR_PARAMS,
    });
    if (reloadList) {
      dispatch(getProductWarehousePositionHistory(product._id));
    }
  };

// ************ REST ***************

export const saveProduct = async (data: GenericObject, dispatch: AppDispatch) => {
  try {
    dispatch(isLoading(true));
    let res = null;
    if (data._id) {
      res = await Api.put(`/product/product/${data._id}`, data, true);
    } else {
      res = await Api.post("/product/product", data, true);
    }
    dispatch(isLoading(false));

    if (res.success) {
      toast.success("Zapisano produkt");
      return res;
    } else if (res.errors) {
      toast.error("Błąd zapisu produktu");
      return res;
    } else {
      toast.error("Błąd zapisu produktu");
      return { success: false, errors: { message: "Błąd zapisu" } };
    }
  } catch (err) {
    dispatch(isLoading(false));
    toast.error("Błąd zapisu produktu");
    return { success: false, errors: { message: "Błąd zapisu" } };
  }
};

export const deleteProduct = async (id: string, dispatch: AppDispatch) => {
  try {
    dispatch(isLoading(true));
    let res = await Api.delete(`/product/product/${id}`);
    dispatch(isLoading(false));
    return res;
  } catch (err) {
    toast.error("Błąd usuwania produktu");
    dispatch(isLoading(false));
  }
};
