import Api from "../../application/services/api.service";
import { AppDispatch } from "../../config/store";
import { CategoryActionType } from "../types/category.types";
import { CategoryInterface } from "../../common/types/component.types";

export const setCategories = (documents: Array<CategoryInterface>) => (dispatch: AppDispatch) => {
  dispatch({
    type: CategoryActionType.SET_CATEGORIES,
    categories: documents,
  });
};

export const getCategories = (content: string) => async (dispatch: AppDispatch) => {
  let url = getUrl(content);
  let res = await Api.get(`${url}/getCategoryTree`);

  if (res.success) {
    dispatch(setCategories(res.document));
  }
};

const getUrl = (content: string) => {
  switch (content) {
    case "product": {
      return "/product";
    }
    default: {
      return null;
    }
  }
};

export const createCategory = (data: any) => async (dispatch: AppDispatch) => {

  const res = await Api.post(`/product/category`, data);

  if (res.success) {
    dispatch(getCategories('product'));
  }
};
export const editCategory = (data: any, id: string) => async (dispatch: AppDispatch) => {

  const res = await Api.put(`/product/category/${id}`, data);

  if (res.success) {
    dispatch(getCategories('product'));
  }
};
export const deleteCategory = (id: string) => async (dispatch: AppDispatch) => {

  const res = await Api.delete(`/product/category/${id}`);

  if (res.success) {
    dispatch(getCategories('product'));
  }
};