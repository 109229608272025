import _ from "lodash";
import { AnyAction } from "redux";
import { UserInterface } from "../../common/types/common.types";
import { AppDispatch, GetState } from "../../config/store";
import Acl from "../services/acl.service";
import Api from "../services/api.service";
import DictService from "../services/dict.service";
import { ApplicationActionType } from "../types/application.types";
import SocketService from "../services/socket.service";
import { toast } from "react-toastify";

export const login = async (login: string, password: string, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.post("/application/login", { login, password });
        dispatch(isLoading(false));
        if (res.success) {
            localStorage.setItem("token", res.token);
            Acl.setIsAdmin(_.get(res, "user.isAdmin", false));
            Acl.setPermissions(_.get(res, "user.role.permissions", []));
            await setDictsAndSettings();
            dispatch(setUser(res.user));
            SocketService.emit("login", parseSocketUserData(res.user));
            return { success: true };
        } else if (res.errors) {
            toast.error("Brak autoryzacji");
            return res;
        } else {
            toast.error("Brak autoryzacji");
            return { success: false, errors: { message: "Brak autoryzacji" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Brak autoryzacji");
        return { success: false, errors: { message: "Brak autoryzacji" } };
    }
}

export const logout = () => async (dispatch: AppDispatch, getState: GetState) => {
    const { application: { user } } = getState();
    SocketService.emit("logout", parseSocketUserData(user));
    dispatch(clearUser());
    Acl.clearPermissions();
    localStorage.removeItem('token');
}

export const checkAuth = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get("/application/isAuth");
        if (res.success) {
            localStorage.setItem("token", res.token);
            Acl.setIsAdmin(_.get(res, "user.isAdmin", false));
            Acl.setPermissions(_.get(res, "user.role.permissions", []));
            await setDictsAndSettings();
            dispatch(setUser(res.user));
            SocketService.emit("login", parseSocketUserData(res.user));
        } else {
            localStorage.removeItem('token');
        }
        setTimeout(() => {
            dispatch(isLoading(false));
        }, 500);
    } catch (err) {
        dispatch(isLoading(false));
    }
}

async function setDictsAndSettings() {
    try {
        let dicts = null;
        let settings = null;
        await Promise.all([
            (async () => {
                let res = await Api.get('/application/dictionary', null);
                if (res.success) {
                    dicts = _.get(res, 'documents', []);
                }
            })(),
            (async () => {
                let res = await Api.get('/application/settings', null);
                if (res.success) {
                    settings = _.get(res, 'documents', []);
                }
            })(),
        ]);
        if (dicts && settings) {
            DictService.setSettings(settings);
            DictService.setDicts(dicts);
        }
    } catch (err) {
        toast.error("Błąd pobierania ustawień i słowników");
    }
};

export const setUser = (user: UserInterface): AnyAction => {
    return {
        type: ApplicationActionType.SET_USER,
        user
    }
}

export const clearUser = (): AnyAction => {
    return {
        type: ApplicationActionType.CLEAR_USER
    }
}

export const isLoading = (isLoading: boolean): AnyAction => {
    return {
        type: ApplicationActionType.SET_IS_LOADING,
        isLoading
    }
}

const parseSocketUserData = (user: UserInterface) => {
    return Object.assign({}, _.pick(user, ["name", "_id"]), {
        deviceType: "WEB",
        deviceInfo: getBrowserInfo()
    });
}

const getBrowserInfo = () => {
    try {
        let nAgt = navigator.userAgent;
        let browserName = navigator.appName;
        let fullVersion = '' + parseFloat(navigator.appVersion);
        let majorVersion = parseInt(navigator.appVersion, 10);
        let nameOffset, verOffset, ix;
        let systemName = "Nieznany";
        if (navigator.userAgent.indexOf("Win") !== -1) systemName =
            "Windows OS";
        if (navigator.userAgent.indexOf("Mac") !== -1) systemName =
            "Macintosh";
        if (navigator.userAgent.indexOf("Linux") !== -1) systemName =
            "Linux OS";
        if (navigator.userAgent.indexOf("Android") !== -1) systemName =
            "Android OS";
        if (navigator.userAgent.indexOf("like Mac") !== -1) systemName =
            "iOS";

        // In Opera 15+, the true version is after "OPR/" 
        if ((verOffset = nAgt.indexOf("OPR/")) !== -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 4);
        }
        // In older Opera, the true version is after "Opera" or after "Version"
        else if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf("Version")) !== -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome" 
        else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
            browserName = "Chrome";
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version" 
        else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
            browserName = "Safari";
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf("Version")) !== -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In Firefox, the true version is after "Firefox" 
        else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent 
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() === browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(";")) !== -1)
            fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) !== -1)
            fullVersion = fullVersion.substring(0, ix);

        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }
        return `${browserName} ${fullVersion} (${systemName})`;
    } catch (err) {
        return `WEB`;
    }
}