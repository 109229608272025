import _ from "lodash";
import moment from "moment";
import React, { FC, Fragment } from "react";
import { ZpDetailsPropsInterface } from "../types/zp.types";
import ZpDetailsHeaderComponent from "./zpDetailsHeader.component";
import ZpDetailsProductTable from "./zpDetailsProductsTable.component";
import Api from "../../application/services/api.service";
import blankAvatar from "../../common/images/ufo-user.jpg";

const ZpDetailsComponent: FC<ZpDetailsPropsInterface> = ({ zp }: ZpDetailsPropsInterface) => {

    const getUserAvatarSrc = (avatar: string) => {
        if (avatar) {
            return Api.getUrl() + avatar;
        }
        return blankAvatar;
    }

    return <Fragment>
        <ZpDetailsHeaderComponent />
        <div id="content-container-right" className="zp-details-container col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2 ">
            <div className="bg-white rounded-10 boxshadow p-0">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="col-md-10 col-sm-12 d-flex flex-wrap py-5 my-5">
                        <div className="row col-sm-12 px-0">
                            <div className="col-sm-12 col-md-8">
                                <h2 className="text-center">{_.get(zp, "documentNumber", "-")} </h2>
                                <div className="row col-sm-12 my-4">
                                    <hr className="mt-0" />
                                </div>
                                <div className="row col-sm-12 zp-details ">
                                    <div className="form-group col-md-4 col-sm-12 input-group d-flex flex-wrap">
                                        <span className="has-float-label">
                                            <div className="form-control2" id="zp-status">{_.get(zp, "positionFrom.code", "-")}</div>
                                            <label htmlFor="zp-status">Pozycja źródłowa</label>
                                        </span>
                                    </div>
                                    <div className="form-group col-md-3 col-sm-12  input-group d-flex flex-wrap">
                                        <span className="has-float-label">
                                            <div className="form-control2" id="zp-status">{_.get(zp, "positionTo.code", "-")}</div>
                                            <label htmlFor="zp-status">Pozycja docelowa</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12 px-0 zp-details">
                                <div className="d-flex flex-column my-3">
                                    <div className="d-flex align-items-center">
                                        <div className="worker-img px-3 mb-3">
                                            <img className="rounded-circle" src={getUserAvatarSrc(_.get(zp, "creator.avatar"))} alt="avatar" />
                                        </div>
                                        <div className="has-float-label">
                                            <div className="form-control2 pb-0" id="zp-add">
                                                <h3>{_.get(zp, "creator.name")}</h3>
                                            </div>
                                            <label htmlFor="zp-add">Dodanie</label>
                                            <h5>{moment(zp?.createdAt).format("DD.MM.YYYY")} <span>{moment(zp?.createdAt).format("HH:mm")}</span></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 px-0 mx-0 mb-4 tabs-container">
                            <ul className="nav nav-pills mb-3 mx-3" id="details-tab" role="tablist">
                                <li className="nav-item " role="presentation">
                                    <a className="nav-link active" id="details-home-tab" data-toggle="tab" href="#tab1" data-bs-toggle="pill" data-bs-target="#tab1" type="button" role="tab" aria-controls="details-1" aria-selected="true">Produkty</a>
                                </li>
 
                            </ul>
                            <div className="tab-content" id="details-tabContent">
                                <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="details-home-tab">
                                    <div className=" table table-responsive my-4">
                                        <ZpDetailsProductTable items={_.get(zp, "products", [])} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}

export default ZpDetailsComponent;