import { findIndex as _findIndex } from "lodash";
import React, { FC, useEffect, useState, useRef, Fragment } from "react";
import { Link } from "react-router-dom";
import IcoArrowDown from "../../common/images/ico-arrowdown.svg"
import IcoPlusB from "../../common/images/ico-plus-b.svg"
import saveTab from "../../common/images/save-tab.svg"
import IcoXred from "../../common/images/ico-xred.svg"
import { getShortcutsInfo, saveShortcut } from "../actions/shortcuts.actions"
import TextInputComponent from "../../common/components/textInput.component";
import { ShortcutPropsInterface } from '../types/shortcut.types';

const ShortcutsComponent: FC = () => {

    const [name, setName] = useState("");
    const [shortcuts, setShortcuts] = useState<any>([]);
    const [dashboardID, setDashboardID] = useState("");
    const [err, setErrors] = useState("");
    const closeFormRef = useRef<HTMLButtonElement>(null);

    function setShortcutsInfo(): void {
        getShortcutsInfo().then(response => {
            setDashboardID(response.document._id);
            setShortcuts(response.document.shortcuts);
        });
    }

    useEffect(() => {
        setShortcutsInfo();
    }, []);

    function getShortcuts() {
        if (shortcuts.length === 0) {
            return <div className="dropdown-item py-2">Brak zapisanych skrótów</div>
        };
        return shortcuts.map((shortcut: any, index: number) => {
            return (
                <Fragment key={`shortcut${index}`}>
                    <button className="dropdown-item py-2" type="button">
                        <Link to={shortcut.path} className="text-dark">
                            <img src={IcoArrowDown} alt="" className="link-arrow mr-2" />
                            {shortcut.name}
                        </Link>
                        <img src={IcoXred} alt="" onClick={() => { deleteShortcut(shortcut._id, shortcut.name) }} />
                    </button >
                </Fragment >);
        });
    };

    function getUrl() {
        return window.location.pathname + window.location.search;
    };

    function createShortcut(name: string) {

        setErrors("");

        let newShortcut: ShortcutPropsInterface = {
            _id: dashboardID,
            name: name,
            path: getUrl()
        };

        if (newShortcut.name === "") {
            setErrors("Nazwa skrótu nie może być pusta!");
            return;
        };

        if (_findIndex(shortcuts, { "name": name }) >= 0) {
            setErrors("Nazwa skrótu jest już zajęta");
            return;
        };

        shortcuts.push(newShortcut);
        saveShortcut(dashboardID, shortcuts).then(() => {
            setShortcutsInfo();
        });

        if (null !== closeFormRef.current) {
            closeFormRef.current.click();
        };

    };

    function deleteShortcut(id: string, shortcut: string) {
        let newShortcuts = shortcuts.filter((value: any) => {
            return value.name !== shortcut
        });
        saveShortcut(dashboardID, newShortcuts).then(() => {
            setShortcutsInfo();
        });
    };

    return (
        <div>
            <div className="dropdown-menu dropdown-menu-right animated fadeIn">
                <h4 className="dropdown-header py-1">Zapisane Skróty</h4>
                <div className="dropdown-divider"></div>
                {getShortcuts()}
                <div className="dropdown-divider"></div>
                <button className="dropdown-item py-2 text-bold" type="button" data-toggle="modal" data-target="#shortcut-modal"><img src={IcoPlusB} alt="" className="mr-2" data-toggle="modal" />Dodaj skrót do tego miejsca</button>
            </div>

            <div id="shortcut-modal" className="modal fade">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div id="shortcuts-form">
                            <div className="modal-header btn-wms ">
                                <h4 className="modal-title text-xs-center text-white">Dodaj skrót do tego miejsca</h4>
                            </div>
                            <div className="modal-body">
                                <span className="has-float-label">
                                    <TextInputComponent
                                        type="text"
                                        label="Nazwa skrótu"
                                        customClass="form-group input-group"
                                        id="shortcut-name"
                                        value={name}
                                        placeholder="Wpisz nazwę"
                                        onChange={(id, value) => setName(value)}
                                        error={err}
                                    />
                                </span>

                            </div>
                            <div className="modal-footer text-xs-center">
                                <button onClick={() => setName("")} className="btn btn-light px-3" data-dismiss="modal" ref={closeFormRef} >Anuluj</button>
                                <button onClick={() => { createShortcut(name) }} className="btn btn-primary btn-wms btn-save ml-1"><img src={saveTab} alt="" className="px-1 mb-1" />Zapisz</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
};


export default ShortcutsComponent;

