import React, { FC, Fragment } from "react";
import { UsersPropsInterface } from "../types/user.types";
import ListComponent from "../../common/components/list.component";
import UsersListCardComponent from "./usersListCard.component";
import UsersFiltersComponent from "./usersFilters.component";
import UsersHeaderComponent from "./usersHeader.component";
import _ from "lodash";

const UsersComponent: FC<UsersPropsInterface> = ({ users, total, params, setFilters, clearFilters, setPage, setParams, filters, page, activeUsers }: UsersPropsInterface) => {

    const getColumns = () => {
        return [
            {
                key: "name",
                label: "Imię i nazwisko"
            },
            {
                key: "flags",
                label: "",
                disableSort: true
            },
            {
                key: "role.name",
                label: "Role"
            },
            {
                key: "warehouse.name",
                label: "Magazyny"
            },
            {
                key: "actions",
                label: "",
                disableSort: true
            }
        ]
    }

    if (_.isArray(users)) {
        users = users.map((user) => {
            user.isLogged = !!_.castArray(activeUsers).find((u) => u._id === user._id);
            return user;
        })
    }

    return (
        <Fragment>
            <UsersHeaderComponent />
            <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2">
                <UsersFiltersComponent filters={filters} onClearFilters={() => clearFilters(true)} onFiltersChange={(filters: any) => setFilters(filters, true)} />
                <ListComponent
                    id="table-users"
                    items={users}
                    total={total}
                    itemsOnPage={params.limit}
                    ItemCard={UsersListCardComponent}
                    currentPage={page}
                    currentSort={params.sort}
                    route="/users"
                    columns={getColumns()}
                    onPageChange={setPage}
                    onSortChange={(sortKey: string, sortValue: number) => setParams(Object.assign({}, params, { sort: { [sortKey]: sortValue } }), true)}
                />
            </div>
        </Fragment>
    )
}

export default UsersComponent;