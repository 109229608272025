import React, { FC, Fragment, useState } from "react";
import TextInputComponent from "../../common/components/textInput.component";
import DeleteConfirmModal from "../../common/components/deleteConfirmModal.component";
import { InventoryHistoryPropsInterface } from "../types/inventory.types";
import resetIcon from "../../common/images/ico-wyczysc.svg";
import Acl from "../../application/services/acl.service";
import _ from "lodash";

const InventoryHistoryComponent: FC<InventoryHistoryPropsInterface> = ({
    inventory,
    items,
    itemsOnPage,
    total,
    currentPage,
    filters,
    onPageChange,
    onFiltersChange,
    onClearFilters,
    onPositionEdit,
    onPositionReset
}: InventoryHistoryPropsInterface) => {
    let nrOfPages = Math.ceil(total / itemsOnPage);
    let [deleteId, setDeleteId] = useState("");
    const handleFilterChange = (field: string, value: any) => {
        let newFilters = Object.assign({}, filters, {
            [field]: value
        });
        onFiltersChange(newFilters);
    }

    const handleFiltersReset = () => {
        let userInput: any = document.getElementById("user");
        if (userInput) {
            userInput.value = '';
        }
        let productInput: any = document.getElementById("product");
        if (productInput) {
            productInput.value = '';
        }
        let seriesInput: any = document.getElementById("seriesNr");
        if (seriesInput) {
            seriesInput.value = '';
        }
        let positionInput: any = document.getElementById("position");
        if (positionInput) {
            positionInput.value = '';
        }
        onClearFilters();
    }

    const renderItems = () => {
        if (!_.isEmpty(items)) {
            return items.map((item: any, index: number) => {
                let allowEditEnabled = !_.includes(inventory?.allowedToEdit, item.position.code);

                return (<div className="d-flex flex-wrap col-sm-12 px-0 border border-top-0 border-left-0 border-right-0" key={`history${index}`}>
                    <div className="document-group col-sm-2 d-flex align-items-center flex-wrap justify-content-center py-3">
                        <div className="d-flex justify-content-center flex-wrap">
                            <h4 className="full-time mb-0 w-100 text-center"><span>14:42</span>08.06.2021</h4>
                            <div className="badge badge-light p-2 border border-2 p-3 my-1">
                                <h3 className="mt-1">{item.position.code}</h3>
                            </div>
                            <h4 className="full-time mb-0 w-100 text-center">{item.creator.name}</h4>
                        </div>
                        {!inventory?.completed && <div className="d-flex justify-content-center flex-wrap align-self-end">
                            {allowEditEnabled && Acl.isAllowedMethod("warehouse/inventory", "update") && <button type="button" className="btn-sm btn-light my-1" onClick={() => onPositionEdit(item.position.code)}>Zezwól na edycję</button>}
                            {Acl.isAllowedMethod("warehouse/inventory", "resetPositionInventory") && <a type="button" className="btn-sm btn-light my-1" data-toggle="modal" href="#position-reset" onClick={() => setDeleteId(item._id)}>Usuń</a>}
                        </div>}
                    </div>
                    <div className="table table-responsive mb-0 col-sm-10 px-0">
                        <table className="table table-hover mb-0 px-0">
                            <tbody>
                                <tr className="thead-light tableonmobile">
                                    <th className="col-md-2">Pozycja</th>
                                    <th className="col-md-2">Numer serii</th>
                                    <th className="col-md-2">Ilość przed</th>
                                    <th className="col-md-2">Ilość po</th>
                                    <th className="col-md-1">Różnica</th>
                                </tr>
                                {item.products.map((itemProduct: any, productIndex: number) => {
                                    return (<tr key={`history${index}product${productIndex}`}>
                                        <th scope="col" className="col-md-4">
                                            {itemProduct.name}
                                        </th>
                                        <th className="col-md-2">
                                            <h3 className="text-left mt-1">{itemProduct.seriesNr}</h3>
                                        </th>
                                        <th className="col-md-2">{itemProduct.amountBefore}</th>
                                        <th className="col-md-1">{itemProduct.amountAfter}</th>
                                        <th className="col-md-1">{itemProduct.amountAfter - itemProduct.amountBefore}</th>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>);
            });
        } else {

        }
    }

    const getPageButtons = () => {
        if (currentPage === 1) {
            return (<Fragment>
                <li className="page-item active"><button className="page-link" type="button">1</button></li>
                {(currentPage + 1 <= nrOfPages) && <li className="page-item" onClick={() => onPageChange(currentPage + 1)}><button className="page-link" type="button">{currentPage + 1}</button></li>}
                {(currentPage + 2 <= nrOfPages) && <li className="page-item" onClick={() => onPageChange(currentPage + 2)}><button className="page-link" type="button">{currentPage + 2}</button></li>}
            </Fragment>);
        } else if (currentPage === nrOfPages) {
            return (<Fragment>
                {(currentPage - 2 > 0) && <li className="page-item" onClick={() => onPageChange(currentPage - 2)}><button className="page-link" type="button">{currentPage - 2}</button></li>}
                {(currentPage - 1 > 0) && <li className="page-item" onClick={() => onPageChange(currentPage - 1)}><button className="page-link" type="button">{currentPage - 1}</button></li>}
                <li className="page-item active"><button className="page-link" type="button">{currentPage}</button></li>
            </Fragment>);
        } else {
            return (<Fragment>
                {(currentPage - 1 > 0) && <li className="page-item" onClick={() => onPageChange(currentPage - 1)}><button className="page-link" type="button">{currentPage - 1}</button></li>}
                <li className="page-item active"><button className="page-link" type="button">{currentPage}</button></li>
                {(currentPage + 1 <= nrOfPages) && <li className="page-item" onClick={() => onPageChange(currentPage + 1)}><button className="page-link" type="button">{currentPage + 1}</button></li>}
            </Fragment>);
        }
    }

    return (<div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="details-home-tab">
        <DeleteConfirmModal id="position-reset" onDelete={() => { onPositionReset(deleteId) }} />
        <div className="filter-top-bar rounded-0 justify-content-between p-0 ">
            <div className="d-flex align-items-center flex-wrap col-md-11 px-0">
                <TextInputComponent
                    id="user"
                    defaultValue={filters.user}
                    onChange={handleFilterChange}
                    label="Użytkownik"
                    placeholder="Szukaj"
                    customClass="col-md-3 col-sm-12 form-group input-group m-0 p-3"
                    debounce={1000}
                />
                <TextInputComponent
                    id="product"
                    defaultValue={filters.product}
                    onChange={handleFilterChange}
                    label="Produkt"
                    placeholder="Szukaj"
                    customClass="col-md-3 col-sm-12 form-group input-group m-0 p-0"
                    debounce={1000}
                />
                <TextInputComponent
                    id="seriesNr"
                    defaultValue={filters.seriesNr}
                    onChange={handleFilterChange}
                    label="Nr serii"
                    placeholder="Szukaj"
                    customClass="col-md-3 form-group input-group m-0 py-3 p-3"
                    debounce={1000}
                />
                <TextInputComponent
                    id="position"
                    defaultValue={filters.position}
                    onChange={handleFilterChange}
                    label="Nr pozycji"
                    placeholder="Szukaj"
                    customClass="col-md-3 col-sm-12 form-group input-group m-0 pl-0 pr-3"
                    debounce={1000}
                />
            </div>
            <div className="d-flex align-self-stretch color-gray" onClick={handleFiltersReset}>
                <div className="d-flex flex-wrap justify-content-center align-items-center bg-light filter-clear-topbar rounded-right px-2 py-2"><img src={resetIcon} alt="reset"/>
                    <h5 className="w-100 text-center"> Wyczyść</h5>
                </div>
            </div>
        </div>
        <div id="stock-table" className="table table-responsive my-4">
            <table className="table table-hover mb-0">
                <thead>
                    <tr className="thead-light tablenomobile">
                        <th scope="col" className="col-md-2">Dokument</th>
                        <th className="col-md-4">Pozycja</th>
                        <th className="col-md-2">Numer serii</th>
                        <th className="col-md-2">Ilość przed</th>
                        <th className="col-md-1">Ilość po</th>
                        <th className="col-md-1">Różnica</th>
                    </tr>
                </thead>
            </table>
            {renderItems()}
        </div>
        <nav id="pagination-container">
            <ul className="pagination">
                {(currentPage > 1) && <li className="page-item" onClick={() => onPageChange(1)}>
                    <button className="page-link" type="button" aria-label="Poprzednia">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Pierwsza</span>
                    </button>
                </li>}
                {getPageButtons()}
                {(currentPage < nrOfPages) && <li className="page-item" onClick={() => onPageChange(nrOfPages)}>
                    <button className="page-link" type="button" aria-label="Następna">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Ostatnia</span>
                    </button>
                </li>}
            </ul>
        </nav>
    </div>)
}

export default InventoryHistoryComponent;