import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import UserComponent from "../components/user.component";
import { UserPropsInterface } from "../types/user.types";
import { getUser, clearUser, saveUser } from "../actions/user.actions";
import { AppDispatch, RootState } from "../../config/store";
import { connect } from 'react-redux'
import { GenericObject } from "../../common/types/common.types";

const UserContainer: FC<UserPropsInterface> = (props: UserPropsInterface) => {
    const { getUser, clearUser } = props;
    let { id }: any = useParams();

    useEffect(() => {
        if (id) {
            getUser(id);
        }
        return () => {
            clearUser();
        }
    }, [getUser, clearUser, id])


    return <UserComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    user: store.user.user
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getUser: (id: string) => dispatch(getUser(id)),
        clearUser: () => dispatch(clearUser()),
        saveUser: (data: GenericObject) => saveUser(data, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);