import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import DocumentsComponent from "../components/documentsList.component";
import { getDocuments, clearDocuments, setFilters, clearFilters, setPage, setParams, clearParams } from '../actions/documents.actions';
import { connect } from 'react-redux'
import { AppDispatch, RootState } from "../../config/store";
import { DocumentsPropsInterface, DocumentsStateInterface } from "../types/document.types";

const DocumentsContainer: FC<DocumentsPropsInterface> = (props: DocumentsPropsInterface) => {
    const { getDocuments, clearDocuments, setPage, page } = props;
    let params: any = useParams();

    useEffect(() => {
        setPage(parseInt(params.page));
        getDocuments();
        return () => {
            clearDocuments();
        }
    }, [getDocuments, clearDocuments, page, params.page, setPage])

    return <DocumentsComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    documents: store.documents.documents,
    total: store.documents.total,
    filters: store.documents.filters,
    params: store.documents.params,
    page: store.documents.page
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getDocuments: () => dispatch(getDocuments()),
        clearDocuments: () => dispatch(clearDocuments()),
        setFilters: (filters: DocumentsStateInterface["filters"], reloadList?: boolean) => dispatch(setFilters(filters, reloadList)),
        clearFilters: (reloadList?: boolean) => dispatch(clearFilters(reloadList)),
        setPage: (page: number) => dispatch(setPage(page)),
        setParams: (params: DocumentsStateInterface["params"], reloadList?: boolean) => dispatch(setParams(params, reloadList)),
        clearParams: (reloadList?: boolean) => dispatch(clearParams(reloadList)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsContainer);