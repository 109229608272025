import { DictsStateInterface, DictsActionType } from "../types/dicts.types";

const initialState: DictsStateInterface = {
    dict: null
}

const dictsReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case DictsActionType.SET_DICT: {
            return {
                ...state,
                dict: action.dict
            }
        }
        case DictsActionType.CLEAR_DICT: {
            return {
                ...state,
                dict: null
            }
        }
        default: return state;
    }
}

export default dictsReducer;