import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import RoleComponent from "../components/role.component";
import { RolePropsInterface } from "../types/role.types";
import { getRole, clearRole, saveRole } from "../actions/role.actions";
import { AppDispatch, RootState } from "../../config/store";
import { connect } from 'react-redux'
import { GenericObject } from "../../common/types/common.types";

import { getCategories, createCategory, editCategory, deleteCategory } from "../../common/actions/category.actions";

const RoleContainer: FC<RolePropsInterface> = (props: RolePropsInterface) => {
    const { getRole, clearRole, getCategories } = props;
    let { id }: any = useParams();

    useEffect(() => {
        if (id) {
            getRole(id);
        }
        return () => {
            clearRole();
        }
    }, [getRole, clearRole, id])
    useEffect(() => {
        getCategories('product');
    }, [getCategories])


    return <RoleComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    role: store.role.role,
    categories: store.category.categories
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getRole: (id: string) => dispatch(getRole(id)),
        clearRole: () => dispatch(clearRole()),
        saveRole: (data: GenericObject) => saveRole(data, dispatch),
        getCategories: (content: string) => dispatch(getCategories(content)),
        createCategory: (data: GenericObject) => dispatch(createCategory(data)),
        editCategory: (data: GenericObject, id: string) => dispatch(editCategory(data, id)),
        deleteCategory: (id: string) => dispatch(deleteCategory(id)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleContainer);