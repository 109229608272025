import { UsersStateInterface, UsersActionType } from "../types/user.types";

const initialState: UsersStateInterface = {
    users: [],
    total: 0,
    filters: {
        name: '',
        warehouse: '',
        role: '',
        active: null
    },
    params: {
        limit: 20,
        offset: 0,
        sort: null
    },
    page: 1
}

const usersReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case UsersActionType.SET_USERS: {
            return {
                ...state,
                users: action.users,
                total: action.total
            }
        }
        case UsersActionType.CLEAR_USERS: {
            return {
                ...state,
                users: [],
                total: 0
            }
        }
        case UsersActionType.SET_FILTERS: {
            return {
                ...state,
                filters: action.filters
            }
        }
        case UsersActionType.CLEAR_FILTERS: {
            return {
                ...state,
                filters: {
                    name: '',
                    warehouse: '',
                    role: '',
                    active: null
                }
            }
        }
        case UsersActionType.SET_PARAMS: {
            return {
                ...state,
                params: action.params
            }
        }
        case UsersActionType.CLEAR_PARAMS: {
            return {
                ...state,
                params: {
                    limit: 20,
                    offset: 0,
                    sort: null
                }
            }
        }
        case UsersActionType.SET_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        default: return state;
    }
}

export default usersReducer;