import { FC, Fragment } from "react";
import { RolesListCardPropsInterface } from "../types/role.types";
import { Link } from "react-router-dom"
import _ from "lodash";
import Api from "../../application/services/api.service";
import Acl from "../../application/services/acl.service";

import blankAvatar from "../../common/images/ufo-user.jpg";
import roleIcon from "../../common/images/ico-role-2.svg";

const RolesListCardComponent: FC<RolesListCardPropsInterface> = ({ item }: RolesListCardPropsInterface) => {

    const renderUsers = () => {
        if (_.isArray(item.users)) {
            if (item.users.length > 5) {
                let visible = _.slice(item.users, 0, 5);
                let hidden = _.slice(item.users, 5, item.users.length);
                return <Fragment>
                    {visible.map((user) => {
                        return (
                            <Link to={`/users/${user._id}`} key={`role${item._id}user${user._id}`} className="avatar avatar-online rounded-circle" title={user.name}>
                                <img src={user.avatar ? Api.getUrl() + user.avatar : blankAvatar} alt="avatar" /><i></i>
                            </Link>
                        )
                    })}
                    <div className="avatar group-over-5 rounded-circle">
                        <div className="dropdown">
                            <button className="dropdown-toggle avatar-list-btn" type="button" id="role-group-list" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">+{hidden.length}</button>
                            <div className="dropdown-menu avatar-list" aria-labelledby="role-group-list">
                                {hidden.map((user) => <Link to={`/users/${user._id}`} key={`role${item._id}user${user._id}`} className="dropdown-item" href="#!">{user.name}</Link>)}
                            </div>
                        </div>
                    </div>
                </Fragment>

            } else {
                return item.users.map((user) => {
                    return (
                        <Link to={`/users/${user._id}`} key={`role${item._id}user${user._id}`} className="avatar avatar-online rounded-circle" title={user.name}>
                            <img src={user.avatar ? Api.getUrl() + user.avatar : blankAvatar} alt="avatar"/><i></i>
                        </Link>
                    )
                })
            }
        }
        return "-";
    }

    const countPermissions = () => {
        let sum = 0;
        _.each(item.permissions, (permission) => {
            sum += permission.actions.length;
        });
        return sum;
    }

    return (<tr>
        <th className="col-md-2" scope="row">
            <div className="avatar-group d-flex">
                {renderUsers()}
            </div>
        </th>
        <td className="col-md-4 ">
            {_.get(item, "name", "-")}
        </td>
        <td className="col-md-2">
            <h3><span className="badge badge-pill badge-primary">{countPermissions()}</span></h3>
        </td>
        <td className="col-md-4 text-right">
            {Acl.getIsAdmin() && <Link type="button" className="bg-light rounded border-0 px-4 py-2 text-dark" to={`/role/${item._id}`}><img src={roleIcon} alt="edytujrole" /> Edytuj rolę</Link>}
        </td>
    </tr>);
}

export default RolesListCardComponent;