import React, { FC } from "react";
import ReportsPropsInterface from "../types/reports.types";

const ReportsProductsExportComponent: FC<ReportsPropsInterface> = ({ onDataChange }: ReportsPropsInterface) => {

    // const type = "6";
    // const props = { type: type };

    return (
        <div id="report-form-eksport" className="report-form tabpane fade" role="tabpanel" aria-labelledby="report-form-eksport-tab">
            <div className="color-gray">Brak dodatkowych opcji do wyboru.</div>
        </div>
    );
};
export default ReportsProductsExportComponent;