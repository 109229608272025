import React, { FC, Fragment, useEffect } from "react";
import { connect } from 'react-redux';
import { AppDispatch, RootState } from "../../config/store";
import { checkAuth } from "../actions/application.actions";
import LoaderContainer from "./loader.container";
import LoginContainer from "./login.container";
import Root from "./root.container";

const mapState = (state: RootState) => ({
    user: state.application.user,
})

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        checkAuth: (email: string, password: string) => dispatch(checkAuth())
    }
};

const AuthContainer: FC<any> = ({ user, checkAuth }: any) => {

    useEffect(() => {
        checkAuth();
    }, [checkAuth]);

    return (<Fragment>
        <LoaderContainer />
        {user ? <Root /> : <LoginContainer />}
    </Fragment>)
}

export default connect(mapState, mapDispatchToProps)(AuthContainer);