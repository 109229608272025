import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import React, { FC } from "react";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import { ZpListHeaderPropsInterface } from "../types/zp.types";

const ZpListHeaderComponent: FC<ZpListHeaderPropsInterface> = ({ filters, onFiltersChange }: ZpListHeaderPropsInterface) => {

    const handleRangeChange = (event: any, picker: any) => {
        let newFilters = Object.assign({}, filters, {
            dateFrom: picker.startDate.clone().format("MM/DD/YYYY"),
            dateTo: picker.endDate.clone().format("MM/DD/YYYY")
        });
        onFiltersChange(newFilters);
    }

    const handlePredefinedRangeSelect = (dateFrom: any, dateTo: any) => {
        let newFilters = Object.assign({}, filters, {
            dateFrom: dateFrom.format("MM/DD/YYYY"),
            dateTo: dateTo.format("MM/DD/YYYY")
        });
        onFiltersChange(newFilters);
    }

    return (<div className="row px-0 mx-0 title-bar d-flex flex-wrap justify-content-between align-items-center">
        <div className="col-md-6 col-sm-12 d-flex flex-nowrap pl-4">
            <h2 id="page-title" className="m-0">Zmiany pozycji</h2>
        </div>
        <div id="date-filter-container" className="d-flex justify-content-end col-md-6 col-sm-12 mx-auto pr-4">
            <div id="current-day" className="date-filter" onClick={() => handlePredefinedRangeSelect(moment().startOf('day'), moment().endOf('day'))}>Dziś</div>
            <div id="current-week" className="date-filter" onClick={() => handlePredefinedRangeSelect(moment().startOf('week'), moment().endOf('week'))}>Tydzień</div>
            <div id="current-month" className="date-filter" onClick={() => handlePredefinedRangeSelect(moment().startOf('month'), moment().endOf('month'))}>Miesiąc</div>
            {/* <div className="d-flex flex-row"> */}
            <div id="current-filter" className="date-filter">
                <span>Dzień:</span>
                <DateRangePicker
                    initialSettings={{ startDate: filters.dateFrom, endDate: filters.dateTo }}
                    onApply={handleRangeChange}
                >
                    <input type="text" name="datefilter" id="datefilter"/>
                </DateRangePicker>
            </div>
        </div>
    </div>);
}

export default ZpListHeaderComponent;