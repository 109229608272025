import { CategoryInterface } from "./component.types";

export enum CategoryActionType {
  SET_CATEGORIES = "CATEGORY_SET_CATEGORIES",
}

export interface CategoryStateInterface {
  categories: Array<CategoryInterface>;
}

export interface CategoryPropsInterface {
  id: string;
  categories: CategoryStateInterface["categories"];
  documentCategories: Array<any>;
  value: Array<any>;
  onChange: (id: string, selectedCategories: any) => void;
  error?: string;
}

export interface CategoryChildrenInterface {
  ancestors: Array<string>;
  breadcrumb: string;
  children: Array<CategoryChildrenInterface>;
  createdAt: string;
  creator: string;
  name: string;
  parent: string;
}
