import React, { FC, Fragment, useEffect, useState } from "react";
import _ from "lodash";
import { GlobalSearchPropsInterface } from "../types/component.types";
import Api from "../../application/services/api.service";
import { Link } from "react-router-dom";
import noImageIcon from "../images/no-image.png";
import arrowDownIcon from "../images/ico-arrowdown.svg";
import documentIcon from "../images/ico-prod-doc.svg";
import warehouseIcon from "../images/ico-magazyn.svg";
import loaderIcon from "../../common/images/logo-wms-color-loader.svg";

const GlobalSearchComponent: FC<GlobalSearchPropsInterface> = ({
  showResults,
  searchedValue,
  results,
  isLoading,
}: GlobalSearchPropsInterface) => {
  const [items, setItems] = useState<any>(_.get(results, "products", []));
  const [resource, setResource] = useState("Produkt");

  useEffect(() => {
    resource === "Użytkownik" && setItems(results.searchedUsers);
    resource === "Produkt" && setItems(results.searchedProducts);
    resource === "Magazyn" && setItems(results.searchedWarehouses);
    resource === "Dokument" && setItems(results.searchedDocuments);
  }, [results]);

  const getResourceIcon = (resource: string, item: any) => {
    switch (resource) {
      case "Produkt":
      case "Użytkownik":
        if (item && item.avatar) {
          return Api.getUrl() + item.avatar;
        }
        return noImageIcon;
      case "Dokument":
        return documentIcon;
      case "Magazyn":
        return warehouseIcon;
    }
  };

  const renderTabs = () => {
    return (
      <div className="ac-filters text-center">
        <div className="btn-group mx-auto py-3" role="group" aria-label="Szybkie filtry">
          <button
            type="button"
            className={`btn btn-light px-4 ${resource === "Produkt" && "active"}`}
            onClick={() => {
              setResource("Produkt");
              setItems(_.get(results, "searchedProducts"));
            }}
          >
            Produkty ({_.size(_.get(results, "searchedProducts"))})
          </button>
          <button
            type="button"
            className={`btn btn-light px-4 ${resource === "Dokument" && "active"}`}
            onClick={() => {
              setResource("Dokument");
              setItems(_.get(results, "searchedDocuments"));
            }}
          >
            Dokumenty ({_.size(_.get(results, "searchedDocuments"))})
          </button>
          <button
            type="button"
            className={`btn btn-light px-4 ${resource === "Użytkownik" && "active"}`}
            onClick={() => {
              setResource("Użytkownik");
              setItems(_.get(results, "searchedUsers"));
            }}
          >
            Użytkownicy ({_.size(_.get(results, "searchedUsers"))})
          </button>
          <button
            type="button"
            className={`btn btn-light px-4 ${resource === "Magazyn" && "active"}`}
            onClick={() => {
              setResource("Magazyn");
              setItems(_.get(results, "searchedWarehouses"));
            }}
          >
            Magazyny ({_.size(_.get(results, "searchedWarehouses"))})
          </button>
        </div>
      </div>
    );
  };

  const getLinkUrl = (item: any) => {
    switch (resource) {
      case "Produkt":
        return `/products/${item._id}`;
      case "Użytkownik":
        return `/users/${item._id}`;
      case "Dokument":
        return `/documents/${item._id}`;
      case "Magazyn":
        return `/warehouses/${item._id}/page/1`;
      default: return "/";
    }
  }

  const renderItems = () => {
    return (
      items &&
      items.map((item: any, index: number) => {
        let name = resource === "Dokument" ? item.documentNumber : item.name;
        let stringIndex = name.toLowerCase().indexOf(searchedValue.toLowerCase());
        let parsedName =
          name.substring(0, stringIndex) +
          `<strong>${name.substring(stringIndex, stringIndex + searchedValue.length)}</strong>` +
          name.substring(stringIndex + searchedValue.length, name.length);

        return (
          <div className="ac-options" key={index}>
            <div className="dropdown-divider"></div>

            <Link className="ac-item d-flex flex-wrap text-dark" to={getLinkUrl(item)}>
              <div className="ac-img col-md-1 col-sm-6">
                <img src={getResourceIcon(resource, item)} alt="" />
              </div>
              <div
                className="ac-name col-md-8 col-sm-12"
                dangerouslySetInnerHTML={{ __html: parsedName }}
              ></div>
              <div className="ac-type col-md-2 col-sm-6">
                <div className="badge badge-light px-2 border border-2">
                  <h4 className="my-0">{resource}</h4>
                </div>
              </div>
              <div className="ac-link col-sm-1">
                <img src={arrowDownIcon} className="link-arrow" alt="" />
              </div>
            </Link>
          </div>
        );
      })
    );
  };

  const renderResults = () => {
    return (
      <div className="autocomplete-form-cont col-sm-12">
        <div className="autocomplete-form border border-1">
          {isLoading && (
            <div id="preloader-overlay-global-search">
              <img src={loaderIcon} alt="loader" />
            </div>
          )}
          {!isLoading && renderTabs()}
          {!isLoading && renderItems()}
          {_.size(items) === 0 && (
            <div className="global-seearch-no-elements">Brak elementów</div>
          )}
        </div>
      </div>
    );
  };

  return <Fragment>{showResults && renderResults()}</Fragment>;
};

export default GlobalSearchComponent;
