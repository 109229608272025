import React, { FC, useState, useCallback, useRef, useEffect } from "react";
import Api from "../../application/services/api.service";
import plusIcon from "../images/ico-plus.svg";
import blankAvatar from "../images/ufo-user.jpg";
import { CropperComponentPropsInterface } from "../types/component.types";
import ModalComponent from "./modal.component";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const CropperComponent: FC<CropperComponentPropsInterface> = ({
    id,
    defaultImage,
    imageWidth,
    imageHeight,
    customClass,
    onCrop
}: CropperComponentPropsInterface) => {
    const [upImg, setUpImg] = useState("");
    const imgRef = useRef(null);
    const [previewImage, setPreviewImage] = useState("");
    const [crop, setCrop] = useState({
        x: 0,
        y: 0,
        aspect: 1,
        width: imageWidth,
        height: imageHeight
        // unit: "px"
    });
    const [completedCrop, setCompletedCrop] = useState(null);

    const getModalContent = () => {
        return <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c: any) => setCrop(c)}
            onComplete={(c: any) => setCompletedCrop(c)}
            circularCrop={true}
        />
    }

    const handleImageSave = () => {
        if (!completedCrop || !imgRef.current) {
            return;
        }

        const image: any = imgRef.current;
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        const crop: any = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx: any = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
        let dataUrl = canvas.toDataURL();
        setPreviewImage(dataUrl);
        canvas.toBlob((b: any) => {
            onCrop(id, new File([b], "avatar.jpg"));
        }, 'image/jpeg');
    }

    const getImageSrc = () => {
        if (previewImage) {
            return previewImage;
        } else if (defaultImage) {
            return Api.getUrl() + defaultImage;
        }
        return blankAvatar;
    }

    const getModalButtons = () => {
        return [
            <button key="avatar-cropper-close" type="button" className="col-sm-4 btn btn-light px-0" data-dismiss="modal" onClick={() => null}>Anuluj</button>,
            <button key="avatar-cropper-save" type="button" className="col-sm-4 btn btn-light px-0" data-dismiss="modal" onClick={handleImageSave}>Zapisz</button>
        ]
    }

    const onSelectFile = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader: any = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            document.getElementById(`${id}-modal-open`)?.click();
        }
    };

    const onLoad = useCallback((img) => {
        imgRef.current = img;
    }, []);

    useEffect(() => {
        if (!completedCrop || !imgRef.current) {
            return;
        }

        const image: any = imgRef.current;
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        const crop: any = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx: any = canvas.getContext('2d');
        const pixelRatio = window.devicePixelRatio;

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }, [completedCrop]);

    return (<div className={customClass ? customClass : "col-md-3 col-sm-12 h-fit user-photo-cont my-5"}>
        <ModalComponent title="Ustawianie avataru" id={`${id}-modal`} content={getModalContent()} buttons={getModalButtons()} />
        <label className="mb-0 cabinet center-block">
            <figure className="mb-0">
                <div className="upload-user-photo form-frame">

                    <img src={getImageSrc()} id={`${id}-preview`} className="user-photo gambar img-responsive img-thumbnail" alt="Avatar" />
                    <div className="upload-btn bg-lightgray d-flex justify-content-center align-items-center flex-column">
                        <img src={plusIcon} alt="Plus" />
                        <h4>Prześlij zdjęcie</h4>
                    </div>
                </div>
            </figure>
            <input type="file" className="item-img file center-block" name="file_photo" onChange={onSelectFile} accept="image/*" />
            <a type="button" id={`${id}-modal-open`} data-toggle="modal" href={`#${id}-modal`}>Open</a>
        </label>
    </div>);
}

export default CropperComponent;