import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './mobile.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContainer from './application/containers/auth.container';
import { store } from './config/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={store}>
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
    />
    <AuthContainer />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
