import { AnyAction } from "redux";
import { BarcodeInterface, AsyncThunkAction, Nullable, GenericObject } from "../../common/types/common.types";

export enum GeneratorActionType {
    SET_BARCODES = 'GENERATOR_SET_BARCODES',
    CLEAR_BARCODES = 'GENERATOR_CLEAR_BARCODES',
    SET_FILTERS = 'GENERATOR_SET_FILTERS',
    CLEAR_FILTERS = 'GENERATOR_CLEAR_FILTERS',
    SET_PARAMS = 'GENERATOR_SET_PARAMS',
    CLEAR_PARAMS = 'GENERATOR_CLEAR_PARAMS',
    SET_PAGE = `GENERATOR_SET_PAGE`
}

export interface GeneratorStateInterface {
    barcodes: Array<BarcodeInterface>,
    total: number,
    filters: {
        name: string,
        ean: string,
        seriesNr: string
    },
    params: {
        limit: number,
        offset: number,
        sort: Nullable<{ [fieldname: string]: number }>
    },
    page: number
}

export interface GeneratorPropsInterface {
    barcodes: GeneratorStateInterface["barcodes"],
    total: GeneratorStateInterface["total"],
    filters: GeneratorStateInterface["filters"],
    params: GeneratorStateInterface["params"],
    page: number,
    getBarcodes: () => AsyncThunkAction,
    clearBarcodes: () => AnyAction,
    setFilters: (filters: GeneratorStateInterface["filters"], reloadList?: boolean) => AsyncThunkAction,
    clearFilters: (reloadList?: boolean) => AsyncThunkAction,
    setParams: (params: GeneratorStateInterface["params"], reloadList?: boolean) => AsyncThunkAction,
    clearParams: (reloadList?: boolean) => AsyncThunkAction,
    setPage: (page: number) => AnyAction
}

export interface BarcodePropsInterface {
    saveBarcode: (data: GenericObject) => Promise<any>
}

export interface GeneratorListCardPropsInterface {
    item: BarcodeInterface
}

export interface GeneratorFiltersPropsInterface {
    onFiltersChange: (filters: GeneratorStateInterface["filters"]) => void,
    onClearFilters: () => void,
    filters: any
}

export interface GeneratorSortBarPorpsInterface {
    currentSort: Nullable<{ [fieldname: string]: number }>,
    onSortChange: (sortKey: string, sortValue: number) => void
}