import _ from 'lodash';
import { RolePermissionInterface } from '../../common/types/common.types';

class AclService implements AclService {
    permissions: Array<RolePermissionInterface>
    isAdmin: boolean

    constructor() {
        this.permissions = [];
        this.isAdmin = false;
    }

    setPermissions(permissions: Array<RolePermissionInterface>) {
        if (!this.permissions) {
            this.permissions = permissions;
        }
    }

    clearPermissions() {
        this.permissions = [];
        this.isAdmin = false;
    }

    setIsAdmin(isAdmin: boolean) {
        this.isAdmin = isAdmin;
    }

    getIsAdmin() {
        return this.isAdmin;
    }

    getPermissions() {
        return this.permissions;
    }

    isAllowedMethod(moduleName: string, methodName: string) {
        if (this.getIsAdmin()) return true;
        if (!moduleName || !methodName) {
            return false;
        }
        if (!this.permissions || _.isEmpty(this.permissions)) {
            return false;
        }
        let isAllowed = false;
        const modulePathInfo = moduleName.split("/");
        for (let i = 0; i < this.permissions.length; i++) {
            let permission = this.permissions[i];
            if (permission.module === _.get(modulePathInfo, '[0]') && permission.controller === _.get(modulePathInfo, '[1]')) {
                if (_.includes(_.get(permission, 'actions', []), methodName)) {
                    isAllowed = true;
                    break;
                }
            }
        }
        return isAllowed;
    }

}

export default new AclService();