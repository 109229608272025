import _ from "lodash";
import { AnyAction } from "redux";
import { isLoading } from "../../application/actions/application.actions";
import Api from "../../application/services/api.service";
import { GenericObject, ProductInterface } from "../../common/types/common.types";
import { AppDispatch, GetState } from "../../config/store";
import { ProductsActionType, ProductsStateInterface } from "../types/product.types";
import { toast } from "react-toastify";

// ************ GET ***************
export const getProducts =
  (hideLoader?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
      const {
        products: { filters, params, page },
      } = getState();
      if (!hideLoader) {
        dispatch(isLoading(true));
      }

      let sendParams: GenericObject = {
        limit: params.limit,
        offset: (page - 1) * params.limit,
      };

      if (_.isObject(params.sort) && !_.isEmpty(params.sort)) {
        let sortKey: string = _.keys(params.sort)[0];
        let sortValue: number = _.values(params.sort)[0];
        sendParams[`sort[${sortKey}]`] = sortValue;
      }

      sendParams = Object.assign(sendParams, parseProductsFilters(filters));

      let res = await Api.get("/product/product", sendParams);
      if (res.success) {
        dispatch(setProducts(res.documents, res.total));
      } else {
        toast.error("Błąd pobierania produktów");
      }
      dispatch(isLoading(false));
    } catch (err) {
      toast.error("Błąd pobierania produktów");
      dispatch(isLoading(false));
    }
  };

// ************ SET ***************

export const setProducts = (products: Array<ProductInterface>, total: number): AnyAction => {
  return {
    type: ProductsActionType.SET_PRODUCTS,
    products,
    total,
  };
};

export const setPage = (page: number): AnyAction => {
  if (page <= 0) page = 1;
  return {
    type: ProductsActionType.SET_PAGE,
    page,
  };
};

export const setParams =
  (params: ProductsStateInterface["params"], reloadList?: boolean) =>
    async (dispatch: AppDispatch) => {
      dispatch({
        type: ProductsActionType.SET_PARAMS,
        params,
      });
      if (reloadList) {
        dispatch(getProducts(true));
      }
    };

export const setFilters =
  (filters: ProductsStateInterface["filters"], reloadList?: boolean) =>
    async (dispatch: AppDispatch) => {
      dispatch({
        type: ProductsActionType.SET_FILTERS,
        filters,
      });
      if (reloadList) {
        dispatch(getProducts(true));
      }
    };

// ************ CLEAR ***************
export const clearProducts = (): AnyAction => {
  return {
    type: ProductsActionType.CLEAR_PRODUCTS,
  };
};

export const clearFilters = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
  dispatch({
    type: ProductsActionType.CLEAR_FILTERS,
  });
  if (reloadList) {
    dispatch(getProducts(true));
  }
};

// ************ REST ***************
const parseProductsFilters = ({ name, ean, code, type, active, isGratis, PAO, category }: any) => {
  let sendParams: GenericObject = {};

  if (name) sendParams.name = name;
  if (ean) sendParams.ean = ean;
  if (code) sendParams.code = code;
  if (type) sendParams.type = type;
  if (active) sendParams.active = active;
  if (isGratis) sendParams["features.isGratis"] = isGratis;
  if (PAO) sendParams["features.PAO"] = PAO;
  if (category) sendParams["categories.categoryId[in]"] = category;

  return sendParams;
};
