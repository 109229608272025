import React, { FC, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router";
import { AppDispatch, RootState } from "../../config/store";
import { clearUser, getUser, deleteUser } from "../actions/user.actions";
import UserDetailsComponent from "../components/userDetails.component";
import { UserPropsInterface } from "../types/user.types";

const UserDetailsContainer: FC<UserPropsInterface> = (props: UserPropsInterface) => {
    const { getUser, clearUser } = props;
    let { id }: any = useParams();

    useEffect(() => {
        getUser(id);
        return () => {
            clearUser();
        }
    }, [getUser, clearUser, id])


    return <UserDetailsComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    user: store.user.user
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getUser: (id: string) => dispatch(getUser(id)),
        clearUser: () => dispatch(clearUser()),
        deleteUser: (id: string) => deleteUser(id, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsContainer);