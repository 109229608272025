import React, { FC, useState } from 'react';
import Autocomplete from '../../common/components/autocomplete.component';
import ReportsPropsInterface from '../types/reports.types';


const ReportsWarehousesProductsSeriesComponent: FC<ReportsPropsInterface> = ({ onDataChange, warehouseError }: ReportsPropsInterface) => {
    const type = "4";
    const [warehouse, setWarehouse] = useState("");
    let props = { type: type, warehouse: warehouse };

    const handleFindWarehouse = (value: any) => {
        if (value === null) {
            setWarehouse("");
            props.warehouse = "";
            onDataChange(props);
        }
        if (value) {
            setWarehouse(value.code);
            props.warehouse = value.code;
            onDataChange(props);
        }
    }

    return (
        <div id="report-form-stan-serie" className="report-form tabpane fade" role="tabpanel" aria-labelledby="report-form-stan-serie-tab">
            <div className="form-group input-group my-0">
                <div className="has-float-label">
                    <Autocomplete
                        id="report-warehouse"
                        multi={false}
                        placeholder="Szukaj"
                        label="Magazyn"
                        url="/warehouse/warehouse"
                        optionLabelField="wyszukaj"
                        optionIdField="reportWarehouseId"
                        onOptionSelect={(id: string, value: any) => handleFindWarehouse(value)}
                        value={warehouse}
                        queryFields={["name", "code", "_id"]}
                        additionalParams={{ limit: 5, select: "name,code,_id" }}
                        parseOption={(option: any) => {
                            option.warehouseId = option._id;
                            return option;
                        }}
                        hideChips={false}
                        readonly={false}
                        preload={false}
                        error={warehouseError}
                    />
                </div>
            </div>
        </div>
    );
};
export default ReportsWarehousesProductsSeriesComponent;