import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { GenericObject, InventoryInterface } from "../../common/types/common.types"
import { AppDispatch, GetState } from "../../config/store"
import { InventoryActionType, InventoryStateInterface } from "../types/inventory.types"
import _ from "lodash";
import { toast } from "react-toastify"

export const getInventory = (id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get(`/warehouse/inventory/${id}`, { getStatistics: true });
        if (res.success) {
            dispatch(setInventory(res.document));
        } else {
            toast.error("Błąd pobierania inwentaryzacji");
        }
        dispatch(isLoading(false));
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd pobierania inwentaryzacji");
    }
}

export const deleteInventory = async (id: string, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.delete(`/warehouse/inventory/${id}`);
        dispatch(isLoading(false));
        if (res.success) {
            toast.success("Usunięto inwentaryzację");
            return res;
        } else if (res.errors) {
            toast.error("Błąd usuwania inwentaryzacji");
            return res;
        } else {
            toast.error("Błąd usuwania inwentaryzacji");
            return { success: false, errors: { message: "Błąd usuwania" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd usuwania inwentaryzacji");
        return { success: false, errors: { message: "Błąd usuwania" } };
    }
}

export const saveInventory = async (data: GenericObject, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = null;
        if (data._id) {
            res = await Api.put(`/warehouse/inventory/${data._id}`, data);
        } else {
            res = await Api.post("/warehouse/inventory", data);
        }
        dispatch(isLoading(false));

        if (res.success) {
            toast.success("Zapisano inwentaryzację");
            return res;
        } else if (res.errors) {
            toast.error("Błąd zapisu inwentaryzacji");
            return res;
        } else {
            toast.error("Błąd zapisu inwentaryzacji");
            return { success: false, errors: { message: "Błąd zapisu" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd zapisu inwentaryzacji");
        return { success: false, errors: { message: "Błąd zapisu" } };
    }
}

export const resetPosition = async (id: string, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.put(`/warehouse/resetPositionInventory/${id}`, {});

        dispatch(isLoading(false));

        if (res.success) {
            toast.success("Zresetowano postęp na pozycji");
            return res;
        } else if (res.errors) {
            toast.error("Błąd resetu pozycji");
            return res;
        } else {
            toast.error("Błąd resetu pozycji");
            return { success: false, errors: { message: "Błąd zapisu" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd resetu pozycji");
        return { success: false, errors: { message: "Błąd zapisu" } };
    }
}

export const setInventory = (inventory: InventoryInterface): AnyAction => {
    return {
        type: InventoryActionType.SET_INVENTORY,
        inventory
    }
}

export const clearInventory = (): AnyAction => {
    return {
        type: InventoryActionType.CLEAR_INVENTORY
    }
}

// Historia

export const getHistory = (id: string, hideLoader?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        const { inventory: { filters, params, page } } = getState();
        if (!hideLoader) {
            dispatch(isLoading(true));
        }

        let sendParams: GenericObject = {
            limit: params.limit,
            offset: (page - 1) * params.limit,
            "document.documentId": id
        }

        if (_.isObject(params.sort) && !_.isEmpty(params.sort)) {
            let sortKey: string = _.keys(params.sort)[0];
            let sortValue: number = _.values(params.sort)[0];
            sendParams[`sort[${sortKey}]`] = sortValue;
        }
        if (filters.user) {
            sendParams["creator.name"] = filters.user;
        }
        if (filters.product) {
            sendParams["products.name"] = filters.product;
        }
        if (filters.seriesNr) {
            sendParams["products.seriesNr"] = filters.seriesNr;
        }
        if (filters.position) {
            sendParams["position.code"] = filters.position;
        }

        let res = await Api.get("/warehouse/positionHistory", sendParams);
        if (res.success) {
            dispatch(setHistory(res.documents, res.total));
        } else {
            toast.error("Błąd pobierania historii inwentaryzacji");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania historii inwentaryzacji");
        dispatch(isLoading(false));
    }
}

export const setHistory = (history: Array<any>, total: number): AnyAction => {
    return {
        type: InventoryActionType.SET_HISTORY,
        history,
        total
    }
}

export const clearHistory = (): AnyAction => {
    return {
        type: InventoryActionType.CLEAR_HISTORY
    }
}

export const setFilters = (filters: InventoryStateInterface["filters"], reloadList?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    const { inventory: { inventory } } = getState();
    dispatch({
        type: InventoryActionType.SET_FILTERS,
        filters
    })
    if (reloadList) {
        dispatch(getHistory(inventory._id, true));
    }
}

export const clearFilters = (reloadList?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    const { inventory: { inventory } } = getState();
    dispatch({
        type: InventoryActionType.CLEAR_FILTERS
    });
    if (reloadList) {
        dispatch(getHistory(inventory._id, true));
    }
}

export const setParams = (params: InventoryStateInterface["params"], reloadList?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    const { inventory: { inventory } } = getState();
    dispatch({
        type: InventoryActionType.SET_PARAMS,
        params
    });
    if (reloadList) {
        dispatch(getHistory(inventory._id, true));
    }
}

export const clearParams = (reloadList?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    const { inventory: { inventory } } = getState();
    dispatch({
        type: InventoryActionType.CLEAR_PARAMS
    })
    if (reloadList) {
        dispatch(getHistory(inventory._id, true));
    }
}

export const setPage = (page: number): AnyAction => {
    if (page <= 0) page = 1;
    return {
        type: InventoryActionType.SET_PAGE,
        page
    }
}