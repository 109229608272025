import React, { FC } from "react";

export interface ModalComponentPropsInterface {
    title: string,
    id: string,
    content: JSX.Element,
    buttons: Array<JSX.Element>
}

const ModalComponent: FC<ModalComponentPropsInterface> = ({ id, content, title, buttons }: ModalComponentPropsInterface) => {
    return (<div id={id} className="modal fade">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header btn-wms ">
                    <h4 className="modal-title text-xs-center text-white">{title}</h4>
                </div>
                <div className="modal-body">
                    {content}
                </div>
                <div className="modal-footer text-center col-sm-8 px-3 mx-auto">
                    {buttons}
                    {/* <button type="button" className="flex-grow-1 btn btn-primary btn-wms btn-save ml-1"><img src="/gfx/save-tab.svg" className="px-1 mb-1" />Zapisz</button>
                    <button type="button" className="col-sm-4 btn btn-light px-0" data-dismiss="modal">Anuluj</button> */}
                </div>
            </div>
        </div>
    </div>)
}

export default ModalComponent;