import React, { FC, useEffect } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router";
import { GenericObject } from "../../common/types/common.types";
import { AppDispatch, RootState } from "../../config/store";
import { getDashboard, clearDashboard, saveDashboard } from "../actions/dashboard.actions";
import DashboardComponent from "../components/dashboard.component";
import { DashboardPropsInterface } from "../types/dashboard.types";

const DashboardContainer: FC<DashboardPropsInterface> = (props: DashboardPropsInterface) => {
    const { getDashboard, clearDashboard } = props;
    let { id }: any = useParams();

    useEffect(() => {
        getDashboard();
        return () => {
            clearDashboard();
        }
    }, [getDashboard, clearDashboard, id])


    return <DashboardComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    dashboard: store.dashboard.dashboard
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getDashboard: () => dispatch(getDashboard()),
        clearDashboard: () => dispatch(clearDashboard()),
        saveDashboard: (data: GenericObject) => saveDashboard(data, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);