import _ from "lodash";
import React, { FC, Fragment } from "react";
import { DashboardPropsInterface } from "../types/dashboard.types";
import DashboardHeaderComponent from "./dashboardHeader.component";
import arrowdownIcon from "../../common/images/ico-arrowdown.svg";
import { Link } from "react-router-dom";
import ChartComponent from "../../common/components/chart.component";

const DashboardComponent: FC<DashboardPropsInterface> = ({ dashboard }: DashboardPropsInterface) => {

    const renderShortcuts = () => {
        return _.get(dashboard, "shortcuts", []).map((shortcut: any, index: number) => {
            return <p className="card-text text-dark" key={`sh${index}`}><Link to={shortcut.path} className="text-dark"><img src={arrowdownIcon} className="link-arrow "alt="skrót" />{shortcut.name}</Link></p>
        });
    }

    const renderDocumentCharts = () => {
        if (_.get(dashboard, "documentStatistics")) {
            return (
                <Fragment>
                    <h2 className="text-center w-100">Przegląd miesiąca - zamówienia</h2>
                    <div className="row sparkboxes mt-2 mb-0">
                        <ChartComponent id="added" title="Nowe" data={_.get(dashboard, "documentStatistics.added", [])} type="line" labelMap={{ count: "Ilość" }} />
                        <ChartComponent id="completed" title="Zrealizowane" data={_.get(dashboard, "documentStatistics.completed", [])} type="line" labelMap={{ count: "Ilość" }} colorOffset={4}/>
                        <ChartComponent id="products" title="Najczęstsze produkty" data={_.get(dashboard, "documentStatistics.products", [])} type="bar" labelMap={{ documents: "Zamówienia" }} colorOffset={1}/>
                    </div>
                </Fragment>
            )
        }
        return null;
    }

    return (<Fragment>
        <DashboardHeaderComponent name={_.get(dashboard, "user.name", "użytkowniku")} />
        <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-4 d-flex flex-wrap">
            <div className="main col-sm-12 px-0">
                {renderDocumentCharts()}
                <div className="row mt-3 mb-4 d-flex align-items-stretch">
                    <div className="col-sm-12 col-md-8 px-2">
                    </div>
                    <div className="col-sm-12 col-md-4 px-2 shortcuts-front ">
                        <div className="card border-0 bg-white rounded-10 nooverflow mb-5 ">
                            <div className="card-header ">
                                <h3 className="text-center mt-2 ">Twoje skróty</h3>
                            </div>
                            <div className="card-body p-0 ">
                                {renderShortcuts()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>);
}

export default DashboardComponent;