import { AsyncThunkAction, Nullable, SettingsInterface } from "../../common/types/common.types";

export interface SettingsStateInterface {
    settings: Nullable<SettingsInterface>
}

export enum SettingsActionType {
    SET_SETTINGS = 'SETTINGS_SET_SETTINGS',
    CLEAR_SETTINGS = 'SETTINGS_CLEAR_SETTINGS'
}

export interface SettingsPropsInterface {
    getSettings: () => AsyncThunkAction,
    saveSettings: (settings: SettingsStateInterface["settings"]) => Promise<any>,
    settings: SettingsStateInterface["settings"]
}