import { RolesStateInterface, RolesActionType } from "../types/role.types";

const initialState: RolesStateInterface = {
    roles: [],
    total: 0,
    filters: {
        name: ''
    },
    params: {
        limit: 20,
        offset: 0,
        sort: null
    },
    page: 1
}

const rolesReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case RolesActionType.SET_ROLES: {
            return {
                ...state,
                roles: action.roles,
                total: action.total
            }
        }
        case RolesActionType.CLEAR_ROLES: {
            return {
                ...state,
                roles: [],
                total: 0
            }
        }
        case RolesActionType.SET_FILTERS: {
            return {
                ...state,
                filters: action.filters
            }
        }
        case RolesActionType.CLEAR_FILTERS: {
            return {
                ...state,
                filters: {
                    name: ''
                }
            }
        }
        case RolesActionType.SET_PARAMS: {
            return {
                ...state,
                params: action.params
            }
        }
        case RolesActionType.CLEAR_PARAMS: {
            return {
                ...state,
                params: {
                    limit: 20,
                    offset: 0,
                    sort: null
                }
            }
        }
        case RolesActionType.SET_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        default: return state;
    }
}

export default rolesReducer;