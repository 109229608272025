import { Formik } from 'formik';
import _ from "lodash";
import React, { FC, Fragment, useEffect, useState } from "react";
import TextInputComponent from "../../common/components/textInput.component";
import SelectComponent from '../../common/components/select.component';
import { GenericObject } from '../../common/types/common.types';
import CheckboxComponent from '../../common/components/checkbox.component';
import { initialValues, settingsValidationSchema } from "../schemas/settings.schema";
import { SettingsPropsInterface } from "../types/settings.types";
import SettingsHeaderComponent from "./settingsHeader.component";
import addIcon from "../../common/images/ico-plus.svg";
import deleteIcon from "../../common/images/ico-xred.svg";
import Autocomplete from '../../common/components/autocomplete.component';
import moment from 'moment';

const documentActions = [
    {
        label: "Rozchodowy",
        value: "subtract"
    },
    {
        label: "Przychodowy",
        value: "add"
    },
    {
        label: "Korekta",
        value: "correction"
    }, {
        label: "Wewnętrzny",
        value: "internal"
    }, {
        label: "Produkcyjny",
        value: "produuction"
    },
]

const SettingsComponent: FC<SettingsPropsInterface> = ({ settings, saveSettings }: SettingsPropsInterface) => {

    const [settingsInitialValues, setSettingsInitialValues] = useState(initialValues);

    useEffect(() => {
        if (settings !== null && _.get(settings, "_id")) {
            setSettingsInitialValues(Object.assign({}, initialValues, settings));
        }
    }, [settings]);

    const handleAddSupportedDocument = (formik: any) => {
        let newValue = JSON.parse(JSON.stringify(formik.values.supportedDocuments));
        newValue.push({ type: "", action: "subtract" });
        formik.setFieldValue("supportedDocuments", newValue);
    }

    const handleDeleteSupportedDocument = (formik: any, index: number) => {
        let newValue = JSON.parse(JSON.stringify(formik.values.supportedDocuments));
        newValue.splice(index, 1);
        formik.setFieldValue("supportedDocuments", newValue);
    }

    const handleAddAutocompletionDocument = (formik: any) => {
        let newValue = JSON.parse(JSON.stringify(formik.values.autoCompletion));
        newValue.push({ type: "", warehouse: null });
        formik.setFieldValue("autoCompletion", newValue);
    }

    const handleDeleteAutocompletionDocument = (formik: any, index: number) => {
        let newValue = JSON.parse(JSON.stringify(formik.values.autoCompletion));
        newValue.splice(index, 1);
        formik.setFieldValue("autoCompletion", newValue);
    }

    const handleAddHoliday = (formik: any) => {
        let newValue = JSON.parse(JSON.stringify(formik.values.holidays));
        newValue.push({ name: "", date: moment().format("YYYY-MM-DD") });
        formik.setFieldValue("holidays", newValue);
    }

    const handleDeleteHoliday = (formik: any, index: number) => {
        let newValue = JSON.parse(JSON.stringify(formik.values.holidays));
        newValue.splice(index, 1);
        formik.setFieldValue("holidays", newValue);
    }

    const handleAddSalesplanCalculatonDocument = (formik: any) => {
        let newValue = JSON.parse(JSON.stringify(formik.values.salesplanCalculationDocuments));
        newValue.push("");
        formik.setFieldValue("salesplanCalculationDocuments", newValue);
    }

    const handleDeleteSalesplanCalculatonDocument = (formik: any, index: number) => {
        let newValue = JSON.parse(JSON.stringify(formik.values.salesplanCalculationDocuments));
        newValue.splice(index, 1);
        formik.setFieldValue("salesplanCalculationDocuments", newValue);
    }

    const renderSupportedDocuments = (formik: any) => {
        return formik.values.supportedDocuments.map((doc: GenericObject, index: number) => {
            return <Fragment key={`supportedDocuments${index}`}>
                <div className="col-6">
                    <TextInputComponent
                        label="Typ dokumentu"
                        type="text"
                        placeholder="Wpisz typ"
                        id={`supportedDocuments[${index}].type`}
                        onChange={formik.setFieldValue}
                        value={formik.values.supportedDocuments[index].type}
                        error={_.get(formik, `errors.supportedDocuments[${index}].type`)}
                    />
                </div>
                <div className="col-4">
                    <SelectComponent
                        id={`supportedDocuments[${index}].action`}
                        label="Akcja"
                        onChange={formik.setFieldValue}
                        value={formik.values.supportedDocuments[index].action}
                        options={documentActions}
                        customClass="form-group input-group my-1"
                        error={_.get(formik, `errors.supportedDocuments[${index}].action`)}
                    />
                </div>
                <div className="col-2">
                    <button type="button" className="btn btn-expand btn-light ml-2 my-3" onClick={() => handleDeleteSupportedDocument(formik, index)}><img src={deleteIcon} alt="Usuń" /><div>Usuń</div></button>
                </div>
            </Fragment>
        });
    }

    const renderAutomaticCompletion = (formik: any) => {
        return formik.values.autoCompletion.map((doc: GenericObject, index: number) => {
            return <Fragment key={`autoCompletion${index}`}>
                <div className="col-6">
                    <TextInputComponent
                        label="Typ dokumentu"
                        type="text"
                        placeholder="Wpisz typ"
                        id={`autoCompletion[${index}].type`}
                        onChange={formik.setFieldValue}
                        value={formik.values.autoCompletion[index].type}
                        error={_.get(formik, `errors.autoCompletion[${index}].type`)}
                    />
                </div>
                <div className="col-4">
                    <Autocomplete
                        url={"/warehouse/warehouse"}
                        id={`autoCompletion[${index}].warehouse`}
                        multi={false}
                        onOptionSelect={formik.setFieldValue}
                        placeholder="Szukaj magazynu"
                        label="Magazyn"
                        optionIdField="warehouseId"
                        optionLabelField="name"
                        value={formik.values.autoCompletion[index].warehouse}
                        queryFields={["name", "code"]}
                        additionalParams={{ limit: 5, select: "name,code" }}
                        parseOption={(option: any) => {
                            option.warehouseId = option._id;
                            return option;
                        }}
                        hideChips={false}
                        readonly={false}
                        preload={false}
                        customClass="form-group input-group my-3"
                        error={_.get(formik, `errors.autoCompletion[${index}].warehouse`)}
                    />
                </div>
                <div className="col-2">
                    <button type="button" className="btn btn-expand btn-light ml-2 my-3" onClick={() => handleDeleteAutocompletionDocument(formik, index)}><img src={deleteIcon} alt="Usuń" /><div>Usuń</div></button>
                </div>
            </Fragment>
        });
    }

    const renderHolidays = (formik: any) => {
        return formik.values.holidays.map((holiday: GenericObject, index: number) => {
            return <Fragment key={`holidays${index}`}>
                <div className="col-6">
                    <TextInputComponent
                        label="Nazwa święta"
                        type="text"
                        placeholder="Wpisz nazwę"
                        id={`holidays[${index}].name`}
                        onChange={formik.setFieldValue}
                        value={formik.values.holidays[index].name}
                        error={_.get(formik, `errors.holidays[${index}].name`)}
                    />
                </div>
                <div className="col-4">
                    <TextInputComponent
                        label="Data święta"
                        type="date"
                        placeholder="Wybierz datę"
                        id={`holidays[${index}].date`}
                        onChange={formik.setFieldValue}
                        value={moment(formik.values.holidays[index].date).format("YYYY-MM-DD")}
                        error={_.get(formik, `errors.holidays[${index}].date`)}
                    />
                </div>
                <div className="col-2">
                    <button type="button" className="btn btn-expand btn-light ml-2 my-3" onClick={() => handleDeleteHoliday(formik, index)}><img src={deleteIcon} alt="Usuń" /><div>Usuń</div></button>
                </div>
            </Fragment>
        });
    }

    const renderSalesplanCalculationDocuments = (formik: any) => {
        return formik.values.salesplanCalculationDocuments.map((doc: GenericObject, index: number) => {
            return <Fragment key={`holidays${index}`}>
                <div className="col-10">
                    <TextInputComponent
                        label="Typ dokumentu"
                        type="text"
                        placeholder="Wpisz nazwę"
                        id={`salesplanCalculationDocuments[${index}]`}
                        onChange={formik.setFieldValue}
                        value={formik.values.salesplanCalculationDocuments[index]}
                        error={_.get(formik, `errors.salesplanCalculationDocuments[${index}]`)}
                    />
                </div>
                <div className="col-2">
                    <button type="button" className="btn btn-expand btn-light ml-2 my-3" onClick={() => handleDeleteSalesplanCalculatonDocument(formik, index)}><img src={deleteIcon} alt="Usuń" /><div>Usuń</div></button>
                </div>
            </Fragment>
        });
    }

    return <Fragment>
        <SettingsHeaderComponent />
        <div id="content-container-right" className="role-add-container col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2">
            <div className="bg-white rounded-10 p-0 d-flex flex-wrap nooverflow">
                <div id="filter-sidebar" className="col-md-2 col-sm-12 nomobile py-4 px-0 d-flex flex-column boxshadow bg-light">
                    <div id="permissions" className="d-flex flex-column pt-5">
                        <button className="btn btn-primary mt-5" type="button" data-toggle="collapse"
                            data-target="#permissions-section1" aria-expanded="true" aria-controls="collapseExample">
                            Obsługiwane dokumenty
                            {/* <span className="badge badge-pill badge-primary ml-3">12</span> */}
                        </button>
                        <button className="btn btn-primary collapsed" type="button" data-toggle="collapse"
                            data-target="#permissions-section2" aria-expanded="false" aria-controls="collapseExample">
                            Automatyczna kompletacja
                            {/* <span className="badge badge-pill badge-primary ml-3">3</span> */}
                        </button>
                        {/* <button className="btn btn-primary collapsed" type="button" data-toggle="collapse"
                            data-target="#permissions-section3" aria-expanded="false" aria-controls="collapseExample">
                            Powiadomienia
                        </button> */}
                        <button className="btn btn-primary collapsed" type="button" data-toggle="collapse"
                            data-target="#permissions-section4" aria-expanded="false" aria-controls="collapseExample">
                            Produkcja
                        </button>
                        <button className="btn btn-primary collapsed" type="button" data-toggle="collapse"
                            data-target="#permissions-section6" aria-expanded="false" aria-controls="collapseExample">
                            Inne
                        </button>
                    </div>
                </div>

                <Formik
                    initialValues={settingsInitialValues}
                    validationSchema={settingsValidationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    onSubmit={(values, { setErrors }) => {
                        _.each(values.holidays, (holiday: any, index: number) => {
                            _.set(values, `holidays[${index}].date`, moment(holiday.date, "YYYY-MM-DD").format("X"))
                        });
                        saveSettings(values).then((res) => {
                            if (res.success) {
                                // history.push(`/role/${res.document}`);
                            } else if (res.errors) {
                                setErrors(res.errors);
                            }
                        })
                    }}
                >
                    {formik =>
                    (
                        <form className="d-flex col-md-10 align-items-flex-start justify-content-center mt-5 flex-wrap" onSubmit={formik.handleSubmit}>
                            <div className="col-sm-12 mb-3">
                                <div className="col-sm-12 onmobile form-group input-group align-items-center mt-3 ">
                                    <label className="form-group has-float-label d-flex justify-content-stretch">
                                        <select className="selectpicker form-control custom-select" title="Wybierz grupę uprawnień">
                                            <option>Obsługiwanie dokumenty</option>
                                            <option>Automatyczna kompletacja</option>
                                            {/* <option>Powiadomienia</option> */}
                                            <option>Produkcja</option>
                                            <option>Inne</option>
                                        </select>
                                        <span>Wybierz grupę uprawnień</span>
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 d-flex flex-wrap mb-5 mt-2 px-0">
                                {/* Obsługiwane dokumenty */}
                                <div className="collapse col-sm-12 show" id="permissions-section1" data-parent="#permissions">
                                    <div className=" col-sm-12 d-flex flex-row flex-wrap">
                                        {/* <p>Obsługiwanie dokumenty</p><br/> */}
                                        {renderSupportedDocuments(formik)}
                                        <div className="col-12">
                                            <button type="button" className="btn btn-primary btn-expand wider2 btn-wms" onClick={() => handleAddSupportedDocument(formik)}>
                                                <img src={addIcon} alt="dodaj" />
                                                <div>Dodaj&nbsp;dokument</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* Automatyczna kompletacja */}
                                <div className="collapse col-sm-12" id="permissions-section2" data-parent="#permissions">
                                    <div className="col-sm-12 d-flex flex-row flex-wrap">
                                        <div className="col-sm-12">
                                            <p>Automatyczna kompletacja</p>
                                        </div>
                                        {renderAutomaticCompletion(formik)}
                                        <div className="col-12">
                                            <button type="button" className="btn btn-primary btn-expand wider2 btn-wms" onClick={() => handleAddAutocompletionDocument(formik)}>
                                                <img src={addIcon} alt="dodaj" />
                                                <div>Dodaj&nbsp;dokument</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Powiadomienia */}
                                {/* <div className="collapse col-sm-12" id="permissions-section3" data-parent="#permissions">
                                    <div className="col-sm-12 d-flex flex-row flex-wrap">
                                        <p>Powiadomienia</p>
                                    </div>
                                </div> */}
                                {/* Produkcja */}
                                <div className="collapse col-sm-12" id="permissions-section4" data-parent="#permissions">
                                    <div className="col-sm-12 d-flex flex-row flex-wrap">
                                        <div className="col-sm-12">
                                            <Autocomplete
                                                url={"/application/user"}
                                                id="planner"
                                                multi={false}
                                                onOptionSelect={formik.setFieldValue}
                                                placeholder="Szukaj użytkownika"
                                                label="Planista"
                                                optionIdField="userId"
                                                optionLabelField="name"
                                                value={formik.values.planner}
                                                queryFields={["name"]}
                                                additionalParams={{ limit: 5, select: "name" }}
                                                parseOption={(option: any) => {
                                                    option.userId = option._id;
                                                    return option;
                                                }}
                                                hideChips={false}
                                                readonly={false}
                                                preload={true}
                                                customClass="form-group input-group"
                                                error={formik.errors.planner}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <Autocomplete
                                                url={"/warehouse/warehouse"}
                                                id={`simulationWarehouses`}
                                                multi={true}
                                                onOptionSelect={formik.setFieldValue}
                                                placeholder="Szukaj magazynu"
                                                label="Magazyny surowców (symulacja)"
                                                optionIdField="warehouseId"
                                                optionLabelField="name"
                                                value={formik.values.simulationWarehouses}
                                                queryFields={["name", "code"]}
                                                additionalParams={{ limit: 5, select: "name,code" }}
                                                parseOption={(option: any) => {
                                                    option.warehouseId = option._id;
                                                    return option;
                                                }}
                                                hideChips={false}
                                                readonly={false}
                                                preload={true}
                                                customClass="form-group input-group"
                                                error={formik.errors.simulationWarehouses?.toString()}
                                            />
                                        </div>
                                        <hr />
                                        <div className="col-sm-4">
                                            <TextInputComponent
                                                label="Deadline planów sprzedażowych (dzień miesiąca)"
                                                type="number"
                                                placeholder="Wpisz numer dnia miesiąca"
                                                id="salesplanDeadlineDayOfMonth"
                                                onChange={formik.setFieldValue}
                                                value={formik.values.salesplanDeadlineDayOfMonth}
                                                error={formik.errors.salesplanDeadlineDayOfMonth}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <TextInputComponent
                                                label="Długość kwarantanny po dostawie"
                                                type="number"
                                                placeholder="Wpisz ilość godzin"
                                                id="deliveryQuarantineHours"
                                                onChange={formik.setFieldValue}
                                                value={formik.values.deliveryQuarantineHours}
                                                error={formik.errors.deliveryQuarantineHours}
                                            />
                                        </div>
                                        <div className="col-sm-4">
                                            <TextInputComponent
                                                label="Maksymlana ilośc dni do konfekcji"
                                                type="number"
                                                placeholder="Wpisz ilość gdni"
                                                id="maxDaysToConfection"
                                                onChange={formik.setFieldValue}
                                                value={formik.values.maxDaysToConfection}
                                                error={formik.errors.maxDaysToConfection}
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-sm-12 d-flex flex-row flex-wrap">
                                        <div className="col-sm-6">
                                            <CheckboxComponent
                                                label="Dodawaj eventy na sobotę (symulacja)"
                                                onChange={formik.setFieldValue}
                                                id="addEventsToSaturday"
                                                checked={formik.values.addEventsToSaturday}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                        <div className="col-sm-6">
                                            <CheckboxComponent
                                                label="Dodawaj eventy na niedzielę (symulacja)"
                                                onChange={formik.setFieldValue}
                                                id="addEventsToSunday"
                                                checked={formik.values.addEventsToSunday}
                                                customClass="custom-control custom-switch"
                                            />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="col-sm-12 d-flex flex-row flex-wrap">
                                        <div className="col-sm-6">
                                            <p>Dokumenty do kalkulacji planów sprzedażowych</p>
                                        </div>
                                        {renderSalesplanCalculationDocuments(formik)}
                                        <div className="col-12">
                                            <button type="button" className="btn btn-primary btn-expand wider2 btn-wms">
                                                <img src={addIcon} alt="dodaj" onClick={() => handleAddSalesplanCalculatonDocument(formik)} />
                                                <div>Dodaj&nbsp;dokument</div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/* Inne */}
                                <div className="collapse col-sm-12" id="permissions-section6" data-parent="#permissions">
                                    <div className="col-sm-12 d-flex flex-row flex-wrap">
                                        <div className="col-sm-12">
                                            <p>Święta / dni wolne</p>
                                        </div>
                                        {renderHolidays(formik)}
                                    </div>
                                    <div className="col-12">
                                        <button type="button" className="btn btn-primary btn-expand wider2 btn-wms">
                                            <img src={addIcon} alt="dodaj" onClick={() => handleAddHoliday(formik)} />
                                            <div>Dodaj&nbsp;święto</div>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-sm-12 text-center">
                                    <button type="submit" className="btn-wms color-white rounded border-0 px-4 my-5 py-2">
                                        Zapisz
                                    </button>
                                </div>
                            </div>
                        </form>)}
                </Formik>

            </div>
        </div>
    </Fragment>;
}

export default SettingsComponent;