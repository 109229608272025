import _ from "lodash";
import { FC, Fragment } from "react";
import TextInputComponent from "../../common/components/textInput.component";
import { ProductWarehousePositionHistoryPropsInterface } from "../types/product.types";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import clearIcon from "../../common/images/ico-wyczysc.svg";

const ProductWarehousePositionHistoryComponent: FC<ProductWarehousePositionHistoryPropsInterface> =
  ({
    items,
    itemsOnPage,
    total,
    currentPage,
    filters,
    onPageChange,
    onFiltersChange,
    onClearFilters,
  }: ProductWarehousePositionHistoryPropsInterface) => {
    let nrOfPages = Math.ceil(total / itemsOnPage);

    const handleRangeChange = (event: any, picker: any) => {
      let newFilters = Object.assign({}, filters, {
        dateFrom: picker.startDate.clone().format("MM/DD/YYYY"),
        dateTo: picker.endDate.clone().format("MM/DD/YYYY"),
      });
      onFiltersChange(newFilters);
    };

    const handleFilterChange = (field: string, value: any) => {
      let newFilters = Object.assign({}, filters, {
        [field]: value,
      });
      onFiltersChange(newFilters);
    };

    const handleFiltersReset = () => {
      let seriesNrInput: any = document.getElementById("seriesNr");
      if (seriesNrInput) {
        seriesNrInput.value = "";
      }
      let documentNumberInput: any = document.getElementById("documentNumber");
      if (documentNumberInput) {
        documentNumberInput.value = "";
      }
      let positionInput: any = document.getElementById("position");
      if (positionInput) {
        positionInput.value = "";
      }
      onClearFilters();
    };

    const getPageButtons = () => {
      if (currentPage === 1) {
        return (
          <Fragment>
            <li className="page-item active">
              <button className="page-link" type="button">
                1
              </button>
            </li>
            {currentPage + 1 <= nrOfPages && (
              <li className="page-item" onClick={() => onPageChange(currentPage + 1)}>
                <button className="page-link" type="button">
                  {currentPage + 1}
                </button>
              </li>
            )}
            {currentPage + 2 <= nrOfPages && (
              <li className="page-item" onClick={() => onPageChange(currentPage + 2)}>
                <button className="page-link" type="button">
                  {currentPage + 2}
                </button>
              </li>
            )}
          </Fragment>
        );
      } else if (currentPage === nrOfPages) {
        return (
          <Fragment>
            {currentPage - 2 > 0 && (
              <li className="page-item" onClick={() => onPageChange(currentPage - 2)}>
                <button className="page-link" type="button">
                  {currentPage - 2}
                </button>
              </li>
            )}
            {currentPage - 1 > 0 && (
              <li className="page-item" onClick={() => onPageChange(currentPage - 1)}>
                <button className="page-link" type="button">
                  {currentPage - 1}
                </button>
              </li>
            )}
            <li className="page-item active">
              <button className="page-link" type="button">
                {currentPage}
              </button>
            </li>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            {currentPage - 1 > 0 && (
              <li className="page-item" onClick={() => onPageChange(currentPage - 1)}>
                <button className="page-link" type="button">
                  {currentPage - 1}
                </button>
              </li>
            )}
            <li className="page-item active">
              <button className="page-link" type="button">
                {currentPage}
              </button>
            </li>
            {currentPage + 1 <= nrOfPages && (
              <li className="page-item" onClick={() => onPageChange(currentPage + 1)}>
                <button className="page-link" type="button">
                  {currentPage + 1}
                </button>
              </li>
            )}
          </Fragment>
        );
      }
    };

    return (
      <div
        className="tab-pane fade"
        id="tab2"
        role="tabpanel"
        aria-labelledby="details-profile-tab"
      >
        <div className="filter-top-bar rounded-0 justify-content-between p-0 ">
          <div className="filter-products-cont d-flex align-items-center col-md-11 px-0">
            <TextInputComponent
              id="seriesNr"
              defaultValue={filters.seriesNr}
              onChange={handleFilterChange}
              label="Nr serii"
              placeholder="Szukaj"
              customClass="col-md-3 col-sm-12 form-group input-group m-0 p-3"
              debounce={1000}
            />

            <TextInputComponent
              id="documentNumber"
              defaultValue={filters.documentNumber}
              onChange={handleFilterChange}
              label="Nr dokumentu"
              placeholder="Szukaj"
              customClass="col-md-3 col-sm-12 form-group input-group m-0 p-3"
              debounce={1000}
            />

            <TextInputComponent
              id="position"
              defaultValue={filters.position}
              onChange={handleFilterChange}
              label="Nr pozycji"
              placeholder="Szukaj"
              customClass="col-md-3 col-sm-12 form-group input-group m-0 p-3"
              debounce={1000}
            />

            <div
              id="date-filter-container"
              className="d-flex justify-content-end col-md-3 col-sm-6 mx-auto "
            >
              <div id="current-filter" className="date-filter">
                <span>Dzień:</span>
                <DateRangePicker
                  initialSettings={{ startDate: filters.dateFrom, endDate: filters.dateTo }}
                  onApply={handleRangeChange}
                >
                  <input type="text" name="datefilter" />
                </DateRangePicker>
              </div>
            </div>
          </div>

          <div
            className="d-flex align-self-stretch color-gray"
            onClick={() => handleFiltersReset()}
          >
            <div className="d-flex flex-wrap justify-content-center align-items-center bg-light filter-clear-topbar rounded-right px-2 py-2">
              <img src={clearIcon} alt="" />

              <h5> Wyczyść</h5>
            </div>
          </div>
        </div>

        {_.size(items) === 0 && (
          <div className="table table-responsive my-4">
            <table className="table table-hover mb-0">
              <thead>
                <tr className="thead-light tablenomobile">
                  <th scope="col" className="col-md-3">
                    Dokument
                  </th>
                  <th className="col-md-2">Pozycja</th>
                  <th className="col-md-2">Numer serii</th>
                  <th className="col-md-2">Ilość przed</th>
                  <th className="col-md-2">Ilość po</th>
                  <th className="col-md-1">Różnica</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="col-md-12 text-center" colSpan={6}>
                    Brak elementów
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {_.size(items) > 0 && (
          <div className="table table-responsive my-4">
            <table className="table table-hover mb-0">
              <thead>
                <tr className="thead-light tablenomobile">
                  <th scope="col" className="col-md-3">
                    Dokument
                  </th>
                  <th className="col-md-2">Pozycja</th>
                  <th className="col-md-2">Numer serii</th>
                  <th className="col-md-2">Ilość przed</th>
                  <th className="col-md-2">Ilość po</th>
                  <th className="col-md-1">Różnica</th>
                </tr>
              </thead>
            </table>

            {_.size(items) > 0 &&
              items.map((item: any, key: number) => {
                return (
                  <div
                    className="d-flex flex-wrap col-sm-12 px-0 border border-top-0 border-left-0 border-right-0"
                    key={`item-${key}`}
                  >
                    <div className="document-group col-sm-3 d-flex align-items-center justify-content-center">
                      <h3 className="text-left mt-1">{_.get(item, "document.documentNumber")}</h3>
                    </div>

                    <div className="table table-responsive mb-0 col-sm-9 px-0">
                      <table className="table table-hover mb-0 px-0">
                        <tbody>
                          <tr className="thead-light tableonmobile">
                            <th className="col-md-2">Pozycja</th>
                            <th className="col-md-2">Numer serii</th>
                            <th className="col-md-2">Ilość przed</th>
                            <th className="col-md-2">Ilość po</th>
                            <th className="col-md-1">Różnica</th>
                          </tr>

                          {_.size(item.products) > 0 &&
                            item.products.map((product: any, index: number) => {
                              return (
                                <tr key={`product-${key}-${index}`}>
                                  <td className="col-md-2">
                                    <div className="badge badge-light p-2 border border-2 ">
                                      {_.get(item, "position.code", "-")}
                                    </div>
                                  </td>
                                  <td className="col-md-2">
                                    <h3 className="text-left mt-1">
                                      {_.get(product, "seriesNr", "-")}
                                    </h3>
                                  </td>
                                  <td className="col-md-2">
                                    {_.get(product, "amountBefore", "-")}
                                  </td>
                                  <td className="col-md-2">{_.get(product, "amountAfter", "-")}</td>
                                  <td className="col-md-1">
                                    {_.sum([
                                      _.get(product, "amountBefore", "-"),
                                      _.get(product, "amountAfter", "-"),
                                    ])}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );
              })}
          </div>
        )}

        {_.size(items) > 0 && (
          <nav id="pagination-container">
            <ul className="pagination">
              {currentPage > 1 && (
                <li className="page-item" onClick={() => onPageChange(1)}>
                  <button className="page-link" type="button" aria-label="Poprzednia">
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Pierwsza</span>
                  </button>
                </li>
              )}
              {getPageButtons()}
              {currentPage < nrOfPages && (
                <li className="page-item" onClick={() => onPageChange(nrOfPages)}>
                  <button className="page-link" type="button" aria-label="Następna">
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Ostatnia</span>
                  </button>
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    );
  };

export default ProductWarehousePositionHistoryComponent;
