import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { GenericObject } from "../../common/types/common.types"
import { AppDispatch } from "../../config/store";
import { toast } from "react-toastify";

export const saveBarcode = async (data: GenericObject, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.post("/generator/generator", data);
        dispatch(isLoading(false));

        if (res.success) {
            toast.success("Zapisano kod");
            return res;
        } else if (res.errors) {
            toast.error("Błąd zapisu kodu");
            return res;
        } else {
            toast.error("Błąd zapisu kodu");
            return { success: false, errors: { message: "Błąd zapisu" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd zapisu kodu");
        return { success: false, errors: { message: "Błąd zapisu" } };
    }
}