import React, { FC } from "react";
import Acl from "../../application/services/acl.service";
import { Link } from "react-router-dom";
import addIcon from "../../common/images/ico-plus.svg"
import { InventoriesHeaderPropsInterface } from "../types/inventory.types";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css';

const InventoriesListHeaderComponent: FC<InventoriesHeaderPropsInterface> = ({ filters, onFiltersChange }: InventoriesHeaderPropsInterface) => {

    const handleRangeChange = (event: any, picker: any) => {
        let newFilters = Object.assign({}, filters, {
            dateFrom: picker.startDate.clone().format("MM/DD/YYYY"),
            dateTo: picker.endDate.clone().format("MM/DD/YYYY")
        });
        onFiltersChange(newFilters);
    }

    return (<div className="row px-0 mx-0 title-bar d-flex flex-wrap justify-content-between align-items-center">
        <div className="col-md-6 col-sm-12 d-flex flex-nowrap pl-4">
            <h2 id="page-title" className="m-0">Inwentaryzacje</h2>
            {Acl.isAllowedMethod("warehouse/inventory", "create") && <Link type="button" to="/inventory" className="btn btn-primary btn-expand btn-wms ml-4">
                <img src={addIcon} alt="dodaj"/>
                <div>Dodaj</div>
            </Link>}
        </div>
        <div id="date-filter-container" className="d-flex justify-content-end col-md-6 col-sm-12 mx-auto pr-4">
            <div id="current-day" className="date-filter">Dziś</div>
            <div id="current-week" className="date-filter">Tydzień</div>
            <div id="current-month" className="date-filter">Miesiąc</div>
            {/* <div className="d-flex flex-row"> */}
            <div id="current-filter" className="date-filter">
                <span>Dzień:</span>
                <DateRangePicker
                    initialSettings={{ startDate: filters.dateFrom, endDate: filters.dateTo }}
                    onApply={handleRangeChange}
                >
                    <input type="text" name="datefilter" />
                </DateRangePicker>
            </div>
        </div>
    </div>);
}

export default InventoriesListHeaderComponent;