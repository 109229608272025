import _ from "lodash";
import { AnyAction } from "redux";
import Api from "../../application/services/api.service";
import { AppDispatch } from "../../config/store";
import { GenericObject } from "../types/common.types";
import { GlobalSearchActionType, GlobalSearchStateInterface } from "../types/component.types";

export const getSearchedResults = (searchedValue: string) => async (dispatch: AppDispatch) => {
  dispatch(setIsLoading(true));

  let searchedResults = {
    searchedUsers: [],
    searchedProducts: [],
    searchedDocuments: [],
    searchedWarehouses: [],
  };
  let sendParams: GenericObject = {
    limit: 7,
  };

  await Promise.all([
    (async () => {
      sendParams.name = searchedValue;
      let res = await Api.get("/product/product", sendParams);
      if (res.success) {
        searchedResults.searchedProducts = _.get(res, "documents", []);
      }
    })(),
    (async () => {
      sendParams.documentNumber = searchedValue;
      let res = await Api.get("/document/document", sendParams);
      if (res.success) {
        searchedResults.searchedDocuments = _.get(res, "documents", []);
      }
    })(),
    (async () => {
      sendParams.name = searchedValue;
      let res = await Api.get("/application/user", sendParams);
      if (res.success) {
        searchedResults.searchedUsers = _.get(res, "documents", []);
      }
    })(),
    (async () => {
      sendParams.name = searchedValue;
      let res = await Api.get("/warehouse/warehouse", sendParams);
      if (res.success) {
        searchedResults.searchedWarehouses = _.get(res, "documents", []);
      }
    })(),
  ]);

  dispatch(setSearchedResults(searchedResults));
  dispatch(setIsLoading(false));
};

export const setSearchedResults =
  (searchedResults: GlobalSearchStateInterface["searchedResults"]) => (dispatch: AppDispatch) => {
    dispatch({
      type: GlobalSearchActionType.SET_SEARCHED_RESULTS,
      searchedResults: searchedResults,
    });
  };

export const setIsLoading = (isLoading: boolean): AnyAction => {
  return {
    type: GlobalSearchActionType.SET_IS_LOADING,
    isLoading,
  };
};
