import React, { FC, Fragment } from "react";
import { ListComponentPropsInterface } from "../types/component.types";
import sortAscIcon from "../../common/images/sort-asc.svg";
import sortDescIcon from "../../common/images/sort-desc.svg";
import { Link } from "react-router-dom";
import _ from "lodash";

const ListComponent: FC<ListComponentPropsInterface> = ({
    id,
    items,
    total,
    onPageChange,
    ItemCard,
    columns,
    currentSort,
    onSortChange,
    itemsOnPage,
    currentPage,
    disableSort,
    route
}: ListComponentPropsInterface) => {

    let nrOfPages = Math.ceil(total / itemsOnPage);

    const renderItems = () => {
        if (!_.isEmpty(items)) {
            return items.map((item, index) => {
                return <ItemCard item={item} key={`item${index}`} />
            });
        }
        return (<tr>
            <td className="col-md-12 text-center" colSpan={columns.length}>Brak elementów</td>
        </tr>)
    }

    const getSortIcon = (columnKey: string) => {
        if (currentSort && currentSort && _.keys(currentSort)[0] === columnKey) {
            if (_.values(currentSort)[0] === 1) {
                return <img src={sortAscIcon} alt="asc" />
            } else {
                return <img src={sortDescIcon} alt="desc" />
            }

        }
        return null;
    }

    const renderColumns = () => {
        return columns.map((column, index) => {
            let nextSortValue = currentSort ? _.keys(currentSort)[0] === column.key ? _.values(currentSort)[0] * -1 : 1 : 1;
            let sortIcon = getSortIcon(column.key)
            return <th scope="col" onClick={() => {
                if (!column.disableSort && !disableSort) {
                    onSortChange(column.key, nextSortValue)
                }
            }} key={`column${index}`}>{column.label}{sortIcon}</th>
        });
    }

    const getPageButtons = () => {
        if (currentPage === 1) {
            return (<Fragment>
                <li className="page-item active"><button className="page-link" type="button">1</button></li>
                {(currentPage + 1 <= nrOfPages) && <Link className="page-item" to={`${route}/page/${currentPage + 1}`} onClick={() => onPageChange(currentPage + 1)}><button className="page-link" type="button">{currentPage + 1}</button></Link>}
                {(currentPage + 2 <= nrOfPages) && <Link className="page-item" to={`${route}/page/${currentPage + 2}`} onClick={() => onPageChange(currentPage + 2)}><button className="page-link" type="button">{currentPage + 2}</button></Link>}
            </Fragment>);
        } else if (currentPage === nrOfPages) {
            return (<Fragment>
                {(currentPage - 2 > 0) && <Link className="page-item" to={`${route}/page/${currentPage - 2}`} onClick={() => onPageChange(currentPage - 2)}><button className="page-link" type="button">{currentPage - 2}</button></Link>}
                {(currentPage - 1 > 0) && <Link className="page-item" to={`${route}/page/${currentPage - 1}`} onClick={() => onPageChange(currentPage - 1)}><button className="page-link" type="button">{currentPage - 1}</button></Link>}
                <li className="page-item active"><button className="page-link" type="button">{currentPage}</button></li>
            </Fragment>);
        } else {
            return (<Fragment>
                {(currentPage - 1 > 0) && <Link className="page-item" to={`${route}/page/${currentPage - 1}`} onClick={() => onPageChange(currentPage - 1)}><button className="page-link" type="button">{currentPage - 1}</button></Link>}
                <li className="page-item active"><button className="page-link" type="button">{currentPage}</button></li>
                {(currentPage + 1 <= nrOfPages) && <Link className="page-item" to={`${route}/page/${currentPage + 1}`} onClick={() => onPageChange(currentPage + 1)}><button className="page-link" type="button">{currentPage + 1}</button></Link>}
            </Fragment>);
        }
    }

    return (<div className="bg-white rounded-10 table table-responsive table-hover">
        <table id={id} className="table">
            <thead>
                <tr>
                    {renderColumns()}
                </tr>
            </thead>
            <tbody>
                {renderItems()}
            </tbody>
        </table>
        <nav id="pagination-container">
            <ul className="pagination">
                {(currentPage > 1) && <Link className="page-item" to={`${route}/page/${1}`} onClick={() => onPageChange(1)}>
                    <button className="page-link" type="button" aria-label="Poprzednia">
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Pierwsza</span>
                    </button>
                </Link>}
                {getPageButtons()}
                {(currentPage < nrOfPages) && <Link className="page-item" to={`${route}/page/${nrOfPages}`} onClick={() => onPageChange(nrOfPages)}>
                    <button className="page-link" type="button" aria-label="Następna">
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Ostatnia</span>
                    </button>
                </Link>}
            </ul>
        </nav>
    </div>)
}

export default ListComponent;