import React, { FC } from "react";
import HelpdeskComponent from "../components/helpdesk.component";
import { sendReport } from '../actions/helpdesk.actions'
import { connect } from 'react-redux'
import { AppDispatch, RootState } from "../../config/store";
import { GenericObject } from "../../common/types/common.types";

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        sendReport: (values: GenericObject) => sendReport(values, dispatch)
    };
};

const mapStateToProps = (store: RootState) => ({
    user: store.application.user
});

const HelpDeskContainer: FC<any> = (props: any) => {
    return <HelpdeskComponent {...props} />
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpDeskContainer);
