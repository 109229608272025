import React, { FC, useEffect, useState } from "react";

import DeleteConfirmModal from "../../common/components/deleteConfirmModal.component";

import EditIcon from '../../common/images/ico-edit.svg';
import KoszIcon from '../../common/images/ico-kosz.svg';
import ArrowIcon from '../../common/images/ico-arrowdown.svg';
import PlusIcon from '../../common/images/ico-plus.svg';
import SaveIcon from '../../common/images/save-tab.svg';

import _ from 'lodash';

interface CategoryInterfaceProps {
    category: any
    index: number
    noLiStyle?: boolean
    handleSelectGroup: any
    selectedGroup: any
    handleSelectForEdit: any
    handleSelectForDelete: any
    handleCheckCategory: any
    checkedCategories?: any
}

const Category: FC<CategoryInterfaceProps> = ({ category, index, noLiStyle, handleSelectGroup, selectedGroup, handleSelectForEdit, handleSelectForDelete, handleCheckCategory, checkedCategories }: CategoryInterfaceProps) => {

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        let isChecked = false;
        if (checkedCategories) {
            checkedCategories.forEach((cat: any) => {
                if (cat._id === category._id) {
                    isChecked = true;
                }
            })
        }

        setChecked(isChecked);
    }, [checkedCategories])

    if (!category.children || category.children.length === 0) {
        return (
            <li className={`${noLiStyle ? null : 'custom-control custom-checkbox'}`}>
                <input type="checkbox" id="customRadio5" name="customRadio" className="custom-control-input" checked={checked} onChange={(e) => handleCheckCategory(category)} />
                <label className="custom-control-label" style={{ color: `${selectedGroup && selectedGroup._id === category._id ? 'red' : 'currentColor'}` }} onClick={() => handleSelectGroup(category)}>{category.name}</label>
                <div className="cat-edit-buttons">
                    <button className="badge badge-light  border-0" onClick={() => handleSelectForEdit(category)} >
                        <img src={EditIcon} alt="icon" />Edytuj
                    </button>
                    <a className="badge badge-light  border-0" data-toggle="modal" type="button" href="#deleteCategoryRole" onClick={() => handleSelectForDelete(category)} >
                        <img src={KoszIcon} alt="icon" />Usuń
                    </a>
                </div>
            </li>
        )
    } else {
        return (
            <li className={`${noLiStyle ? null : 'custom-control custom-checkbox'}`}>
                <div className="d-flex flex-wrap">
                    <input type="checkbox" id="customRadio5" name="customRadio" className="custom-control-input" checked={checked} onChange={(e) => handleCheckCategory(category)} />
                    <label className="custom-control-label" style={{ color: `${selectedGroup && selectedGroup._id === category._id ? 'red' : 'currentColor'}` }} onClick={() => handleSelectGroup(category)} >{category.name} </label>
                    <button className="subcategory-btn badge badge-light collapsed border-0" data-toggle="collapse" data-target={`#subcategory${category._id}`} type="button">
                        <img src={ArrowIcon} alt="icon" className="cat-arrow"  aria-controls="collapseExample" />
                    </button>
                </div>
                <div className="cat-edit-buttons">
                    <button className="badge badge-light  border-0" onClick={() => handleSelectForEdit(category)} >
                        <img src={EditIcon} alt="icon" />Edytuj
                    </button>
                    <a className="badge badge-light  border-0" data-toggle="modal" type="button" href="#deleteCategoryRole" onClick={() => handleSelectForDelete(category)} >
                        <img src={KoszIcon} alt="icon" />Usuń
                    </a>
                </div>

                <ul id={`subcategory${category._id}`} className="subcategories collapse mt-2">
                    {
                        category.children.map((cat: any, i: number) => {
                            return <Category checkedCategories={checkedCategories} category={cat} index={i} key={cat._id} noLiStyle={true} handleCheckCategory={handleCheckCategory} handleSelectGroup={handleSelectGroup} selectedGroup={selectedGroup} handleSelectForEdit={handleSelectForEdit} handleSelectForDelete={handleSelectForDelete} />
                        })
                    }
                </ul>
            </li>
        )
    }


}


interface CategoryModalRoleProps {
    categories: Array<any>
    createCategory: any
    editCategory: any
    deleteCategory: any
    setProductCategories: any
    formik: any,
    selectedCategories?: any
}

const CategoryModalRole: FC<CategoryModalRoleProps> = ({ formik, categories, createCategory, editCategory, deleteCategory, setProductCategories, selectedCategories }: CategoryModalRoleProps) => {

    const [selectedGroup, setSelectedGroup] = useState<any>(null);
    const [categoryName, setCategoryName] = useState<any>('');
    const [groupForEdit, setGroupForEdit] = useState<any>(null);
    const [groupForDelete, setGroupForDelete] = useState<any>(null);
    const [checkedCategories, setCheckedCategories] = useState<any>([]);

    useEffect(() => {
        setCheckedCategories(selectedCategories);
    }, [selectedCategories])


    const renderCategories = () => {
        if (!categories) return;
        return categories.map((cat, i) => {
            return <Category checkedCategories={checkedCategories} category={cat} index={cat._id} key={cat._id} handleCheckCategory={handleCheckCategory} handleSelectGroup={handleSelectGroup} selectedGroup={selectedGroup} handleSelectForEdit={handleSelectForEdit} handleSelectForDelete={handleSelectForDelete} />
        })
    }

    const renderSelectedGroup = () => {
        if (!selectedGroup) return <option>-</option>

        return <option>{selectedGroup.name}</option>
    }

    const handleSelectGroup = (category: any) => {
        if (selectedGroup === category) {
            setSelectedGroup(null);
        } else {
            setSelectedGroup(category);
        }
    }

    const handleSelectForEdit = (category: any) => {
        setGroupForEdit(category);
        setCategoryName(category.name);

        if (category.parent) {
            const breadcrumb = category.breadcrumb.split('->');
            let parent = {
                _id: category.parent,
                name: breadcrumb[breadcrumb.length - 2]
            }

            setSelectedGroup(parent);
        }
    }

    const handleCancelEdit = () => {
        setGroupForEdit(null);
        setCategoryName('');
        setSelectedGroup(null);
    }

    const handleSelectForDelete = (category: any) => {
        setGroupForDelete(category)
    }

    const handleDelete = () => {
        deleteCategory(groupForDelete._id);
        document.getElementById(`deleteCategoryRole`)?.click();
        setGroupForDelete(null);
    }

    const handleAddCategory = () => {
        interface CategoryData {
            name: string,
            parent?: string,
            breadcrumb?: string
        }

        if (categoryName && categoryName !== '') {
            let data: CategoryData = {
                name: ''
            }
            data.name = categoryName;

            if (selectedGroup) {
                data.parent = selectedGroup._id
            } else {
                delete data.parent;
                delete data.breadcrumb;
            }

            if (groupForEdit) {
                editCategory(data, groupForEdit._id);
                handleCancelEdit();
            } else {
                createCategory(data);
            }
            setSelectedGroup(null);
            setCategoryName('');
        }
    }

    const handleCheckCategory = (category: any) => {
        const i = _.findIndex(checkedCategories, (o: any) => {
            return o._id === category._id;
        })

        category.categoryId = category._id;

        if (i > -1) {
            setCheckedCategories(checkedCategories.filter((cat: any) => cat._id !== category._id));
        } else {
            setCheckedCategories([...checkedCategories, category]);
        }
    }

    const handleSetProductCategories = () => {
        setProductCategories(formik, 'product', 'product', checkedCategories);
        document.getElementById(`cat-modal`)?.click();
    }

    return (
        <div id="cat-modal" className="modal fade">
            <DeleteConfirmModal
                id={`deleteCategoryRole`}
                onDelete={() => handleDelete()}
            />
            <div className="modal-dialog">
                <div className="modal-content">

                    <div className="modal-header btn-wms">
                        <h4 className="modal-title text-xs-center text-white">Filtruj kategorie</h4>
                    </div>

                    <div className="modal-body">
                        <div className="d-flex align-items-stretch flex-wrap category-adder px-0 mt-3">

                            <div className="has-float-label">
                                <label >Kategorie</label>
                            </div>

                            <ul className="col-sm-12 px-0 pt-3 form-frame scrolling rounded text-left">

                                {renderCategories()}

                            </ul>
                            <div className="add-category-input col-sm-12 d-flex flex-wrap align-items-center py-2 px-0">
                                <div className="input-group col-sm-6 px-0">
                                    <span className="has-float-label">
                                        <input className="form-control" value={categoryName} id="new-cat" type="search" placeholder="Wpisz nazwę" onChange={(e) => setCategoryName(e.target.value)} />
                                        <label>Dodaj nową kategorię</label>
                                    </span> </div>
                                <div className="form-group col-sm-5 input-group my-0">
                                    <label className="form-group has-float-label my-0">
                                        <select className="selectpicker form-control custom-select" disabled title="Wybierz">
                                            {renderSelectedGroup()}
                                        </select>
                                        <span>Grupa nadrzędna</span>
                                    </label>
                                </div>
                                <div className="col-sm-1 px-0 add-btn-cat">
                                    <button className=" btn btn-wms btn-secondary px-0" onClick={() => handleAddCategory()}  ><img src={PlusIcon} alt="icon" className="mb-1 mx-0" /></button>
                                </div>
                            </div>

                            {groupForEdit &&
                                <div className="add-category-input col-sm-12">
                                    <button className="btn btn-light px-3" onClick={handleCancelEdit}>Anuluj edycję</button>
                                </div>
                            }

                        </div>
                    </div>
                    <div className="modal-footer text-xs-center">
                        <button className="btn btn-light px-3" data-dismiss="modal">Anuluj</button>
                        <button type="button" className="btn btn-primary btn-wms btn-save ml-1" onClick={() => handleSetProductCategories()} ><img src={SaveIcon} alt="icon" className="px-1 mb-1" />Zapisz</button>
                    </div>
                </div>

            </div>

        </div>
    )

}


export default CategoryModalRole