import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import UsersComponent from "../components/usersList.component";
import { getUsers, clearUsers, setFilters, clearFilters, setPage, setParams, clearParams } from '../actions/users.actions';
import { connect } from 'react-redux'
import { AppDispatch, RootState } from "../../config/store";
import { UsersPropsInterface, UsersStateInterface } from "../types/user.types";

const UsersContainer: FC<UsersPropsInterface> = (props: UsersPropsInterface) => {
    const { getUsers, clearUsers, setPage, page } = props;
    let params: any = useParams();

    useEffect(() => {
        setPage(parseInt(params.page));
        getUsers();
        return () => {
            clearUsers();
        }
    }, [getUsers, clearUsers, page, params.page, setPage])

    return <UsersComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    users: store.users.users,
    total: store.users.total,
    filters: store.users.filters,
    params: store.users.params,
    page: store.users.page,
    activeUsers: store.application.activeUsers
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getUsers: () => dispatch(getUsers()),
        clearUsers: () => dispatch(clearUsers()),
        setFilters: (filters: UsersStateInterface["filters"], reloadList?: boolean) => dispatch(setFilters(filters, reloadList)),
        clearFilters: (reloadList?: boolean) => dispatch(clearFilters(reloadList)),
        setPage: (page: number) => dispatch(setPage(page)),
        setParams: (params: UsersStateInterface["params"], reloadList?: boolean) => dispatch(setParams(params, reloadList)),
        clearParams: (reloadList?: boolean) => dispatch(clearParams(reloadList)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);