import * as Yup from 'yup';
import { InventoryInterface } from '../../common/types/common.types';

export const initialValues: InventoryInterface = {
    _id: "",
    name: "",
    kind: "",
    inventoryNumber: "",
    completed: false,
    warehouse: null,
    allowedToEdit: []
}

export const inventoryValidationSchema = Yup.object({
    _id: Yup.string(),
    name: Yup.string().required("Wartość jest wymagana"),
    kind: Yup.string().required("Wartość jest wymagana"),
    inventoryNumber: Yup.string(),
    completed: Yup.boolean(),
    warehouse: Yup.object().shape({
        warehouseId: Yup.string().required("Wartość jest wymagana"),
        name: Yup.string(),
        code: Yup.string()
    }).required("Wartość jest wymagana"),
    allowedToEdit: Yup.array().of(Yup.string())
})
