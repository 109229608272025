import React, { FC, useEffect } from "react";
import DictComponent from "../components/dicts.component";
import { getDict, saveDict, clearDict } from '../actions/dicts.actions';
import { connect } from 'react-redux'
import { AppDispatch, RootState } from "../../config/store";
import { DictInterface } from "../../common/types/common.types";

const DictsContainer: FC<any> = (props: any) => {
    const { getDict, clearDict } = props;
    useEffect(() => {
        getDict(1);
        return () => {
            clearDict();
        }
    }, [getDict, clearDict])

    return <DictComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    dict: store.dicts.dict
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getDict: (type: number) => dispatch(getDict(type)),
        saveDict: (data: DictInterface) => saveDict(data, dispatch),
        clearDict: () => dispatch(clearDict())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DictsContainer);