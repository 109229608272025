import { Nullable, GenericObject, GlobalSearchInterface } from "./common.types";
import { FC } from "react";
import { CategoryChildrenInterface } from "./category.types";
import { LoaderPropsInterface } from "../../application/types/application.types";

export interface TextInputComponentPropsInterface {
    error?: string,
    placeholder?: string,
    label: string,
    id: string,
    type?: string,
    disabled?: boolean,
    value?: string | number,
    defaultValue?: string | number,
    customClass?: string,
    debounce?: number,
    hideLabel?: boolean,
    info?: string;
    onChange: (id: string, text: string) => void
}

export interface FileInputComponentPropsInterface {
    error?: string,
    label?: string,
    id: string,
    type?: string,
    value?: FileList,
    defaultValue?: FileList,
    customClass?: string,
    multiple: boolean,
    onChange: (id: string, files: any) => void
}


export interface TextAreaComponentPropsInterface {
    error?: string,
    placeholder?: string,
    label?: string,
    id: string,
    disabled?: boolean,
    customClass?: string,
    value?: string,
    defaultValue?: string,
    debounce?: number,
    rows: number,
    onChange: (id: string, text: string) => void
}

export interface SelectComponentPropsInterface {
    error?: string,
    label: string,
    id: string,
    disabled?: boolean,
    value?: string | number,
    customClass?: string,
    onChange: (id: string, value: string) => void,
    options: Array<{ value: string, label: string, customClass?: string }>
}


export interface CheckboxComponentPropsInterface {
    label: string,
    id: string,
    disabled?: boolean,
    checked: boolean,
    customClass?: string,
    title?: string,
    onChange: (id: string, checked: boolean) => void
}

export interface ListComponentPropsInterface {
    id: string,
    items: Array<any>,
    itemsOnPage: number,
    total: number,
    ItemCard: FC<any>,
    currentPage: number,
    disableSort?: boolean,
    currentSort: Nullable<{ [fieldname: string]: number }>,
    columns: Array<{ key: string, label: string, disableSort?: boolean }>,
    onPageChange: (page: number) => void,
    onSortChange: (sortKey: string, sortValue: number) => void,
    route: string
}

export interface AutocompletePropsInterface {
    id: string,
    url: string,
    multi: boolean,
    placeholder: string,
    label: string,
    optionLabelField: string,
    optionIdField: string,
    onOptionSelect: (id: string, selected: any) => void,
    value: any,
    error?: string,
    queryFields: Array<string>,
    additionalParams: GenericObject,
    parseOption: (option: any) => void,
    hideChips: boolean,
    readonly: boolean,
    preload: boolean,
    customClass?: string
}

export interface DeleteConfirmModalPropsInterface {
    id: string,
    onDelete: () => void
}

export interface SelectComponentPropsInterface {
    error?: string,
    label: string,
    id: string,
    disabled?: boolean,
    value?: string | number,
    customClass?: string,
    onChange: (id: string, value: string) => void,
    options: Array<{ value: string, label: string, customClass?: string }>
}

export interface CropperComponentPropsInterface {
    id: string,
    defaultImage?: string,
    imageWidth: number,
    imageHeight: number,
    customClass?: string,
    onCrop: (id: string, file: Blob) => void
}
export interface CategoryInterface {
    id: string;
    _id: string;
    name: string;
    parent: string;
    description: string;
    breadcrumb: string;
    children: Array<CategoryChildrenInterface>
    checked: boolean;
    
}

export interface CategoriesModalPropsInterface {
    id: string;
    categories: Array<CategoryInterface>
}

export interface ChartComponentPropsInterface {
    id: string,
    title: string,
    type: string,
    data: any,
    labelMap: GenericObject,
    colorOffset?: number
}

export interface GlobalSearchStateInterface {
    searchedResults: GlobalSearchInterface;
    isLoading: boolean;
}

export interface GlobalSearchPropsInterface {
    showResults: boolean;
    searchedValue: string;
    results: GlobalSearchStateInterface["searchedResults"];
    isLoading: LoaderPropsInterface["isLoading"]
}

export enum GlobalSearchActionType {
    SET_SEARCHED_RESULTS = "GLOBALSEARCH_SET_SEARCHED_RESULTS",
    SET_IS_LOADING = 'GLOBALSEARCH_SET_IS_LOADING',
}