import { Formik } from 'formik';
import _ from "lodash";
import React, { FC, Fragment, useEffect, useState } from "react";
import TextInputComponent from "../../common/components/textInput.component";
import deleteIcon from "../../common/images/ico-delete.svg";
import addIcon from "../../common/images/ico-plus.svg";
import productTypesIcon from "../../common/images/product-types.svg";
import transportationIcon from "../../common/images/transportation.svg";
import unitsIcon from "../../common/images/units.svg";
import { dictValidationSchema, initialValues } from "../schemas/dicts.schema";
import { DictsPropsInterface } from "../types/dicts.types";
import DictsHeaderComponent from "./dictsHeaderComponent";

const DictsComponent: FC<DictsPropsInterface> = ({ dict, saveDict, getDict }: DictsPropsInterface) => {

    const [dictType, setDictType] = useState<any>(1);
    const [dictInitialValues, setDictInitialValues] = useState(initialValues);
    useEffect(() => {
        if (dict !== null && _.get(dict, "_id")) {
            setDictInitialValues(dict);
        }
    }, [dict]);

    const handleAddValue = (formik: any) => {
        let newValues = JSON.parse(JSON.stringify(formik.values.values));
        newValues.push({ name: "", value: "" });
        formik.setFieldValue("values", newValues);
    }

    const handleDeleteValue = (formik: any, index: number) => {
        let newValues = JSON.parse(JSON.stringify(formik.values.values));
        newValues.splice(index, 1);
        formik.setFieldValue("values", newValues);
    }

    const handleDictTypeChange = (type: number) => {
        if (type !== dictType) {
            setDictType(type);
            getDict(type);
        }
    }

    const renderValues = (formik: any) => {
        return formik.values.values.map((value: any, index: number) => {
            return (<div className="row" key={`value${index}`}>
                <div className="col-5">
                    <TextInputComponent
                        label="Nazwa"
                        type="text"
                        placeholder="Wpisz nazwę"
                        id={`values[${index}].name`}
                        onChange={formik.setFieldValue}
                        value={value.name}
                        error={_.get(formik, `errors.values[${index}].name`)}
                        disabled={value.readonly}
                    />
                </div>
                <div className="col-5">
                    <TextInputComponent
                        label="Wartość"
                        type="text"
                        placeholder="Wpisz wartość"
                        id={`values[${index}].value`}
                        onChange={formik.setFieldValue}
                        value={value.value.toString()}
                        error={_.get(formik, `errors.values[${index}].value`)}
                        disabled={value.readonly}
                    />
                </div>
                <div className="col-2 pt-3">
                    {!value.readonly && <button className="btn-wms rounded px-2" onClick={() => handleDeleteValue(formik, index)}><img src={deleteIcon} className="mx-1" alt="usuń"/></button>}
                </div>
            </div>);
        })
    }

    return <Fragment>
        <DictsHeaderComponent />
        <div id="content-container-right" className="col-md-12 settings col-sm-12 scrolling py-4 pl-4 pr-4">
            <div className="d-flex justify-content-stretch report-tiles mb-4">
                <div id="report-stan" className="flex-grow-1 report-tile-container active" onClick={() => handleDictTypeChange(1)}>
                    <button type="button" className="report-tile"><img src={productTypesIcon} alt="typy"/>
                        <h5>Typy produktów</h5>
                    </button>
                </div>
                <div id="report-stan-serie" className="flex-grow-1 report-tile-container" onClick={() => handleDictTypeChange(2)}>
                    <button type="button" className="report-tile"><img src={productTypesIcon} alt="rodzaje"/>
                        <h5>Rodzaje produktów</h5>
                    </button>
                </div>
                <div id="report-czasy" className="flex-grow-1 report-tile-container" onClick={() => handleDictTypeChange(3)}>
                    <button type="button" className="report-tile"><img src={unitsIcon} alt="jednostki"/>
                        <h5>Jednostki miar</h5>
                    </button>
                </div>
                <div id="report-gmp" className="flex-grow-1 report-tile-container" onClick={() => handleDictTypeChange(4)}>
                    <button type="button" className="report-tile"><img src={productTypesIcon} alt="źródła"/>
                        <h5>Źródła pochodzenia produktu</h5>
                    </button>
                </div>
                <div id="report-terminy" className="flex-grow-1 report-tile-container" onClick={() => handleDictTypeChange(5)}>
                    <button type="button" className="report-tile"><img src={transportationIcon} alt="transport"/>
                        <h5>Typy transportu</h5>
                    </button>
                </div>
            </div>
            <div className="bg-white rounded-10 p-1 settings-container">
                <div className="col-sm-12 report-tiles-mobile form-group input-group align-items-center mt-3 ">
                    <label className="form-group has-float-label d-flex justify-content-stretch">
                        <select className="selectpicker form-control custom-select " title="Wybierz ustawienia">
                            <option value="1">Typy produktów</option>
                            <option value="2">Rodzaje produktów</option>
                            <option value="3">Jednostki miar</option>
                            <option value="4">Źródła pochodzenia produktu</option>
                            <option value="5">Typy transportu</option>
                        </select>
                        <span>Wybierz ustawienia</span>
                    </label>
                </div>
                <Formik
                    initialValues={dictInitialValues}
                    validationSchema={dictValidationSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                    enableReinitialize={true}
                    onSubmit={(values, { setErrors }) => {
                        saveDict(values).then((res) => {
                            if (res.success) {

                            } else if (res.errors) {
                                setErrors(res.errors);
                            }
                        })
                    }}
                >
                    {formik =>
                    (
                        <form className="d-flex align-items-center justify-content-center" onSubmit={formik.handleSubmit}>
                            <div id="settings-form-container" className="col-md-8 col-sm-12 d-flex py-5 my-5 ">
                                <div className="col-12 d-flex align-items-center pr-3">
                                    <div className="col-12 py-2">
                                        {renderValues(formik)}
                                        <div className="input-group">

                                            <button className="btn btn-wms px-2 rounded" type="button" onClick={() => handleAddValue(formik)}><img src={addIcon} className="mx-1 " alt="dodaj"/></button>
                                            <br />

                                        </div>
                                        <div className="row">
                                            <div className="col-12 text-center">
                                                <button type="submit" className="btn-wms color-white rounded border-0 px-4 my-5 py-2">
                                                    Zapisz
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-12 py-2">
                                        <button type="submit" className="btn-wms color-white rounded border-0 px-4 my-5 py-2">
                                            Zapisz
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                        </form>)}
                </Formik>
            </div>
        </div>
    </Fragment>;
}

export default DictsComponent;