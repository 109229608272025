import * as Yup from 'yup';

export const initialValues = {
    _id: '',
    type: 0,
    column: '',
    row: '',
}

export const positionValidationSchema = Yup.object({
    _id: Yup.string(),
    type: Yup.number().min(1, "Wartość jest wymagana").required("Wartość jest wymagana"),
    column: Yup.string().required("Wartość jest wymagana"),
    row: Yup.string().required("Wartość jest wymagana"),
})
