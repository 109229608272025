import moment from 'moment';
import * as Yup from 'yup';
import { BarcodeInterface } from '../../common/types/common.types';

export const initialValues: BarcodeInterface = {
    _id: '',
    ean: '',
    seriesNr: '',
    image: '',
    name: '',
    unitsInPackage: 1,
    PAO: false,
    expirationDate: moment().format("YYYY-MM-DD"),
    expiresIn: 12
}

export const barcodeValidationSchema = Yup.object({
    _id: Yup.string(),
    ean: Yup.string().required("Wartość jest wymagana"),
    seriesNr: Yup.string().required("Wartość jest wymagana"),
    image: Yup.string(),
    name: Yup.string(),
    unitsInPackage: Yup.number(),
    PAO: Yup.boolean(),
    expirationDate: Yup.mixed().required("Wartość jest wymagana"),
    expiresIn: Yup.number()
})
