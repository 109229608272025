import { AppDispatch } from "../../config/store";
import Api from "../services/api.service";
import { isLoading } from "./application.actions"
import { GenericObject } from "../../common/types/common.types";

export const sendReport = async (values: GenericObject, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.post("/helpdesk/ticket", values, true, true);
        dispatch(isLoading(false))
        if (res.success) {
            return { success: true };
        } else if (res.errors) {
            return res;
        } else {
            return { success: false, errors: { message: "Brak autoryzacji" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        return { success: false, errors: { message: "Brak autoryzacji" } };
    }
}
