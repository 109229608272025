import { createStore, ThunkAction, Action } from '@reduxjs/toolkit';
import reducers from './reducers';
import { applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import SocketService from "../application/services/socket.service";
import { composeWithDevTools } from 'redux-devtools-extension';

export const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(thunk)
  )
)

export type GetState = typeof store.getState;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
SocketService.setDispatch(store.dispatch);

