import * as Yup from 'yup';
import { RoleInterface } from '../../common/types/common.types';

export const initialValues: RoleInterface = {
    _id: '',
    name: '',
    permissions: []
}

export const roleValidationSchema = Yup.object({
    _id: Yup.string(),
    name: Yup.string().required("Wartość jest wymagana"),
    permissions: Yup.array().of(Yup.object().shape({
        module: Yup.string().required("Moduł jest wymagany"),
        controller: Yup.string().required("Kontroler jest wymagany"),
        actions: Yup.array().of(Yup.string()),
        filters: Yup.object().nullable()
    }))
})
