import React, { FC, Fragment, useEffect, useState } from "react";
import _ from "lodash";
import { useHistory, useParams } from "react-router";
import Api from "../../application/services/api.service";
import ProductDetailsHeaderComponent from "./productDetailsHeader.component";
import { ProductPropsInterface } from "../types/product.types";
import DeleteConfirmModal from "../../common/components/deleteConfirmModal.component";
import DictService from "../../application/services/dict.service";

import tickIcon from "../../common/images/tick.svg";
import noImageIcon from "../../common/images/no-image.jpg";
import ProductWarehouseStateDetailsComponent from "./productWarehouseStateDetails.component";
import ProductWarehousePositionHistoryComponent from "./productWarehousePositionHistory.component";
import ProductDocumentReservationComponent from "./productDocumentReservation.component";

const ProductDetailsComponent: FC<ProductPropsInterface> = ({
  product,
  productWarehouseState,
  productWarehousePositionHistory,
  productDocumentReservations,
  page,
  params,
  filters,
  total,
  setPage,
  deleteProduct,
  clearFilters,
  setFilters,
}: ProductPropsInterface) => {
  let { id }: any = useParams();
  let history = useHistory();

  const [availableUnits, setAvailableUnits] = useState<any[]>([]);
  const [availableTypes, setAvailableTypes] = useState<any[]>([]);

  useEffect(() => {
    getProductDict(1);
    getProductDict(3);
    getProductDict(4);
  }, []);

  const getProductAvatarSrc = () => {
    if (product && product.avatar) {
      return Api.getUrl() + product.avatar;
    }
    return noImageIcon;
  };

  const getProductDict = (type: number) => {
    let dict = DictService.getDict(type)?.values || [];
    let documents = [{ label: "", value: "" }];

    if (dict.length > 0) {
      documents = dict.map(({ name: label, value }) => ({
        label,
        value: value.toString(),
      }));
    }

    if (type === 1) setAvailableTypes(documents);
    if (type === 3) setAvailableUnits(documents);
  };

  return (
    <Fragment>
      <DeleteConfirmModal
        id="product-delete"
        onDelete={() => {
          deleteProduct(id).then((res) => {
            if (res.success) {
              document.getElementById(`product-delete-cancel`)?.click();
              history.push("/products/page/1");
            }
          });
        }}
      />
      <ProductDetailsHeaderComponent />

      <div
        id="content-container-right"
        className="product-details-container col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2 "
      >
        <div className="bg-white rounded-10 boxshadow p-0">
          <div className="d-flex align-items-center justify-content-center">
            <div className="col-md-8 col-sm-12 d-flex flex-wrap py-5 my-5">
              <div className="col-sm-12 px-0 d-flex flex-wrap">
                <div className="col-md-4 col-sm-12 px-0">
                  <div className="product-image nooverflow pr-3 rounded text-center">
                    <img src={getProductAvatarSrc()} alt="" />
                  </div>

                  <div className="d-flex flex-row flex-row flex-wrap my-3 pr-3 specs-list-special">
                    <div className="badge badge-pill badge-light">
                      <img src={tickIcon} alt="" />
                      PAO
                    </div>
                    <div className="badge badge-pill badge-light">
                      <img src={tickIcon} alt="" />
                      Gratis{" "}
                    </div>
                    <div className="badge badge-pill badge-light">
                      <img src={tickIcon} alt="" />
                      GMP{" "}
                    </div>
                    <div className="badge badge-pill badge-light">
                      <img src={tickIcon} alt="" />
                      Usługa{" "}
                    </div>
                    <div className="badge badge-pill badge-light">
                      <img src={tickIcon} alt="" />
                      Surowiec{" "}
                    </div>
                    <div className="badge badge-pill badge-light">
                      <img src={tickIcon} alt="" />
                      Bez nr serii{" "}
                    </div>
                  </div>
                </div>

                <div className="col-sm-8 d-flex flex-wrap justify-content-center px-0">
                  <h2 className="text-center">{_.get(product, "name", "-")}</h2>

                  <div className="d-flex flex-wrap justify-content-center">
                    <div className="mx-3">
                      <h4 className="product-preview-codes">
                        <span>KATEGORIA:</span>
                        {_.get(product, "categories") && _.size(product.categories) > 0
                          ? product.categories.map((category: any, index: number) => {
                              if (index < _.size(product.categories) - 1)
                                return category.name + ", ";
                              else return category.name;
                            })
                          : "-"}
                      </h4>
                    </div>

                    <div className="mx-3">
                      <h4 className="product-preview-codes">
                        <span>EAN:</span>
                        {_.get(product, "ean", "")}
                      </h4>
                    </div>

                    <div className="mx-3">
                      <h4 className="product-preview-codes">
                        <span>KOD:</span> {_.get(product, "code", "")}
                      </h4>
                    </div>
                  </div>

                  <div className="col-sm-12 px-0">
                    <hr className="mt-0" />
                  </div>

                  <div className="specs-list-cont col-sm-12 px-0">
                    <div className="specs-list">
                      Typ
                      <span>
                        {product &&
                          product.type &&
                          _.size(availableTypes) > 0 &&
                          _.find(availableTypes, { value: product.type.toString() }) &&
                          _.find(availableTypes, { value: product.type.toString() }).label}
                      </span>
                    </div>
                    <div className="specs-list">
                      Termin ważności<span>{_.get(product, "expiresIn")} miesiecy</span>
                    </div>
                    <div className="specs-list">
                      Ilość opakowań jednostkowych w kartonie zbiorczym
                      <span>{_.get(product, "parameters.unitsInPackage")}/karton</span>
                    </div>
                    <div className="specs-list">
                      Ilość opakowań jednostkowych na palecie euro
                      <span>{_.get(product, "parameters.packagesOnEuroPallet")}/paleta</span>
                    </div>
                    <div className="specs-list">
                      Waga brutto pełnego kartonu
                      <span>{_.get(product, "parameters.packageWeight")}kg/karton</span>
                    </div>
                    <div className="specs-list">
                      Waga brutto jednej sztuki
                      <span>{_.get(product, "parameters.singleUnitWeight")}kg/opakowanie</span>
                    </div>
                  </div>

                  <div className="col-sm-12 px-0">
                    <hr className="mt-0" />
                  </div>

                  <div className="specs-list-cont col-sm-12 px-0">
                    <div className="specs-list">
                      Jednostka miary
                      <span>
                        {product &&
                          product.unit &&
                          _.size(availableUnits) > 0 &&
                          _.find(availableUnits, { value: product.unit.toString() }) &&
                          _.find(availableUnits, { value: product.unit.toString() }).label}
                      </span>
                    </div>
                    <div className="specs-list">
                      Wymiary
                      <div className="float-right d-flex size-specs">
                        <div id="width">{_.get(product, "parameters.width")}</div>✕
                        <div id="length">{_.get(product, "parameters.length")}</div>✕
                        <div id="height">{_.get(product, "parameters.height")}</div>
                        <div id="unit">m</div>
                      </div>
                    </div>

                    <div className="specs-list">
                      Objętość
                      <span>
                        {_.get(product, "parameters.totalVolume")} m<sup>3</sup>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 px-0 mx-0 my-4 tabs-container">
                  <ul className="nav nav-pills mb-3 mx-3" id="details-tab" role="tablist">
                    <li className="nav-item " role="presentation">
                      <a
                        className="nav-link active"
                        id="details-home-tab"
                        data-toggle="tab"
                        href="#tab1"
                        data-bs-toggle="pill"
                        data-bs-target="#tab1"
                        type="button"
                        role="tab"
                        aria-controls="details-1"
                        aria-selected="true"
                      >
                        Stan magazynowy
                      </a>
                    </li>

                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="details-profile-tab"
                        data-toggle="tab"
                        href="#tab2"
                        data-bs-toggle="pill"
                        data-bs-target="#tab2"
                        type="button"
                        role="tab"
                        aria-controls="details-2"
                        aria-selected="false"
                      >
                        Historia
                      </a>
                    </li>

                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="details-contact-tab"
                        data-toggle="tab"
                        href="#tab3"
                        data-bs-toggle="pill"
                        data-bs-target="#tab3"
                        type="button"
                        role="tab"
                        aria-controls="details-3"
                        aria-selected="false"
                      >
                        Rezerwacje
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="details-tabContent">
                    <ProductWarehouseStateDetailsComponent
                      productWarehouseState={productWarehouseState}
                      availableUnits={availableUnits}
                    />
                    <ProductWarehousePositionHistoryComponent
                      currentPage={page}
                      itemsOnPage={params.limit}
                      total={total}
                      items={productWarehousePositionHistory}
                      onPageChange={(newPage: number) => setPage(newPage)}
                      filters={filters}
                      onClearFilters={() => clearFilters(true)}
                      onFiltersChange={(filters: any) => setFilters(filters, true)}
                    />
                    <ProductDocumentReservationComponent items={productDocumentReservations} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductDetailsComponent;
