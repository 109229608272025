import React, { FC, useEffect, useState } from "react";
import { TopbarPropsInterface } from "../types/application.types";
import Acl from "../services/acl.service";
import { Link } from "react-router-dom";
import Api from "../services/api.service";
import blankAvatar from "../../common/images/ufo-user.jpg";
import arrowdownIcon from "../../common/images/ico-arrowdown.svg";
import shortcutIcon from "../../common/images/ico-skroty.svg";
import searchIcon from "../../common/images/ico-szukaj.svg";
import notificationIcon from "../../common/images/ico-powiadomienie.svg";
import GlobalSearchComponent from "../../common/components/globalSearch.component";
import ShortcutsComponent from "./shortcuts.component";

const TopbarComponent: FC<TopbarPropsInterface> = ({
  logout,
  user,
  socketConnection,
  searchedResults,
  isLoading,
  getSearchedResults,
}: TopbarPropsInterface) => {
  let amountDelayTimer: any;
  const [searchedValue, setSearchedValue] = useState("");
  const [showSearchedResults, setShowSearchedResults] = useState(false);

  useEffect(() => {
    if (searchedValue !== "") getSearchedResults(searchedValue);
  }, [searchedValue]);

  const handleChangeSearchedValue = (val: string) => {
    clearTimeout(amountDelayTimer);
    amountDelayTimer = setTimeout(function () {
      setSearchedValue(val);
    }, 500);
  };

  const getUserAvatarSrc = () => {
    if (user && user.avatar) {
      return Api.getUrl() + user.avatar;
    }
    return blankAvatar;
  };

  const handleBurgerMenuClick = () => {
    let menuEl = $('#burgermenu');
    if (menuEl) {
      menuEl.toggleClass('opened');
      menuEl.attr('aria-expanded', menuEl.hasClass('opened') ? "true" : "false")
      menuEl.toggleClass('bewhite');
    }
    $('#wmssidebar').toggleClass('burgertoggled');
  }

  return (
    <div className="container px-0 mx-0 mw100vw">
      <button className="burgermenu" onClick={handleBurgerMenuClick} aria-label="Main Menu" id="burgermenu">
        <svg width="50" height="50" viewBox="0 0 100 100">
          <path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
          <path className="line line2" d="M 20,50 H 80" />
          <path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
        </svg>
      </button>

      <div
        id="search-bar"
        className="row px-0 mx-0 d-flex align-items-center "
        onMouseLeave={() => setShowSearchedResults(false)}
      >
        <form
          onSubmit={(e) => e.preventDefault()}
          action=""
          id="search-form"
          className="d-flex flex-no-wrap align-items-center h-100 col-md-6 col-sm-12"
        >
          <div
            className="form-group has-feedback"
            onMouseEnter={() => setShowSearchedResults(true)}
          >
            <label htmlFor="search" className="sr-only">
              Szukaj
            </label>
            <input
              type="text"
              className="form-control"
              name="search"
              id="search"
              placeholder="Szukaj"
              onChange={(e) => handleChangeSearchedValue(e.target.value)}
            />
            <span className="form-control-feedback">
              <img src={searchIcon} alt="szukaj" />
            </span>
          </div>
        </form>
        <div className="col-md-6 col-sm-12 d-flex flex-nowrap align-items-center justify-content-end pr-4">
          <div className="profile-submenu d-flex h-100 align-items-center pr-1">
            <button
              className="btn rounded px-0"
              type="button"
              id="shortcut-btn"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={shortcutIcon} alt="skróty" />
            </button>
            <ShortcutsComponent />
            <div id="dingding-container" className="dropdown open">
              <button
                className="btn rounded px-0"
                type="button"
                id="notifications-btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img src={notificationIcon} alt="powiadomienia" />
              </button>
              <div className="dropdown-menu dropdown-menu-right animated fadeIn py-0 rounded-10">
                <h4 className="dropdown-header py-3">
                  <span className="count mr-3" id="notifications-count">
                    2
                  </span>{" "}
                  Powiadomienia
                </h4>
                <div className="dingding-list">
                  <div className="dingding new">
                    <img src={notificationIcon} className="notification-img" alt="powiadomienie" />{" "}
                    Powiadomienie lorem ipsum 0
                  </div>
                  <div className="dingding new">
                    <img src={blankAvatar} className="notification-img" alt="powiadomienie" />{" "}
                    Powiadomienie lorem ipsum 1
                  </div>
                  <div className="dingding">
                    <img src={blankAvatar} className="notification-img" alt="powiadomienie" />{" "}
                    Powiadomienie lorem ipsum 2
                  </div>
                  <div className="dingding">
                    <img src={notificationIcon} className="notification-img" alt="powiadomienie" />{" "}
                    Powiadomienie lorem ipsum 3
                  </div>
                  <div className="dingding">
                    <img src={notificationIcon} className="notification-img" alt="powiadomienie" />{" "}
                    Powiadomienie lorem ipsum 4
                  </div>
                </div>
              </div>
            </div>
            <div className={`avatar ${socketConnection ? "avatar-online" : "avatar-offline"}`}>
              <img id="user-avatar" src={getUserAvatarSrc()} alt="avatar" />
              <i />
            </div>
            <div className="btn-group">
              <button
                type="button"
                className="btn rounded px-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span id="user-name">{user ? user.name : "Użytkownik"}</span>
                <img src={arrowdownIcon} className="m-0" alt="down" />
              </button>
              <div className="dropdown-menu dropdown-menu-right animated fadeIn">
                <Link className="dropdown-item" type="button" to="/profile">
                  Profil
                </Link>
                {Acl.getIsAdmin() && (
                  <Link className="dropdown-item" type="button" to="/dicts">
                    Słowniki
                  </Link>
                )}
                {Acl.getIsAdmin() && (
                  <Link className="dropdown-item" type="button" to="/settings">
                    Ustawienia
                  </Link>
                )}
                <Link className="dropdown-item" type="button" to="/issueReport">
                  Zgłoś problem
                </Link>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item" type="button" onClick={logout}>
                  Wyloguj się
                </button>
              </div>
            </div>
          </div>
        </div>
        <GlobalSearchComponent
          showResults={showSearchedResults}
          results={searchedResults}
          isLoading={isLoading}
          searchedValue={searchedValue}
        />
      </div>
    </div>
  );
};

export default TopbarComponent;