import { ApplicationStateInterface, ApplicationActionType } from "../types/application.types";

const initialState: ApplicationStateInterface = {
    user: null,
    isLoading: false,
    socketConnection: false,
    activeUsers: []
}

const applicationReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case ApplicationActionType.SET_IS_LOADING: {
            return {
                ...state,
                isLoading: action.isLoading
            }
        }
        case ApplicationActionType.SET_USER: {
            return {
                ...state,
                user: action.user
            }
        }
        case ApplicationActionType.CLEAR_USER: {
            return {
                ...state,
                user: null
            }
        }
        case ApplicationActionType.SET_SOCKET_CONNECTION: {
            return {
                ...state,
                socketConnection: action.socketConnection
            }
        }
        case ApplicationActionType.SET_ACTIVE_USERS: {
            return {
                ...state,
                activeUsers: action.activeUsers
            }
        }
        default: return state;
    }
}

export default applicationReducer;