import React, { FC } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Acl from "../../application/services/acl.service";
import arrowdownIcon from "../../common/images/ico-arrowdown.svg";
import editIcon from "../../common/images/ico-edit-full.svg";
import deleteIcon from "../../common/images/ico-xred.svg";
// import passwordIcon from "../../common/images/ico-pass.svg";
// import deactivateIcon from "../../common/images/ico-deactivate.svg";
// import activateIcon from "../../common/images/ico-activate.svg";

const UserDetailsHeaderComponent: FC = () => {
    let history = useHistory();
    const { id }: any = useParams();

    return (<div className="row px-0 mx-0 title-bar d-flex flex-nowrap justify-content-between align-items-center">
        <div className="col-sm-12 d-flex flex-no-wrap px-4 justify-content-between align-items-center">
            <div className="page-title d-flex flex-nowrap align-items-center">
                <div className="step-back mr-3 p-2" onClick={() => history.goBack()}><img src={arrowdownIcon} alt="Wstecz" /></div>
                <h2 id="page-title" className="m-0">Szczegóły użytkownika</h2>
            </div>
            {Acl.getIsAdmin() && <div className="d-flex flex-no-wrap  justify-content-end align-items-center">
                {/* <button type="button" className="btn btn-expand wider btn-light ml-2" data-toggle="modal"><img src={passwordIcon} alt="Hasło"/><div>Zmień hasło</div></button> */}
                <Link type="button" className="btn btn-expand btn-light ml-2" to={`/user/${id}`}><img src={editIcon} alt="Edytuj" /><div>Edytuj</div></Link>
                {/* <button type="button" className="btn btn-expand wider btn-light ml-2"><img src={deactivateIcon} alt="Deaktywuj"/><div>Deaktywuj</div></button>
                <button type="button" className="btn btn-expand btn-light ml-2"><img src={activateIcon} alt="Aktywuj"/><div>Aktywuj</div></button> */}
                <a type="button" className="btn btn-expand btn-light ml-2" data-toggle="modal" href="#user-delete"><img src={deleteIcon} alt="Usuń" /><div>Usuń</div></a>
            </div>}
        </div>
    </div>);
}

export default UserDetailsHeaderComponent;