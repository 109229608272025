import * as Yup from 'yup';

export const initialValues = {
    login: '',
    password: '',
    message: null
}

export const loginValidationSchema = Yup.object({
    login: Yup.string().required('Wpisz login'),
    password: Yup.string().required('Wpisz hasło'),
    message: Yup.string().nullable()
})
