const template = `<html>

<head>
    <title>
        <%= documentNumber %>
    </title>
    <style>
        * {
            font-family: Arial, sans-serif;
            font-size: 12px;
            line-break: normal;
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;
            border-color: #dee2e6;
        }

        th,
        td {
            border-color: #303030;
            border-style: solid;
            border-width: 1px;
            overflow: hidden;
            text-align: center;
            vertical-align: middle;
            padding: 10px 5px;
            font-weight: 400;
        }
        .divider {
            padding-top: 20px;  
            border: none;
        }
        .footer {
            margin-top: 10rem;
            text-align: center;
        }
        .footer-left {
            width: 25%;
            float: left;
        }
        .footer-right {
            width: 25%;
            float: right;
        }
        .stamp-area {
            padding: 70px 5px 5px 5px;
        }
    </style>
</head>

<body>
    <table>
        <thead>
            <tr>
                <th colspan="3" rowspan="2" class="stamp-area">Pieczęć firmy</th>
                <th colspan="4" rowspan="2">
                    <%= documentNumber %><br>Numer dokumentu
                </th>
                <th colspan="3" rowspan="2">
                    <%= currentDate %><br>Data wystawienia dokumentu.
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th colspan="4">
                    <%= warehouse %>
                    <br>Magazyn
                </th>
                <th colspan="6">
                    <%= contractor %>
                    <br>Odbiorca
                </th>
            </tr>
            <tr>
                <td class="divider" colspan="10"></td>
            </tr>
            <tr class="products-header">
                <td>L.p.</td>
                <td colspan="3">Towar / usługa</td>
                <td colspan="2">Seria</td>
                <td colspan="2">Data ważności</td>
                <td>Ilość</td>
                <td> J.m.</td>
            </tr>

            <products> 

        </tbody>
    </table>

    <div class="footer">
        <div class="footer-left">
            <hr />
            <p>
                Imię, nazwisko i podpis osoby upoważnionej do odebrania dokumentu
            </p>
        </div>
        <div class="footer-right">
            <hr />
            <p>
                Wystawił(a)
            </p>
        </div>
    </div>
</body>

</html>`

export default template;