import { Formik } from 'formik';
import _ from "lodash";
import React, { FC, Fragment, useEffect, useState } from "react";
import CropperComponent from "../../common/components/cropper.component";
import TextInputComponent from "../../common/components/textInput.component";
import usersIcon from "../../common/images/ico-uzytkownicy.svg";
import { initialValues, profileValidationSchema } from "../schemas/profile.schema";
import { ProfilePropsInterface } from "../types/profile.types";
import ProfileHeaderComponent from "./profileHeader.component";

const ProfileComponent: FC<ProfilePropsInterface> = ({ profile, saveProfile }: ProfilePropsInterface) => {

    const [profileInitialValues, setProfileInitialValues] = useState(initialValues);
    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        if (profile !== null && _.get(profile, "_id")) {
            setProfileInitialValues(Object.assign({}, initialValues, profile));
        }
    }, [profile]);

    return (<Fragment>
        <ProfileHeaderComponent name={_.get(profile, "name", "użytkowniku")} />
        <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-4">
            <div className="bg-white rounded-10 p-1">
                <div className="d-flex align-items-center justify-content-center">

                    <Formik
                        initialValues={profileInitialValues}
                        validationSchema={profileValidationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize={true}
                        onSubmit={(values, { setErrors }) => {
                            saveProfile(Object.assign({}, values, { avatar: avatar })).then((res) => {
                                if (res.success) {

                                } else if (res.errors) {
                                    setErrors(res.errors);
                                }
                            })
                        }}
                    >{formik => (
                        <form className="profile-container col-md-10 col-sm-12 py-5 my-5 d-flex flex-wrap" onSubmit={formik.handleSubmit}>
                            {/* {JSON.stringify(formik.errors)} */}
                            <CropperComponent
                                defaultImage={_.get(profile, "avatar")}
                                imageHeight={100}
                                imageWidth={100}
                                id="avatar"
                                onCrop={(id: string, file: any) => setAvatar(file)}
                                customClass="col-md-3 col-sm-12 h-fit user-photo-cont my-5 mr-5 ml-5 p-0"
                            />
                            <div className="col-sm-8 pr-0 d-flex flex-wrap ml-5">
                                <TextInputComponent
                                    label="Nazwa użytkownika"
                                    type="text"
                                    placeholder="Imię i Nazwisko"
                                    id="name"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.name || ""}
                                    error={formik.errors.name?.toString()}
                                    customClass="form-group input-group d-flex flex-wrap"
                                />
                                <TextInputComponent
                                    label="Adres e-mail"
                                    type="text"
                                    placeholder="Wpisz adres e-mail"
                                    id="email"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.email || ""}
                                    error={formik.errors.email?.toString()}
                                    customClass="form-group input-group col-6 px-0 mx-0"
                                />
                                <TextInputComponent
                                    label="Telefon kontaktowy"
                                    type="text"
                                    placeholder="Wpisz numer telefonu"
                                    id="phone"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.phone || ""}
                                    error={formik.errors.phone?.toString()}
                                    customClass="form-group input-group col-6 pr-md-0 pr-0 pl-3 mx-0"
                                />
                                <TextInputComponent
                                    label="Login"
                                    type="text"
                                    placeholder="Wpisz login"
                                    id="login"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.login || ""}
                                    error={formik.errors.login?.toString()}
                                    customClass="form-group input-group col-6 px-0 mx-0"
                                />
                                <TextInputComponent
                                    label="Rola"
                                    type="text"
                                    placeholder="Szukaj roli"
                                    id="role"
                                    onChange={() => null}
                                    value={_.get(formik.values, "role.name", "")}
                                    error={formik.errors.role?.toString()}
                                    customClass="form-group input-group readonly col-6 pr-0 pl-3"
                                    disabled={true}
                                />
                                <div className="col-12 px-0 mx-0">
                                    <h3 className="mb-4">Zmiana hasła</h3>
                                </div>
                                <TextInputComponent
                                    label="Stare hasło"
                                    type="text"
                                    placeholder="Wpisz hasło"
                                    id="oldPassword"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.oldPassword || ""}
                                    error={formik.errors.oldPassword?.toString()}
                                    customClass="form-group input-group col-md-4 col-sm-12 px-0 mx-0"
                                />
                                <TextInputComponent
                                    label="Nowe hasło"
                                    type="text"
                                    placeholder="Wpisz hasło"
                                    id="newPassword"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.newPassword || ""}
                                    error={formik.errors.newPassword?.toString()}
                                    customClass="form-group input-group col-md-4 col-sm-12 px-3 mx-0"
                                />
                                <TextInputComponent
                                    label="Powtórz hasło"
                                    type="text"
                                    placeholder="Wpisz hasło"
                                    id="repeatPassword"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.repeatPassword || ""}
                                    error={formik.errors.repeatPassword?.toString()}
                                    customClass="form-group input-group col-md-4 col-sm-12 px-0 mx-0"
                                />
                            </div>
                            <div className="col-sm-12 text-center">
                                <button type="submit" className="btn-wms color-white rounded border-0 px-4 my-5 py-2"><img src={usersIcon} alt="zapisz" /> Zapisz</button>
                            </div>
                        </form>)}
                    </Formik>
                </div>
            </div>
        </div>
    </Fragment>);
}

export default ProfileComponent;