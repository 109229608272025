import { setSocketConnection, setActiveUsers } from "../actions/socket.actions";
import { Nullable } from "../../common/types/common.types";
import { AppDispatch } from "../../config/store";
import Api from "./api.service";
import { io } from "socket.io-client";
import _ from "lodash";

interface SocketService {
    socket: Nullable<ReturnType<typeof io>>,
    dispatch: AppDispatch,
    activeWarehouse?: string
}

class SocketService {

    constructor() {
        this.socket = io(Api.getUrl());

        this.socket.on("connect", () => {
            this.dispatch(setSocketConnection(true));
        });

        this.socket.on("disconnect", () => {
            this.dispatch(setSocketConnection(false));
        });

        this.socket.on("userList", (data) => {
            if (_.isArray(data)) {
                this.dispatch(setActiveUsers(data));
            }
        })

    }

    setDispatch(dispatch: AppDispatch) {
        this.dispatch = dispatch;
    }

    emit(eventName: string, data: any) {
        this.socket?.emit(eventName, data);
    }

    setActiveWarehouse(activeWarehouse: string) {
        this.activeWarehouse = activeWarehouse;
    }

}

export default new SocketService();