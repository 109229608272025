import _ from "lodash"
import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import apiService from "../../application/services/api.service"
import { GenericObject, UserInterface } from "../../common/types/common.types"
import { AppDispatch, GetState } from "../../config/store"
import { UsersActionType, UsersStateInterface } from "../types/user.types"

export const getUsers = (hideLoader?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        const { users: { filters, params, page } } = getState();
        if (!hideLoader) {
            dispatch(isLoading(true));
        }

        let sendParams: GenericObject = {
            limit: params.limit,
            offset: (page - 1) * params.limit
        }

        if (_.isObject(params.sort) && !_.isEmpty(params.sort)) {
            let sortKey: string = _.keys(params.sort)[0];
            let sortValue: number = _.values(params.sort)[0];
            sendParams[`sort[${sortKey}]`] = sortValue;
        }
        if (filters.name) {
            sendParams.name = filters.name;
        }
        if (filters.warehouse) {
            sendParams["warehouse.name"] = filters.warehouse;
        }
        if (filters.role) {
            sendParams["role.name"] = filters.role;
        }
        if (filters.active) {
            sendParams.active = filters.active;
        }

        let res = await apiService.get("/application/user", sendParams);
        if (res.success) {
            dispatch(setUsers(res.documents, res.total));
        }
        dispatch(isLoading(false));
    } catch (err) {
        dispatch(isLoading(false));
    }
}

export const setUsers = (users: Array<UserInterface>, total: number): AnyAction => {
    return {
        type: UsersActionType.SET_USERS,
        users,
        total
    }
}

export const clearUsers = (): AnyAction => {
    return {
        type: UsersActionType.CLEAR_USERS
    }
}

export const setFilters = (filters: UsersStateInterface["filters"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: UsersActionType.SET_FILTERS,
        filters
    })
    if (reloadList) {
        dispatch(getUsers(true));
    }
}

export const clearFilters = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: UsersActionType.CLEAR_FILTERS
    });
    if (reloadList) {
        dispatch(getUsers(true));
    }
}

export const setParams = (params: UsersStateInterface["params"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: UsersActionType.SET_PARAMS,
        params
    });
    if (reloadList) {
        dispatch(getUsers(true));
    }
}

export const clearParams = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: UsersActionType.CLEAR_PARAMS
    })
    if (reloadList) {
        dispatch(getUsers(true));
    }
}

export const setPage = (page: number): AnyAction => {
    if (page <= 0) page = 1;
    return {
        type: UsersActionType.SET_PAGE,
        page
    }
}