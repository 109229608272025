import { CategoryStateInterface, CategoryActionType } from "../types/category.types";

const initialState: CategoryStateInterface = {
  categories: [],
};

const categoryReducer = (state = initialState, action: any) => {
  const { type } = action;

  switch (type) {
    case CategoryActionType.SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories,
      };
    default:
      return state;
  }
};

export default categoryReducer;
