import { AnyAction } from "redux";
import { AnyDateFormat, AsyncThunkAction, GenericObject, Nullable, ZpDocumentInterface } from "../../common/types/common.types";

export enum ZpListActionType {
    SET_ZPS = 'ZPS_SET_ZPS',
    CLEAR_ZPS = 'ZPS_CLEAR_ZPS',
    SET_FILTERS = 'ZPS_SET_FILTERS',
    CLEAR_FILTERS = 'ZPS_CLEAR_FILTERS',
    SET_PARAMS = 'ZPS_SET_PARAMS',
    CLEAR_PARAMS = 'ZPS_CLEAR_PARAMS',
    SET_PAGE = `ZPS_SET_PAGE`
}

export enum ZpActionType {
    SET_ZP = 'ZP_SET_ZP',
    CLEAR_ZP = 'ZP_CLEAR_ZP'
}

export interface ZpListStateInterface {
    documents: Array<ZpDocumentInterface>,
    total: number,
    filters: {
        positionFrom: string,
        positionTo: string,
        creator: string,
        product: string,
        dateFrom: AnyDateFormat,
        dateTo: AnyDateFormat
    },
    params: {
        limit: number,
        offset: number,
        sort: Nullable<{ [fieldname: string]: number }>
    },
    page: number
}

export interface ZpStateInterface {
    zp: Nullable<ZpDocumentInterface>
}

export interface ZpListPropsInterface {
    documents: ZpListStateInterface["documents"],
    total: ZpListStateInterface["total"],
    filters: ZpListStateInterface["filters"],
    params: ZpListStateInterface["params"],
    page: number,
    getZps: () => AsyncThunkAction,
    clearZps: () => AnyAction,
    setFilters: (filters: ZpListStateInterface["filters"], reloadList?: boolean) => AsyncThunkAction,
    clearFilters: (reloadList?: boolean) => AsyncThunkAction,
    setParams: (params: ZpListStateInterface["params"], reloadList?: boolean) => AsyncThunkAction,
    clearParams: (reloadList?: boolean) => AsyncThunkAction,
    setPage: (page: number) => AnyAction
}

export interface ZpListHeaderPropsInterface {
    onFiltersChange: (filters: ZpListStateInterface["filters"]) => void,
    onClearFilters: () => void,
    filters: any
}

export interface ZpListCardPropsInterface {
    item: ZpDocumentInterface
}

export interface ZpsFiltersPropsInterface {
    onFiltersChange: (filters: ZpListStateInterface["filters"]) => void,
    onClearFilters: () => void,
    filters: any
}

export interface ZpDetailsPropsInterface {
    zp: ZpStateInterface["zp"],
    getZp: (id: string) => AsyncThunkAction,
    clearZp: () => AnyAction,
    deleteZp: (id: string) => Promise<any>
}

export interface ZpDetailsProductTablePropsInterface {
    items: Array<GenericObject>
}