import moment from "moment";
import { DocumentsStateInterface, DocumentsActionType } from "../types/document.types";

const initialState: DocumentsStateInterface = {
    documents: [],
    total: 0,
    filters: {
        completionStatus: "",
        inspectionStatus: "",
        warehouse: [],
        relatedWarehouse: [],
        type: "",
        transportType: "",
        product: "",
        customer: "",
        completionUser: "",
        inspectionUser: "",
        dateFrom: moment().subtract(2, "weeks").startOf("week").format("MM/DD/YYYY"),
        dateTo: moment().format("MM/DD/YYYY")
    },
    params: {
        limit: 20,
        offset: 0,
        sort: null
    },
    page: 1
}

const documentsReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case DocumentsActionType.SET_DOCUMENTS: {
            return {
                ...state,
                documents: action.documents,
                total: action.total
            }
        }
        case DocumentsActionType.CLEAR_DOCUMENTS: {
            return {
                ...state,
                documents: [],
                total: 0
            }
        }
        case DocumentsActionType.SET_FILTERS: {
            return {
                ...state,
                filters: action.filters
            }
        }
        case DocumentsActionType.CLEAR_FILTERS: {
            return {
                ...state,
                filters: {
                    completionStatus: "",
                    inspectionStatus: "",
                    warehouse: [],
                    relatedWarehouse: [],
                    type: "",
                    transportType: "",
                    product: "",
                    customer: "",
                    completionUser: "",
                    inspectionUser: "",
                    dateFrom: moment().subtract(2, "weeks").startOf("week").format("MM/DD/YYYY"),
                    dateTo: moment().format("MM/DD/YYYY")
                }
            }
        }
        case DocumentsActionType.SET_PARAMS: {
            return {
                ...state,
                params: action.params
            }
        }
        case DocumentsActionType.CLEAR_PARAMS: {
            return {
                ...state,
                params: {
                    limit: 20,
                    offset: 0,
                    sort: null
                }
            }
        }
        case DocumentsActionType.SET_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        default: return state;
    }
}

export default documentsReducer;