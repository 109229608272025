import { FC, Fragment, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { WarehousePropsInterface } from "../types/warehouse.types";
import { getWarehouse, clearWarehouse, saveWarehouse } from "../actions/warehouse.actions";
import { AppDispatch, RootState } from "../../config/store";
import { connect } from 'react-redux'
import { GenericObject } from "../../common/types/common.types";

import WarehouseComponent from '../components/Warehouse.component';

import arrowdownIcon from "../../common/images/ico-arrowdown.svg";

const WarehouseContainer: FC<WarehousePropsInterface> = (props: WarehousePropsInterface) => {
    const { getWarehouse, clearWarehouse } = props;
    const { id }: any = useParams();
    const history = useHistory();

    useEffect(() => {
        if (id) {
            getWarehouse(id);
        }
        return () => {
            clearWarehouse();
        }
    }, [getWarehouse, clearWarehouse, id])


    return (
        <Fragment>
            <div className="row px-0 mx-0 title-bar d-flex flex-nowrap justify-content-between align-items-center">
                <div className="col-sm-12 d-flex flex-no-wrap px-4 justify-content-between align-items-center">
                    <div className="page-title d-flex flex-nowrap align-items-center">
                        <div className="step-back mr-3 p-2" onClick={() => history.goBack()}><img src={arrowdownIcon} alt="Wstecz" /></div><h2 id="page-title" className="m-0">Dodaj nowy magazyn</h2>
                    </div>
                </div>
            </div>

            <WarehouseComponent {...props} />
        </Fragment>
    )
}

const mapStateToProps = (store: RootState) => ({
    warehouse: store.warehouse.warehouse
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getWarehouse: (id: string) => dispatch(getWarehouse(id)),
        clearWarehouse: () => dispatch(clearWarehouse()),
        saveWarehouse: (data: GenericObject) => saveWarehouse(data, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseContainer);