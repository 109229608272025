import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { GenericObject, UserInterface } from "../../common/types/common.types"
import { AppDispatch } from "../../config/store"
import { UserActionType } from "../types/user.types"
import { toast } from "react-toastify"

export const getUser = (id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get(`/application/user/${id}`);
        if (res.success) {
            dispatch(setUser(res.document));
        } else {
            toast.error("Błąd pobierania użytkownika");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania użytkownika");
        dispatch(isLoading(false));
    }
}

export const deleteUser = async (id: string, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.delete(`/application/user/${id}`);
        dispatch(isLoading(false));
        return res
    } catch (err) {
        toast.error("Błąd usuwania użytkownika");
        dispatch(isLoading(false));
    }
}

export const saveUser = async (data: GenericObject, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = null;
        if (data._id) {
            res = await Api.put(`/application/user/${data._id}`, data, true);
        } else {
            res = await Api.post("/application/user", data, true);
        }
        dispatch(isLoading(false));

        if (res.success) {
            toast.success("Zapisano użytkownika");
            return res;
        } else if (res.errors) {
            toast.error("Błąd zapisu użytkownika");
            return res;
        } else {
            toast.error("Błąd zapisu użytkownika");
            return { success: false, errors: { message: "Błąd zapisu" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd zapisu użytkownika");
        return { success: false, errors: { message: "Błąd zapisu" } };
    }
}

export const setUser = (user: UserInterface): AnyAction => {
    return {
        type: UserActionType.SET_USER,
        user
    }
}

export const clearUser = (): AnyAction => {
    return {
        type: UserActionType.CLEAR_USER
    }
}