import _ from "lodash";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { InventoriesListCardPropsInterface } from "../types/inventory.types";
import moment from "moment";

const InventoriesListCardComponent: FC<InventoriesListCardPropsInterface> = ({ item }: InventoriesListCardPropsInterface) => {

    let history = useHistory();
    let percentCompleted = _.get(item, "statistics.percentCompleted", 0) || 0;
    return (<tr onClick={() => history.push(`/inventories/${item._id}`)}>
        <td className="col-md-2">
            <h3 className="mb-0"><span>{moment(item.createdAt).format("HH:mm")}</span>{moment(item.createdAt).format("DD.MM.YYYY")}</h3>
        </td>
        <th className="col-md-2" scope="row">{_.get(item, "name", "-")}</th>
        <td className="col-md-2">{_.get(item, "inventoryNumber", "-")}</td>
        <td className="col-md-2">{_.get(item, "warehouse.name", "-")}</td>
        <td className="col-md-4">
            <div className="progress-percentage">{item.completed ? "Zakończona" : `${percentCompleted}%`}</div>
            {!item.completed && <div className="progress mt-1">
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow={percentCompleted} aria-valuemin={0} aria-valuemax={100} style={{ width: `${percentCompleted}%` }}></div>
            </div>}
        </td>
    </tr>);
}

export default InventoriesListCardComponent;