import React, { FC } from "react";
import Acl from "../../application/services/acl.service";
import { Link } from "react-router-dom";
import addIcon from "../../common/images/ico-plus.svg";

const ProductsHeaderComponent: FC = () => {
  return (
    <div className="row px-0 mx-0 title-bar d-flex flex-nowrap justify-content-between align-items-center">
      <div className="col-md-12 col-sm-12 d-flex flex-no-wrap px-4">
        <div className="col-md-6 col-sm-12 d-flex flex-nowrap px-0 align-items-center">
          <h2 id="page-title" className="m-0">
            Produkty
          </h2>
        </div>
        <div className="col-md-6 px-0 d-flex flex-no-wrap justify-content-end align-items-center">
          {Acl.getIsAdmin() && (
            <Link
              type="button"
              className="btn btn-primary btn-expand wider2 btn-wms ml-4"
              to="/product"
            >
              <img src={addIcon} alt="dodaj" />
              <div>Dodaj&nbsp;produkt</div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsHeaderComponent;
