import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import ProductComponent from "../components/product.component";
// import { getProduct, clearProduct, saveUser } from "../actions/product.actions";
import { AppDispatch, RootState } from "../../config/store";
import { connect } from "react-redux";
import { ProductPropsInterface } from "../types/product.types";

import { saveProduct, getProduct, clearProduct } from "../actions/product.actions";
import { getCategories } from "../../common/actions/category.actions";
import { GenericObject } from "../../common/types/common.types";

const ProductContainer: FC<ProductPropsInterface> = (props: ProductPropsInterface) => {
  const { getCategories, getProduct, clearProduct } = props;
  let { id }: any = useParams();

  useEffect(() => {
    getCategories("product");
    if (id) {
      getProduct(id);
    }
    return () => {
      clearProduct();
    };
  }, [getCategories, getProduct, clearProduct, id]);

  return <ProductComponent {...props} />;
};

const mapStateToProps = (store: RootState) => ({
  product: store.product.product,
  categories: store.category.categories,
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
  return {
    saveProduct: (data: GenericObject) => saveProduct(data, dispatch),
    getProduct: (id: string) => dispatch(getProduct(id)),
    clearProduct: () => dispatch(clearProduct()),
    getCategories: (content: string) => dispatch(getCategories(content)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
