import React, { FC } from "react";
import { connect } from 'react-redux'
import { RootState } from "../../config/store";
import { LoaderPropsInterface } from "../types/application.types";
import loaderIcon from "../../common/images/logo-wms-color-loader.svg";

const mapStateToProps = (state: RootState) => ({
    isLoading: state.application.isLoading,
})

const LoaderContainer: FC<LoaderPropsInterface> = ({ isLoading }: LoaderPropsInterface) => {
    return isLoading ? <div id="preloader-overlay"><img src={loaderIcon} alt="loader" /></div> : null;
}

export default connect(mapStateToProps)(LoaderContainer);