import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import GeneratorComponent from "../components/generator.component";
import { getBarcodes, clearBarcodes, setFilters, clearFilters, setPage, setParams, clearParams } from '../actions/generator.actions';
import { connect } from 'react-redux'
import { AppDispatch, RootState } from "../../config/store";
import { GeneratorPropsInterface, GeneratorStateInterface } from "../types/generator.types";

const GeneratorContainer: FC<GeneratorPropsInterface> = (props: GeneratorPropsInterface) => {
    const { getBarcodes, clearBarcodes, setPage, page } = props;
    let params: any = useParams();

    useEffect(() => {
        setPage(parseInt(params.page));
        getBarcodes();
        return () => {
            clearBarcodes();
        }
    }, [getBarcodes, clearBarcodes, page, params.page, setPage])

    return <GeneratorComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    barcodes: store.generator.barcodes,
    total: store.generator.total,
    filters: store.generator.filters,
    params: store.generator.params,
    page: store.generator.page
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getBarcodes: () => dispatch(getBarcodes()),
        clearBarcodes: () => dispatch(clearBarcodes()),
        setFilters: (filters: GeneratorStateInterface["filters"], reloadList?: boolean) => dispatch(setFilters(filters, reloadList)),
        clearFilters: (reloadList?: boolean) => dispatch(clearFilters(reloadList)),
        setPage: (page: number) => dispatch(setPage(page)),
        setParams: (params: GeneratorStateInterface["params"], reloadList?: boolean) => dispatch(setParams(params, reloadList)),
        clearParams: (reloadList?: boolean) => dispatch(clearParams(reloadList)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneratorContainer);