import { AnyAction } from "redux";
import { AsyncThunkAction, Nullable, GenericObject } from "../../common/types/common.types";
import { PositionInterface } from './position.types';

export enum WarehouseActionType {
    SET_WAREHOUSES = 'WAREHOUSE_SET_WAREHOUSES',
    CLEAR_WAREHOUSES = 'WAREHOUSE_CLEAR_WAREHOUSES',
    SET_FILTERS = 'WAREHOUSE_SET_FILTERS',
    CLEAR_FILTERS = 'WAREHOUSE_CLEAR_FILTERS',
    SET_PARAMS = 'WAREHOUSE_SET_PARAMS',
    CLEAR_PARAMS = 'WAREHOUSE_CLEAR_PARAMS',
    SET_PAGE = 'WAREHOUSE_SET_PAGE',
    SET_WAREHOUSE = 'WAREHOUSE_SET_WAREHOUSE',
    CLEAR_WAREHOUSE = 'WAREHOUSE_CLEAR_WAREHOUSE',
    SET_WAREHOUSE_POSITIONS = 'WAREHOUSE_SET_WAREHOUSE_POSITIONS',
    CLEAR_WAREHOUSE_POSITIONS = 'WAREHOUSE_CLEAR_WAREHOUSE_POSITIONS',
}

export interface WarehouseInterface {
    code: string,
    name: string,
    _id?: string,
    createdAt?: string,
    updatedAt?: string,
    positionsCount?: number
}

export interface WarehouseStateInterface {
    warehouses: Array<WarehouseInterface>
    total: number,
    filters: {
        name: string,
        code: string,
    },
    params: {
        limit: number,
        offset: number,
        sort: Nullable<{ [fieldname: string]: number }>
    },
    page: number,
    warehouse: Nullable<WarehouseInterface>,
    positions: Array<any>,
    positionTotal: number,
    positionFilters: {
        products: string,
        code: string,
    },
    positionParams: {
        limit: number,
        offset: number,
        sort: Nullable<{ [fieldname: string]: number }>
    },
    positionPage: number,
    position: Nullable<PositionInterface>,
    positionHistory: Nullable<GenericObject>
    positionHistoryTotal: number,
    // positionHistoryFilters: {
    //     products: string,
    //     code: string,
    // },
    positionHistoryParams: {
        limit: number,
        offset: number,
        sort: Nullable<{ [fieldname: string]: number }>
    },
    positionHistoryPage: number,

}

export interface WarehousesPropsInterface {
    warehouses: WarehouseStateInterface["warehouses"],
    total: WarehouseStateInterface["total"],
    filters: WarehouseStateInterface["filters"],
    params: WarehouseStateInterface["params"],
    page: WarehouseStateInterface["page"],
    getWarehouses: (hideLoader?: boolean) => AsyncThunkAction,
    clearWarehouses: () => AnyAction,
    setFilters: (filters: WarehouseStateInterface["filters"], reloadList?: boolean) => AsyncThunkAction,
    clearFilters: (reloadList?: boolean) => AsyncThunkAction,
    setParams: (params: WarehouseStateInterface["params"], reloadList?: boolean) => AsyncThunkAction,
    clearParams: (reloadList?: boolean) => AsyncThunkAction,
    setPage: (page: number) => AnyAction
}

export interface WarehouseListCardPropsInterface {
    item: WarehouseInterface
}

export interface WarehousesFiltersPropsInterface {
    onFiltersChange: (filters: WarehouseStateInterface["filters"]) => void,
    onClearFilters: () => void,
    filters: any
}

// Warehouse
export interface WarehousePropsInterface {
    warehouse: WarehouseStateInterface,
    positions: Array<any>,
    positionTotal: WarehouseStateInterface["positionTotal"],
    positionFilters: WarehouseStateInterface["positionFilters"],
    positionParams: WarehouseStateInterface["positionParams"],
    positionPage: WarehouseStateInterface["positionPage"],
    getWarehouse: (id: string) => AsyncThunkAction,
    getWarehousePositions: (id: string, hideLoader?: boolean) => AsyncThunkAction,
    clearWarehouse: () => AnyAction,
    saveWarehouse: (data: WarehouseStateInterface["warehouse"]) => Promise<any>,
    setParamsPage: (page: number) => AnyAction,
    setFilters: (filters: WarehouseStateInterface["positionFilters"], id: string, reloadList?: boolean) => AsyncThunkAction,
    clearFilters: (id: string, reloadList?: boolean) => AsyncThunkAction,
    // deleteUser: (id: string) => Promise<any>
}
