import React, { FC } from "react";
import CheckboxComponent from "../../common/components/checkbox.component";
import TextInputComponent from "../../common/components/textInput.component";
import resetIcon from "../../common/images/ico-wyczysc.svg";
import { InventoriesFiltersPropsInterface } from "../types/inventory.types";

const InventoriesListFiltersComponent: FC<InventoriesFiltersPropsInterface> = ({ filters, onClearFilters, onFiltersChange }: InventoriesFiltersPropsInterface) => {

    const handleFilterChange = (field: string, value: any) => {
        let newFilters = Object.assign({}, filters, {
            [field]: value
        });
        onFiltersChange(newFilters);
    }

    const handleFiltersReset = () => {
        let nameInput: any = document.getElementById("name");
        if (nameInput) {
            nameInput.value = '';
        }
        let warehouseInput: any = document.getElementById("warehouse");
        if (warehouseInput) {
            warehouseInput.value = '';
        }
        let completedInput: any = document.getElementById("completed");
        if (completedInput) {
            completedInput.checked = '';
        }
        onClearFilters();
    }

    return (<div className="filter-top-bar justify-content-between p-0">
        <div className="d-flex align-items-center flex-wrap col-md-8 px-0">
            <TextInputComponent
                id="name"
                defaultValue={filters.name}
                onChange={handleFilterChange}
                label="Nazwa inwentaryzacji"
                placeholder="Szukaj"
                customClass="col-md-5 col-sm-12 form-group input-group m-0 p-3"
                debounce={1000}
            />
            <TextInputComponent
                id="warehouse"
                defaultValue={filters.warehouse}
                onChange={handleFilterChange}
                label="Nazwa magazynu"
                placeholder="Szukaj"
                customClass="col-md-5 col-sm-12 form-group input-group m-0 p-3"
                debounce={1000}
            />
            <div className="col-md-2 col-sm-12">
                <CheckboxComponent
                    onChange={handleFilterChange}
                    checked={filters.completed || false}
                    label="Zakmnięta"
                    id="completed"
                    customClass="custom-control custom-checkbox text-left"
                />
            </div>
        </div>
        <div className="d-flex align-self-stretch color-gray" onClick={handleFiltersReset}>
            <div className="d-flex flex-wrap justify-content-center align-items-center bg-light filter-clear-topbar rounded-right px-4 py-2"><img src={resetIcon} alt="reset"/> Wyczyść</div>
        </div>
    </div>);
}

export default InventoriesListFiltersComponent;