import { Formik } from 'formik';
import _ from "lodash";
import { FC, Fragment, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import TextInputComponent from "../../common/components/textInput.component";
import SelectComponent from "../../common/components/select.component";
import plusIcon from "../../common/images/ico-plus.svg";
import { initialValues, positionValidationSchema } from "../schemas/position.schema";
import { PositionPropsInterface } from "../types/position.types";

const UserComponent: FC<PositionPropsInterface> = ({ dict, warehouse, savePosition }: PositionPropsInterface) => {

    // const [positionInitialValues, setPositionInitialValues] = useState(initialValues);
    const history = useHistory();
    let { id }: any = useParams();

    // useEffect(() => {
    //     if (warehouse !== null && _.get(warehouse, "_id")) {
    //         setWarehouseInitialValues(Object.assign({}, initialValues, warehouse));
    //     }
    // }, [warehouse]);

    useEffect(() => {
        //parse dictow dla selecta
        if (dict) {
            dict.values.forEach((d: any) => {
                d.label = d.name
            })
        }
    }, [dict])

    return <Fragment>
        <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2">
            <div className="bg-white rounded-10 p-1">

                <div className="d-flex align-items-flex-start justify-content-center">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={positionValidationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize={true}
                        onSubmit={(values, { setErrors }) => {
                            //wywalenie zbednego info i zjoinowanie
                            let data = {
                                code: _.get(values, 'column', '-') + '.' + _.get(values, 'row', '-'),
                                type: _.get(values, 'type'),
                                warehouse: { warehouseId: _.get(warehouse, '_id', '-') }
                            }

                            if (id) values._id = id;
                            savePosition(data).then((res) => {
                                if (res.success) {
                                    history.goBack();
                                } else if (res.errors) {
                                    if (res.errors.code) {
                                        res.errors.row = res.errors.code;
                                        res.errors.column = res.errors.code;
                                    }
                                    setErrors(res.errors);
                                }
                            })
                        }}
                    >
                        {formik => (<form className="col-md-10 col-sm-12 d-flex flex-wrap py-5 my-5" onSubmit={formik.handleSubmit}>
                            <div className="col-sm-6">
                                <TextInputComponent
                                    label="Nazwa magazynu"
                                    type="text"
                                    placeholder="Nazwa magazynu"
                                    id="name"
                                    onChange={formik.setFieldValue}
                                    value={_.get(warehouse, 'name', '-')}
                                    customClass="form-group input-group"
                                    disabled={true}
                                />
                            </div>
                            <div className="col-sm-6">
                                <SelectComponent
                                    label="Typ pozycji"
                                    id="type"
                                    onChange={formik.setFieldValue}
                                    options={_.get(dict, 'values')}
                                    value={formik.values.type}
                                    error={formik.errors.type}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-6">
                                <TextInputComponent
                                    label="Kolumna"
                                    type="text"
                                    placeholder="Kolumna"
                                    id="column"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.column || ""}
                                    error={formik.errors.column}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-6">
                                <TextInputComponent
                                    label="Rząd"
                                    type="text"
                                    placeholder="Rząd"
                                    id="row"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.row}
                                    error={formik.errors.row}
                                    customClass="form-group input-group"
                                />
                            </div>

                            <div className="col-sm-12 text-center">
                                <button type="submit" className="btn-wms color-white rounded border-0 px-4 my-5 py-2"><img src={plusIcon} alt="Dodaj" /> {id ? "Edytuj" : "Dodaj"}</button>
                            </div>
                        </form>)}
                    </Formik>
                </div>
            </div>
        </div>
    </Fragment>
}

export default UserComponent;