import { combineReducers } from 'redux';
import applicationReducer from '../application/reducers/application.reducer';
import usersReducer from '../user/reducers/users.reducer';
import userReducer from '../user/reducers/user.reducer';
import rolesReducer from '../role/reducers/roles.reducer';
import roleReducer from '../role/reducers/role.reducer';
import profileReducer from '../profile/reducers/profile.reducer';
import generatorReducer from '../generator/reducers/generator.reducer';
import inventoriesReducer from '../inventory/reducers/inventories.reducer';
import inventoryReducer from '../inventory/reducers/inventory.reducer';
import settingsReducer from '../application/reducers/settings.reducer';
import dictsReducer from '../application/reducers/dicts.reducer';
import documentsReducer from '../document/reducers/documents.reducer';
import warehouseReducer from '../warehouse/reducers/warehouse.reducer';
import productsReducer from '../product/reducers/products.reducer';
import productReducer from '../product/reducers/product.reducer';
import categoryReducer from '../common/reducers/category.reducer';
import documentReducer from '../document/reducers/document.reducer';
import dashboardReducer from '../dashboard/reducers/dashboard.reducer';
import zpReducer from '../zp/reducers/zp.reducer';
import zpListReducer from '../zp/reducers/zpList.reducer';
import globalSearchReducer from '../common/reducers/globalSearch.reducer';

export default combineReducers({
    application: applicationReducer,
    users: usersReducer,
    user: userReducer,
    roles: rolesReducer,
    role: roleReducer,
    profile: profileReducer,
    generator: generatorReducer,
    inventories: inventoriesReducer,
    inventory: inventoryReducer,
    settings: settingsReducer,
    dicts: dictsReducer,
    warehouse: warehouseReducer,
    products: productsReducer,
    product: productReducer,
    category: categoryReducer,
    documents: documentsReducer,
    document: documentReducer,
    dashboard: dashboardReducer,
    zp: zpReducer,
    zpList: zpListReducer,
    globalsearch: globalSearchReducer,
})