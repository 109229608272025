import _ from "lodash";
import moment from 'moment';
import React, { FC, Fragment } from "react";
import { useHistory, useParams } from "react-router";
import DeleteConfirmModal from '../../common/components/deleteConfirmModal.component';
import { InventoryPropsInterface } from "../types/inventory.types";
import InventoryDetailsHeaderComponent from "./inventoryDetailsHeader.component";
import InventoryHistoryComponent from "./inventoryHistory.component";

const InventoryDetailsComponent: FC<InventoryPropsInterface> = ({
    inventory,
    deleteInventory,
    history,
    total,
    filters,
    params,
    page,
    setFilters,
    clearFilters,
    setPage,
    saveInventory,
    getInventory,
    resetPosition
}: InventoryPropsInterface) => {
    let { id }: any = useParams();
    let browserHistory = useHistory();

    const roundToFive = (x: number) => {
        return Math.ceil(x / 5) * 5;
    }

    let percentCompleted = _.get(inventory, "statistics.percentCompleted", 0) || 0;
    let percentLeft = _.round((_.get(inventory, "statistics.left", 0) / _.get(inventory, "statistics.all", 1)) * 100, 2);

    const renderPositionsLeft = () => {
        return _.get(inventory, "statistics.leftCodes", []).map((code: string, index: number) => {
            return (<tr key={`left${index}`}>
                <td>
                    <div className="badge badge-light p-2 border border-2 ">{code}</div>
                </td>
            </tr>)
        })
    }

    const renderProgress = () => {
        let positionsInProgress = _.get(inventory, "inProgress", []);
        if (!_.isEmpty(positionsInProgress)) {
            return positionsInProgress.map((progress: any, index: number) => {
                return (<div className="d-flex flex-wrap col-sm-12 px-0 border border-top-0 border-left-0 border-right-0" key={`progress${index}`}>
                    <div className="document-group col-sm-2 d-flex align-items-center flex-wrap justify-content-center py-3">
                        <div className="d-flex justify-content-center flex-wrap">
                            {/* <h4 className="full-time mb-0 w-100 text-center"><span>14:42</span>08.06.2021</h4> */}
                            <div className="badge badge-light p-2 border border-2 p-3 my-1">
                                <h3 className="mt-1">{progress.position.code}</h3>
                            </div>
                            <h4 className="full-time mb-0 w-100 text-center">{progress.creator.name}</h4>
                        </div>
                    </div>
                    <div className="table table-responsive mb-0 col-sm-10 px-0">
                        <table className="table table-hover mb-0 px-0">
                            <tbody>
                                <tr className="thead-light tableonmobile">
                                    <th className="col-md-2">Pozycja</th>
                                    <th className="col-md-2">Numer serii</th>
                                    <th className="col-md-2">Ilość</th>
                                </tr>
                                {progress.products.map((progressProduct: any, productIndex: number) => {
                                    return (<tr key={`progress${index}product${productIndex}`}>
                                        <th scope="col" className="col-md-4">
                                            {progressProduct.name}
                                        </th>
                                        <th className="col-md-2">
                                            <h3 className="text-left mt-1">{progressProduct.seriesNr}</h3>
                                        </th>
                                        <th className="col-md-2">{progressProduct.amount}</th>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>);
            });
        } else {
            return <h3 className="text-center my-5">Brak pozycji w trakcie inwentaryzacji</h3>
        }
    }

    const handlePositionAllowEdit = (code: string) => {
        let newAllowed = JSON.parse(JSON.stringify(inventory?.allowedToEdit));
        newAllowed.push(code);
        newAllowed = _.uniq(newAllowed);
        saveInventory(Object.assign({}, {
            _id: inventory?._id,
            allowedToEdit: newAllowed
        })).then((res) => {
            if (res.success) {
                getInventory(id);
            }
        })
    }

    const handlePositionReset = (positionId: string) => {
        resetPosition(positionId).then((res) => {
            if (res.success) {
                getInventory(id);
            }
        })
    }

    const handleInventoryFinish = () => {
        saveInventory(Object.assign({}, {
            _id: inventory?._id,
            completedAt: new Date()
        })).then((res) => {
            if (res.success) {
                getInventory(id);
            }
        })
    }

    return <Fragment>
        <InventoryDetailsHeaderComponent onInventoryFinish={handleInventoryFinish} inventory={inventory} />
        <DeleteConfirmModal id="inventory-delete" onDelete={() => {
            deleteInventory(id).then((res) => {
                if (res.success) {
                    document.getElementById(`inventory-delete-cancel`)?.click();
                    browserHistory.push("/inventories/page/1");
                }
            })
        }} />
        <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2 ">
            <div className="bg-white boxshadow rounded-10 p-0">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="col-md-10 col-sm-12 d-flex flex-wrap align-items-center py-5 ">
                        <div className="col-sm-10 px-0 d-flex flex-wrap ">
                            <div className="row col-sm-12 px-0">
                                <div className="col-sm-12">
                                    <h2 className="text-center">{_.get(inventory, "name", "-")}</h2>
                                    <div className="d-flex flex-no-wrap justify-content-center">
                                        <div className="mx-3">
                                            <h4 className="product-preview-codes"><span>Numer:</span>{_.get(inventory, "inventoryNumber", "-")}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2 col-sm-6 d-flex flex-wrap align-items-center justify-content-center">
                            <div className={`m-0 progress--circle progress--${inventory?.completed ? 100 : roundToFive(percentCompleted)}`}>
                                <div className="progress__number">{inventory?.completed ? 100 : percentCompleted}%</div>
                            </div>
                        </div>
                        <div className="col-sm-12 px-0 d-flex flex-wrap ">
                            <div className="col-sm-12 px-0">
                                <hr className="" />
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group input-group d-flex flex-wrap noborderinputlabel">
                                    <div className="has-float-label">
                                        <div className="form-control2" id="start-time">
                                            <h3 className="full-time2 mb-0">{moment(inventory?.createdAt).format("DD.MM.YYYY")}<span>{moment(inventory?.createdAt).format("HH:mm")}</span></h3>
                                        </div>
                                        <label htmlFor="user-role">Data rozpoczęcia</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                {inventory?.completed && <div className="form-group input-group d-flex flex-wrap noborderinputlabel">
                                    <div className="has-float-label">
                                        <div className="form-control2" id="stop-time">
                                            <h3 className="full-time2 mb-0">{moment(inventory.completedAt).format("DD.MM.YYYY")}<span>{moment(inventory.completedAt).format("HH:mm")}</span></h3>
                                        </div>
                                        <label htmlFor="user-role">Data zakończenia</label>
                                    </div>
                                </div>}
                            </div>
                            <div className="col-sm-4">
                                <div className="form-group input-group d-flex flex-wrap noborderinputlabel">
                                    <div className="has-float-label">
                                        <div className="form-control2" id="start-time">
                                            <h3 className="full-time2 mb-0">{_.get(inventory, "warehouse.name", "-")}</h3>
                                        </div>
                                        <label htmlFor="user-role">Magazyn</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 px-0 mx-0 my-4 tabs-container">
                            <ul className="nav nav-pills mb-3 mx-3" id="details-tab" role="tablist">
                                <li className="nav-item " role="presentation">
                                    <a className="nav-link active" id="details-home-tab" data-toggle="tab" href="#tab1" data-bs-toggle="pill" data-bs-target="#tab1" type="button" role="tab" aria-controls="details-1" aria-selected="true">Historia</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="details-profile-tab" data-toggle="tab" href="#tab2" data-bs-toggle="pill" data-bs-target="#tab2" type="button" role="tab" aria-controls="details-2" aria-selected="false">Nieodczytane pozycje</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="details-contact-tab" data-toggle="tab" href="#tab3" data-bs-toggle="pill" data-bs-target="#tab3" type="button" role="tab" aria-controls="details-3" aria-selected="false">W trakcie inwentaryzacji</a>
                                </li>
                            </ul>
                            <div className="tab-content" id="details-tabContent">
                                {/* Historia */}
                                <InventoryHistoryComponent
                                    inventory={inventory}
                                    currentPage={page}
                                    itemsOnPage={params.limit}
                                    total={total}
                                    items={history}
                                    onPageChange={(newPage: number) => setPage(newPage)}
                                    filters={filters}
                                    onClearFilters={() => clearFilters(true)}
                                    onFiltersChange={(filters: any) => setFilters(filters, true)}
                                    onPositionEdit={handlePositionAllowEdit}
                                    onPositionReset={handlePositionReset}
                                />

                                {/* Pozostałe */}
                                <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="details-profile-tab">
                                    <div className="col-sm-12 p-3 ">
                                        <div className="progress-percentage">{_.get(inventory, "statistics.left", 0)}/{_.get(inventory, "statistics.all", 0)}</div>
                                        <div className="progress-percentage">{percentLeft}%</div>
                                        <div className="progress mt-1">
                                            <div className="progress-bar " role="progressbar" aria-valuenow={percentLeft} aria-valuemin={0} aria-valuemax={100} style={{ width: "5%" }}></div>
                                        </div>
                                    </div>
                                    <div className=" table table-responsive my-4">
                                        <table className="table table-hover mb-0">
                                            <tbody>
                                                {renderPositionsLeft()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                {/* W trakcie */}
                                <div className="tab-pane fade " id="tab3" role="tabpanel" aria-labelledby="details-contact-tab ">
                                    <div id="stock-table" className="table table-responsive my-4">
                                        <table className="table table-hover mb-0">
                                            <thead>
                                                <tr className="thead-light tablenomobile">
                                                    <th scope="col" className="col-md-2">Dokument</th>
                                                    <th className="col-md-4">Pozycja</th>
                                                    <th className="col-md-2">Numer serii</th>
                                                    <th className="col-md-2">Ilość</th>
                                                </tr>
                                            </thead>
                                        </table>
                                        {renderProgress()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Fragment>
}

export default InventoryDetailsComponent;