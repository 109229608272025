import { FC } from "react";
import { DocumentsListCardPropsInterface } from "../types/document.types";
import { useHistory } from "react-router-dom"
import _ from "lodash";

import completedIcon from "../../common/images/ico-zrealizowane.svg";
import completionInProgressIcon from "../../common/images/ico-wrealizacji.svg";
import completionTodoIcon from "../../common/images/ico-dorealizacji.svg";

import inspectedIcon from "../../common/images/ico-sprawdzone.svg";
import inspectionInProgressIcon from "../../common/images/ico-sprawdzanie.svg";
import inspectionTodoIcon from "../../common/images/ico-dosprawdzenia.svg";

const DocumentsListCardComponent: FC<DocumentsListCardPropsInterface> = ({ item }: DocumentsListCardPropsInterface) => {

    let history = useHistory();

    const getCompletionIcon = () => {
        switch (_.get(item, "completion.status", "1")) {
            case 3:
            case "3": return <img src={completedIcon} alt="zrealizowany" />;
            case 2:
            case "2": return <img src={completionInProgressIcon} alt="w trakcie realizacji" />;
            case 1:
            case "1":
            default: return <img src={completionTodoIcon} alt="do realizacji" />;
        }
    }

    const getInspectionIcon = () => {
        switch (_.get(item, "inspection.status", "1")) {
            case 3:
            case "3": return <img src={inspectedIcon} alt="sprawdzony" />;
            case 2:
            case "2": return <img src={inspectionInProgressIcon} alt="w trakcie sprawdzania" />;
            case 1:
            case "1":
            default: return <img src={inspectionTodoIcon} alt="do sprawdzenia" />;
        }
    }

    return (<tr onClick={() => history.push(`/documents/${item._id}`)} className={item.completionDisabled ? "product-missing" : ""}>
        <td>
            {item.documentNumber}
            <div className="onmobile text-center px-2 d-block">{getInspectionIcon()} {getCompletionIcon()} </div>
        </td>
        <td>{_.get(item, "contractor.name", "")}</td>
        <td></td>
        <td>
            {getInspectionIcon()} {getCompletionIcon()}
        </td>
    </tr>);
}

export default DocumentsListCardComponent;