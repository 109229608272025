import moment from "moment";
import { ProductStateInterface, ProductActionType } from "../types/product.types";

const initialState: ProductStateInterface = {
  product: null,
  categories: [],
  productWarehouseState: [],
  productWarehousePositionHistory: [],
  productDocumentReservations: [],
  total: 0,
  filters: {
    documentNumber: "",
    seriesNr: "",
    position: "",
    dateFrom: moment().startOf("month").format("MM/DD/YYYY"),
    dateTo: moment().format("MM/DD/YYYY"),
  },
  params: {
    limit: 10,
    offset: 0,
  },
  page: 1,
};

const productReducer = (state = initialState, action: any) => {
  const { type } = action;
  switch (type) {
    case ProductActionType.SET_PRODUCT: {
      return {
        ...state,
        product: action.product,
      };
    }
    case ProductActionType.CLEAR_PRODUCT: {
      return {
        ...state,
        product: null,
      };
    }
    case ProductActionType.SET_PRODUCT_WAREHOUSE_STATE: {
      return {
        ...state,
        productWarehouseState: action.productWarehouseState,
      };
    }
    case ProductActionType.CLEAR_PRODUCT_WAREHOUSE_STATE: {
      return {
        ...state,
        productWarehouseState: [],
      };
    }
    case ProductActionType.SET_PRODUCT_WAREHOUSE_POSITION_HISTORY: {
      return {
        ...state,
        productWarehousePositionHistory: action.productWarehousePositionHistory,
        total: action.total,
      };
    }
    case ProductActionType.CLEAR_PRODUCT_WAREHOUSE_POSITION_HISTORY: {
      return {
        ...state,
        productWarehousePositionHistory: [],
        total: 0,
      };
    }
    case ProductActionType.SET_PRODUCT_DOCUMENT_RESERVATIONS: {
      return {
        ...state,
        productDocumentReservations: action.productDocumentReservations,
      };
    }
    case ProductActionType.CLEAR_PRODUCT_DOCUMENT_RESERVATIONS: {
      return {
        ...state,
        productDocumentReservations: [],
      };
    }
    case ProductActionType.SET_FILTERS: {
      return {
        ...state,
        filters: action.filters,
      };
    }
    case ProductActionType.CLEAR_FILTERS: {
      return {
        ...state,
        filters: {
          documentNumber: "",
          seriesNr: "",
          position: "",
          dateFrom: moment().startOf("month").format("MM/DD/YYYY"),
          dateTo: moment().format("MM/DD/YYYY"),
        },
      };
    }
    case ProductActionType.SET_PARAMS: {
      return {
        ...state,
        params: action.params,
      };
    }
    case ProductActionType.CLEAR_PARAMS: {
      return {
        ...state,
        params: {
          limit: 20,
          offset: 0,
          sort: null,
        },
      };
    }
    case ProductActionType.SET_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    default:
      return state;
  }
};

export default productReducer;
