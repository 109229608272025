import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import ZpsComponent from "../components/zpList.component";
import { getZps, clearZps, setFilters, clearFilters, setPage, setParams, clearParams } from '../actions/zpList.actions';
import { connect } from 'react-redux'
import { AppDispatch, RootState } from "../../config/store";
import { ZpListPropsInterface, ZpListStateInterface } from "../types/zp.types";

const ZpListContainer: FC<ZpListPropsInterface> = (props: ZpListPropsInterface) => {
    const { getZps, clearZps, setPage, page } = props;
    let params: any = useParams();

    useEffect(() => {
        setPage(parseInt(params.page));
        getZps();
        return () => {
            clearZps();
        }
    }, [getZps, clearZps, page, params.page, setPage])

    return <ZpsComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    documents: store.zpList.documents,
    total: store.zpList.total,
    filters: store.zpList.filters,
    params: store.zpList.params,
    page: store.zpList.page
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getZps: () => dispatch(getZps()),
        clearZps: () => dispatch(clearZps()),
        setFilters: (filters: ZpListStateInterface["filters"], reloadList?: boolean) => dispatch(setFilters(filters, reloadList)),
        clearFilters: (reloadList?: boolean) => dispatch(clearFilters(reloadList)),
        setPage: (page: number) => dispatch(setPage(page)),
        setParams: (params: ZpListStateInterface["params"], reloadList?: boolean) => dispatch(setParams(params, reloadList)),
        clearParams: (reloadList?: boolean) => dispatch(clearParams(reloadList)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ZpListContainer);