import React, { FC, Fragment } from "react";
import { InventoriesPropsInterface } from "../types/inventory.types";
import ListComponent from "../../common/components/list.component";
import InventoriesListCardComponent from "./inventoriesListCard.component";
import InventoriesListFiltersComponent from "./inventoriesListFilters.component";
import InventoriesListHeaderComponent from "./inventoriesListHeader.component";

const InventoriesList: FC<InventoriesPropsInterface> = ({ inventories, total, params, setFilters, clearFilters, setPage, setParams, filters, page }: InventoriesPropsInterface) => {

    const getColumns = () => {
        return [
            {
                key: "createdAt",
                label: "Data inwentaryzacji"
            },
            {
                key: "name",
                label: "Nazwa"
            },
            {
                key: "inventoryNumber",
                label: "Kod inwentaryzacji"
            },
            {
                key: "warehouse.name",
                label: "Magazyn"
            },
            {
                key: "progress",
                label: "Postęp",
                disableSort: true
            }
        ]
    }

    return (
        <Fragment>
            <InventoriesListHeaderComponent filters={filters} onClearFilters={() => clearFilters(true)} onFiltersChange={(filters: any) => setFilters(filters, true)}/>
            <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2">
                <InventoriesListFiltersComponent filters={filters} onClearFilters={() => clearFilters(true)} onFiltersChange={(filters: any) => setFilters(filters, true)} />
                <ListComponent
                    id="table-stock"
                    items={inventories}
                    total={total}
                    itemsOnPage={params.limit}
                    ItemCard={InventoriesListCardComponent}
                    currentPage={page}
                    currentSort={params.sort}
                    route="/inventories"
                    columns={getColumns()}
                    onPageChange={setPage}
                    onSortChange={(sortKey: string, sortValue: number) => setParams(Object.assign({}, params, { sort: { [sortKey]: sortValue } }), true)}
                />
            </div>
        </Fragment>
    )
}

export default InventoriesList;