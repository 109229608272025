import React, { FC } from "react";
import { connect } from 'react-redux'
import ReportsComponent from "../components/reports.component";
import { AppDispatch, RootState } from "../../config/store";

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {};
};

const mapStateToProps = (store: RootState) => ({
    user: store.application.user
});

const ReportsContainer: FC<any> = (props: any) => {
    return <ReportsComponent {...props} />
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);