import React, { FC } from 'react';
import _ from 'lodash';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { ChartComponentPropsInterface } from '../types/component.types';
import { GenericObject } from '../types/common.types';

const borderColors = [
    'rgb(54, 162, 235)',
    'rgb(255, 99, 132)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(201, 203, 207)'
]

const backgroundColors = [
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 99, 132, 0.2)',
    'rgba(255, 159, 64, 0.2)',
    'rgba(255, 205, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(201, 203, 207, 0.2)'
]

const ChartComponent: FC<ChartComponentPropsInterface> = ({ id, title, data, type, labelMap, colorOffset = 0 }: ChartComponentPropsInterface) => {

    let labels: Array<string> = [];
    let datasets: any = [];
    _.each(labelMap, (value: string, key: string) => {
        datasets.push({
            fill: true,
            label: value,
            data: [],
            backgroundColor: [],
            borderColor: [],
            borderWidth: 2
        });
    })

    data.forEach((obj: GenericObject, index: number) => {
        labels.push(obj._id);
        let setIndex = 0;
        _.each(labelMap, (value: string, key: string) => {
            let colorIndex = (setIndex + colorOffset) % backgroundColors.length;
            datasets[setIndex].data.push(obj[key] || 0);
            datasets[setIndex].borderColor.push(borderColors[colorIndex]);
            datasets[setIndex].backgroundColor.push(backgroundColors[colorIndex]);
            setIndex++;
        })
    })

    let options = {
        data: {
            labels,
            datasets
        },
        options: {
            cubicInterpolationMode: "monotone",
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: title
                },
                legend: {
                    display: false
                }
            },
            scales: {
                x: {
                    display: false,
                    grid: {
                        display: false
                    }
                },
                y: {
                    display: false,
                    suggestedMin: 0,
                    grid: {
                        display: false
                    }
                }
            }
        }
    }

    const renderChart = () => {
        switch (type) {
            case "bar": {
                return <Bar {...options} />;
            }
            case "pie": {
                return <Pie {...options} />;
            }
            case "line":
            default: {
                return <Line {...options} />;
            }
        }
    }

    return (
        <div className="col-md-4 rounded-10 px-2">
            <div className="box box3 bg-white rounded-10 nooverflow pt-3">
                <div id={id}>
                    {renderChart()}
                </div>
            </div>
        </div>
    );
}

export default ChartComponent;