import * as Yup from "yup";
import { ProductInterface } from "../../common/types/common.types";

export const initialValues: ProductInterface = {
  _id: "",
  name: "",
  ean: "",
  code: "",
  type: 0,
  source: 0,
  avatar: "",
  unit: 0,
  categories: [],
  expiresIn: 12,
  parameters: {
    width: "",
    length: "",
    height: "",
    unitsInPackage: null,
    singleUnitWeight: null,
    packageWeight: "",
    totalVolume: "",
    pkwiu: "",
    packagesOnEuroPallet: null,
  },
};

export const validationSchema = [
  Yup.object().shape({
    name: Yup.string().required("Wartość jest wymagana"),
    code: Yup.string().required("Wartość jest wymagana"),
    type: Yup.number().min(1, "Wartość jest wymagana").required("Wartość jest wymagana"),
    source: Yup.number().min(1, "Wartość jest wymagana").required("Wartość jest wymagana"),
    unit: Yup.number().min(1, "Wartość jest wymagana").required("Wartość jest wymagana"),
    avatar: Yup.string().nullable(),
    categories: Yup.array(
      Yup.object().shape({
        id: Yup.string(),
        parent: Yup.string(),
        name: Yup.string(),
      })
    ).min(1, "Wartość jest wymagana"),
  }),
  Yup.object().shape({
    expiresIn: Yup.number().required("Wartość jest wymagana"),
    ean: Yup.string().required("Wartość jest wymagana"),
    parameters: Yup.object().shape({
      unitsInPackage: Yup.number().required("Wartość jest wymagana").nullable(),
      packagesOnEuroPallet: Yup.number().required("Wartość jest wymagana").nullable(),
      pkwiu: Yup.string().required("Wartość jest wymagana"),
      packageWeight: Yup.string().required("Wartość jest wymagana"),
      singleUnitWeight: Yup.string().required("Wartość jest wymagana").nullable(),
      totalVolume: Yup.string().required("Wartość jest wymagana"),
      width: Yup.string().required("Wartość jest wymagana"),
      height: Yup.string().required("Wartość jest wymagana"),
      length: Yup.string().required("Wartość jest wymagana"),
    }),
  }),
];
