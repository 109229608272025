import _ from "lodash"
import moment from "moment"
import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import apiService from "../../application/services/api.service"
import { GenericObject, UserInterface } from "../../common/types/common.types"
import { AppDispatch, GetState } from "../../config/store"
import { ZpListActionType, ZpListStateInterface } from "../types/zp.types"

export const getZps = (hideLoader?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        const { zpList: { filters, params, page } } = getState();
        if (!hideLoader) {
            dispatch(isLoading(true));
        }

        let sendParams: GenericObject = parseFilters(filters, {
            limit: params.limit,
            offset: (page - 1) * params.limit
        });

        if (_.isObject(params.sort) && !_.isEmpty(params.sort)) {
            let sortKey: string = _.keys(params.sort)[0];
            let sortValue: number = _.values(params.sort)[0];
            sendParams[`sort[${sortKey}]`] = sortValue;
        }

        let res = await apiService.get("/document/zp", sendParams);
        if (res.success) {
            dispatch(setZps(res.documents, res.total));
        }
        dispatch(isLoading(false));
    } catch (err) {
        dispatch(isLoading(false));
    }
}

export const setZps = (documents: Array<UserInterface>, total: number): AnyAction => {
    return {
        type: ZpListActionType.SET_ZPS,
        documents,
        total
    }
}

export const clearZps = (): AnyAction => {
    return {
        type: ZpListActionType.CLEAR_ZPS
    }
}

export const setFilters = (filters: ZpListStateInterface["filters"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: ZpListActionType.SET_FILTERS,
        filters
    })
    if (reloadList) {
        dispatch(getZps(true));
    }
}

export const clearFilters = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: ZpListActionType.CLEAR_FILTERS
    });
    if (reloadList) {
        dispatch(getZps(true));
    }
}

export const setParams = (params: ZpListStateInterface["params"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: ZpListActionType.SET_PARAMS,
        params
    });
    if (reloadList) {
        dispatch(getZps(true));
    }
}

export const clearParams = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: ZpListActionType.CLEAR_PARAMS
    })
    if (reloadList) {
        dispatch(getZps(true));
    }
}

export const setPage = (page: number): AnyAction => {
    if (page <= 0) page = 1;
    return {
        type: ZpListActionType.SET_PAGE,
        page
    }
}

const parseFilters = (filters: ZpListStateInterface["filters"], sendParams: GenericObject) => {

    if (!_.isEmpty(filters.positionFrom)) {
        sendParams["positionFrom.code"] = filters.positionFrom;
    }
    if (!_.isEmpty(filters.positionTo)) {
        sendParams["positionTo.code"] = filters.positionTo;
    }
    if (!_.isEmpty(filters.creator)) {
        sendParams["creator.name"] = filters.creator;
    }
    if (!_.isEmpty(filters.product)) {
        sendParams["product.name"] = filters.product;
    }

    sendParams['createdAt[gte]'] = moment(filters.dateFrom, "MM/DD/YYYY").startOf("day").format("X");
    sendParams['createdAt[lte]'] = moment(filters.dateTo, "MM/DD/YYYY").endOf("day").format("X");

    return sendParams;
};
