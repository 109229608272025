import _ from "lodash"
import moment from "moment"
import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import apiService from "../../application/services/api.service"
import { GenericObject, UserInterface } from "../../common/types/common.types"
import { AppDispatch, GetState } from "../../config/store"
import { InventoriesActionType, InventoriesStateInterface } from "../types/inventory.types";
import { toast } from "react-toastify"

export const getInventories = (hideLoader?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        const { inventories: { filters, params, page } } = getState();
        if (!hideLoader) {
            dispatch(isLoading(true));
        }

        let sendParams: GenericObject = {
            limit: params.limit,
            offset: (page - 1) * params.limit,
            getStatistics: true,
            "createdAt[gte]": moment(filters.dateFrom, "MM/DD/YYYY").format("X"),
            "createdAt[lte]": moment(filters.dateTo, "MM/DD/YYYY").format("X")
        }

        if (_.isObject(params.sort) && !_.isEmpty(params.sort)) {
            let sortKey: string = _.keys(params.sort)[0];
            let sortValue: number = _.values(params.sort)[0];
            sendParams[`sort[${sortKey}]`] = sortValue;
        }
        if (filters.name) {
            sendParams.name = filters.name;
        }
        if (filters.warehouse) {
            sendParams["warehouse.name"] = filters.warehouse;
        }
        if (filters.completed) {
            sendParams.completed = filters.completed;
        }

        let res = await apiService.get("/warehouse/inventory", sendParams);
        if (res.success) {
            dispatch(setInventories(res.documents, res.total));
        } else {
            toast.error("Błąd pobierania inwentaryzacji");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania inwentaryzacji");
        dispatch(isLoading(false));
    }
}

export const setInventories = (inventories: Array<UserInterface>, total: number): AnyAction => {
    return {
        type: InventoriesActionType.SET_INVENTORIES,
        inventories,
        total
    }
}

export const clearInventories = (): AnyAction => {
    return {
        type: InventoriesActionType.CLEAR_INVENTORIES
    }
}

export const setFilters = (filters: InventoriesStateInterface["filters"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: InventoriesActionType.SET_FILTERS,
        filters
    })
    if (reloadList) {
        dispatch(getInventories(true));
    }
}

export const clearFilters = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: InventoriesActionType.CLEAR_FILTERS
    });
    if (reloadList) {
        dispatch(getInventories(true));
    }
}

export const setParams = (params: InventoriesStateInterface["params"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: InventoriesActionType.SET_PARAMS,
        params
    });
    if (reloadList) {
        dispatch(getInventories(true));
    }
}

export const clearParams = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: InventoriesActionType.CLEAR_PARAMS
    })
    if (reloadList) {
        dispatch(getInventories(true));
    }
}

export const setPage = (page: number): AnyAction => {
    if (page <= 0) page = 1;
    return {
        type: InventoriesActionType.SET_PAGE,
        page
    }
}