import { GlobalSearchActionType, GlobalSearchStateInterface } from "../types/component.types";

const initialState: GlobalSearchStateInterface = {
  searchedResults: {
    searchedProducts: [],
    searchedDocuments: [],
    searchedUsers: [],
    searchedWarehouses: [],
  },
  isLoading: false
};

const globalSearchReducer = (state = initialState, action: any) => {
  const { type } = action;

  switch (type) {
    case GlobalSearchActionType.SET_SEARCHED_RESULTS:
      return {
        ...state,
        searchedResults: action.searchedResults,
      };
      
    case GlobalSearchActionType.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    default:
      return state;
  }
};

export default globalSearchReducer;