import React, { FC, Fragment } from "react";
import { ProductsPropsInterface } from "../types/product.types";

import ProductsHeaderComponent from "./productsHeader.component";
import ProductsFilterComponent from "./productsFilters.component";
import ProductsListCardComponent from "./productsListCard.component";
import ListComponent from "../../common/components/list.component";

const ProductsComponent: FC<ProductsPropsInterface> = ({
  products,
  categories,
  total,
  params,
  filters,
  page,
  setPage,
  setParams,
  setFilters,
  clearFilters,
}: ProductsPropsInterface) => {
  const getColumns = () => {
    return [
      {
        key: "name",
        label: "Nazwa produktu",
      },
      {
        key: "categories",
        label: "Kategoria",
        disableSort: true,
      },
      {
        key: "code",
        label: "Kod produktu",
        disableSort: true,
      },
      {
        key: "ean",
        label: "EAN",
        disableSort: true,
      },
    ];
  };

  return (
    <Fragment>
      <ProductsHeaderComponent />
      <div className="container mw100vw content-container ">
        <div className="row">
          <ProductsFilterComponent
            filters={filters}
            categories={categories}
            onFiltersChange={(filters: any) => setFilters(filters, true)}
            onClearFilters={() => clearFilters(true)}
          />
          <div id="content-container-right" className="col-md-9 col-sm-12 scrolling py-4 pl-4 pr-2">
            <ListComponent
              id="table-products"
              items={products}
              total={total}
              itemsOnPage={params.limit}
              ItemCard={ProductsListCardComponent}
              currentPage={page}
              currentSort={params.sort}
              route="/products"
              columns={getColumns()}
              onPageChange={setPage}
              onSortChange={(sortKey: string, sortValue: number) =>
                setParams(Object.assign({}, params, { sort: { [sortKey]: sortValue } }), true)
              }
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductsComponent;
