import React, { FC } from 'react'
import ReportsPropsInterface from '../types/reports.types';

const ReportsProductsAvaiabilityComponent: FC<ReportsPropsInterface> = ({ onDataChange }: ReportsPropsInterface) => {

    // const type = "8";
    // const props = { type: type };


    return (
        <div id="report-form-dostepnosc" role="tabpanel" aria-labelledby="report-form-dostepnosc-tab" className="report-form tabpane fade">
            <div className="color-gray">Brak dodatkowych opcji do wyboru.</div>
        </div>
    );
};
export default ReportsProductsAvaiabilityComponent;