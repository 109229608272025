import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import ProductsComponent from "../components/productsList.component";
import {
  getProducts,
  clearProducts,
  setFilters,
  clearFilters,
  setPage,
  setParams,
} from "../actions/products.actions";
import { getCategories } from "../../common/actions/category.actions";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../config/store";
import { ProductsPropsInterface, ProductsStateInterface } from "../types/product.types";

const ProductsContainer: FC<ProductsPropsInterface> = (props: ProductsPropsInterface) => {
  const { getProducts, getCategories, clearProducts, clearFilters, setPage, page } = props;
  let params: any = useParams();

  useEffect(() => {
    getCategories("product");
  }, [getCategories]);

  useEffect(() => {
    setPage(parseInt(params.page));
    getProducts();

    return () => {
      clearProducts();
      clearFilters();
    };
  }, [page, params.page, getProducts, clearProducts, clearFilters, setPage]);

  return <ProductsComponent {...props} />;
};

const mapStateToProps = (store: RootState) => ({
  products: store.products.products,
  total: store.products.total,
  filters: store.products.filters,
  params: store.products.params,
  page: store.products.page,
  categories: store.category.categories,
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
  return {
    getProducts: () => dispatch(getProducts()),
    getCategories: (content: string) => dispatch(getCategories(content)),
    setPage: (page: number) => dispatch(setPage(page)),
    setParams: (params: ProductsStateInterface["params"], reloadList?: boolean) =>
      dispatch(setParams(params, reloadList)),
    setFilters: (filters: ProductsStateInterface["filters"], reloadList?: boolean) =>
      dispatch(setFilters(filters, reloadList)),
    clearProducts: () => dispatch(clearProducts()),
    clearFilters: (reloadList?: boolean) => dispatch(clearFilters(reloadList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsContainer);
