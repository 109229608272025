import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import InventoryComponent from "../components/inventory.component";
import { InventoryPropsInterface } from "../types/inventory.types";
import { getInventory, clearInventory, saveInventory } from "../actions/inventory.actions";
import { AppDispatch, RootState } from "../../config/store";
import { connect } from 'react-redux'
import { GenericObject } from "../../common/types/common.types";

const InventoryContainer: FC<InventoryPropsInterface> = (props: InventoryPropsInterface) => {
    const { getInventory, clearInventory } = props;
    let { id }: any = useParams();

    useEffect(() => {
        if (id) {
            getInventory(id);
        }
        return () => {
            clearInventory();
        }
    }, [getInventory, clearInventory, id])


    return <InventoryComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    inventory: store.inventory.inventory
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getInventory: (id: string) => dispatch(getInventory(id)),
        clearInventory: () => dispatch(clearInventory()),
        saveInventory: (data: GenericObject) => saveInventory(data, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryContainer);