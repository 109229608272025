import { Formik } from 'formik';
import _ from "lodash";
import { FC, Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import TextInputComponent from "../../common/components/textInput.component";
import warehouseIcon from "../../common/images/ico-magazyny.svg";
import { initialValues, warehouseValidationSchema } from "../schemas/warehouse.schema";
import { WarehousePropsInterface } from "../types/warehouse.types";

const UserComponent: FC<WarehousePropsInterface> = ({ warehouse, saveWarehouse }: WarehousePropsInterface) => {

    const [warehouseInitialValues, setWarehouseInitialValues] = useState(initialValues);
    const history = useHistory();
    let { id }: any = useParams();

    useEffect(() => {
        if (warehouse !== null && _.get(warehouse, "_id")) {
            setWarehouseInitialValues(Object.assign({}, initialValues, warehouse));
        }
    }, [warehouse]);

    return <Fragment>
        <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2">
            <div className="bg-white rounded-10 p-1">
                <div className="d-flex align-items-flex-start justify-content-center my-5 flex-wrap">
                    <Formik
                        initialValues={warehouseInitialValues}
                        validationSchema={warehouseValidationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize={true}
                        onSubmit={(values, { setErrors }) => {
                            if (id) values._id = id;
                            saveWarehouse({ ...values }).then((res) => {
                                if (res.success) {
                                    history.goBack();
                                } else if (res.errors) {
                                    setErrors(res.errors);
                                }
                            })
                        }}
                    >
                        {formik => (<form className="col-md-10 col-sm-12 d-flex flex-wrap py-5 my-5" onSubmit={formik.handleSubmit}>
                            <div className="col-sm-6">
                                <TextInputComponent
                                    label="Nazwa magazynu"
                                    type="text"
                                    placeholder="Nazwa magazynu"
                                    id="name"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.name || ""}
                                    error={formik.errors.name}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-6">
                                <TextInputComponent
                                    label="Kod magazynu"
                                    type="text"
                                    placeholder="Kod magazynu"
                                    id="code"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.code}
                                    error={formik.errors.code}
                                    customClass="form-group input-group"
                                />
                            </div>

                            <div className="col-sm-12 text-center">
                                <button type="submit" className="btn-wms color-white rounded border-0 px-4 my-5 py-2"><img src={warehouseIcon} alt="Dodaj" /> {id ? "Edytuj" : "Dodaj"}</button>
                            </div>
                        </form>)}
                    </Formik>
                </div>
            </div>
        </div>
    </Fragment>
}

export default UserComponent;