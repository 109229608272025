import React, { FC } from "react";
import TextInputComponent from "../../common/components/textInput.component";
import resetIcon from "../../common/images/ico-wyczysc.svg";
import { RolesFiltersPropsInterface } from "../types/role.types";

const RolesFiltersComponent: FC<RolesFiltersPropsInterface> = ({ filters, onClearFilters, onFiltersChange }: RolesFiltersPropsInterface) => {

    const handleFilterChange = (field: string, value: any) => {
        let newFilters = Object.assign({}, filters, {
            [field]: value
        });
        onFiltersChange(newFilters);
    }

    const handleFiltersReset = () => {
        let nameInput: any = document.getElementById("name");
        if (nameInput) {
            nameInput.value = '';
        }
        onClearFilters();
    }

    return (<div className="filter-top-bar justify-content-between flex-wrap p-0">
        <div className="d-flex flex-wrap align-items-center col-md-10 col-sm-12 ">
            <TextInputComponent
                id="name"
                defaultValue={filters.name}
                onChange={handleFilterChange}
                label="Nazwa roli"
                placeholder="Szukaj"
                customClass="col-md-3 col-sm-12 form-group input-group m-0 py-3 px-0"
                debounce={1000}
            />
        </div>
        <div className="col-md-1 col-sm-12 d-flex align-self-stretch justify-content-end p-0 m-0 rounded-right color-gray" onClick={handleFiltersReset}>
            <div className="col-sm-12 d-flex flex-wrap justify-content-center align-items-center bg-light filter-clear-topbar rounded-right px-4 py-2">
                <img src={resetIcon} alt="reset" />
                Wyczyść
            </div>
        </div>
    </div>);
}

export default RolesFiltersComponent;