import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { GenericObject } from "../../common/types/common.types"
import { AppDispatch, GetState } from "../../config/store"
import { WarehouseActionType, WarehouseInterface, WarehouseStateInterface } from "../types/warehouse.types"
import { toast } from "react-toastify"
import _ from 'lodash';

//
// Warehouses
//
export const getWarehouses = (hideLoader?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        if (!hideLoader) {
            dispatch(isLoading(true));
        }

        const { warehouse: { filters, params, page } } = getState();

        let sendParams: GenericObject = {
            limit: params.limit,
            offset: (page - 1) * params.limit,
            joinPositionsCount: true
        }

        if (_.isObject(params.sort) && !_.isEmpty(params.sort)) {
            let sortKey: string = _.keys(params.sort)[0];
            let sortValue: number = _.values(params.sort)[0];
            sendParams[`sort[${sortKey}]`] = sortValue;
        }

        if (filters.name) {
            sendParams.name = filters.name;
        }
        if (filters.code) {
            sendParams.code = filters.code;
        }

        let res = await Api.get('/warehouse/warehouse', sendParams);
        if (res.success) {
            dispatch(setWarehouses(res.documents, res.total));
        } else {
            toast.error("Błąd pobierania magazynów");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania magazynów");
        dispatch(isLoading(false));
    }
}

export const setWarehouses = (warehouses: WarehouseInterface, total: Number): AnyAction => {
    return {
        type: WarehouseActionType.SET_WAREHOUSES,
        warehouses,
        total
    }
}

export const clearUsers = (): AnyAction => {
    return {
        type: WarehouseActionType.CLEAR_WAREHOUSES
    }
}

export const setFilters = (filters: WarehouseStateInterface["filters"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: WarehouseActionType.SET_FILTERS,
        filters
    })
    if (reloadList) {
        dispatch(getWarehouses(true));
    }
}

export const clearFilters = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: WarehouseActionType.CLEAR_FILTERS
    });
    if (reloadList) {
        dispatch(getWarehouses(true));
    }
}

export const setParams = (params: WarehouseStateInterface["params"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: WarehouseActionType.SET_PARAMS,
        params
    });
    if (reloadList) {
        dispatch(getWarehouses(true));
    }
}

export const clearParams = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: WarehouseActionType.CLEAR_PARAMS
    })
    if (reloadList) {
        dispatch(getWarehouses(true));
    }
}

export const setPage = (page: number): AnyAction => {
    if (page <= 0) page = 1;
    return {
        type: WarehouseActionType.SET_PAGE,
        page
    }
}

//
// Warehouse
//
export const getWarehouse = (id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get(`/warehouse/warehouse/${id}`);
        if (res.success) {
            dispatch(setWarehouse(res.document));
        } else {
            toast.error("Błąd pobierania magazynu");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania magazynu");
        dispatch(isLoading(false));
    }
}
export const getWarehousePositions = (id: string, hideLoader?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        if (!hideLoader) {
            dispatch(isLoading(true));
        }
        const { warehouse: { positionFilters, positionParams, positionPage } } = getState();
        let sendParams: GenericObject = {
            "warehouse.warehouseId": id,
            limit: positionParams.limit,
            offset: (positionPage - 1) * positionParams.limit
        }

        if (positionFilters.products) {
            sendParams['products.name'] = positionFilters.products;
        }
        if (positionFilters.code) {
            sendParams.code = positionFilters.code;
        }

        let res = await Api.get(`/warehouse/position`, sendParams);
        if (res.success) {
            dispatch(setWarehousePositions(res.documents, res.total));
        } else {
            toast.error("Błąd pobierania pozycji");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania pozycji");
        dispatch(isLoading(false));
    }
}

export const saveWarehouse = async (data: GenericObject, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = null;
        if (data._id) {
            res = await Api.put(`/warehouse/warehouse/${data._id}`, data);
        } else {
            res = await Api.post("/warehouse/warehouse", data);
        }
        dispatch(isLoading(false));

        if (res.success) {
            toast.success("Zapisano magazyn");
            return res;
        } else if (res.errors) {
            toast.error("Błąd zapisu magazynu");
            return res;
        } else {
            toast.error("Błąd zapisu magazynu");
            return { success: false, errors: { message: "Błąd zapisu" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd zapisu magazynu");
        return { success: false, errors: { message: "Błąd zapisu" } };
    }
}

export const setWarehouse = (warehouse: WarehouseInterface): AnyAction => {
    return {
        type: WarehouseActionType.SET_WAREHOUSE,
        warehouse
    }
}

export const clearWarehouse = (): AnyAction => {
    return {
        type: WarehouseActionType.CLEAR_WAREHOUSE
    }
}

export const setWarehousePositions = (positions: any, positionTotal: number): AnyAction => {
    return {
        type: WarehouseActionType.SET_WAREHOUSE_POSITIONS,
        positions,
        positionTotal
    }
}

export const clearWarehousePositions = (): AnyAction => {
    return {
        type: WarehouseActionType.CLEAR_WAREHOUSE_POSITIONS
    }
}
