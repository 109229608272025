import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import React, { FC } from "react";
import { useHistory } from 'react-router';
import { DocumentDetailsProductTablePropsInterface } from '../types/document.types';

const DocumentDetailsProductTable: FC<DocumentDetailsProductTablePropsInterface> = ({ status, items }: DocumentDetailsProductTablePropsInterface) => {

    const getCorrectAmount = (amount: number, subtracts: boolean) => {
        return amount * (subtracts ? -1 : 1);
    }
    let history = useHistory();

    const renderItems = () => {
        let index = 0;
        return items.map((item, productIndex) => {
            return item.series.map((series: any, seriesIndex: number) => {
                index++;
                return (<tr key={`pr${productIndex}sr${seriesIndex}`} className={item.completionDisabled ? "product-missing" : ""} onClick={() => history.push("/products/" + (status === 3 ? item.product.productId : item.productId))}>
                    <td>{index}</td>
                    <th scope="row">{status === 3 ? item.product.name : item.name}</th>
                    <td>{moment(series.expirationDate).format("DD.MM.YYYY")}</td>
                    {status === 3 && <td>
                        <h3 className="text-left mt-1">{series.documentSeriesNr}</h3>
                    </td>}
                    <td>
                        <h3 className="text-left mt-1">{series.seriesNr}</h3>
                    </td>
                    <td>{status === 3 ? getCorrectAmount(series.amount, series.subtracts) : series.amount}</td>
                    <td>szt</td>
                </tr>)
            });
        });
    }

    return (<table id="table-warehouses" className="table table-hover mb-0">
        <thead>
            <tr className="thead-light">
                <th scope="col" className="col-md-1 text-center">Lp.</th>
                <th scope="col" className="col-md-5">Nazwa</th>
                <th className="col-md-2">Data ważności</th>
                {status === 3 && <th className="col-md-2">Seria w dokumencie</th>}
                <th className="col-md-2">Seria</th>
                <th className="col-md-1">Ilość</th>
                <th className="col-md-1">Jednostka</th>
            </tr>
        </thead>
        <tbody>
            {renderItems()}
        </tbody>
    </table>);
}

export default DocumentDetailsProductTable;