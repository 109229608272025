import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import InventoriesListComponent from "../components/inventoriesList.component";
import { getInventories, clearInventories, setFilters, clearFilters, setPage, setParams, clearParams } from '../actions/inventories.actions';
import { connect } from 'react-redux'
import { AppDispatch, RootState } from "../../config/store";
import { InventoriesPropsInterface, InventoriesStateInterface } from "../types/inventory.types";

const InventoriesListContainer: FC<InventoriesPropsInterface> = (props: InventoriesPropsInterface) => {
    const { getInventories, clearInventories, setPage, page } = props;
    let params: any = useParams();

    useEffect(() => {
        setPage(parseInt(params.page));
        getInventories();
        return () => {
            clearInventories();
        }
    }, [getInventories, clearInventories, page, params.page, setPage])

    return <InventoriesListComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    inventories: store.inventories.inventories,
    total: store.inventories.total,
    filters: store.inventories.filters,
    params: store.inventories.params,
    page: store.inventories.page
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getInventories: () => dispatch(getInventories()),
        clearInventories: () => dispatch(clearInventories()),
        setFilters: (filters: InventoriesStateInterface["filters"], reloadList?: boolean) => dispatch(setFilters(filters, reloadList)),
        clearFilters: (reloadList?: boolean) => dispatch(clearFilters(reloadList)),
        setPage: (page: number) => dispatch(setPage(page)),
        setParams: (params: InventoriesStateInterface["params"], reloadList?: boolean) => dispatch(setParams(params, reloadList)),
        clearParams: (reloadList?: boolean) => dispatch(clearParams(reloadList)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoriesListContainer);