import { Formik } from 'formik';
import React, { FC, Fragment } from "react";
import TextInputComponent from "../../common/components/textInput.component";
import { HelpdeskPropsInterface } from '../types/helpdesk.types';
import { initialValues, reportValidationSchema } from "../schemas/helpdesk.schema";
import _ from 'lodash';
import TextAreaComponent from '../../common/components/textarea.component';
import SelectComponent from '../../common/components/select.component';
import FileInputComponent from '../../common/components/fileInput.component';
import IcoX from '../../common/images/ico-x.svg';
import IcoPlus from '../../common/images/ico-plus.svg';
import IcoDelete from '../../common/images/ico-delete.svg';
import IcoMail from '../../common/images/ico-mail.svg';

const priorityOptions = [
    { value: "4", label: 'Niski', customClass: " prio1" },
    { value: "3", label: 'Średni', customClass: " prio2" },
    { value: "2", label: 'Wysoki', customClass: " prio3" },
    { value: "1", label: "Pilne!", customClass: " prio4" }
];
const typeOptions = [
    { value: 'Problem - Błąd', label: 'Błąd' },
    { value: 'Feature Request - Modyfikacja', label: 'Modyfikacja' },
    { value: 'Question - Pytanie', label: 'Pytanie' },
];

// ...

const HelpdeskComponent: FC<HelpdeskPropsInterface> = ({ report, sendReport, user }: HelpdeskPropsInterface) => {


    function delEmail(index: number, formik: any) {
        let copy = JSON.parse(JSON.stringify(formik.values.emails));
        copy.splice(index, 1);
        formik.setFieldValue("emails", copy);

    };

    function delAttachments(fileList: any, index: number, formik: any) {
        const fileListArr = Array.from(fileList);
        fileListArr.splice(index, 1);
        formik.setFieldValue("attachments", fileListArr);
    }

    function addEmail(formik: any) {
        formik.setFieldValue("emails", _.concat(formik.values.emails, [""]));

    }

    function addAttachments(formik: any, fileList: any) {
        let result = [];
        for (let i = 0, numFiles = fileList.length; i < numFiles; i++) {
            const file = fileList[i];
            result.push(<Fragment key={`attachments-row-${i}`}>
                <div className="badge badge-pill ac-badge border border-1 py-1 px-2 mb-2 mr-2">
                    {file['name']}
                    <img src={IcoX} alt="" onClick={() => delAttachments(fileList, i, formik)} />
                </div>
            </Fragment>);
        };
        return result;
    }

    return (
        <Fragment>
            <div id="content-container-right" className="report-problem col-md-12 col-sm-12 scrolling py-4 pl-4 pr-4">
                <div className="bg-white rounded-10 p-1">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="col-md-8 col-sm-12 d-flex flex-wrap py-5 my-5">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={reportValidationSchema}
                                validateOnChange={false}
                                validateOnBlur={false}
                                onSubmit={(values, { setErrors, resetForm }) => {
                                    values.group = "[WMS]";
                                    values.email = _.get(user, "email", "x");
                                    sendReport(values).then((res) => {
                                        if (res.errors) {
                                            setErrors(res.errors);
                                        } else {
                                            resetForm()
                                        }
                                    })
                                }}
                            >
                                {formik => (
                                    <Fragment>
                                        <form className="col-sm-8 pr-0 row px-2 " onSubmit={formik.handleSubmit} id="rep" autoComplete="vsdvsvd">
                                            <TextInputComponent
                                                id="subject"
                                                value={formik.values.subject}
                                                onChange={formik.setFieldValue}
                                                label="Temat"
                                                placeholder="Wpisz temat zgłoszenia"
                                                customClass="col-sm-12 mx-0 px-0 form-group input-group"
                                                error={formik.errors.subject}
                                            />
                                            <SelectComponent
                                                id="priority"
                                                options={priorityOptions}
                                                onChange={formik.setFieldValue}
                                                label="Priorytet"
                                                customClass="col-md-6 px-0 mb-2 "
                                                error={formik.errors.priority}
                                            />
                                            <SelectComponent
                                                id="type"
                                                onChange={formik.setFieldValue}
                                                options={typeOptions}
                                                label="Typ"
                                                customClass="col-md-6 pl-2 pr-0 mb-2"
                                                error={formik.errors.type}

                                            />
                                            <TextAreaComponent
                                                id="description"
                                                value={formik.values.description}
                                                onChange={formik.setFieldValue}
                                                label="Opis problemu"
                                                placeholder="Opisz w kilku słowach problem"
                                                customClass="col-sm-12 mx-0 px-0 form-group input-group "
                                                rows={6}
                                                error={formik.errors.description}
                                            />

                                        </form>
                                        <div className="col-sm-4 px-0 d-flex flex-wrap ">
                                            <div className="col-md-12 ">
                                                {addAttachments(formik, formik.values.attachments)}
                                                <label htmlFor={`attachments`} className="file-upload col-12 px-0 text-center" onClick={() => addAttachments(formik, formik.values.attachments)}>
                                                    <div className="col-12 btn-wms p-2 color-white rounded border-0 ">
                                                        <img src={IcoPlus} alt="" /> Dodaj załącznik
                                                    </div>
                                                </label>
                                                <FileInputComponent
                                                    id={`attachments`}
                                                    type="file"
                                                    multiple={true}
                                                    value={_.get(formik, `values.attachments`)}
                                                    error={_.get(formik, `errors.attachments`, null)}
                                                    onChange={(id: string, files: any) => {
                                                        formik.setFieldValue(id, files);
                                                    }}
                                                />

                                                <div id="additional-mail" className="col-md-12 px-0 mb-2">
                                                    {
                                                        formik.values.emails && formik.values.emails.map((emails: any, index: number) => {

                                                            return <Fragment key={`emails-row-${index}`}>
                                                                <div className="input-group mb-2">
                                                                    <TextInputComponent
                                                                        id={`emails[${index}]`}
                                                                        type="text"
                                                                        label={`email`}
                                                                        placeholder="Dodatkowy adres e-mail odbiorcy"
                                                                        customClass="form group"
                                                                        value={_.get(formik, `values.emails[${index}]`)}
                                                                        onChange={formik.setFieldValue}
                                                                        error={_.get(formik, `errors.emails[${index}]`, null)}
                                                                    />

                                                                    <div className="input-group-append">
                                                                        <button className="btn btn-wms px-2" type="button" form="rep" onClick={() => delEmail(index, formik)}><img src={IcoDelete} alt="" className="m-0" /></button>
                                                                    </div>
                                                                </div>
                                                            </Fragment>

                                                        })
                                                    }
                                                </div>
                                                <button type="button" form="rep" className="col-12 btn-wms p-2 color-white rounded border-0 " onClick={() => addEmail(formik)}><img src={IcoMail} alt="" /> Dodaj adres mail do DW</button>

                                            </div>
                                        </div>
                                        <div className="col-sm-12 text-center">
                                            <button type="submit" className="btn-wms color-white rounded border-0 px-4 my-5 py-2" form="rep"><img src={IcoPlus} alt="" /> Wyślij zgłoszenie</button>
                                        </div>

                                    </Fragment>
                                )
                                }

                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >

    );
};
export default HelpdeskComponent;
