import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { GenericObject } from "../../common/types/common.types"
import { AppDispatch, GetState } from "../../config/store"
import { PositionInterface, PositionsActionType, } from "../types/position.types"
import { WarehouseStateInterface } from "../types/warehouse.types"
import { toast } from "react-toastify"
import { getWarehousePositions } from './warehouse.actions';

// 
// Positions
// 

export const setFilters = (positionFilters: WarehouseStateInterface["positionFilters"], id: string, reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: PositionsActionType.SET_FILTERS,
        positionFilters
    })
    if (reloadList) {
        dispatch(getWarehousePositions(id, true));
    }
}

export const clearFilters = (id: string, reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: PositionsActionType.CLEAR_FILTERS
    });
    if (reloadList) {
        dispatch(getWarehousePositions(id, true));
    }
}

export const setParams = (positionParams: WarehouseStateInterface["positionParams"], id: string, reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: PositionsActionType.SET_PARAMS,
        positionParams
    });
    if (reloadList) {
        dispatch(getWarehousePositions(id, true));
    }
}

export const clearParams = (id: string, reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: PositionsActionType.CLEAR_PARAMS
    })
    if (reloadList) {
        dispatch(getWarehousePositions(id, true));
    }
}

export const setParamsPage = (page: number): AnyAction => {
    if (page <= 0) page = 1;
    return {
        type: PositionsActionType.SET_PAGE,
        positionPage: page
    }
}

//
// Position
//
export const savePosition = async (data: GenericObject, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = null;
        if (data._id) {
            res = await Api.put(`/warehouse/position/${data._id}`, data);
        } else {
            res = await Api.post("/warehouse/position", data, false);
        }
        dispatch(isLoading(false));

        if (res.success) {
            toast.success("Zapisano pozycję");
            return res;
        } else if (res.errors) {
            toast.error("Błąd zapisu pozycji");
            return res;
        } else {
            toast.error("Błąd zapisu pozycji");
            return { success: false, errors: { message: "Błąd zapisu" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd zapisu pozycji");
        return { success: false, errors: { message: "Błąd zapisu" } };
    }
}

export const getPosition = (id: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get(`/warehouse/position/${id}`);
        if (res.success && res.document) {
            dispatch(setPosition(res.document));
        } else {
            toast.error("Błąd pobierania pozycji");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania pozycji");
        dispatch(isLoading(false));
    }
}

export const setPosition = (position: PositionInterface): AnyAction => {
    return {
        type: PositionsActionType.SET_POSITION,
        position
    }
}

export const clearPosition = (): AnyAction => {
    return {
        type: PositionsActionType.CLEAR_POSITION
    }
}

export const getPositionHistory = (code: string) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        dispatch(isLoading(true));

        const { warehouse: { positionHistoryParams, positionHistoryPage } } = getState();
        let sendParams: GenericObject = {
            "position.code": code,
            limit: positionHistoryParams.limit,
            offset: (positionHistoryPage - 1) * positionHistoryParams.limit
        }

        let res = await Api.get(`/warehouse/positionHistory`, sendParams);
        if (res.success && res.documents) {
            dispatch(setPositionHistory(res.documents, res.total));
        } else {
            toast.error("Błąd pobierania historii pozycji");
        }

        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania historii pozycji");
        dispatch(isLoading(false));
    }
}

export const setPositionHistory = (positionHistory: any, positionHistoryTotal: number): AnyAction => {
    return {
        type: PositionsActionType.SET_POSITION_HISTORY,
        positionHistory,
        positionHistoryTotal
    }
}

export const clearPositionHistory = (): AnyAction => {
    return {
        type: PositionsActionType.CLEAR_POSITION_HISTORY
    }
}

export const setHistoryPage = (positionHistoryPage: number): AnyAction => {
    if (positionHistoryPage <= 0) positionHistoryPage = 1;
    return {
        type: PositionsActionType.SET_HISTORY_PAGE,
        positionHistoryPage
    }
}

export const deleteWarehousePosition = (warehouseId: string) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        const { warehouse: { warehouse } } = getState();

        dispatch(isLoading(true));

        let res = await Api.delete(`/warehouse/position/${warehouseId}`);

        dispatch(isLoading(false));

        if (res.success) {
            dispatch(getWarehousePositions(warehouse._id));
        } else {
            toast.error("Błąd usuwania historii pozycji");
        }
    } catch (err) {
        toast.error("Błąd usuwania pozycji");
        dispatch(isLoading(false));
    }
}