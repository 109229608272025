import { Formik } from 'formik';
import _ from "lodash";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Autocomplete from "../../common/components/autocomplete.component";
import CheckboxComponent from "../../common/components/checkbox.component";
import CropperComponent from "../../common/components/cropper.component";
import TextInputComponent from "../../common/components/textInput.component";
import usersIcon from "../../common/images/ico-uzytkownicy.svg";
import { initialValues, userValidationSchema } from "../schemas/user.schema";
import { UserPropsInterface } from "../types/user.types";
import UserHeaderComponent from "./userHeader.component";

const UserComponent: FC<UserPropsInterface> = ({ user, saveUser }: UserPropsInterface) => {

    const [userInitialValues, setUserInitialValues] = useState(initialValues);
    const history = useHistory();
    const [avatar, setAvatar] = useState(null);
    let { id }: any = useParams();

    useEffect(() => {
        if (user !== null && _.get(user, "_id")) {
            setUserInitialValues(Object.assign({}, initialValues, user));
        }
    }, [user]);

    return <Fragment>
        <UserHeaderComponent />
        <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2">
            <div className="bg-white rounded-10 p-1">
                <div className="d-flex align-items-flex-start justify-content-center my-5 flex-wrap">
                    <CropperComponent defaultImage={_.get(user, "avatar")} imageHeight={100} imageWidth={100} id="avatar" onCrop={(id: string, file: any) => setAvatar(file)} />
                    <Formik
                        initialValues={userInitialValues}
                        validationSchema={userValidationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize={true}
                        onSubmit={(values, { setErrors }) => {
                            if (id) values._id = id;
                            saveUser(Object.assign({}, values, { avatar: avatar })).then((res) => {
                                if (res.success) {
                                    history.push(`/users/${res.document}`);
                                } else if (res.errors) {
                                    setErrors(res.errors);
                                }
                            })
                        }}
                    >
                        {formik => (<form className="col-md-7 col-sm-12 d-flex flex-wrap my-5" onSubmit={formik.handleSubmit}>
                            <div className="col-sm-12 pr-0 ">
                                <TextInputComponent
                                    label="Nazwa użytkownika"
                                    type="text"
                                    placeholder="Imię i Nazwisko"
                                    id="name"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.name || ""}
                                    error={formik.errors.name}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-6">
                                <TextInputComponent
                                    label="Adres e-mail"
                                    type="text"
                                    placeholder="Wpisz adres e-mail"
                                    id="email"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.email || ""}
                                    error={formik.errors.email}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-6 px-0">
                                <TextInputComponent
                                    label="Telefon kontaktowy"
                                    type="text"
                                    placeholder="(48) 000 000 000"
                                    id="phone"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.phone}
                                    error={formik.errors.phone}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-6">
                                <TextInputComponent
                                    label="Login"
                                    type="text"
                                    placeholder="Wpisz login"
                                    id="login"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.login || ""}
                                    error={formik.errors.login}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-6 px-0">
                                <TextInputComponent
                                    label="Hasło"
                                    type="text"
                                    placeholder="Wpisz hasło"
                                    id="password"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.password || ""}
                                    error={formik.errors.password}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-6">
                                <Autocomplete
                                    url={"/warehouse/warehouse"}
                                    id="warehouse"
                                    multi={true}
                                    onOptionSelect={formik.setFieldValue}
                                    placeholder="Szukaj magazynu"
                                    label="Magazyn"
                                    optionIdField="warehouseId"
                                    optionLabelField="name"
                                    value={formik.values.warehouse}
                                    queryFields={["name", "code"]}
                                    additionalParams={{ limit: 5, select: "name,code" }}
                                    parseOption={(option: any) => {
                                        option.warehouseId = option._id;
                                        return option;
                                    }}
                                    hideChips={false}
                                    readonly={false}
                                    preload={true}
                                    customClass="form-group input-group"
                                // error={formik.errors.warehouse}
                                />
                            </div>
                            <div className="col-sm-6 px-0">
                                <Autocomplete
                                    url={"/application/role"}
                                    id="role"
                                    multi={false}
                                    onOptionSelect={formik.setFieldValue}
                                    placeholder="Szukaj roli"
                                    label="Rola"
                                    optionIdField="roleId"
                                    optionLabelField="name"
                                    value={formik.values.role}
                                    queryFields={["name"]}
                                    additionalParams={{ limit: 5, select: "name" }}
                                    parseOption={(option: any) => {
                                        option.roleId = option._id;
                                        return option;
                                    }}
                                    hideChips={false}
                                    readonly={false}
                                    preload={true}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-12 d-flex align-items-center flex-wrap px-0">
                                <CheckboxComponent
                                    label="Administrator"
                                    onChange={formik.setFieldValue}
                                    id="isAdmin"
                                    checked={formik.values.isAdmin}
                                    customClass="col-md-6 col-sm-12 custom-control custom-checkbox text-center mb-3"
                                />
                                <CheckboxComponent
                                    label="Aktywny"
                                    onChange={formik.setFieldValue}
                                    id="active"
                                    checked={formik.values.active}
                                    customClass="col-md-6 col-sm-12 custom-control custom-checkbox text-center mb-3"
                                />

                            </div>
                            <div className="col-sm-12 d-flex align-items-center flex-wrap px-0">
                                <CheckboxComponent
                                    label="Integracja"
                                    onChange={formik.setFieldValue}
                                    id="integrationUser"
                                    checked={formik.values.integrationUser}
                                    customClass="col-md-6 col-sm-12 custom-control custom-checkbox text-center mb-3"
                                />
                                <CheckboxComponent
                                    label="Aplikacji mobilna"
                                    onChange={formik.setFieldValue}
                                    id="allowMobile"
                                    checked={formik.values.allowMobile}
                                    customClass="col-md-6 col-sm-12 custom-control custom-checkbox text-center mb-3"
                                />
                            </div>
                            <div className="col-sm-12 text-center">
                                <button type="submit" className="btn-wms color-white rounded border-0 px-4 my-5 py-2"><img src={usersIcon} alt="Dodaj" /> {id ? "Edytuj" : "Dodaj"}</button>
                            </div>
                        </form>)}
                    </Formik>
                </div>
            </div>
        </div>
    </Fragment>
}

export default UserComponent;