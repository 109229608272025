
import { FC } from "react";
import { PositionProductsListCardPropsInterface } from "../types/position.types";

import _ from "lodash";
import moment from 'moment';


const PositionProductsListComponent: FC<PositionProductsListCardPropsInterface> = ({ product, index }: PositionProductsListCardPropsInterface) => {

    return (
        <tr>
            <td>{index + 1}</td>
            <th scope="row">{_.get(product, 'name')}</th>
            <td>{moment(_.get(product, 'expirationDate')).format('DD.MM.YYYY')}</td>
            <td>
                <h3 className="text-left mt-1">{_.get(product, 'seriesNr')}</h3>
            </td>
            <td>{_.get(product, 'amount')}</td>
            <td>szt</td>
        </tr>
    );
}

export default PositionProductsListComponent;




