import React, { FC } from "react";
import { DashboardHeaderPropsInterface } from "../types/dashboard.types";

const DashboardHeaderComponent: FC<DashboardHeaderPropsInterface> = ({ name }: DashboardHeaderPropsInterface) => {
    return (<div className="row px-0 mx-0 title-bar d-flex flex-nowrap justify-content-between align-items-center">
        <div className="col-sm-12 d-flex flex-no-wrap px-4 justify-content-between align-items-center">
            <div className="page-title d-flex flex-nowrap align-items-center">
                <h2 id="page-title" className="m-0">Witaj, {name}!</h2>
            </div>
        </div>
    </div>);
}

export default DashboardHeaderComponent;