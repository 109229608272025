import { FC } from "react";
import { UsersListCardPropsInterface } from "../types/user.types";
import { Link, useHistory } from "react-router-dom"
import _ from "lodash";
import Api from "../../application/services/api.service";
import Acl from "../../application/services/acl.service";

import blankAvatar from "../../common/images/ufo-user.jpg";
import adminIcon from "../../common/images/ico-admin.svg"
import mobileIcon from "../../common/images/ico-mobile.svg"
import editIcon from "../../common/images/ico-edit.svg"
import roleIcon from "../../common/images/ico-role-2.svg"

const UsersListCardComponent: FC<UsersListCardPropsInterface> = ({ item }: UsersListCardPropsInterface) => {

    const getUserAvatarSrc = () => {
        if (item && item.avatar) {
            return Api.getUrl() + item.avatar;
        }
        return blankAvatar;
    }

    const renderWarehouses = () => {
        if (item && _.isArray(item.warehouse)) {
            let names = item.warehouse.map((warehouse) => {
                return warehouse.name;
            })
            return names.join(", ");
        }
        return "-";
    }

    let history = useHistory();
    let roleId = _.get(item, "role.roleId");

    return (<tr onClick={() => history.push(`/users/${item._id}`)}>
        <th className="col-md-3" scope="row">
            <div className={`avatar ${item.isLogged ? 'avatar-online' : 'avatar-offline'}`}><img src={getUserAvatarSrc()} className="user-avatar" alt="avatar" /><i></i>{item.name}</div>
        </th>
        <td className="col-md-1 text-center">
            {item.isAdmin && <img src={adminIcon} title="Administrator" alt="administrator" />}
            {item.allowMobile && <img src={mobileIcon} title="Dostęp do aplikacji mobilnej" alt="aplikacja" />}
        </td>
        <td className="col-md-2">{_.get(item, "role.name", "-")}</td>
        <td className="col-md-2">{renderWarehouses()}</td>
        <td className="col-md-4 text-right">
            {Acl.getIsAdmin() && <Link type="button" className="bg-light rounded border-0 px-4 py-2 text-dark" to={`/user/${item._id}`}><img src={editIcon} alt="edytuj" /> Edytuj użytkownika</Link>}
            {Acl.getIsAdmin() && roleId && <Link type="button" className="ml-1 bg-light rounded border-0 px-4 py-2 text-dark" to={`/role/${roleId}`}><img src={roleIcon} alt="edytujrole" /> Edytuj rolę</Link>}
        </td>
    </tr>);
}

export default UsersListCardComponent;