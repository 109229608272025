import _ from "lodash";
import React, { FC } from "react";
import sortAscIcon from "../../common/images/sort-asc.svg";
import sortDescIcon from "../../common/images/sort-desc.svg";
import { GeneratorSortBarPorpsInterface } from "../types/generator.types";

const GeneratorSortBarComponent: FC<GeneratorSortBarPorpsInterface> = ({ onSortChange, currentSort }: GeneratorSortBarPorpsInterface) => {

    const getSortIcon = (columnKey: string) => {
        if (currentSort && currentSort && _.keys(currentSort)[0] === columnKey) {
            if (_.values(currentSort)[0] === 1) {
                return <img src={sortAscIcon} alt="asc" />
            } else {
                return <img src={sortDescIcon} alt="desc" />
            }

        }
        return null;
    }

    const handleSortClick = (columnKey: string) => {
        let nextSortValue = currentSort ? _.keys(currentSort)[0] === columnKey ? _.values(currentSort)[0] * -1 : 1 : 1;
        onSortChange(columnKey, nextSortValue);
    }

    return (<div className="filter-top-bar px-3 justify-content-between">
        <h3 className="mb-0">Sortowanie</h3>
        <div className="m-0 px-2" onClick={() => handleSortClick("name")}>
            <div className="d-flex flex-no-wrap justify-content-center align-items-center bg-light sort-topbar rounded  py-2">
                {getSortIcon("name")}Nazwa</div>
        </div>
        <div className="m-0 px-2" onClick={() => handleSortClick("expirationDate")}>
            <div
                className="d-flex flex-no-wrap justify-content-center align-items-center bg-light sort-topbar active rounded py-2">
                {getSortIcon("expirationDate")} Termin ważności</div>
        </div>
        <div className="m-0 px-2" onClick={() => handleSortClick("seriesNr")}>
            <div className="d-flex flex-no-wrap justify-content-center align-items-center bg-light sort-topbar rounded  py-2">
                {getSortIcon("seriesNr")} Numer serii</div>
        </div>
        <div className="m-0 pl-2" onClick={() => handleSortClick("ean")}>
            <div className="d-flex flex-no-wrap justify-content-center align-items-center bg-light sort-topbar rounded  py-2">
                {getSortIcon("ean")} Numer EAN</div>
        </div>
    </div>);
}

export default GeneratorSortBarComponent;