import * as Yup from 'yup';

export const initialValues = {
    type: 1,
    values: [
        {
            name: "",
            value: 1
        }
    ]
}

export const dictValidationSchema = Yup.object({
    type: Yup.number().required("Wartość jest wymagana"),
    values: Yup.array().of(Yup.object().shape({
        name: Yup.string().required("Wartość jest wymagana"),
        value: Yup.mixed().required("Wartość jest wymagana"),
        readonly: Yup.boolean()
    }))
})
