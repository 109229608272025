import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import Api from "../../application/services/api.service"
import { GenericObject, UserInterface } from "../../common/types/common.types"
import { AppDispatch } from "../../config/store"
import { ProfileActionType } from "../types/profile.types";
import { toast } from "react-toastify"

export const getProfile = () => async (dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get('/application/profile');
        if (res.success) {
            dispatch(setProfile(res.document));
        } else {
            toast.error("Błąd pobierania profilu");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania profilu");
        dispatch(isLoading(false));
    }
}

export const saveProfile = async (data: GenericObject, dispatch: AppDispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.put("/application/profile", data, true);

        dispatch(isLoading(false));

        if (res.success) {
            toast.success("Zapisano profil");
            return res;
        } else if (res.errors) {
            toast.error("Błąd zapisu profilu");
            return res;
        } else {
            toast.error("Błąd zapisu profilu");
            return { success: false, errors: { message: "Błąd zapisu" } };
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast.error("Błąd zapisu profilu");
        return { success: false, errors: { message: "Błąd zapisu" } };
    }
}

export const setProfile = (profile: UserInterface): AnyAction => {
    return {
        type: ProfileActionType.SET_PROFILE,
        profile
    }
}

export const clearProfile = (): AnyAction => {
    return {
        type: ProfileActionType.CLEAR_PROFILE
    }
}