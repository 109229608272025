import React, { FC } from "react";
import { connect } from 'react-redux';
import { GenericObject } from "../../common/types/common.types";
import { AppDispatch, RootState } from "../../config/store";
import { saveBarcode } from "../actions/barcode.actions";
import BarcodeComponent from "../components/barcode.component";
import { BarcodePropsInterface } from "../types/generator.types";

const BarcodeContainer: FC<BarcodePropsInterface> = (props: BarcodePropsInterface) => {
    return <BarcodeComponent {...props} />
}

const mapStateToProps = (store: RootState) => ({
    
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        saveBarcode: (data: GenericObject) => saveBarcode(data, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeContainer);