import React, { FC } from "react";
import sidebarBackground from "../../common/images/bg-overwms.jpg"
import Acl from "../services/acl.service";
import { Link } from "react-router-dom";
import wmsLogo from "../../common/images/logo-wms-pion-wh.png";
import wmsLogoMobile from "../../common/images/over-wms-logo-wh.png";

import documentsIcon from "../../common/images/ico-dokumenty.svg";
import warehousesIcon from "../../common/images/ico-magazyny.svg";
import productsIcon from "../../common/images/ico-produkty.svg";
import generatorIcon from "../../common/images/ico-kody.svg";
import productionIcon from "../../common/images/ico-produkcja.svg";
import inventoryIcon from "../../common/images/ico-inwentaryzacje.svg";
import usersIcon from "../../common/images/ico-uzytkownicy.svg";
import rolesIcon from "../../common/images/ico-role.svg";
import reportsIcon from "../../common/images/ico-raporty.svg";
import zpIcon from "../../common/images/ico-zp.svg";

const SidebarComponent: FC = () => {
    return (
        <div id="wmssidebar" className="col-md-1 blue-bg scrolling mh100vh px-0" style={{ backgroundImage: `url(${sidebarBackground})` }}>
            <div className="logo-section">
                <Link to="/"><img src={wmsLogo} className="wms-logo" alt="wms-logo" /></Link>
                <Link to="/"><img src={wmsLogoMobile} className="wms-logo-mobile" alt="wms-logo" /></Link>
            </div>
            <div className="menu-wrapper">
                {Acl.isAllowedMethod("document/document", "get") && <Link to="/documents/page/1">
                    <div className="menu-item">
                        <img src={documentsIcon} alt="dokumenty" />
                        <h5>Dokumenty</h5>
                    </div>
                </Link>}
                {Acl.isAllowedMethod("document/zp", "get") && <Link to="/zps/page/1">
                    <div className="menu-item">
                        <img src={zpIcon} alt="zmiany pozycji" />
                        <h5>Zmiany pozycji</h5>
                    </div>
                </Link>}
                {Acl.isAllowedMethod("warehouse/warehouse", "get") && <Link to="/warehouses/page/1">
                    <div className="menu-item">
                        <img src={warehousesIcon} alt="magazyny" />
                        <h5>Magazyny</h5>
                    </div>
                </Link>}
                {Acl.isAllowedMethod("product/product", "get") && <Link to="/products/page/1">
                    <div className="menu-item">
                        <img src={productsIcon} alt="produkty" />
                        <h5>Produkty</h5>
                    </div>
                </Link>}
                {Acl.isAllowedMethod("generator/generator", "get") && <Link to="/generator/page/1">
                    <div className="menu-item">
                        <img src={generatorIcon} alt="generator" />
                        <h5>Generator kodów</h5>
                    </div>
                </Link>}
                {Acl.isAllowedMethod("production/document", "get") && <Link to="/production">
                    <div className="menu-item">
                        <img src={productionIcon} alt="produkcja" />
                        <h5>Produkcja</h5>
                    </div>
                </Link>}
                {Acl.isAllowedMethod("warehouse/inventory", "get") && <Link to="/inventories/page/1">
                    <div className="menu-item">
                        <img src={inventoryIcon} alt="inwentaryzacje" />
                        <h5>Inwentaryzacje</h5>
                    </div>
                </Link>}
                {Acl.isAllowedMethod("application/user", "get") && <Link to="/users/page/1">
                    <div className="menu-item">
                        <img src={usersIcon} alt="użytkownicy" />
                        <h5>Użytkownicy</h5>
                    </div>
                </Link>}
                {Acl.isAllowedMethod("application/role", "get") && <Link to="/roles/page/1">
                    <div className="menu-item">
                        <img src={rolesIcon} alt="role" />
                        <h5>Role</h5>
                    </div>
                </Link>}
                {Acl.isAllowedMethod("statistic/report", "get") && <Link to="/reports">
                    <div className="menu-item ">
                        <img src={reportsIcon} alt="raporty" />
                        <h5>Raporty</h5>
                    </div>
                </Link>}
            </div>
        </div>
    )
}

export default SidebarComponent;