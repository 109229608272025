import _ from "lodash";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { ProductsListCardPropsInterface } from "../types/product.types";

const UsersListCardComponent: FC<ProductsListCardPropsInterface> = ({
  item,
}: ProductsListCardPropsInterface) => {
  let history = useHistory();

  return (
    <tr onClick={() => history.push(`/products/${item._id}`)}>
      <th scope="row">{item.name}</th>
      <td>{_.size(item.categories) > 0 ? item.categories[0].breadcrumb : "-"}</td>
      <td>{item.code}</td>
      <td>{item.ean}</td>
    </tr>
  );
};

export default UsersListCardComponent;
