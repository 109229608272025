import { Formik } from 'formik';
import _ from "lodash";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Autocomplete from "../../common/components/autocomplete.component";
import SelectComponent from '../../common/components/select.component';
import TextInputComponent from "../../common/components/textInput.component";
import { initialValues, inventoryValidationSchema } from "../schemas/inventory.schema";
import { InventoryPropsInterface } from "../types/inventory.types";
import InventoryHeaderComponent from "./inventoryHeader.component";
import inventoryIcon from "../../common/images/ico-inwentaryzacje.svg";

const inventoryKinds = [
    { label: "Inwentaryzacja roczna", value: "annual" },
    // { name: "Inwentaryzacja ciągła", value: "adhoc" },
    { label: "Inwentaryzacja cykliczna", value: "cyclical" },
    { label: "Inwentaryzacja nadzwyczajna", value: "extraordinary" },
    { label: "Inwentaryzacja częściowa", value: "partial" },
    { label: "Inwentaryzacja kontrolna", value: "control" },
    { label: "Spis z natury", value: "physical" }
]

const InventoryComponent: FC<InventoryPropsInterface> = ({ inventory, saveInventory }: InventoryPropsInterface) => {

    const [inventoryInitialValues, setInventoryInitialValues] = useState(initialValues);
    const history = useHistory();
    let { id }: any = useParams();

    useEffect(() => {
        if (inventory !== null && _.get(inventory, "_id")) {
            setInventoryInitialValues(Object.assign({}, initialValues, inventory));
        }
    }, [inventory]);

    return <Fragment>
        <InventoryHeaderComponent />
        <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2">
            <div className="bg-white rounded-10 p-1">
                <div className="d-flex align-items-flex-start justify-content-center my-5 flex-wrap">

                    <Formik
                        initialValues={inventoryInitialValues}
                        validationSchema={inventoryValidationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize={true}
                        onSubmit={(values, { setErrors }) => {
                            if (id) values._id = id;
                            saveInventory(values).then((res) => {
                                if (res.success) {
                                    history.push(`/inventories/${res.document}`);
                                } else if (res.errors) {
                                    setErrors(res.errors);
                                }
                            })
                        }}
                    >
                        {formik => (<form className="col-md-10 col-sm-12 d-flex flex-wrap py-5 my-5" onSubmit={formik.handleSubmit}>
                            {/* {JSON.stringify(formik.errors)} */}
                            <div className="col-sm-6">
                                <TextInputComponent
                                    label="Nazwa inwentaryzacji"
                                    type="text"
                                    placeholder="Wpisz nazwę inwentaryzacji"
                                    id="name"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.name || ""}
                                    error={formik.errors.name}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-6">
                                <Autocomplete
                                    url={"/warehouse/warehouse"}
                                    id="warehouse"
                                    multi={false}
                                    onOptionSelect={formik.setFieldValue}
                                    placeholder="Szukaj magazynu"
                                    label="Wybierz magazyn"
                                    optionIdField="warehouseId"
                                    optionLabelField="name"
                                    value={formik.values.warehouse}
                                    queryFields={["name", "code"]}
                                    additionalParams={{ limit: 5, select: "name,code" }}
                                    parseOption={(option: any) => {
                                        option.warehouseId = option._id;
                                        return option;
                                    }}
                                    hideChips={false}
                                    readonly={false}
                                    preload={true}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-6">
                                <SelectComponent
                                    label="Rodzaj inwentaryzacji"
                                    options={inventoryKinds}
                                    id="kind"
                                    onChange={formik.setFieldValue}
                                    value={formik.values.kind || ""}
                                    error={formik.errors.kind}
                                    customClass="form-group input-group"
                                />
                            </div>
                            <div className="col-sm-12 text-center">
                                <button type="submit" className="btn-wms color-white rounded border-0 px-4 my-5 py-2"><img src={inventoryIcon} alt="zapisz"/> Zapisz</button>
                            </div>
                        </form>)}
                    </Formik>
                </div>
            </div>
        </div>
    </Fragment>
}

export default InventoryComponent;