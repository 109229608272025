import { WarehouseStateInterface, WarehouseActionType } from "../types/warehouse.types";
import { PositionsActionType } from "../types/position.types";

const initialState: WarehouseStateInterface = {
    warehouses: [],
    total: 0,
    filters: {
        name: '',
        code: '',
    },
    params: {
        limit: 20,
        offset: 0,
        sort: null
    },
    page: 1,
    warehouse: null,
    positions: [],
    positionTotal: 0,
    positionFilters: {
        products: '',
        code: '',
    },
    positionParams: {
        limit: 4,
        offset: 0,
        sort: null
    },
    positionPage: 1,
    position: null,
    positionHistory: null,
    positionHistoryTotal: 0,
    // positionHistoryFilters: {
    //     products: '',
    //     code: '',
    // },
    positionHistoryParams: {
        limit: 4,
        offset: 0,
        sort: null
    },
    positionHistoryPage: 1,
}

const warehouseReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        // Warehouses
        case WarehouseActionType.SET_WAREHOUSES: {
            return {
                ...state,
                warehouses: action.warehouses,
                total: action.total
            }
        }
        case WarehouseActionType.SET_PAGE: {
            return {
                ...state,
                page: action.page
            }
        }
        case WarehouseActionType.SET_FILTERS: {
            return {
                ...state,
                filters: action.filters
            }
        }
        case WarehouseActionType.CLEAR_FILTERS: {
            return {
                ...state,
                filters: {
                    name: '',
                    code: '',
                }
            }
        }

        // Warehouse
        case WarehouseActionType.SET_WAREHOUSE: {
            return {
                ...state,
                warehouse: action.warehouse
            }
        }
        case WarehouseActionType.CLEAR_WAREHOUSE: {
            return {
                ...state,
                warehouse: null
            }
        }

        // Positions
        case WarehouseActionType.SET_WAREHOUSE_POSITIONS: {
            return {
                ...state,
                positions: action.positions,
                positionTotal: action.positionTotal
            }
        }
        case WarehouseActionType.CLEAR_WAREHOUSE_POSITIONS: {
            return {
                ...state,
                positions: []
            }
        }

        case PositionsActionType.SET_PAGE: {
            return {
                ...state,
                positionPage: action.positionPage
            }
        }
        case PositionsActionType.SET_FILTERS: {
            return {
                ...state,
                positionFilters: action.positionFilters
            }
        }
        case PositionsActionType.CLEAR_FILTERS: {
            return {
                ...state,
                positionFilters: {
                    name: '',
                    code: '',
                }
            }
        }

        // Position
        case PositionsActionType.SET_POSITION: {
            return {
                ...state,
                position: action.position
            }
        }
        case PositionsActionType.CLEAR_POSITION: {
            return {
                ...state,
                position: null
            }
        }
        case PositionsActionType.SET_POSITION_HISTORY: {
            return {
                ...state,
                positionHistory: action.positionHistory,
                positionHistoryTotal: action.positionHistoryTotal
            }
        }
        case PositionsActionType.CLEAR_POSITION_HISTORY: {
            return {
                ...state,
                positionHistory: null,
                positionHistoryTotal: 0
            }
        }
        case PositionsActionType.SET_HISTORY_PAGE: {
            return {
                ...state,
                positionHistoryPage: action.positionHistoryPage,
            }
        }
        default: return state;
    }
}

export default warehouseReducer;