import * as Yup from 'yup';

export const initialValues = {
    subject: "",
    description: "",
    priority: "",
    type: "",
    emails: [],
    attachments: [],
    group: "[WMS]",
    email: ''
};

export const reportValidationSchema = Yup.object({
    subject: Yup.string().required("Wpisz temat"),
    description: Yup.string().required("Wpisz wiadomość"),
    priority: Yup.mixed().oneOf(["4", "3", "2", "1", 4, 3, 2, 1]).required("wybiesz wartość z lisy"),
    type: Yup.mixed().oneOf(["Problem - Błąd", "Feature Request - Modyfikacja", "Question - Pytanie"]).required("wybiesz wartość z lisy"),
    emails: Yup.array().of(Yup.string().required("wprowadź maila")),
    attachments: Yup.array().nullable(),
    group: Yup.string(),
    email: Yup.string()
});
