import { AnyAction } from "redux";
import { RoleInterface, AsyncThunkAction, Nullable } from "../../common/types/common.types";
import { CategoryInterface } from "../../common/types/component.types";

export enum RolesActionType {
    SET_ROLES = 'ROLES_SET_ROLES',
    CLEAR_ROLES = 'ROLES_CLEAR_ROLES',
    SET_FILTERS = 'ROLES_SET_FILTERS',
    CLEAR_FILTERS = 'ROLES_CLEAR_FILTERS',
    SET_PARAMS = 'ROLES_SET_PARAMS',
    CLEAR_PARAMS = 'ROLES_CLEAR_PARAMS',
    SET_PAGE = `ROLES_SET_PAGE`
}

export enum RoleActionType {
    SET_ROLE = 'ROLES_SET_ROLES',
    CLEAR_ROLE = 'ROLES_CLEAR_ROLES'
}

export interface RolesStateInterface {
    roles: Array<RoleInterface>,
    total: number,
    filters: {
        name: string
    },
    params: {
        limit: number,
        offset: number,
        sort: Nullable<{ [fieldname: string]: number }>
    },
    page: number
}

export interface RoleStateInterface {
    role: Nullable<RoleInterface>
}

export interface RolesPropsInterface {
    roles: RolesStateInterface["roles"],
    total: RolesStateInterface["total"],
    filters: RolesStateInterface["filters"],
    params: RolesStateInterface["params"],
    page: number,
    getRoles: () => AsyncThunkAction,
    clearRoles: () => AnyAction,
    setFilters: (filters: RolesStateInterface["filters"], reloadList?: boolean) => AsyncThunkAction,
    clearFilters: (reloadList?: boolean) => AsyncThunkAction,
    setParams: (params: RolesStateInterface["params"], reloadList?: boolean) => AsyncThunkAction,
    clearParams: (reloadList?: boolean) => AsyncThunkAction,
    setPage: (page: number) => AnyAction
}

export interface RolesListCardPropsInterface {
    item: RoleInterface
}

export interface RolesFiltersPropsInterface {
    onFiltersChange: (filters: RolesStateInterface["filters"]) => void,
    onClearFilters: () => void,
    filters: any
}

export interface RolePropsInterface {
    role: RoleStateInterface["role"],
    categories: Array<CategoryInterface>
    getRole: (id: string) => AsyncThunkAction,
    clearRole: () => AnyAction,
    createCategory: () => AnyAction,
    editCategory: () => AnyAction,
    deleteCategory: () => AnyAction,
    getCategories: (content: string) => AnyAction,
    saveRole: (data: RoleStateInterface["role"]) => Promise<any>
}