import React, { FC, Fragment } from "react";
import { RolesPropsInterface } from "../types/role.types";
import ListComponent from "../../common/components/list.component";
import RolesListCardComponent from "./rolesListCard.component";
import RolesFiltersComponent from "./rolesFilters.component";
import RolesHeaderComponent from "./rolesHeader.component";

const RolesListComponent: FC<RolesPropsInterface> = ({ roles, total, params, setFilters, clearFilters, setPage, setParams, filters, page }: RolesPropsInterface) => {

    const getColumns = () => {
        return [
            {
                key: "team",
                label: "Zespół",
                disableSort: true
            },
            {
                key: "name",
                label: "Nazwa roli",
            },
            {
                key: "permissions",
                label: "Suma uprawnień",
                disableSort: true
            },
            {
                key: "actions",
                label: "",
                disableSort: true
            }
        ]
    }

    return (
        <Fragment>
            <RolesHeaderComponent />
            <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2">
                <RolesFiltersComponent filters={filters} onClearFilters={() => clearFilters(true)} onFiltersChange={(filters: any) => setFilters(filters, true)} />
                <ListComponent
                    id="table-users"
                    items={roles}
                    total={total}
                    itemsOnPage={params.limit}
                    ItemCard={RolesListCardComponent}
                    currentPage={page}
                    currentSort={params.sort}
                    route="/users"
                    columns={getColumns()}
                    onPageChange={setPage}
                    onSortChange={(sortKey: string, sortValue: number) => setParams(Object.assign({}, params, { sort: { [sortKey]: sortValue } }), true)}
                />
            </div>
        </Fragment>
    );
}

export default RolesListComponent;