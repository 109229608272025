import React, { FC, Fragment } from "react";
import { GeneratorPropsInterface } from "../types/generator.types";
import ListComponent from "../../common/components/list.component";
import GeneratorListCardComponent from "./generatorListCard.component";
import GeneratorSortBarComponent from "./generatorSortBar.component";
import GeneratorFiltersComponent from "./generatorFilters.component";
import GeneratorHeaderComponent from "./generatorHeader.component";

const GeneratorComponent: FC<GeneratorPropsInterface> = ({ barcodes, total, params, setFilters, clearFilters, setPage, setParams, filters, page }: GeneratorPropsInterface) => {

    return (
        <Fragment>
            <GeneratorHeaderComponent />
            <div className="container mw100vw content-container ">
                <div className="row">
                    <GeneratorFiltersComponent filters={filters} onClearFilters={() => clearFilters(true)} onFiltersChange={(filters: any) => setFilters(filters, true)}/>
                    <div id="content-container-right" className="sort-container col-md-9 col-sm-12 scrolling py-4 pl-4 pr-2">
                        <GeneratorSortBarComponent
                            currentSort={params.sort}
                            onSortChange={(sortKey: string, sortValue: number) => setParams(Object.assign({}, params, { sort: { [sortKey]: sortValue } }), true)}
                        />
                        <ListComponent
                            id="table-barcodes"
                            items={barcodes}
                            total={total}
                            itemsOnPage={params.limit}
                            ItemCard={GeneratorListCardComponent}
                            currentPage={page}
                            currentSort={params.sort}
                            route="/generator"
                            columns={[]}
                            onPageChange={setPage}
                            onSortChange={() => null}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default GeneratorComponent;