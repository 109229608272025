import _ from "lodash"
import { AnyAction } from "redux"
import { isLoading } from "../../application/actions/application.actions"
import apiService from "../../application/services/api.service"
import { BarcodeInterface, GenericObject } from "../../common/types/common.types"
import { AppDispatch, GetState } from "../../config/store"
import { GeneratorActionType, GeneratorStateInterface } from "../types/generator.types";
import { toast } from "react-toastify"

export const getBarcodes = (hideLoader?: boolean) => async (dispatch: AppDispatch, getState: GetState) => {
    try {
        const { generator: { filters, params, page } } = getState();
        if (!hideLoader) {
            dispatch(isLoading(true));
        }

        let sendParams: GenericObject = {
            limit: params.limit,
            offset: (page - 1) * params.limit
        }

        if (_.isObject(params.sort) && !_.isEmpty(params.sort)) {
            let sortKey: string = _.keys(params.sort)[0];
            let sortValue: number = _.values(params.sort)[0];
            sendParams[`sort[${sortKey}]`] = sortValue;
        }
        if (filters.name) {
            sendParams.name = filters.name;
        }
        if (filters.ean) {
            sendParams.ean = filters.ean;
        }
        if (filters.seriesNr) {
            sendParams.seriesNr = filters.seriesNr;
        }

        let res = await apiService.get("/generator/generator", sendParams);
        if (res.success) {
            dispatch(setBarcodes(res.documents, res.total));
        } else {
            toast.error("Błąd pobierania kodów");
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast.error("Błąd pobierania kodów");
        dispatch(isLoading(false));
    }
}

export const setBarcodes = (barcodes: Array<BarcodeInterface>, total: number): AnyAction => {
    return {
        type: GeneratorActionType.SET_BARCODES,
        barcodes,
        total
    }
}

export const clearBarcodes = (): AnyAction => {
    return {
        type: GeneratorActionType.CLEAR_BARCODES
    }
}

export const setFilters = (filters: GeneratorStateInterface["filters"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: GeneratorActionType.SET_FILTERS,
        filters
    })
    if (reloadList) {
        dispatch(getBarcodes(true));
    }
}

export const clearFilters = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: GeneratorActionType.CLEAR_FILTERS
    });
    if (reloadList) {
        dispatch(getBarcodes(true));
    }
}

export const setParams = (params: GeneratorStateInterface["params"], reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: GeneratorActionType.SET_PARAMS,
        params
    });
    if (reloadList) {
        dispatch(getBarcodes(true));
    }
}

export const clearParams = (reloadList?: boolean) => async (dispatch: AppDispatch) => {
    dispatch({
        type: GeneratorActionType.CLEAR_PARAMS
    })
    if (reloadList) {
        dispatch(getBarcodes(true));
    }
}

export const setPage = (page: number): AnyAction => {
    if (page <= 0) page = 1;
    return {
        type: GeneratorActionType.SET_PAGE,
        page
    }
}