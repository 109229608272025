import React, { FC, useState } from 'react';
import ReportsPropsInterface from '../types/reports.types';
import Autocomplete from '../../common/components/autocomplete.component';
import TextInputComponent from '../../common/components/textInput.component';
const ReportsGmpComponent: FC<ReportsPropsInterface> = ({ onDataChange, warehouseError, productsError, seriesNrError }: ReportsPropsInterface) => {

    const type = "5";
    const [warehouse, setWarehouse] = useState("");
    const [product, setProduct] = useState("");
    const [seriesNr, setSeriesNr] = useState("");
    let props = { type: type, product: product, warehouse: warehouse, seriesNr: seriesNr };
    const handleFindWarehouse = (value: any) => {
        if (value === null) {
            setWarehouse("");
            props.warehouse = "";
            onDataChange(props);
        }
        if (value) {
            setWarehouse(value.code);
            props.warehouse = value.code;
            onDataChange(props);
        }
    }
    const handleFindProduct = (value: any) => {
        if (value === null) {
            setProduct("");
            props.product = "";
            onDataChange(props);
        }
        if (value) {
            setProduct(value.code);
            props.product = value.code;
            onDataChange(props);
        }
    }
    const handleSeriesNr = (value: any) => {
        if (value) {
            setSeriesNr(value);
            props.seriesNr = value;
            onDataChange(props);
        } else {
            setSeriesNr("");
            props.product = "";
            onDataChange(props);
        }
    }
    return (
        <div id="report-form-gmp" role="tabpanel" className="report-form tabpane fade" aria-labelledby="report-form-gmp-tab">
            <div className="form-group input-group my-0">
                <div className="has-float-label">
                    <Autocomplete
                        id="report-warehouse"
                        multi={false}
                        placeholder="Szukaj"
                        label="Magazyn"
                        url="/warehouse/warehouse"
                        optionLabelField="wyszukaj"
                        optionIdField="reportWarehouseId"
                        onOptionSelect={(id: string, value: any) => handleFindWarehouse(value)}
                        value={warehouse}
                        queryFields={["name", "code", "_id"]}
                        additionalParams={{ limit: 5, select: "name,code,_id" }}
                        parseOption={(option: any) => {
                            option.warehouseId = option._id;
                            return option;
                        }}
                        hideChips={false}
                        readonly={false}
                        preload={false}
                        error={warehouseError}
                    />
                </div>
            </div>
            <div className="form-group input-group my-0 mx-3">
                <div className="has-float-label">
                    <Autocomplete
                        id="report-product"
                        multi={false}
                        placeholder="Szukaj"
                        label="Produkt"
                        url="/product/product"
                        optionLabelField="wyszukaj"
                        optionIdField="reportProductId"
                        onOptionSelect={(id: string, value: any) => handleFindProduct(value)}
                        value={product}
                        queryFields={["name", "code", "_id"]}
                        additionalParams={{ limit: 5, select: "name,code,_id" }}
                        parseOption={(option: any) => {
                            option.productId = option._id;
                            return option;
                        }}
                        hideChips={false}
                        readonly={false}
                        preload={false}
                        error={productsError}
                    />
                </div>
            </div>
            <div className="form-group input-group my-0">
                <div className="has-float-label">
                    <TextInputComponent
                        type="text"
                        id="reportSeriesNr"
                        placeholder="Wpisz numer serii"
                        label="Numer serii"
                        value={seriesNr}
                        onChange={(id, value) => { handleSeriesNr(value); }}
                        error={seriesNrError}
                    />
                </div>
            </div>
        </div>
    );
};
export default ReportsGmpComponent;