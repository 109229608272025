import { AnyAction } from "redux";
import { AsyncThunkAction, Nullable, GenericObject } from "../../common/types/common.types";
import { WarehouseInterface, WarehouseStateInterface } from './warehouse.types';

// Positions
export enum PositionsActionType {
    SET_POSITIONS = 'POSITION_SET_POSITIONS',
    CLEAR_POSITIONS = 'POSITION_CLEAR_POSITIONS',
    SET_FILTERS = 'POSITION_SET_FILTERS',
    CLEAR_FILTERS = 'POSITION_CLEAR_FILTERS',
    SET_PARAMS = 'POSITION_SET_PARAMS',
    CLEAR_PARAMS = 'POSITION_CLEAR_PARAMS',
    SET_PAGE = 'POSITION_SET_PAGE',
    SET_POSITION = 'POSITION_SET_POSITION',
    CLEAR_POSITION = 'POSITION_CLEAR_POSITION',
    SET_POSITION_HISTORY = 'POSITION_SET_POSITION_HISTORY',
    CLEAR_POSITION_HISTORY = 'POSITION_CLEAR_POSITION_HISTORY',
    SET_HISTORY_PAGE = 'POSITION_SET_HISTORY_PAGE',
}

export interface PositionInterface {
    code: string,
    name: string,
    _id?: string,
    createdAt?: string,
    updatedAt?: string,
    products: Array<any>,
    warehouse: {
        warehouseId: string,
        name: string,
        code: string
    },
    barcode: string,
    type: number
}

export interface PositionStateInterface {
    positions: Array<PositionInterface>
    total: number,
    filters: {
        name: string,
        code: string,
    },
    params: {
        limit: number,
        offset: number,
        sort: Nullable<{ [fieldname: string]: number }>
    },
    page: number,
    position: Nullable<PositionInterface>
}

export interface PositionsPropsInterface {
    positions: PositionStateInterface["positions"],
    total: PositionStateInterface["total"],
    filters: PositionStateInterface["filters"],
    params: PositionStateInterface["params"],
    page: PositionStateInterface["page"],
    getPositions: () => AsyncThunkAction,
    clearPositions: () => AnyAction,
    setFilters: (filters: PositionStateInterface["filters"], reloadList?: boolean) => AsyncThunkAction,
    clearFilters: (reloadList?: boolean) => AsyncThunkAction,
    setParams: (params: PositionStateInterface["params"], reloadList?: boolean) => AsyncThunkAction,
    clearParams: (reloadList?: boolean) => AsyncThunkAction,
    setPage: (page: number) => AnyAction
}

export interface PositionListCardPropsInterface {
    item: PositionInterface,
    deleteWarehousePosition: (id: string) => AnyAction
}
export interface PositionsListPropsInterface {
    positions: Array<PositionInterface>,
    warehouseId: string | undefined,
    positionTotal: WarehouseStateInterface["positionTotal"],
    positionFilters: WarehouseStateInterface["positionFilters"],
    positionParams: WarehouseStateInterface["positionParams"],
    positionPage: WarehouseStateInterface["positionPage"],
    setParamsPage: (page: number) => AnyAction
}

export interface PositionsFiltersPropsInterface {
    onFiltersChange: (filters: PositionStateInterface["filters"]) => void,
    onClearFilters: () => void,
    filters: any
}

// Position

export interface PositionPropsInterface {
    warehouse: WarehouseInterface,
    getWarehouse: (id: string) => AsyncThunkAction,
    clearWarehouse: () => AnyAction,
    dict: GenericObject
    savePosition: (data: {
        code: string
        type: number,
        warehouse: {
            warehouseId: string
        }
    }) => Promise<any>,
}

export interface PositionDetailsPropsInterface {
    position: PositionInterface,
    getPosition: (id: string) => AsyncThunkAction,
    clearPosition: () => AnyAction,
    getPositionHistory: (code: string) => AsyncThunkAction,
    positionHistory: Array<any>,
    clearPositionHistory: () => AnyAction,
    positionHistoryParams: WarehouseStateInterface["positionHistoryParams"],
    positionHistoryTotal: WarehouseStateInterface["positionHistoryTotal"],
    setHistoryPage: (page: number) => AnyAction
    positionHistoryPage: number
}

export interface PositionProductsListPropsInterface {
    products: Array<any>
    active: boolean
}
export interface PositionHistoryListPropsInterface {
    history: Array<any>
    active: boolean
    itemsOnPage: number,
    total: number,
    currentPage: number,
    onPageChange: (page: number) => void,
    onSortChange?: (sortKey: string, sortValue: number) => void,
    route?: string
}

export interface PositionProductsListCardPropsInterface {
    product: GenericObject,
    index: number
}

export interface PositionHistoryListCardPropsInterface {
    item: GenericObject
}