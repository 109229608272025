import { ProductsStateInterface, ProductsActionType } from "../types/product.types";

const initialState: ProductsStateInterface = {
  products: [],
  total: 0,
  filters: {
    name: "",
    category: "",
    EAN: "",
    code: "",
    type: "",
    source: "",
    isGratis: null,
    PAO: null,
    active: null,
  },
  params: {
    limit: 20,
    offset: 0,
    sort: { name: 1 },
  },
  page: 1,
};

const productsReducer = (state = initialState, action: any) => {
  const { type } = action;

  switch (type) {
    case ProductsActionType.SET_PRODUCTS: {
      return {
        ...state,
        products: action.products,
        total: action.total,
      };
    }
    case ProductsActionType.CLEAR_PRODUCTS: {
      return {
        ...state,
        products: [],
        total: 0,
      };
    }
    case ProductsActionType.SET_FILTERS: {
      return {
        ...state,
        filters: action.filters,
      };
    }
    case ProductsActionType.CLEAR_FILTERS: {
      return {
        ...state,
        filters: {
          name: "",
          category: "",
          EAN: "",
          code: "",
          type: "",
          source: "",
          isGratis: null,
          PAO: null,
          active: null,
        },
      };
    }
    case ProductsActionType.SET_PARAMS: {
      return {
        ...state,
        params: action.params,
      };
    }
    case ProductsActionType.SET_PAGE: {
      return {
        ...state,
        page: action.page,
      };
    }
    default:
      return state;
  }
};

export default productsReducer;
