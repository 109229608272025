import React, { FC, Fragment, useState, useEffect } from "react";
import _ from "lodash";
import { CategoryPropsInterface } from "../types/category.types";
import CheckboxComponent from "./checkbox.component";
import { CategoryInterface } from "../types/component.types";

import arrowIcon from "../images/ico-arrowdown.svg";
// import plusIcon from "../images/ico-plus.svg";

const CategoryComponent: FC<CategoryPropsInterface> = ({
  id,
  categories,
  error,
  documentCategories,
  onChange,
}: CategoryPropsInterface) => {
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    if (rendered) {
      onChange(id, selectedCategories);
    }
  }, [selectedCategories, rendered]);

  const checkIfCategorySelected = (
    categories: Array<any>,
    documentCategories: Array<CategoryInterface>
  ) => {
    categories = categories.map((category, key) => {
      let index = _.findIndex(documentCategories, (cat) => cat.breadcrumb === category.breadcrumb);

      if (index > -1) {
        category.checked = true;
        onCategoryCheck(category._id, category.name, category.parent, true, null);
      }
      if (_.size(category.children) > 0) {
        checkIfCategorySelected(category.children, documentCategories);
      }
      return category;
    });
  };

  useEffect(() => {
    if (_.size(categories) > 0 && _.size(documentCategories) > 0) {
      checkIfCategorySelected(categories, documentCategories);
    }
  }, [categories, documentCategories]);

  const onCategoryCheck = (_id: string, name: string, parent: any, val: boolean, path: any) => {
    if (rendered === false) setRendered(true);

    if (path) _.set(_.get(categories, path), "checked", val);

    if (val === true)
      setSelectedCategories((prevState) => [...prevState, { _id, name, parent: parent || "" }]);
    else setSelectedCategories((prevState) => prevState.filter((item) => item._id !== _id));
  };

  const renderCategories = () => {
    return (
      <Fragment>
        {categories.map(({ name, parent, children, _id, checked }, key) => {
          return (
            <li key={key}>
              <div className="d-flex">
                <CheckboxComponent
                  onChange={(id, val) => onCategoryCheck(id, name, parent, val, `${key}`)}
                  checked={checked || false}
                  id={_id}
                  label={name}
                />
                {_.size(children) > 0 && (
                  <button
                    className="subcategories badge badge-light collapsed border-0"
                    type="button"
                    data-toggle="collapse"
                    data-target={`#category${key}children`}
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    <img src={arrowIcon} className="cat-arrow" alt="arrow" />
                    Rozwiń
                  </button>
                )}
              </div>
              {children && _.size(children) > 0
                ? renderCategoryChildrens(children, `[${key}].children`, `category${key}children`)
                : null}
            </li>
          );
        })}
        {/* <li className="add-category-input py-2">
          <a
            className="btn btn-secondary btn-secondary px-2"
            type="button"
            data-toggle="modal"
            href="#product-categories-modal"
          >
            <img src={plusIcon} className="mb-1" alt="" />
            Zarządzaj kategoriami
          </a>
        </li> */}
      </Fragment>
    );
  };

  const renderCategoryChildrens = (childrens: any, path: string, targetId: string) => {
    return (
      <ul className="collapse" id={targetId}>
        <li>
          {childrens.map(({ name, parent, children, _id, checked }: any, key: number) => {
            let newTargetId = `${targetId}${key}children`;
            return (
              <Fragment key={key}>
                <div className="d-flex">
                  <CheckboxComponent
                    onChange={(id, val) =>
                      onCategoryCheck(id, name, parent, val, `${path}[${key}]`)
                    }
                    checked={checked || false}
                    id={_id}
                    label={name}
                  />
                  {_.size(children) > 0 && (
                    <button
                      className="subcategories badge badge-light collapsed border-0"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#${newTargetId}`}
                      aria-expanded="false"
                      aria-controls="collapseExample"
                    >
                      <img src={arrowIcon} className="cat-arrow" alt="arrow" />
                      Rozwiń
                    </button>
                  )}
                </div>
                {children && _.size(children) > 0
                  ? renderCategoryChildrens(children, `${path}[${key}].children`, newTargetId)
                  : null}
              </Fragment>
            );
          })}
        </li>
      </ul>
    );
  };

  return (
    <Fragment>
      <div className="d-flex align-items-stretch category-adder px-0 mr-3">
        <div className="has-float-label pt-4">
          <label htmlFor="product-category">Kategoria</label>
        </div>
        <ul
          className={`col-sm-12 form5-frame pt-3 form-frame scrolling rounded mb-0 ${
            error ? "is-invalid" : ""
          }`}
        >
          {renderCategories()}
        </ul>
      </div>
      {error && <span className="is-invalid-alert">{error.toString()}</span>}
    </Fragment>
  );
};

export default CategoryComponent;
