import _ from "lodash";
import React, { FC } from "react";
import DictService from "../../application/services/dict.service";
import Autocomplete from "../../common/components/autocomplete.component";
import SelectComponent from "../../common/components/select.component";
import TextInputComponent from "../../common/components/textInput.component";
import resetIcon from "../../common/images/ico-wyczysc.svg";
import collapseIcon from "../../common/images/ico-zwin.svg";
import { DocumentsFiltersPropsInterface } from "../types/document.types";
import { useHistory, useParams } from "react-router";

const completionStatusOptions = [
    {
        label: "Do realizacji",
        value: "1"
    },
    {
        label: "W trakcie realizacji",
        value: "2"
    },
    {
        label: "Zrealizowany",
        value: "3"
    }
];

const inspectionStatusOptions = [
    {
        label: "Do sprawdzenia",
        value: "1"
    },
    {
        label: "W trakcie sprawdzania",
        value: "2"
    },
    {
        label: "Sprawdzony",
        value: "3"
    }
];

const DocumentsListFiltersComponent: FC<DocumentsFiltersPropsInterface> = ({ filters, onClearFilters, onFiltersChange }: DocumentsFiltersPropsInterface) => {

    const history = useHistory();
    const { page }: any = useParams();

    const handleFilterChange = (field: string, value: any) => {
        let newFilters = Object.assign({}, filters, {
            [field]: value
        });
        if (page !== "1") {
            history.push("/documents/page/1");
        }
        onFiltersChange(newFilters);
    }

    const handleFiltersReset = () => {
        let nameInput: any = document.getElementById("name");
        if (nameInput) {
            nameInput.value = '';
        }
        let warehouseInput: any = document.getElementById("warehouse");
        if (warehouseInput) {
            warehouseInput.value = '';
        }
        let completedInput: any = document.getElementById("completed");
        if (completedInput) {
            completedInput.checked = '';
        }

        if (page !== "1") {
            history.push("/documents/page/1");
        }
        onClearFilters();
    }

    const getDocumentOptions = () => {
        let setting = DictService.getSetting("supportedDocuments", []);
        return _.filter(setting, (doc: any) => ["add", "subtract", "correction"].indexOf(doc.action) > -1).map((doc: any) => {
            return { label: doc.type, value: doc.type };
        });
    }

    const handleCollapseClick = () => {
        $('#filter-sidebar').toggleClass("toggled");
        $('#filter-toggle').toggleClass("toggled");
        if ($('#filter-toggle').hasClass('toggled')) {
            $('#filter-list').addClass("fadeOut");
            $('#filter-list').removeClass("fadeIn");
        } else {
            $('#filter-list').addClass("fadeIn");
            $('#filter-list').removeClass("fadeOut");
        }
        $('#content-container-right').toggleClass("col-md-12");
    }

    return (<div id="filter-sidebar" className="col-md-3 col-sm-12 bg-white py-4 px-4 d-flex flex-column">
        <div id="filter-toggle" className="filter-collapse" onClick={handleCollapseClick}><img src={collapseIcon} alt="zwiń"/></div>
        <h3 className="mb-4 align-items-center">Filtry
            <div id="filter-toggle3"><img src="gfx/ico-x.svg" alt="zwiń"/></div>
        </h3>
        <div id="filter-list" className="animated">
            <Autocomplete
                url={"/warehouse/warehouse"}
                id="warehouse"
                multi={true}
                onOptionSelect={handleFilterChange}
                placeholder="Szukaj magazynu"
                label="Magazyn"
                optionIdField="warehouseId"
                optionLabelField="name"
                value={filters.warehouse}
                queryFields={["name", "code"]}
                additionalParams={{ limit: 5, select: "name,code" }}
                parseOption={(option: any) => {
                    option.warehouseId = option._id;
                    return option;
                }}
                hideChips={false}
                readonly={false}
                preload={false}
                customClass="form-group input-group"
            // error={formik.errors.warehouse}
            />
            <Autocomplete
                url={"/warehouse/warehouse"}
                id="relatedWarehouse"
                multi={true}
                onOptionSelect={handleFilterChange}
                placeholder="Szukaj magazynu"
                label="Magazyn powiązany"
                optionIdField="warehouseId"
                optionLabelField="name"
                value={filters.relatedWarehouse}
                queryFields={["name", "code"]}
                additionalParams={{ limit: 5, select: "name,code" }}
                parseOption={(option: any) => {
                    option.warehouseId = option._id;
                    return option;
                }}
                hideChips={false}
                readonly={false}
                preload={false}
                customClass="form-group input-group"
            // error={formik.errors.warehouse}
            />
            <div className="row my-0">
                <div className="col-md-6 col-sm-12">
                    <SelectComponent
                        id="type"
                        label="Typ dokumentu"
                        onChange={handleFilterChange}
                        value={filters.type}
                        options={getDocumentOptions()}
                        customClass="form-group input-group my-0"
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <SelectComponent
                        id="transportType"
                        label="Typ transportu"
                        onChange={handleFilterChange}
                        value={filters.transportType}
                        options={DictService.getDictAsOptions(5)}
                        customClass="form-group input-group my-0"
                    />
                </div>
            </div>
            <div className="row my-3">
                <div className="col-md-6 col-sm-12">
                    <SelectComponent
                        id="completionStatus"
                        label="Status"
                        onChange={handleFilterChange}
                        value={filters.completionStatus}
                        options={completionStatusOptions}
                        customClass="form-group input-group my-0"
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <SelectComponent
                        id="inspectionStatus"
                        label="Status sprawdzania"
                        onChange={handleFilterChange}
                        value={filters.inspectionStatus}
                        options={inspectionStatusOptions}
                        customClass="form-group input-group my-0"
                    />
                </div>
            </div>
            <TextInputComponent
                label="Produkt"
                type="text"
                placeholder="Produkt"
                id="product"
                onChange={handleFilterChange}
                value={filters.product}
                hideLabel={true}
            />
            <TextInputComponent
                label="Klient"
                type="text"
                placeholder="Klient"
                id="customer"
                onChange={handleFilterChange}
                value={filters.customer}
                hideLabel={true}
            />
            <TextInputComponent
                label="Realizujący"
                type="text"
                placeholder="Realizujący"
                id="completionUser"
                onChange={handleFilterChange}
                value={filters.completionUser}
                hideLabel={true}
            />
            <TextInputComponent
                label="Sprawdzający"
                type="text"
                placeholder="Sprawdzający"
                id="inspectionUser"
                onChange={handleFilterChange}
                value={filters.inspectionUser}
                hideLabel={true}
            />
            <div className="filter-clear float-right color-gray align-self-end" onClick={handleFiltersReset}>
                <p>Wyczyść wszystko <img src={resetIcon} alt="reset"/></p>
            </div>
        </div>
    </div>);
}

export default DocumentsListFiltersComponent;