import React, { FC, Fragment, useEffect, useState } from "react";
import _ from "lodash";
import { Formik } from "formik";
import { initialValues, validationSchema } from "../schemas/product.schema";
import { ProductPropsInterface } from "../types/product.types";
import DictService from "../../application/services/dict.service";

import CropperComponent from "../../common/components/cropper.component";
import ProductHeaderComponent from "./productHeader.component";
import TextInputComponent from "../../common/components/textInput.component";
import SelectComponent from "../../common/components/select.component";
import CategoryComponent from "../../common/components/category.component";

import stepCompletedIcon from "../../common/images/step-completed.svg";
import arrowDownIcon from "../../common/images/ico-arrowdown.svg";
import saveIcon from "../../common/images/save-tab.svg";
import { useHistory } from "react-router";
// import CategoriesModal from "../../common/components/categoriesModal.component";

const ProductComponent: FC<ProductPropsInterface> = ({
  categories,
  product,
  saveProduct,
}: ProductPropsInterface) => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(1);
  const isLastStep = activeStep === 3;
  const currentValidationSchema = validationSchema[activeStep - 1];
  const [productInitialValues, setProductInitialValues] = useState(initialValues);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [availableUnits, setAvailableUnits] = useState<any[]>([]);
  const [availableSources, setAvailableSources] = useState<any[]>([]);
  const [availableTypes, setAvailableTypes] = useState<any[]>([]);
  const [avatar, setAvatar] = useState("");

  useEffect(() => {
    getProductDict(1);
    getProductDict(3);
    getProductDict(4);
  }, []);

  useEffect(() => {
    if (_.size(availableUnits) > 0 && _.size(availableTypes) > 0 && product) {
      if (_.get(product, "type")) {
        let type = _.find(availableTypes, { value: _.get(product, "type").toString() });
        if (type) setSelectedType(type.label);
      }

      if (_.get(product, "type")) {
        let unit = _.find(availableUnits, { value: _.get(product, "unit").toString() });
        if (unit) setSelectedUnit(unit.label);
      }
    }
  }, [availableTypes, availableUnits, product]);

  useEffect(() => {
    if (product !== null && _.get(product, "_id")) {
      setProductInitialValues(Object.assign({}, initialValues, product));
    }
  }, [product]);

  const getProductDict = (type: number) => {
    let dict = DictService.getDict(type)?.values || [];
    let documents = [{ label: "", value: "" }];

    if (dict.length > 0) {
      documents = dict.map(({ name: label, value }) => ({
        label,
        value: value.toString(),
      }));
    }

    if (type === 1) setAvailableTypes(documents);
    if (type === 3) setAvailableUnits(documents);
    if (type === 4) setAvailableSources(documents);
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={productInitialValues}
        validationSchema={currentValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize={true}
        onSubmit={(values, { setErrors }) => {
          if (isLastStep) {
            saveProduct(Object.assign({}, values, { avatar: avatar })).then((res) => {
              if (res.success) {
                history.push(`/products/${res.document}`);
              } else if (res.errors) {
                setErrors(res.errors);
              }
            });
          }
        }}
      >
        {(formik) => (
          <Fragment>
            <form id="form" onSubmit={formik.handleSubmit}>
              {renderFormHeader(formik)}
              <div className="d-flex align-items-center justify-content-center">
                <div className="col-md-10 col-sm-12 d-flex flex-wrap py-5 my-5">
                  {renderStep(formik)}
                </div>
              </div>
            </form>
          </Fragment>
        )}
      </Formik>
    );
  };

  const renderStep = (formik: any) => {
    if (activeStep === 1) {
      return (
        <Fragment>
          <div className="col-md-8 col-sm-12 d-flex flex-column product-add-container">
            <TextInputComponent
              label="Nazwa produktu"
              type="text"
              placeholder="Nazwa produktu"
              id="name"
              onChange={formik.setFieldValue}
              value={formik.values.name || ""}
              error={formik.errors.name}
              customClass="form-group input-group"
            />
            <div className="d-flex flex-wrap flex-grow-1 product-categories">
              <div className="col-md-7 col-sm-12 px-0 product-category-adder">
                <CategoryComponent
                  id="categories"
                  categories={categories}
                  error={formik.errors.categories}
                  value={formik.values.categories}
                  onChange={formik.setFieldValue}
                  documentCategories={_.get(product, "categories", [])}
                />
              </div>

              <div className="col-md-5 col-sm-12 px-0 d-flex flex-column">
                <TextInputComponent
                  label="Kod produktu"
                  type="text"
                  placeholder="Kod produktu"
                  id="code"
                  onChange={formik.setFieldValue}
                  value={formik.values.code || ""}
                  error={formik.errors.code}
                  customClass="form-group input-group"
                />

                <SelectComponent
                  id="source"
                  onChange={formik.setFieldValue}
                  label="Źródło pochodzenia produktu"
                  options={availableSources}
                  value={formik.values.source || ""}
                  error={formik.errors.source}
                />

                <SelectComponent
                  id="type"
                  onChange={(id, value) => {
                    formik.setFieldValue(id, value);
                    setSelectedType(_.find(availableTypes, { value }).label);
                  }}
                  label="Typ produktu"
                  options={availableTypes}
                  value={formik.values.type || ""}
                  error={formik.errors.type}
                />

                <SelectComponent
                  id="unit"
                  onChange={(id, value) => {
                    formik.setFieldValue(id, value);
                    setSelectedUnit(_.find(availableUnits, { value }).label);
                  }}
                  label="Jednostka miary"
                  options={availableUnits}
                  value={formik.values.unit || ""}
                  error={formik.errors.unit}
                />
              </div>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 px-0">
            <CropperComponent
              defaultImage={_.get(product, "avatar")}
              imageHeight={100}
              imageWidth={100}
              id="avatar"
              onCrop={(id: string, file: any) => setAvatar(file)}
            />
          </div>

          <div className="col-sm-12 d-flex justify-content-center pt-5">
            <a
              href="# "
              className="color-gray next-step"
              onClick={() => handleChangeStep(formik, activeStep + 1)}
            >
              Następny krok <img src={arrowDownIcon} alt="" />
            </a>
          </div>
        </Fragment>
      );
    }

    if (activeStep === 2) {
      return (
        <Fragment>
          <div className="row col-sm-12">
            <div className="col-md-6 col-sm-12 px-0">
              <TextInputComponent
                label="Ilość jednostek w kartonie zbiorczym"
                type="text"
                placeholder="Wpisz ilość"
                id="parameters.unitsInPackage"
                onChange={formik.setFieldValue}
                value={formik.values.parameters.unitsInPackage}
                error={_.get(formik.errors, "parameters.unitsInPackage")}
                customClass="form-group input-group"
                info="szt./karton"
              />
            </div>

            <div className="col-md-6 col-sm-12 px-0">
              <TextInputComponent
                label="Ilość jednostek na palecie euro"
                type="text"
                placeholder="Wpisz ilość"
                id="parameters.packagesOnEuroPallet"
                onChange={formik.setFieldValue}
                value={formik.values.parameters.packagesOnEuroPallet}
                error={_.get(formik.errors, "parameters.packagesOnEuroPallet")}
                customClass="form-group input-group"
                info="szt./paleta"
              />
            </div>
          </div>

          <div className="row col-sm-12 px-0">
            <div className="col-sm-4 pr-0">
              <TextInputComponent
                label="PKWiU"
                type="text"
                placeholder="PKWiU"
                id="parameters.pkwiu"
                onChange={formik.setFieldValue}
                value={formik.values.parameters.pkwiu}
                error={_.get(formik.errors, "parameters.pkwiu")}
                customClass="form-group input-group"
              />
            </div>

            <div className="col-sm-4 pr-0">
              <TextInputComponent
                label="EAN"
                type="text"
                placeholder="EAN"
                id="ean"
                onChange={formik.setFieldValue}
                value={formik.values.ean || ""}
                error={formik.errors.ean}
                customClass="form-group input-group"
              />
            </div>

            <div className="col-sm-4">
              <div className="form-group input-group">
                <div className="has-float-label d-flex flex-no-wrap form-frame rounded pl-3 range-selector">
                  <div className="date-range">
                    <input
                      // id="expiresIn"
                      type="range"
                      name="rangeInput"
                      min="0"
                      max="24"
                      step="1"
                      value={formik.values.expiresIn || 12}
                      onChange={(el) => formik.setFieldValue("expiresIn", el.target.value)}
                    />

                    <input
                      className="form-control ml-3 border-0"
                      id="expiresIn"
                      value={formik.values.expiresIn || 12}
                      type="text"
                      disabled
                    />
                  </div>

                  <label htmlFor="date-valid">Okres ważności</label>

                  <div className="input-group-append">
                    <span className="input-group-text rounded-0">miesięcy</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row col-sm-12 ">
            <hr className="mt-0" />
          </div>

          <div className="row col-sm-12 px-0">
            <div className="col-md-4 col-sm-6">
              <TextInputComponent
                label="Waga brutto pełnego kartonu"
                type="text"
                placeholder="Wpisz ilość"
                id="parameters.packageWeight"
                onChange={formik.setFieldValue}
                value={formik.values.parameters.packageWeight}
                error={_.get(formik.errors, "parameters.packageWeight")}
                customClass="form-group input-group"
                info="kg/karton"
              />
            </div>

            <div className="col-md-4 col-sm-6 px-0">
              <TextInputComponent
                label="Waga brutto jednej sztuki"
                type="text"
                placeholder="Wpisz ilość"
                id="parameters.singleUnitWeight"
                onChange={formik.setFieldValue}
                value={formik.values.parameters.singleUnitWeight}
                error={_.get(formik.errors, "parameters.singleUnitWeight")}
                customClass="form-group input-group"
                info="kg/opakowanie"
              />
            </div>

            <div className="col-md-4 col-sm-6">
              <TextInputComponent
                label="Objętość całkowita"
                type="text"
                placeholder="Wpisz ilość"
                id="parameters.totalVolume"
                onChange={formik.setFieldValue}
                value={formik.values.parameters.totalVolume}
                error={_.get(formik.errors, "parameters.totalVolume")}
                customClass="form-group input-group"
                info="m2"
              />
            </div>
          </div>

          <div className="row col-sm-12 px-0">
            <div className="col-md-4 col-sm-6">
              <TextInputComponent
                label="Szerokość"
                type="text"
                placeholder="Wpisz ilość"
                id="parameters.width"
                onChange={formik.setFieldValue}
                value={formik.values.parameters.width}
                error={_.get(formik.errors, "parameters.width")}
                customClass="form-group input-group"
                info="m"
              />
            </div>

            <div className="col-md-4 col-sm-6 px-0">
              <TextInputComponent
                label="Długość"
                type="text"
                placeholder="Wpisz ilość"
                id="parameters.length"
                onChange={formik.setFieldValue}
                value={formik.values.parameters.length}
                error={_.get(formik.errors, "parameters.length")}
                customClass="form-group input-group"
                info="m"
              />
            </div>

            <div className="col-md-4 col-sm-6">
              <TextInputComponent
                label="Wysokość"
                type="text"
                placeholder="Wpisz ilość"
                id="parameters.height"
                onChange={formik.setFieldValue}
                value={formik.values.parameters.height}
                error={_.get(formik.errors, "parameters.height")}
                customClass="form-group input-group"
                info="m"
              />
            </div>
          </div>

          <div className="col-sm-12 d-flex justify-content-center pt-5">
            <a
              href="# "
              className="color-gray next-step"
              onClick={() => handleChangeStep(formik, activeStep + 1)}
            >
              Następny krok <img src={arrowDownIcon} alt="" />
            </a>
          </div>
        </Fragment>
      );
    }

    if (activeStep === 3) {
      return (
        <div className="row col-sm-12 px-0">
          <div className="col-md-4 col-sm-12 px-0">
            <CropperComponent
              defaultImage={_.get(product, "avatar")}
              imageHeight={100}
              imageWidth={100}
              id="avatar"
              onCrop={(id: string, file: any) => setAvatar(file)}
            />
          </div>

          <div className="col-sm-8 spec-list-cont">
            <h2 className="text-center">{formik.values.name}</h2>

            <div className="d-flex flex-no-wrap justify-content-center">
              <div className="mx-3">
                <h5 className="product-preview-codes">
                  <span>KATEGORIE:</span>
                  {formik.values.categories.map((category: any, index: number) => {
                    if (index < _.size(formik.values.categories) - 1) return category.name + ", ";
                    else return category.name;
                  })}
                </h5>
              </div>

              <div className="mx-3">
                <h5 className="product-preview-codes">
                  <span>EAN:</span>
                  {formik.values.ean}
                </h5>
              </div>

              <div className="mx-3">
                <h5 className="product-preview-codes">
                  <span>KOD:</span> {formik.values.code}
                </h5>
              </div>
            </div>

            <div className="row col-sm-12 ">
              <hr className="mt-0" />
            </div>

            <div className="row col-sm-12 ">
              <div className="specs-list">
                Typ<span>{selectedType}</span>
              </div>

              <div className="specs-list">
                Ilość opakowań jednostkowych w kartonie zbiorczym
                <span>{formik.values.unitsInPackage}/karton</span>
              </div>

              <div className="specs-list">
                Termin ważności<span>{formik.values.expiresIn} miesiecy</span>
              </div>

              <div className="specs-list">
                Jednostka miary<span>{selectedUnit}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleChangeStep = (formik: any, step: number) => {
    if (step > activeStep) {
      formik.validateForm().then((res: any) => {
        if (_.isEmpty(res)) setActiveStep(step);
      });
    }
    if (step > activeStep && step - activeStep > 1) {
      formik.validateForm().then((res: any) => {
        if (_.isEmpty(res)) {
          setActiveStep(step - 1);
          formik.validateForm().then((res: any) => {
            if (_.isEmpty(res)) setActiveStep(step);
          });
        }
      });
    }
    if (step < activeStep) setActiveStep(step);
  };

  const renderFormHeader = (formik: any) => {
    return (
      <div id="tabs-header" className="row d-flex boxshadow p-0 m-0">
        <div
          className={`col-md-4 col-sm-10 add-tab ${activeStep === 1 && "active"}`}
          onClick={() => handleChangeStep(formik, 1)}
        >
          <div>
            <h3>Dane podstawowe</h3>
            <h4>Krok 1.</h4>
          </div>
          {activeStep > 1 && <img src={stepCompletedIcon} className="step-status mr-3" alt="" />}
        </div>
        <div
          className={`col-md-3 col-sm-10 add-tab ${activeStep === 2 && "active"}`}
          onClick={() => handleChangeStep(formik, 2)}
        >
          <div>
            <h3>Dane magazynowe</h3>
            <h4>Krok 2.</h4>
          </div>
          {activeStep > 2 && <img src={stepCompletedIcon} className="step-status mr-3" alt="" />}
        </div>
        <div className="col-md-3 col-sm-10 add-tab" onClick={() => handleChangeStep(formik, 3)}>
          <div>
            <h3>Podsumowanie</h3>
            <h4>Krok 3.</h4>
          </div>
        </div>
        <button
          type="submit"
          className="col-md-2 col-sm-10 add-tab save-tab btn-wms"
          style={{ opacity: !isLastStep ? "0.5" : "" }}
          disabled={!isLastStep}
        >
          <div>
            <img src={saveIcon} alt="save" />
            <span>Zapisz</span>
          </div>
        </button>
      </div>
    );
  };

  return (
    <Fragment>
      {/* <CategoriesModal id="product-categories-modal" categories={categories} /> */}
      <ProductHeaderComponent />
      <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 px-4 ">
        <div className="bg-white boxshadow p-0">{renderForm()}</div>
      </div>
    </Fragment>
  );
};

export default ProductComponent;
