import React, { FC, useEffect } from "react";
import { useParams } from "react-router";
import RolesListComponent from "../components/rolesList.component";
import { getRoles, clearRoles, setFilters, clearFilters, setPage, setParams, clearParams } from '../actions/roles.actions';
import { connect } from 'react-redux'
import { AppDispatch, RootState } from "../../config/store";
import { RolesPropsInterface, RolesStateInterface } from "../types/role.types";

const RolesListContainer: FC<RolesPropsInterface> = (props: RolesPropsInterface) => {
    const { getRoles, clearRoles, setPage, page } = props;
    let params: any = useParams();

    useEffect(() => {
        setPage(parseInt(params.page));
        getRoles();
        return () => {
            clearRoles();
        }
    }, [getRoles, clearRoles, page, params.page, setPage])

    return <RolesListComponent {...props}/>;
}

const mapStateToProps = (store: RootState) => ({
    roles: store.roles.roles,
    total: store.roles.total,
    filters: store.roles.filters,
    params: store.roles.params,
    page: store.roles.page
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getRoles: () => dispatch(getRoles()),
        clearRoles: () => dispatch(clearRoles()),
        setFilters: (filters: RolesStateInterface["filters"], reloadList?: boolean) => dispatch(setFilters(filters, reloadList)),
        clearFilters: (reloadList?: boolean) => dispatch(clearFilters(reloadList)),
        setPage: (page: number) => dispatch(setPage(page)),
        setParams: (params: RolesStateInterface["params"], reloadList?: boolean) => dispatch(setParams(params, reloadList)),
        clearParams: (reloadList?: boolean) => dispatch(clearParams(reloadList)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesListContainer);