import React, { FC } from "react";
import _ from "lodash";
import DictService from "../../application/services/dict.service";
import CheckboxComponent from "../../common/components/checkbox.component";
import SelectComponent from "../../common/components/select.component";
import TextInputComponent from "../../common/components/textInput.component";
import { ProductsFiltersPropsInterface } from "../types/product.types";

import collapseIcon from "../../common/images/ico-zwin.svg";
import closeIcon from "../../common/images/ico-x.svg";
import clearIcon from "../../common/images/ico-wyczysc.svg";
import CategoryFiltersComponent from "../../common/components/categoryFilters.component";
import { useHistory } from "react-router";

const ProductsFiltersComponent: FC<ProductsFiltersPropsInterface> = ({
  filters,
  categories,
  onFiltersChange,
  onClearFilters,
}: ProductsFiltersPropsInterface) => {
  const history = useHistory();
  const getProductDict = (type: number) => {
    let dict = DictService.getDict(type)?.values || [];

    if (dict.length > 0) {
      return dict.map(({ name: label, value }) => ({
        label,
        value: value.toString(),
      }));
    }

    return [{ label: "", value: "" }];
  };

  const handleFilterChange = (field: string, value: any) => {
    let newFilters = Object.assign({}, filters, {
      [field]: value,
    });
    history.push("/products/page/1");
    onFiltersChange(newFilters);
  };

  const clearCategories = (categories: any) => {
    categories = categories.map((category: any) => {
      if (category.checked) category.checked = false;
      if (_.size(category.children) > 0) clearCategories(category.children);
      return category;
    });
  };

  const handleFiltersReset = () => {
    let nameInput: any = document.getElementById("name");
    if (nameInput) {
      nameInput.value = "";
    }
    let EANInput: any = document.getElementById("ean");
    if (EANInput) {
      EANInput.value = "";
    }
    let codeInput: any = document.getElementById("code");
    if (codeInput) {
      codeInput.value = "";
    }
    let typeInput: any = document.getElementById("type");
    if (typeInput) {
      typeInput.value = "";
    }
    let sourceInput: any = document.getElementById("source");
    if (sourceInput) {
      sourceInput.value = "";
    }
    let activeInput: any = document.getElementById("active");
    if (activeInput) {
      activeInput.checked = "";
    }
    let isGratisInput: any = document.getElementById("isGratis");
    if (isGratisInput) {
      isGratisInput.checked = "";
    }
    let PAOInput: any = document.getElementById("PAO");
    if (PAOInput) {
      PAOInput.checked = "";
    }
    clearCategories(categories);
    history.push("/products/page/1");
    onClearFilters();
  };

  const handleCollapseClick = () => {
    $('#filter-sidebar').toggleClass("toggled");
    $('#filter-toggle').toggleClass("toggled");
    if ($('#filter-toggle').hasClass('toggled')) {
      $('#filter-list').addClass("fadeOut");
      $('#filter-list').removeClass("fadeIn");
    } else {
      $('#filter-list').addClass("fadeIn");
      $('#filter-list').removeClass("fadeOut");
    }
    $('#content-container-right').toggleClass("col-md-12");
  }

  return (
    <div id="filter-sidebar" className="col-md-3 col-sm-12 bg-white py-4 px-4 d-flex flex-column">
      <div id="filter-toggle" className="filter-collapse" onClick={handleCollapseClick}>
        <img src={collapseIcon} alt="collapse" />
      </div>

      <h3 className="mb-4">
        Filtry
        <div id="filter-toggle3">
          <img src={closeIcon} alt="close" />
        </div>
      </h3>

      <div id="filter-list" className="animated">
        <TextInputComponent
          id="name"
          defaultValue={filters.name}
          onChange={handleFilterChange}
          label="Nazwa produktu"
          placeholder="Szukaj"
          debounce={1000}
        />

        <div className="col-sm-12 px-0">
          <CategoryFiltersComponent
            categories={categories}
            filters={filters}
            onCategoryChange={(filters: any) => onFiltersChange(filters)}
          />
        </div>

        <TextInputComponent
          id="ean"
          defaultValue={filters.ean}
          onChange={handleFilterChange}
          label="EAN"
          placeholder="Szukaj"
          debounce={1000}
        />

        <TextInputComponent
          id="code"
          defaultValue={filters.code}
          onChange={handleFilterChange}
          label="Kod produktu"
          placeholder="Szukaj"
          debounce={1000}
        />

        <div className="row my-0">
          <div className="col-sm-12">
            <SelectComponent
              id="type"
              onChange={handleFilterChange}
              label="Typ produktu"
              options={getProductDict(1)}
            />
          </div>

          <div className="col-sm-12">
            <SelectComponent
              id="source"
              onChange={handleFilterChange}
              label="Źródło produktu"
              options={getProductDict(4)}
            />
          </div>

          <div className="col-sm-12 d-flex flex-wrap">
            <div className="col-md-4 col-4">
              <CheckboxComponent
                onChange={handleFilterChange}
                checked={filters.active || false}
                label="Aktywny"
                id="active"
                customClass="custom-control custom-checkbox text-left"
              />
            </div>

            <div className="col-md-4 col-4">
              <CheckboxComponent
                onChange={handleFilterChange}
                checked={filters.isGratis || false}
                label="Gratis"
                id="isGratis"
                customClass="custom-control custom-checkbox text-center"
              />
            </div>

            <div className="col-md-4 col-4">
              <CheckboxComponent
                onChange={handleFilterChange}
                checked={filters.PAO || false}
                label="PAO"
                id="PAO"
                customClass="custom-control custom-checkbox text-center"
              />
            </div>
          </div>
        </div>

        <div
          className="filter-clear float-right color-gray align-self-end"
          onClick={handleFiltersReset}
        >
          <p>
            Wyczyść wszystko <img src={clearIcon} alt="clear" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductsFiltersComponent;
