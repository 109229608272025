import { FC, Fragment, useEffect } from "react";

import { connect } from 'react-redux';
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { AppDispatch, RootState } from "../../config/store";
import { getWarehouses, setFilters, clearFilters, setPage, setParams, clearParams } from "../actions/warehouse.actions";
import { WarehousesPropsInterface, WarehouseStateInterface } from "../types/warehouse.types";

import ListComponent from "../../common/components/list.component";

import WarehouseListCardComponent from "../components/WarehouseListCard.component";
import WarehousesFiltersComponent from "../components/WarehousesFilters.component";

import addIcon from "../../common/images/ico-plus.svg"

const WarehousesContainer: FC<WarehousesPropsInterface> = ({ getWarehouses, setFilters, clearFilters, setPage, setParams, warehouses, total, params, page, filters }: WarehousesPropsInterface) => {
    const pageParams: any = useParams();

    useEffect(() => {
        setPage(parseInt(pageParams.page));
        getWarehouses();
    }, [page, pageParams.page, getWarehouses, setPage])

    const getColumns = () => {
        return [
            {
                key: "",
                label: ""
            },
            {
                key: "name",
                label: "Nazwa magazynu"
            },
            {
                key: "code",
                label: "Kod magazynu",
                disableSort: true
            },
            {
                key: "positionsTotal",
                label: "Ilość pozycji",
                disableSort: true
            },
        ]
    }

    return (

        <Fragment>
            {/* Header */}
            <div className="row px-0 mx-0 title-bar d-flex flex-nowrap justify-content-between align-items-center">
                <div className="col-sm-12 d-flex flex-no-wrap px-4 justify-content-between align-items-center">
                    <div className="page-title d-flex flex-nowrap align-items-center">
                        <h2 id="page-title" className="m-0">Magazyny</h2>
                    </div>
                    <div className="col-md-6 px-0 d-flex flex-no-wrap justify-content-end align-items-center">
                        <Link type="button" className="btn btn-primary btn-wms btn-expand" to="/warehouse"><img src={addIcon} alt="dodaj" /><div>Dodaj</div></Link>
                    </div>
                </div>
            </div>

            <div className="container mw100vw content-container ">
                <div className="row">
                    <div id="content-container-right" className="col-md-12 col-sm-12 scrolling py-4 pl-4 pr-2">
                        {/* Filters */}
                        <WarehousesFiltersComponent
                            filters={filters}
                            onClearFilters={() => clearFilters(true)}
                            onFiltersChange={(filters: any) => setFilters(filters, true)}
                        />
                        {/* List */}
                        <ListComponent
                            id="table-warehouse"
                            items={warehouses}
                            total={total}
                            itemsOnPage={params.limit}
                            ItemCard={WarehouseListCardComponent}
                            currentPage={page}
                            currentSort={params.sort}
                            route="/warehouses"
                            columns={getColumns()}
                            onPageChange={setPage}
                            onSortChange={(sortKey: string, sortValue: number) => setParams(Object.assign({}, params, { sort: { [sortKey]: sortValue } }), true)}
                        />
                    </div>
                </div>
            </div>

        </Fragment>



    )
}

const mapStateToProps = (store: RootState) => ({
    warehouses: store.warehouse.warehouses,
    total: store.warehouse.total,
    params: store.warehouse.params,
    page: store.warehouse.page,
    filters: store.warehouse.filters,
});

const mapDispatchToProps = (dispatch: AppDispatch): any => {
    return {
        getWarehouses: () => dispatch(getWarehouses()),
        setFilters: (filters: WarehouseStateInterface["filters"], reloadList?: boolean) => dispatch(setFilters(filters, reloadList)),
        clearFilters: (reloadList?: boolean) => dispatch(clearFilters(reloadList)),
        setPage: (page: number) => dispatch(setPage(page)),
        setParams: (params: WarehouseStateInterface["params"], reloadList?: boolean) => dispatch(setParams(params, reloadList)),
        clearParams: (reloadList?: boolean) => dispatch(clearParams(reloadList)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WarehousesContainer);