import { SettingsStateInterface, SettingsActionType } from "../types/settings.types";

const initialState: SettingsStateInterface = {
    settings: null
}

const settingsReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case SettingsActionType.SET_SETTINGS: {
            return {
                ...state,
                settings: action.settings
            }
        }
        case SettingsActionType.CLEAR_SETTINGS: {
            return {
                ...state,
                settings: null
            }
        }
        default: return state;
    }
}

export default settingsReducer;