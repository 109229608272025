import { AnyAction } from "redux";
import { ApplicationStateInterface } from "../../application/types/application.types";
import { AsyncThunkAction, Nullable, UserInterface } from "../../common/types/common.types";

export enum UsersActionType {
    SET_USERS = 'USERS_SET_USERS',
    CLEAR_USERS = 'USERS_CLEAR_USERS',
    SET_FILTERS = 'USERS_SET_FILTERS',
    CLEAR_FILTERS = 'USERS_CLEAR_FILTERS',
    SET_PARAMS = 'USERS_SET_PARAMS',
    CLEAR_PARAMS = 'USERS_CLEAR_PARAMS',
    SET_PAGE = `USERS_SET_PAGE`
}

export enum UserActionType {
    SET_USER = 'USERS_SET_USERS',
    CLEAR_USER = 'USERS_CLEAR_USERS'
}

export interface UsersStateInterface {
    users: Array<UserInterface>,
    total: number,
    filters: {
        name: string,
        warehouse: string,
        role: string,
        active: Nullable<boolean>
    },
    params: {
        limit: number,
        offset: number,
        sort: Nullable<{ [fieldname: string]: number }>
    },
    page: number
}

export interface UserStateInterface {
    user: Nullable<UserInterface>
}

export interface UsersPropsInterface {
    users: UsersStateInterface["users"],
    total: UsersStateInterface["total"],
    filters: UsersStateInterface["filters"],
    params: UsersStateInterface["params"],
    page: number,
    activeUsers: ApplicationStateInterface["activeUsers"],
    getUsers: () => AsyncThunkAction,
    clearUsers: () => AnyAction,
    setFilters: (filters: UsersStateInterface["filters"], reloadList?: boolean) => AsyncThunkAction,
    clearFilters: (reloadList?: boolean) => AsyncThunkAction,
    setParams: (params: UsersStateInterface["params"], reloadList?: boolean) => AsyncThunkAction,
    clearParams: (reloadList?: boolean) => AsyncThunkAction,
    setPage: (page: number) => AnyAction
}

export interface UserPropsInterface {
    user: UserStateInterface["user"],
    getUser: (id: string) => AsyncThunkAction,
    clearUser: () => AnyAction,
    saveUser: (data: UserStateInterface["user"]) => Promise<any>,
    deleteUser: (id: string) => Promise<any>
}

export interface UsersListCardPropsInterface {
    item: UserInterface
}

export interface UsersFiltersPropsInterface {
    onFiltersChange: (filters: UsersStateInterface["filters"]) => void,
    onClearFilters: () => void,
    filters: any
}
