import React, { FC } from "react";
import { SelectComponentPropsInterface } from "../types/component.types";

const SelectComponent: FC<SelectComponentPropsInterface> = ({
    error,
    label,
    id,
    disabled = false,
    customClass,
    value,
    options,
    onChange
}: SelectComponentPropsInterface) => {

    const renderOptions = () => {
        let arr = [<option key={`${id}blank-option`} value="">Wybierz</option>]
        if(options){
            options.forEach((option, index) => {
                arr.push(<option key={`${id}blank-${index}`} value={option.value} className={option.customClass || ""}>{option.label}</option>)
            });
        }
    
        return arr;
    }

    return (<div className={customClass ? customClass : "form-group input-group"}>
        <label className="form-group has-float-label mb-1 mx-0">
            <select
                className={`selectpicker form-control custom-select ${error ? 'is-invalid' : ''}`}
                title="Wybierz"
                disabled={disabled}
                id={id}
                onChange={(e) => onChange(id, e.target.value)}
                value={value}
            >
                {renderOptions()}
            </select>
            <span>{label}</span>
        </label>
        {error && <span className="is-invalid-alert">{error.toString()}</span>}
    </div>);
}

export default SelectComponent;