import { ProfileStateInterface, ProfileActionType } from "../types/profile.types";

const initialState: ProfileStateInterface = {
    profile: null
}

const profileReducer = (state = initialState, action: any) => {
    const { type } = action;
    switch (type) {
        case ProfileActionType.SET_PROFILE: {
            return {
                ...state,
                profile: action.profile
            }
        }
        case ProfileActionType.CLEAR_PROFILE: {
            return {
                ...state,
                profile: null
            }
        }
        default: return state;
    }
}

export default profileReducer;