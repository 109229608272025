import { FC } from "react";
import { ZpListCardPropsInterface } from "../types/zp.types";
import { useHistory } from "react-router-dom"
import _ from "lodash";

const ZpListCardComponent: FC<ZpListCardPropsInterface> = ({ item }: ZpListCardPropsInterface) => {

    let history = useHistory();

    return (<tr onClick={() => history.push(`/zps/${item._id}`)}>
        <td>
            {_.get(item, "documentNumber", "")}
        </td>
        <td>{_.get(item, "creator.name", "")}</td>
        <td>
            {_.get(item, "positionFrom.code", "")}
        </td>
        <td>
            {_.get(item, "positionTo.code", "")}
        </td>
    </tr>);
}

export default ZpListCardComponent;