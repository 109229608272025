import React, { FC, Fragment } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import SidebarComponent from "../components/sidebar.component";
import TopbarContainer from "./topbar.container";
import UsersContainer from "../../user/containers/usersList.container";
import UserDetailsContainer from "../../user/containers/userDetails.container";
import UserContainer from "../../user/containers/user.container";
import RolesListContainer from "../../role/containers/rolesList.container";
import RoleContainer from "../../role/containers/role.container";
import ProfileContainer from "../../profile/containers/profile.container";
import ProductsContainer from "../../product/containers/productsList.container";
import ProductDetailsContainer from "../../product/containers/productDetails.container";
import ProductContainer from "../../product/containers/product.container";

import GeneratorContainer from "../../generator/containers/generator.container";
import BarcodeContainer from "../../generator/containers/barcode.container";
import InventoriesListContainer from "../../inventory/containers/inventoriesList.container";
import InventoryContainer from "../../inventory/containers/inventory.container";
import InventoryDetailsContainter from "../../inventory/containers/inventoryDetails.containter";
import SettingsContainer from "./settings.container";
import DictsContainer from "./dicts.container";

// Warehouse
import WarehousesContainer from "../../warehouse/containers/warehouses.container";
import WarehouseContainer from "../../warehouse/containers/warehouse.container";
import WarehouseDetailsContainer from "../../warehouse/containers/WarehouseDetails.container";
import PositionContainer from "../../warehouse/containers/Position.container";
import PositionDetailsContainer from "../../warehouse/containers/PositionDetailsContainer";
import DocumentsListContainer from "../../document/containers/documentsList.container";
import DocumentDetailsContainer from "../../document/containers/documentDetails.container";
import DashboardContainer from "../../dashboard/containers/dashboard.container";
import ZpDetailsContainer from "../../zp/containers/zpDetails.container";
import ZpListContainer from "../../zp/containers/zpList.container";
import HelpDeskContainer from "./helpdesk.container";
import ReportsContainer from "../../reports/containers/reports.container";

const Root: FC = () => {
    return (
        <Fragment>
            <Router>
                <SidebarComponent />
                <div className="col-md-11 px-0">
                    <TopbarContainer />
                    <Fragment>
                        <Route path="/" exact={true} component={DashboardContainer} />
                        <Route path="/profile" exact={true} component={ProfileContainer} />
                        <Route path="/settings" exact={true} component={SettingsContainer} />
                        <Route path="/dicts" exact={true} component={DictsContainer} />

                        <Route path="/products/page/:page" exact={true} component={ProductsContainer} />
                        <Route path="/products/:id" exact={true} component={ProductDetailsContainer} />
                        <Route path="/product/:id?" exact={true} component={ProductContainer} />

                        <Route path="/users/page/:page" exact={true} component={UsersContainer} />
                        <Route path="/users/:id" exact={true} component={UserDetailsContainer} />
                        <Route path="/user/:id?" exact={true} component={UserContainer} />

                        <Route path="/roles/page/:page" exact={true} component={RolesListContainer} />
                        <Route path="/role/:id?" exact={true} component={RoleContainer} />

                        <Route path="/generator/page/:page" exact={true} component={GeneratorContainer} />
                        <Route path="/barcode" exact={true} component={BarcodeContainer} />

                        <Route path="/inventories/page/:page" exact={true} component={InventoriesListContainer} />
                        <Route path="/inventories/:id" exact={true} component={InventoryDetailsContainter} />
                        <Route path="/inventory/:id?" exact={true} component={InventoryContainer} />
                        {/* Warehouse */}
                        <Route path="/warehouses/page/:page" exact={true} component={WarehousesContainer} />
                        <Route path="/warehouse/:id?" exact={true} component={WarehouseContainer} />
                        <Route path="/warehouses/:id?/page/:page" exact={true} component={WarehouseDetailsContainer} />
                        <Route path="/position/:warehouseId/:id?" exact={true} component={PositionContainer} />
                        <Route path="/positions/:id?/page/:page" exact={true} component={PositionDetailsContainer} />

                        <Route path="/documents/page/:page" exact={true} component={DocumentsListContainer} />
                        <Route path="/documents/:id" exact={true} component={DocumentDetailsContainer} />

                        <Route path="/zps/page/:page" exact={true} component={ZpListContainer} />
                        <Route path="/zps/:id" exact={true} component={ZpDetailsContainer} />
                        <Route path='/issueReport' exact={true} component={HelpDeskContainer} />
                        <Route path="/reports" exact={true} component={ReportsContainer} />

                    </Fragment>
                </div>
            </Router>
        </Fragment>
    )
}

export default Root;